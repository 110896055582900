import React from 'react';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KTCaseBillabilityTeamPlaceholder = props => {
  return (
    <>
      {[...Array(5)].map((e, i) => (
        <KtLabelWithSkeleton
          isLoading={props.isLoading}
          showLabelGraphValue
          size={props.width}
          key={i}
        />
      ))}
    </>
  );
};
export default KTCaseBillabilityTeamPlaceholder;
