/* eslint-disable func-names */
import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
} from './actionTypes';

import { batch } from 'react-redux';
import { beginAPICall, endAPICall } from './apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { fetchPost } from 'utils/KtCommonServices';
import CONFIG from 'config/configProps';

const MESSAGES = {
  GROUP: 'FILTERS',
  ERROR: 'Network error, please try again later',
};

/**
 * Action Creator: Seed Data fetch Success
 * @param {*} data data received from API
 * @param {*} apiIdentifier API identifier
 */
function seedDataFetchSuccess(data, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_SUCCESS,
    id: apiIdentifier,
    results: data,
  };
}
/**
 * Action Creator: Seed Data Fetch Failure
 * @param {*} error error received from API
 * @param {*} apiIdentifier  API identifier
 */
function seedDataFetchFailure(error, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_FAILURE,
    id: apiIdentifier,
    error,
  };
}

/**
 * THUNK: fetch seed data for ALL Filters for Business Comparison
 */
function fetchSeedDataFilters({
  fetchPaList,
  fetchSectorList,
  fetchSubSectorList,
  fetchGeoMarketList,
  fetchMemberList,
  fetchPathList,
  fetchManagedByList,
  postData,
}) {
  return async function (dispatch) {
    batch(() => {
      // batch all Seed Data Calls dispatch as ONE
      fetchPaList && dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_PA_LIST));
      fetchSectorList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_SECTOR_LIST));
      fetchSubSectorList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_SUB_SECTOR_LIST));
      fetchGeoMarketList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_GEO_MARKET_LIST));
      fetchMemberList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_MEMBER_LIST));
      fetchPathList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_PATH_LIST));
      fetchManagedByList &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_MANAGED_BY_LIST));
    });

    fetchPaList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_PA_LIST);
    fetchSectorList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_SECTOR_LIST);
    fetchSubSectorList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_SUB_SECTOR_LIST);
    fetchGeoMarketList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_GEO_MARKET_LIST);
    fetchPathList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_PATH_LIST);
    fetchManagedByList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_MANAGED_BY_LIST);
    fetchMemberList &&
      fetchList(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_MEMBER_LIST);
  };
}

/**
 * Method to check if 'error' is being set from ServiceLayer. If yes, dispatch API-FAIL action
 * @param {*} param0 error
 * @param {*} apiIdentifier API Identifier
 * @param {*} dispatch dispatch Ref
 */
function isErroneousResponse({ error }) {
  if (error) {
    throw new Error(`${MESSAGES.ERROR}`);
  }
  return false;
}

async function fetchList(dispatch, data, apiIdentifier) {
  let endpoint;
  let addValues = false;
  const baseUrl = CONFIG.KT_FILTERS_API_URL;
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_PA_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.PA_LIST;
      addValues = true;
      break;
    case API_CALL_IDENTIFIERS.FETCH_SECTOR_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.SECTOR_LIST;
      break;
    case API_CALL_IDENTIFIERS.FETCH_SUB_SECTOR_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.SUB_SECTOR_LIST;
      break;
    case API_CALL_IDENTIFIERS.FETCH_GEO_MARKET_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.GEO_MARKET_LIST;
      break;
    case API_CALL_IDENTIFIERS.FETCH_MEMBER_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.MEMBER_LIST;
      break;
    case API_CALL_IDENTIFIERS.FETCH_PATH_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.PATH_LIST;
      break;
    case API_CALL_IDENTIFIERS.FETCH_MANAGED_BY_LIST:
      endpoint = CONFIG.BUSINESS_COMPARISON_API_URLS.MANAGED_BY;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchPost(endpoint, data, baseUrl);
    if (!isErroneousResponse(response)) {
      return dispatch(
        seedDataFetchSuccess(
          addValues ? getPaData(response.data) : response.data,
          apiIdentifier
        )
      );
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

function getPaData(data) {
  const additionalData = [
    { paId: '-11', practiceArea: 'All FPA', taxonomy: '-11' },
    { paId: '-111', practiceArea: 'All IPA', taxonomy: '-111' },
  ];
  return additionalData.concat(data);
}

export const bcFilterActions = {
  fetchSeedDataFilters,
};
