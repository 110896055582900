/* eslint-disable react/jsx-no-bind */
import { Button, Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'redux/store';
import { fetchDeleteWithId } from 'utils/KtCommonServices';
import KTCheckbox from 'components/common/KTCheckbox';
import { updateToastNotificationDetails } from 'redux/actions';
import {
  getApiFailMessage,
  isImpersonatorRole,
  isPaAdminRole,
  isSuperAdminRole,
} from 'utils';
import { isEqual, isEmpty } from 'utils/common';
import CONFIG_KTS from 'config/configProps';
import './KtPaAdminConfirmationPopup.scss';

class KtPaAdminConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: props.modalData,
      selectedAdminDeleteRoleId: [],
    };
  }

  checkboxChangeHandler = id => {
    let { selectedAdminDeleteRoleId } = this.state;
    let checkBoxId = `checkBox-${id}`;
    // updating array for selected role's id to be delete
    selectedAdminDeleteRoleId.indexOf(id) < 0
      ? selectedAdminDeleteRoleId.push(id)
      : selectedAdminDeleteRoleId.splice(
          selectedAdminDeleteRoleId.indexOf(id),
          1
        );
    this.setState({
      selectedAdminDeleteRoleId,
      [checkBoxId]: !this.state[checkBoxId],
    });
  };

  deleteRole = () => {
    const { dataRefresh, onHide } = this.props;
    let deleteUserIdArray = this.state.selectedAdminDeleteRoleId;
    deleteUserIdArray.forEach(item => {
      let roleIdToBeDelete = item;
      let deleteURI = `admin/${roleIdToBeDelete}`;
      const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
      fetchDeleteWithId(deleteURI, baseUrl)
        .then(() => {
          let displayNotification = true;
          let notificationMessage =
            'Selected role(s) deleted successfully. KT member will no more have access to the deleted Practice area, Topic/Sector or Sub/Topic Sector combination.';
          dataRefresh(displayNotification, notificationMessage);
          onHide();
        })
        .catch(() => {
          onHide();
          store.dispatch(
            updateToastNotificationDetails(getApiFailMessage(true))
          );
        });
    });
  };

  showDeleteIcon = (item, index) => {
    return (
      <div className="checkbox-delete-admin" key={index}>
        <KTCheckbox
          checked={this.state[`checkBox-${item.userId}`]}
          labelName={item.role}
          value={item.role}
          checkboxColor="dark-blue"
          handleChange={() => this.checkboxChangeHandler(item.userId)}
        />
        <div className="sector-sub-sector-txt">
          {item.practiceArea}/{item.sector}/{item.subSector}
        </div>
      </div>
    );
  };

  render() {
    const { modalData } = this.state;
    const {
      onHide,
      loggedInUserHighestRole,
      userTypes,
      ktPaPaList,
      loggedInUserEmailId,
    } = this.props;
    const popupClassName = `kt-admin-confirmation-popup`;
    const isPaAdmin = isSuperAdminRole(loggedInUserHighestRole);
    const isSuperAdmin = isPaAdminRole(loggedInUserHighestRole);
    const isImpersonator = isImpersonatorRole(loggedInUserHighestRole);
    let checkboxDetails = modalData.map((item, index) => {
      const isPaHasDeletePermission = isPaAdmin
        ? ktPaPaList.filter(paList =>
            isEqual(paList.practiceArea, item.practiceArea)
          )
        : [];
      const isUserSuperAdmin = isEqual(userTypes[0], item.role);
      const isUserPaAdmin = isEqual(userTypes[1], item.role);
      const isMember =
        isEqual(item.role, 'Team Member') ||
        isEqual(item.role, 'Geo Team Member');
      if (isMember) {
        return null;
      } else {
        return (
          <>
            {isImpersonator && this.showDeleteIcon(item, index)}
            {isPaAdmin &&
              !isUserSuperAdmin &&
              !isUserPaAdmin &&
              !isEmpty(isPaHasDeletePermission) &&
              this.showDeleteIcon(item, index)}
            {isPaAdmin &&
              isUserPaAdmin &&
              isEqual(item.creatorEmail, loggedInUserEmailId) &&
              this.showDeleteIcon(item, index)}
            {isSuperAdmin &&
              !isUserSuperAdmin &&
              this.showDeleteIcon(item, index)}
            {isSuperAdmin &&
              isUserSuperAdmin &&
              isEqual(item.creatorEmail, loggedInUserEmailId) &&
              this.showDeleteIcon(item, index)}
          </>
        );
      }
    });
    return (
      <>
        <Modal
          aria-labelledby="contained-modal-title-vCenter"
          centered
          show
          onHide={onHide}
          dialogClassName={popupClassName}
          className="kt-scorecard-delete-permission-modal"
        >
          <Modal.Header closeButton className="popup-heading">
            <Modal.Title id="contained-modal-title-vCenter">
              Delete Permissions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup-body">
            <div className="delete-permission-txt">
              <span className="member-name">{modalData[0].userName}</span> has
              following role affiliations with Practice Area,Topic/Sector and
              Sub-Topic/Sub-Sector. Please select a record to delete user
              access.
            </div>
            <div className="permission-section">{checkboxDetails}</div>
          </Modal.Body>
          <Modal.Footer className="popup-footer">
            <Button
              variant="secondary popup-button button-cancel"
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button
              variant="primary popup-button button-save"
              onClick={this.deleteRole}
            >
              <span>Confirm</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    userTypes: reduxStore.userRoleData.userTypes,
    ktPaPaList: reduxStore.headerFilterData.ktPaPaList,
  };
};

export default connect(mapStateToProps)(KtPaAdminConfirmationPopup);
