import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
  SAVE_APPLIED_FILTERS,
  UPDATE_IS_FILTER_OPEN,
  UPDATE_LAST_UPDATED_DATE,
  IS_MEMBER_FILTER_SELECTED,
  SAVE_PA_TOPICS_SUBTOPICS,
  UPDATE_FILTER_STATE,
  UPDATE_FILTER_STATE_BC,
  SAVE_APPLIED_FILTERS_BC,
  UPDATE_SELECTED_DATE,
  SAVE_APPLIED_COLLECTION,
  SAVE_SELECTED_DATA_FOR_FILTERS,
} from 'redux/actions/actionTypes';

import InitialState from './InitialState';
const STATE_SLICE_NAME = 'filterDataPt'; // must match the INitial State Key

/**
 * This reducer will return the state-slice for seed data (filter values)
 * When the action.type is :
 * - FILTER_DATA_FETCH_FAILURE  the action will have  property 'id' and 'error'
 * -  FILTER_DATA_FETCH_SUCCESS the action will have  property 'id' and 'results'
 * . These are pssed along from Dispatcher (Action)
 * @param {*} state state object.
 * @param {*} action action object
 */
export function filterSectionReducerPt(
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case FILTER_DATA_FETCH_SUCCESS: {
      const newState = { ...state };
      newState[action.id] = { results: action.results, error: '' };
      return newState;
    }

    //invoked when case search api call is failure
    case FILTER_DATA_FETCH_FAILURE: {
      const newState = { ...state };
      newState[action.id] = { results: [], error: action.error };
      return newState;
    }

    case SAVE_APPLIED_FILTERS:
      //set the filters applied for this API call
      return {
        ...state,
        filters: action.filters,
      };

    case UPDATE_IS_FILTER_OPEN:
      //set the filters state
      return {
        ...state,
        isFilterOpen: action.isFilterOpen,
      };

    case UPDATE_LAST_UPDATED_DATE:
      //set the filters state
      return {
        ...state,
        lastUpdatedDate: action.lastUpdatedDate,
      };

    case IS_MEMBER_FILTER_SELECTED:
      return {
        ...state,
        isMemberFilterSelected: action.isMemberFilterSelected,
      };

    case SAVE_PA_TOPICS_SUBTOPICS:
      return {
        ...state,
        paTopicsSubtopics: action.paTopicsSubtopics,
      };

    case UPDATE_FILTER_STATE:
      return {
        ...state,
        filterState: action.filterState,
      };

    case UPDATE_FILTER_STATE_BC:
      return {
        ...state,
        filterStateBc: action.filterStateBc,
      };

    case SAVE_APPLIED_FILTERS_BC:
      //set the filters applied for this API call
      return {
        ...state,
        filtersBc: action.filtersBc,
      };

    case UPDATE_SELECTED_DATE:
      return {
        ...state,
        selectedDateFilter: action.selectedDateFilter,
      };

    case SAVE_APPLIED_COLLECTION:
      return {
        ...state,
        appliedCollection: action.collection,
      };

    case SAVE_SELECTED_DATA_FOR_FILTERS:
      return {
        ...state,
        selectedFiltersData: action.selectedFiltersData,
      };

    //default: return existing state
    default:
      return state;
  }
}

export const selectors = {
  /**
   * Get Seed Data results for specific API Identifier
   */
  getResults: (appState, apiIdentifier) => {
    return (
      appState[STATE_SLICE_NAME][apiIdentifier] &&
      appState[STATE_SLICE_NAME][apiIdentifier].results
    );
  },
  /**
   * Get Errors occured during API Call for a specific API Identifier
   */
  getError: (appState, apiIdentifier) =>
    appState[STATE_SLICE_NAME][apiIdentifier] &&
    appState[STATE_SLICE_NAME][apiIdentifier].error,
};
