/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import store from 'redux/store';
import KtKnPlaceholder from './KtKnPlaceholder';
import { showTooltipPopup } from 'redux/actions';
import Icon from 'components/common/Icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isEqual } from 'utils/common';

class KTKnDeck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: this.props.apiData,
    };
  }

  /**
   * React lifecycle methods
   */

  static getDerivedStateFromProps(props, state) {
    if (state.apiData !== props.apiData) {
      return {
        apiData: props.apiData,
      };
    }
    return null;
  }

  stopEventPropagation(event) {
    event.stopPropagation();
  }

  showTooltipModal = () => {
    const { category } = this.props;
    store.dispatch(
      showTooltipPopup({ showTooltip: true, tooltipName: category })
    );
  };

  renderToolTip = () => {
    const { InfoText } = this.props;
    return InfoText ? (
      <div
        className="tooltip-with-icon homeTileToolTip"
        onClick={this.showTooltipModal}
      >
        <Icon
          name="info-tableau"
          class="info-icon"
          alt="infoIcon"
          src="/img/info-tableau.svg"
        />
      </div>
    ) : null;
  };
  renderHighlights = knDecksOtherContent => {
    const { isManager } = this.props;
    const highlightsClassName = isManager
      ? 'kn-highlights-div'
      : 'kn-highlights-div member';

    return knDecksOtherContent?.Highlights?.length > 0 ? (
      <div className={highlightsClassName}>
        <hr className="kn-separator" />
        <div className="kn-decks-highlights">HIGHLIGHTS</div>
        {knDecksOtherContent.Highlights.map((el, index) => {
          return (
            <div key={index} style={{ display: 'flex' }}>
              <div>
                <Icon
                  name="kt-highlights"
                  class="kt-highlights-star"
                  alt="kt-highlights"
                  src="/img/kt-highlights.svg"
                />
              </div>
              <div className="highlights-section">
                <div className="highlights-value">
                  {el.fileName.length > 68 ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Popover id="tooltip-basic">
                          <Popover.Content>{el.fileName}</Popover.Content>
                        </Popover>
                      }
                    >
                      <div>
                        <div>
                          {el.fileName.substring(0, 68)}...
                          <span>{` | ${el.lastUpdatedBy}`}</span>
                        </div>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <span>{`${el.fileName} | ${el.lastUpdatedBy}`}</span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  };

  renderTotal = knDecksOtherContent => {
    return knDecksOtherContent.hasOwnProperty('Total') ? (
      <div className="body-heading">
        <div className="totalMetricName content-padding">{`Total`}</div>
        <div className="totalMetricValue content-padding">
          {knDecksOtherContent.Total}
        </div>
      </div>
    ) : null;
  };

  render() {
    const { className, category, isLoading } = this.props;
    const { apiData } = this.state;
    const fields = Object.keys(apiData);
    const knDecksOtherContent = isEqual(category, 'KN Decks')
      ? apiData[fields[0]]
      : apiData[fields[1]];

    return (
      <div className={className}>
        <div className="card-heading border-bottom height9">
          <span className="heading-text">{category}</span>
          {this.renderToolTip()}
        </div>

        {!isLoading ? (
          <>
            {knDecksOtherContent && (
              <div className="tableau-card-body">
                {this.renderTotal(knDecksOtherContent)}
                <hr className="kn-separator" />
                {Object.keys(knDecksOtherContent.CategoryTypes).map(
                  (el, index) => {
                    return (
                      <div
                        className="body-content content-body-padding"
                        key={index}
                      >
                        <div className="metricName">{el}</div>
                        <div className="metricValue">
                          {knDecksOtherContent.CategoryTypes[el]}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            )}

            {knDecksOtherContent?.Updates?.length > 0 && (
              <>
                <div className="kn-decks-updates">UPDATES</div>

                {knDecksOtherContent.Updates.map((el, index) => {
                  const url = el.url.replace(/^www/, 'http://www');

                  return (
                    <div
                      key={index}
                      style={{ display: 'flex', padding: '0 0.5rem' }}
                    >
                      <div>
                        <Icon
                          name="comments_arrow"
                          class="right-arrow-home"
                          alt="comments_arrow"
                          src="/img/comments_arrow.svg"
                        />
                      </div>
                      <div className="updates-section">
                        <div>
                          <a
                            href={url}
                            className="update-value"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={this.stopEventPropagation}
                          >
                            {`${el.updateType} | ${el.lastUpdateDate}`}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {this.renderHighlights(knDecksOtherContent)}
          </>
        ) : (
          <KtKnPlaceholder isLoading={isLoading} section={category} />
        )}
      </div>
    );
  }
}

export default withRouter(KTKnDeck);
