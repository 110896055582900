import * as types from '../actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'headerFilterData';

const headerFilterReducer = function (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case types.SET_CALENDER_DROPDOWN_VALUE:
      return Object.assign({}, state, {
        calenderDropdownVal: action.data,
      });

    case types.INITIAL_DATE:
      return Object.assign({}, state, {
        initialDate: action.data,
      });

    case types.UPDATE_SELECTED_SECTION_DETAILS:
      return Object.assign({}, state, {
        selectedSectionDetails: action.data,
      });

    case types.UPDATE_SELECTED_SECTION_DETAIL_VALUE:
      return Object.assign({}, state, {
        selectedSectionDetailOptionValue: action.data,
      });

    case types.UPDATE_SECTOR_DETAILS:
      return Object.assign({}, state, {
        selectedSectorDetailsFromHeader: action.data,
      });

    case types.UPDATE_SUB_SECTOR_DETAILS:
      return Object.assign({}, state, {
        selectedSubSectorDetailsFromHeader: action.data,
      });

    case types.UPDATE_MANAGED_BY: {
      return Object.assign({}, state, {
        managedBy: action.managedBy,
      });
    }

    case types.UPDATE_PATH: {
      return Object.assign({}, state, {
        path: action.path,
      });
    }

    case types.UPDATE_SELECTED_TEAM_MEMBERS: {
      return Object.assign({}, state, {
        selectedTeamMembers: action.selectedTeamMembers,
      });
    }

    case types.UPDATE_TEAM_MEMBERS_LIST: {
      return Object.assign({}, state, {
        memberList: action.memberList,
      });
    }

    case types.ALL_TEAM_MEMBER_SELECTED: {
      return Object.assign({}, state, {
        isAllSelected: action.isAllSelected,
      });
    }

    case types.UPDATE_DEFAULT_MEMBER_LIST: {
      return Object.assign({}, state, {
        defaultMemberList: action.defaultMemberList,
      });
    }

    case types.UPDATE_SA_PA_LIST: {
      return Object.assign({}, state, {
        ktSaPaList: action.paList,
      });
    }

    case types.SET_ALL_PA_LIST: {
      return Object.assign({}, state, {
        allPaList: action.paList,
      });
    }

    case types.UPDATE_PA_PA_LIST: {
      return Object.assign({}, state, {
        ktPaPaList: action.paList,
      });
    }

    case types.UPDATE_GEO_MARKET_LIST: {
      return Object.assign({}, state, {
        geoMarketList: action.geoMarketList,
      });
    }

    case types.UPDATE_FILTERS: {
      return Object.assign({}, state, {
        filters: action.filters,
      });
    }

    case types.IS_COLLECTION_APPLIED: {
      return Object.assign({}, state, {
        isCollectionApplied: action.value,
      });
    }

    case types.PASS_GEO_DETAILS: {
      return Object.assign({}, state, {
        passGeo: action.passGeo,
      });
    }

    case types.SET_PA_LIST: {
      return Object.assign({}, state, {
        paList: {
          ...state.paList,
          [action.geoRegion]: action.paList,
        },
      });
    }

    case types.SET_SECTOR_LIST: {
      return Object.assign({}, state, {
        sectorList: {
          ...state.sectorList,
          [action.selectedId]: action.sectorList,
        },
      });
    }

    case types.SET_SUB_SECTOR_LIST: {
      return Object.assign({}, state, {
        subSectorList: {
          ...state.subSectorList,
          [action.selectedId]: action.subSectorList,
        },
      });
    }

    default:
      return state;
  }
};

export default headerFilterReducer;
