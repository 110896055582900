import * as types from '../actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'tableauStackData';

const tableauStackReducer = function (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case types.UPDATE_TOTAL_RECORDS:
      return Object.assign({}, state, {
        totalRecords: action.data,
      });

    case types.UPDATE_API_REQUEST_DATA: {
      return Object.assign({}, state, {
        apiRequestData: action.apiRequestData,
      });
    }

    case types.LOAD_HOME_EDIT_PAGE: {
      return Object.assign({}, state, {
        loadHomeEditPage: action.loadHomeEditPage,
      });
    }

    case types.UPDATE_TABLEAU_FILTER_PARAMS: {
      return Object.assign({}, state, {
        tableauParamString: action.data,
      });
    }

    case types.UPDATE_TABLEAU_STRING: {
      return Object.assign({}, state, {
        tableauStringData: action.string,
      });
    }

    case types.UPDATE_BC_TABLEAU_PARAMS: {
      return Object.assign({}, state, {
        tableauParamsForBC: action.data,
      });
    }

    default:
      return state;
  }
};
export default tableauStackReducer;
