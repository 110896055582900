import React from 'react';
import { Table } from 'semantic-ui-react';

import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const TableLoader = ({ loading, columns }) => {
  return (
    <Table.Body>
      <Table.Row>
        {columns.map((columnConf, index) => {
          return (
            <Table.Cell key={`${columnConf?.dataField}-${index}`}>
              <KtLabelWithSkeleton isLoading={loading} showLine size="small" />
            </Table.Cell>
          );
        })}
      </Table.Row>
    </Table.Body>
  );
};

export default React.memo(TableLoader);
