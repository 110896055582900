import React from 'react';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KTCaseBillabilityLoader = props => {
  return (
    <>
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLabelValue
        size={props.width}
      />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLabelValue
        size={props.width}
      />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLabelValue
        size={props.width}
      />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLabelValue
        size={props.width}
      />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLabelValue
        size={props.width}
      />
    </>
  );
};
export default KTCaseBillabilityLoader;
