/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import store from 'redux/store';
import KtGrantPermissionModal from './KtGrantPermissionModal';
import KtPaAdminConfirmationPopup from 'components/common/KtPaAdminConfirmationPopup';
import { closeGrantModal } from 'redux/actions';
import KTCheckbox from 'components/common/KTCheckbox';
import CONFIG from 'config/configProps';
import './KtGrantDeletePermissionModal.scss';
import {
  hasBusinessComparisonRole,
  isAllowedRoleForBusinessComparison,
  isSuperAdminRole,
  isPaAdminRole,
  isPaAdminRoleId,
  isSuperAdminRoleId,
  isMemberRole,
  isImpersonatorRole,
} from 'utils';

class KtGrantDeletePermissionModal extends Component {
  constructor(props) {
    super(props);
    const { employeeInitialData, modalData } = props;
    this.state = {
      modalData: modalData,
      addMoreRoles: false,
      typeKt: modalData.typeKt,
      searchedUserDetails: modalData.searchedUserDetails,
      openModal: false,
      employeeName: employeeInitialData.employeeName,
      openModalDeleteConfirmation: false,
      carouselActiveIndex: 0,
      email: employeeInitialData.email,
    };
  }

  handleCarouselSelect = async selectedIndex => {
    this.setState({
      carouselActiveIndex: selectedIndex,
    });
  };

  /**
   * This methods closes the grant permission modal.
   */

  handlePermissionKtMemberClose = async () => {
    this.setState({
      modalData: {},
      addMoreRoles: false,
    });
    this.props.dataRefreshHandler();
    this.props.onHide();
  };

  handleCheckboxClick = async () => {
    const { modalData } = this.props;
    const { addMoreRoles } = this.state;
    this.setState({
      addMoreRoles: !addMoreRoles,
    });
    const allowBusinessComparison = modalData.searchedUserDetails.some(
      ({ roleId }) => isAllowedRoleForBusinessComparison(roleId)
    );
    let requireModelData = {};
    if (!addMoreRoles) {
      requireModelData.userId = modalData.userId
        ? modalData.userId
        : modalData.searchedUserDetails[0].employeedIdHRO;
      requireModelData.emailId = modalData.emailId ? modalData.emailId : '';
      requireModelData.fullName = modalData.fullName
        ? modalData.fullName
        : modalData.searchedUserDetails[0].userName;
      requireModelData.typeKt = modalData.typeKt;
      requireModelData.geoRegion = modalData.geoRegion
        ? modalData.geoRegion
        : modalData.searchedUserDetails[0].geoRegion;
      requireModelData.managedBy = modalData.managedBy
        ? modalData.managedBy
        : modalData.searchedUserDetails[0].managedBy;
      requireModelData.allowBusinessComparison = allowBusinessComparison;
      this.setState({
        openModal: !this.state.openModal,
        modalData: requireModelData,
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalData: {},
      openModal: false,
      addMoreRoles: !this.state.addMoreRoles,
    });
    this.props.onHide();
    this.props.dataRefreshHandler();
    if (this.props.closeGrantModal) {
      store.dispatch(closeGrantModal(false));
    }
  };

  /**
   * delete confirmation popup
   */

  closeModalDeleteConfirmation = () => {
    this.setState({ openModalDeleteConfirmation: false });
    this.props.onHide();
  };

  /**
   * Delete a certain role
   */
  onDeleteRole = () => {
    this.setState({ openModalDeleteConfirmation: true });
  };

  showDeleteIcon = searchedUserDetail => {
    const { employeeName } = this.state;
    const { loggedInUserHighestRole, ktPaPaList } = this.props;
    const isSuperAdmin = isSuperAdminRole(loggedInUserHighestRole);
    const isPaAdmin = isPaAdminRole(loggedInUserHighestRole);
    const isImpersonator = isImpersonatorRole(loggedInUserHighestRole);
    const isSelectedUserSuperAdmin = isSuperAdminRoleId(
      searchedUserDetail.roleId
    );
    const isSelectedUserPaAdmin = isPaAdminRoleId(searchedUserDetail.roleId);
    const isPaHasDeletePermission =
      isPaAdmin &&
      ktPaPaList.some(
        item => item.practiceArea === searchedUserDetail.practiceArea
      );
    const isTeamMember = isMemberRole(searchedUserDetail.role);

    let hasDeletePermission = true;

    if (isImpersonator) {
      hasDeletePermission = true;
    } else if (isTeamMember || !(isSuperAdmin || isPaAdmin || isImpersonator)) {
      hasDeletePermission = false;
    } else if (
      isSuperAdmin &&
      isSelectedUserSuperAdmin &&
      employeeName !== searchedUserDetail.createdBy
    ) {
      hasDeletePermission = false;
    } else if (
      !(
        isPaAdmin &&
        ((searchedUserDetail.roleId > 2 && !isPaHasDeletePermission) ||
          (isSelectedUserPaAdmin &&
            employeeName === searchedUserDetail.createdBy))
      )
    ) {
      hasDeletePermission = false;
    }

    return hasDeletePermission;
  };

  renderDeleteIcon = searchedUserDetail => {
    return (
      this.showDeleteIcon(searchedUserDetail) && (
        <span
          onClick={this.onDeleteRole}
          style={{
            flex: '1',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <img
            src="./img/kt-delete-icon.svg"
            className="delete-icon"
            alt="delete-icon"
          />
        </span>
      )
    );
  };

  createDropdown = ({
    fieldName,
    className,
    name,
    addHR,
    searchedUserDetail,
  }) => {
    const isBusinesscomparison = hasBusinessComparisonRole([
      searchedUserDetail,
    ]);
    if (fieldName !== 'role' && isBusinesscomparison) {
      return null;
    }

    return (
      <>
        <div className={`${className}`}>
          <select
            className={`${className}-item select-style`}
            name={name}
            value={searchedUserDetail[`${fieldName}`]}
          >
            <option
              key={searchedUserDetail[`${fieldName}Id`]}
              value={searchedUserDetail[`${fieldName}Id`]}
              selected={true}
            >
              {CONFIG.PA_NAME_ALIAS_MAP[searchedUserDetail[`${fieldName}`]] ||
                searchedUserDetail[`${fieldName}`] ||
                'NA'}
            </option>
          </select>
        </div>
        {!isBusinesscomparison && addHR && (
          <hr className="kt-dropdown-separator" />
        )}
      </>
    );
  };

  cardMarkup = searchedUserDetail => {
    const { addMoreRoles } = this.state;
    const dropdownList = [
      {
        fieldName: 'role',
        className: 'view-type-list',
        name: 'roleId',
        addHR: true,
      },
      {
        fieldName: 'practiceArea',
        className: 'pa-name-list',
        name: 'practiceAreaId',
      },
      {
        fieldName: 'sector',
        className: 'topic-name-list',
        name: 'topicSectorId',
      },
      {
        fieldName: 'subSector',
        className: 'sub-topic-name-list',
        name: 'topicSubSectorId',
      },
      {
        fieldName: 'geoRegion',
        className: 'geo-market-list',
        name: 'geoRegion',
      },
    ];

    return (
      <div>
        <div className="tile-modal-view-container">
          <div className="main-content">
            <div className="kt-modal-header">
              <div className="modal-heading">
                <span className="flex2">{searchedUserDetail.userName}</span>
                {this.renderDeleteIcon(searchedUserDetail)}
              </div>
            </div>
            {dropdownList.map(configProps =>
              this.createDropdown({
                ...configProps,
                searchedUserDetail,
              })
            )}
          </div>
        </div>

        <div className="kt-modal-buttons flex-end">
          <div className="kt-add-roles-checkbox">
            <KTCheckbox
              checked={addMoreRoles}
              labelName="Add More Roles"
              value="Add More Roles"
              checkboxColor="light-blue"
              handleChange={this.handleCheckboxClick}
            />

            <button
              className="kt-button cancel-button"
              onClick={this.handlePermissionKtMemberClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  carouselItemMarkup = () => {
    const { searchedUserDetails } = this.state;
    return searchedUserDetails.map(searchedUserDetail => {
      return (
        <Carousel.Item key={searchedUserDetails.userId}>
          {this.cardMarkup(searchedUserDetail)}
        </Carousel.Item>
      );
    });
  };

  getCardMarkup() {
    const { searchedUserDetails, carouselActiveIndex } = this.state;
    const { onHide } = this.props;
    return (
      <div>
        <div className="tile-modal-container">
          <Modal centered show onHide={onHide} className="kt-scorecard-modal">
            <div className="info-text">
              <span className="kt-download-icon" />
              <span className="info-text-1">
                Note: Team members will be provided editor access to their
                respective decks and not throughout the app.
              </span>
            </div>
            <Modal.Body className="tile-modal-view">
              {searchedUserDetails.length === 1
                ? this.cardMarkup(searchedUserDetails[0])
                : searchedUserDetails.length > 1 && (
                    <Carousel
                      className="kt-carousel-align-arrows"
                      interval={null}
                      activeIndex={carouselActiveIndex}
                      onSelect={this.handleCarouselSelect}
                    >
                      {this.carouselItemMarkup()}
                    </Carousel>
                  )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }

  render() {
    let cardMarkup = this.getCardMarkup();
    const {
      modalData,
      openModal,
      searchedUserDetails,
      openModalDeleteConfirmation,
      email,
    } = this.state;
    const { onHide, dataRefreshHandler, loggedInUserHighestRole } = this.props;
    return (
      <div>
        {cardMarkup}

        {openModal && (
          <KtGrantPermissionModal
            modalData={modalData}
            onHide={this.closeModal}
            closeBeneathModal={onHide}
            dataRefreshHandler={dataRefreshHandler}
          />
        )}
        {openModalDeleteConfirmation && (
          <KtPaAdminConfirmationPopup
            modalData={searchedUserDetails}
            onHide={this.closeModalDeleteConfirmation}
            dataRefresh={dataRefreshHandler}
            loggedInUserRole={loggedInUserHighestRole}
            loggedInUserEmailId={email}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    closeGrantModal: reduxStore.ktScorecard.closeGrantModal,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    ktUserDetails: reduxStore.userRoleData.ktUserDetails,
    userTypes: reduxStore.userRoleData.userTypes,
    ktPaPaList: reduxStore.headerFilterData.ktPaPaList,
  };
};
export default connect(mapStateToProps)(KtGrantDeletePermissionModal);
