import CONFIG from 'config/configProps';
import React, { useEffect, useState, useCallback } from 'react';
import WithTooltip from '../withTooltip';
import './PeopleImage.scss';

export default function PeopleImage({
  isSelected,
  hrId,
  imageTitle = 'image',
  primary,
  position,
  actions,
  children,
  peopleImageBytes,
  showToolTip,
  className,
}) {
  const { fetchPeopleImage } = actions;

  const [imageBytes, setImageBytes] = useState(
    peopleImageBytes ||
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUdFBZbl_qSONKU9QF_c_hIIpEJON0YLUnbLWQy78kLfa_rwZs_g'
  );

  //#region Use Effect
  useEffect(() => {
    const fetchImages = async () => {
      await fetchPeopleImage(hrId);
    };
    if (hrId && !peopleImageBytes) {
      fetchImages();
    }
  }, [hrId, peopleImageBytes, fetchPeopleImage]);

  useEffect(() => {
    if (peopleImageBytes) {
      setImageBytes(peopleImageBytes);
    }
  }, [peopleImageBytes]);

  //#endregion

  const handleOnClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      window.open(`${CONFIG.BCG_URL.STAFF_DETAIL_URL(hrId)}`, '_blank');
    },
    [hrId]
  );

  const showImage = React.useCallback(() => {
    const style = {
      backgroundImage: `url(${imageBytes})`,
    };
    return (
      <div
        onClick={hrId ? handleOnClick : null}
        className={`peopleImage_container ${isSelected ? 'selected' : ''} ${
          primary ? 'primary' : ''
        } ${hrId ? 'cursorPointer' : ''} ${className ? className : ''}`}
        style={style}
      >
        {children}
      </div>
    );
  }, [
    hrId,
    children,
    isSelected,
    className,
    primary,
    imageBytes,
    handleOnClick,
  ]);

  return React.useMemo(
    () =>
      showToolTip
        ? WithTooltip(showImage())(imageTitle, {
            inverted: true,
            size: 'small',
            position: position,
          })
        : showImage(),
    [imageTitle, showImage, position, showToolTip]
  );
}
