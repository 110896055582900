import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBackToListViewPage } from 'redux/actions';
import KtTableauLoader from 'components/common/TableauLoader';
import { getTableauUrl, getParameterByName, getEmpID } from 'utils';
import AuthorizeHOC from 'components/common/authorizeHOC';
import UnknownRoute from 'components/UnknownRoute/UnknownRoute';
import { globalAdobeAnalytics } from 'config/analytics';
import { isEmpty } from 'utils/common';
import BillabilityDownload from './BillabilityDownload';

let viz, workbook, activeSheet;
class TableauContainer extends Component {
  constructor(props) {
    super(props);
    props.dispatch(updateBackToListViewPage(true));
    this.state = {
      empInitialData: this.props.employeeInitialData,
      loggedInUserHighestRole: this.props.loggedInUserHighestRole,
      paramString: '',
      loadUnauthorized: false,
      isTableauReportLoaded: false,
      activeSheetName: '',
      activeSheetName2: '',
    };
  }

  /**
   *
   * React life cycle method
   */
  componentDidMount() {
    globalAdobeAnalytics('KTDashboard-Legacy-DetailedView');
    // default scrolling page to top
    window.scrollTo(0, 0);
    this.props.tableauParamString && this.initializeData();
  }

  componentDidUpdate(prevProps) {
    const {
      tableauParamString,
      loggedInUserHighestRole,
      match,
      selectedTeamMembers,
    } = this.props;
    const tableauParamsChanged =
      tableauParamString !== prevProps.tableauParamString;
    const selectedTeamMembersChanged =
      selectedTeamMembers !== prevProps.selectedTeamMembers;
    const loggedInUserHighestRoleChanged =
      loggedInUserHighestRole !== prevProps.loggedInUserHighestRole;
    if (loggedInUserHighestRoleChanged) {
      this.initializeData();
    } else if (tableauParamsChanged || selectedTeamMembersChanged) {
      if (viz) {
        this.changeParams(tableauParamString);
      } else {
        this.initializeData();
      }
    } else if (match.params.token !== prevProps.match.params.token) {
      this.getTableauUrl();
    }
  }

  componentWillUnmount() {
    if (viz) {
      viz.dispose();
    }
  }

  initializeData = () => {
    const { employeeInitialData, loggedInUserHighestRole } = this.props;

    this.setState(
      {
        empInitialData: employeeInitialData,
        loggedInUserHighestRole,
      },
      () => {
        this.getTableauUrl();
      }
    );
  };

  /**
   * update tableau url based on brower url
   */
  getTableauUrl = () => {
    const { match, loggedInUserHighestRole, filters, employeeInitialData } =
      this.props;
    const managedBy = !isEmpty(filters)
      ? filters.managedBy
      : getParameterByName('managedBy') || employeeInitialData?.managedBy;
    const urlParameter = getTableauUrl(
      match.params.token,
      loggedInUserHighestRole,
      managedBy
    );
    const { url, activeSheetName, activeSheetName2 } = urlParameter;
    if (url) {
      this.initialize(url, activeSheetName, activeSheetName2);
      this.setState({ activeSheetName, activeSheetName2 });
    } else {
      this.setState({ loadUnauthorized: true });
    }
  };

  initialize = tableauLink => {
    const { tableauParamString } = this.props;
    if (!tableauParamString) return;
    if (viz) {
      viz.dispose();
    }
    const vizContainer = document.getElementById('tableau-deep-dive');
    const options = {
      width: '1340px',
      height: '920px',
      hideToolbar: true,
      hideTabs: true,
      onFirstInteractive: () => {
        this.changeParams(tableauParamString);
        this.setState({
          isTableauReportLoaded: true,
        });
      },
    };

    viz = new window.tableau.Viz(vizContainer, tableauLink, options);
  };

  changeParams = paramString => {
    if (!viz || !paramString) {
      return;
    }

    setTimeout(() => {
      const {
        selectedTeamMembers,
        defaultMemberList,
        loggedInUserHighestRole,
        employeeInitialData,
      } = this.props;
      const { activeSheetName, activeSheetName2 } = this.state;
      const empId = getEmpID(loggedInUserHighestRole, employeeInitialData);
      const selectedMembers = empId
        ? empId
        : isEmpty(selectedTeamMembers)
        ? defaultMemberList
        : selectedTeamMembers;
      workbook = viz.getWorkbook();
      workbook.changeParameterValueAsync('Paramstring', paramString);
      if (activeSheetName) {
        activeSheet = workbook.getActiveSheet();
        this.applyFilter(activeSheetName, selectedMembers);
        if (activeSheetName2)
          this.applyFilter(activeSheetName2, selectedMembers);
      }
    }, 0);
  };

  applyFilter = (activeSheetName, selectedMembers) => {
    activeSheet
      .getWorksheets()
      .get(activeSheetName)
      .applyFilterAsync(
        'Employee ID',
        selectedMembers,
        window.tableau.FilterUpdateType.REPLACE
      );
  };

  render() {
    const { empInitialData, loadUnauthorized, isTableauReportLoaded } =
      this.state;
    const { match, showKTScorecardPageContent } = this.props;
    const detailedViewPage = match.params.token;

    if (!loadUnauthorized) {
      return (
        <div
          className={`tableau-container home-container kt-tableau-container readOnly ${
            showKTScorecardPageContent && empInitialData ? 'show' : 'hide'
          }`}
        >
          <div className="main-container">
            {isTableauReportLoaded && (
              <BillabilityDownload detailedViewPage={detailedViewPage} />
            )}
            <div id="tableau-deep-dive" />
          </div>
          {!isTableauReportLoaded ? (
            <div className="main-container">
              <KtTableauLoader
                loaderType="dashboard"
                isLoading={!isTableauReportLoaded}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      return <UnknownRoute />;
    }
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    showKTScorecardPageContent:
      reduxStore.ktScorecard.showKTScorecardPageContent,
    path: reduxStore.headerFilterData.path,
    filters: reduxStore.headerFilterData.filters,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    defaultMemberList: reduxStore.headerFilterData.defaultMemberList,
  };
};

// withAuth() makes Okta "Auth" object available as "this.props.auth"

export default connect(mapStateToProps)(AuthorizeHOC(TableauContainer));
