import { applyMiddleware, createStore, compose } from 'redux';
import { reducers } from './reducers';
import thunk from 'redux-thunk';

const setupStore = (preloadedState = {}) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add suport for redux dev tools
  return createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk))
  );
};

export default setupStore;
