import React from 'react';
import CONFIG from 'config/configProps';
import TrendsChart from './TrendsChart';

const KTBillabilityTrend = props => {
  const colors = CONFIG.PROTOTYPE_BILLABILITY_LEGENDS_API_KEY;
  const legends = CONFIG.PROTOTYPE_BILLABILITY_LEGENDS;

  return (
    <div className="home_page_main_split_row_billability_trend highchart_container main_conatiner">
      <div className="heading">
        <div className="heading_text">BILLABILITY TREND</div>
      </div>
      <TrendsChart
        colors={colors}
        legends={legends}
        data={props.data}
        section="Billability Trends"
      />
    </div>
  );
};

export default KTBillabilityTrend;
