/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import store from 'redux/store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateBackToListViewPage } from 'redux/actions';
import TableauStackWrapper from 'components/KTTableauStackWrapper/TableauStackWrapper';
import AuthorizeHOC from 'components/common/authorizeHOC';
import { globalAdobeAnalytics } from 'config/analytics';

class KtDashboard extends Component {
  constructor(props) {
    super(props);
    const { loggedInUserHighestRole, ktUserDetails } = this.props;
    store.dispatch(updateBackToListViewPage(true));
    this.state = {
      checkedKTDecksSwitch: false,
      empChangedData: {},
      loggedInUserHighestRole,
      ktUserDetails: ktUserDetails,
    };
  }
  /**
   *
   * React life cycle method
   */
  componentDidMount() {
    // default scrolling page to top
    window.scrollTo(0, 0);
    this.updateState();
    globalAdobeAnalytics('KTDashboard-Legacy-Homepage');
  }

  updateState = () => {
    const { loggedInUserHighestRole } = this.props;
    this.setState({
      loggedInUserHighestRole,
    });
  };

  render() {
    const {
      showKTScorecardPageContent,
      tableauParamString,
      employeeInitialData,
    } = this.props;

    return (
      showKTScorecardPageContent &&
      employeeInitialData && (
        <div className="tableau-container home-container readOnly">
          {tableauParamString && <TableauStackWrapper />}
        </div>
      )
    );
  }
}
const mapStateToProps = function (reduxStore) {
  return {
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    showKTScorecardPageContent:
      reduxStore.ktScorecard.showKTScorecardPageContent,
    ktUserDetails: reduxStore.userRoleData.ktUserDetails,
  };
};
export default connect(mapStateToProps)(withRouter(AuthorizeHOC(KtDashboard)));
