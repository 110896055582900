import React from 'react';
import { Table } from 'semantic-ui-react';

import { times } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KTBillabilityLoader = props => {
  if (props.HideHeaders) {
    return times(15, i => (
      <Table.Row
        key={i}
        className="kt-billability-container__chart__table__body_main"
      >
        {times(5, i => (
          <Table.Cell className="name" key={i}>
            <LabelWithSkeleton isLoading size="small" showLine />
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  } else {
    return (
      <Table
        basic
        celled
        sortable
        compact
        id="spark-line-table"
        className="kt-billability-container__chart__table"
      >
        <Table.Header className="kt-billability-container__chart__table__header">
          <Table.Row>
            {times(5, i => (
              <Table.HeaderCell key={i}>
                <LabelWithSkeleton isLoading size="small" showLine />
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body className="kt-billability-container__chart__table__body">
          {times(15, i => (
            <Table.Row
              key={i}
              className="kt-billability-container__chart__table__body_main"
            >
              {times(6, i => (
                <Table.Cell className="name" key={i}>
                  <LabelWithSkeleton isLoading size="small" showLine />
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
};
export default KTBillabilityLoader;
