import React, { useEffect, useState } from 'react';
import { batch, connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import CONFIG from 'config/configProps';
import { isEqual, isLoading, times } from 'utils/common';
import KTDetailedView from './KTDetailView';
import KTBillabilityLoader from 'components/common/KTBillabilityLoader';
import KTBillRate from './KTBillRate';
import { isEmptyOrNull, getPostData } from 'utils';
import { useHistory } from 'react-router-dom';
import DropDownFilter from 'components/common/AdminFilter/KtDdFilters';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

const KTChargesBillRate = props => {
  const {
    selectedIndex,
    appliedFilter,
    memberList,
    loggedInUserId,
    detailedViewData,
    billRateData,
    detailedViewTotalData,
    appliedCollection,
    chargesHoursFilters,
    isAnalystUser,
  } = props;
  const history = useHistory();
  let FILTERS = CONFIG.CHARGES_FILTERS_COLUMNS;
  const defaultSortingOption = `${CONFIG.PROTOTYPE_CHARGES_DEFAULT_SORT_OPTIONS[selectedIndex]}Desc`;
  const isBillRatePageOpen = isEqual(selectedIndex, 1);
  let defaultValue = {};
  defaultValue[defaultSortingOption] = true;
  const [apiData, setApiData] = useState({});
  const [apiResponse, setApiResponse] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [showSortingOptions, setShowSortingOptions] = useState(defaultValue);
  const [showTooltip, setShowToolTip] = useState({});
  const [openedFilters, setOpenedFilters] = useState({
    [FILTERS[0]]: false,
    [FILTERS[1]]: false,
  });
  const [selectedValues, setSelectedValues] = useState({
    [FILTERS[0]]: chargesHoursFilters?.[FILTERS[0]] || [],
    [FILTERS[1]]: chargesHoursFilters?.[FILTERS[1]] || [],
  });

  if (isAnalystUser) {
    if (isBillRatePageOpen) history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }

  useEffect(() => {
    getApiData();
  }, [appliedFilter, chargesHoursFilters]);

  useEffect(() => {
    if (selectedIndex === 0) {
      let apiResponse = detailedViewData?.data || {};
      apiResponse.EmployeeTotal = [
        'Total',
        [detailedViewTotalData?.data?.chargesTotal],
      ];
      setApiData(detailedViewData);
      setApiResponse(apiResponse);
    } else {
      setApiData(billRateData);
      setApiResponse(billRateData?.data);
    }
  }, [detailedViewData, billRateData, detailedViewTotalData]);

  const getApiData = () => {
    const postData = getPostData(appliedFilter, memberList, isAnalystUser);
    if (isEmptyOrNull(postData)) return;
    if (!isEmptyOrNull(chargesHoursFilters)) {
      postData['utilizationCategories'] = chargesHoursFilters[FILTERS[0]];
      postData['projectTypes'] = chargesHoursFilters[FILTERS[1]];
    }
    if (isAnalystUser) postData.selectedUserId = [loggedInUserId];
    else if (!isEmptyOrNull(appliedCollection)) {
      postData['selectedUserId'] = appliedCollection?.selectedUserId;
    }
    let apiIdentifier = '',
      getTotal = false;
    if (isEqual(selectedIndex, 0)) {
      apiIdentifier = API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA;
      getTotal = true;
    } else {
      apiIdentifier = API_CALL_IDENTIFIERS.FETCH_BILL_RATE_DATA;
    }
    batch(() => {
      props.fetchChargesData(apiIdentifier, postData);
      getTotal &&
        props.fetchChargesData(
          API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA_TOTAL,
          postData
        );
    });
  };

  const renderChartDataTable = () => {
    const propPack = {
      apiData: apiResponse,
      selectedOption: selectedOption,
    };
    switch (selectedIndex) {
      case 0:
        return <KTDetailedView {...propPack} />;
      case 1:
        return <KTBillRate {...propPack} />;
      default:
        return null;
    }
  };

  const onFilterChange = (selectedName, selectedValue) => {
    let selectedFilters = { ...selectedValues, [selectedName]: selectedValue };
    props.saveChargesHoursFilters(selectedFilters);
    setSelectedValues(selectedFilters);
    setOpenedFilters({});
    props.getDownloadsData(selectedFilters);
  };

  const renderBillabilityChart = () => {
    let headerColumns = CONFIG.PROTOTYPE_CHARGES_HOURS_COLUMNS;
    headerColumns = isEqual(selectedIndex, 0)
      ? headerColumns.detailedView
      : headerColumns.billRate;

    return (
      <Table
        basic
        celled
        sortable
        compact
        id="spark-line-table"
        className="kt-billability-container__chart__table"
      >
        <Table.Header className="kt-billability-container__chart__table__header">
          <Table.Row>
            {Object.keys(headerColumns).map(col => {
              const tooltipDescClassName = showTooltip[`${col}ToolTipDesc`]
                ? 'on'
                : 'off';
              const tooltipAccClassName = showTooltip[`${col}ToolTipAcc`]
                ? 'on'
                : 'off';
              return (
                <Table.HeaderCell key={col}>
                  <div className="heading-content">
                    <div className="heading">{col}</div>
                    {isEqual(selectedIndex, 0) && FILTERS.indexOf(col) > -1 && (
                      <div
                        className="filter-icon"
                        onClick={() =>
                          setOpenedFilters({
                            [col]: !openedFilters[col],
                          })
                        }
                      >
                        {times(3, i => (
                          <div className="filter-icon__dot" key={i}>
                            .
                          </div>
                        ))}
                      </div>
                    )}
                    {openedFilters[col] && (
                      <DropDownFilter
                        dataList={CONFIG.CHARGES_HOURS_FILTERS_LIST[col]}
                        selectedList={selectedValues[col]}
                        className="padding-10 charges_hours_filters"
                        onChange={onFilterChange}
                        clearFilter={onFilterChange}
                        isAllSelected={isEqual(
                          CONFIG.CHARGES_HOURS_FILTERS_LIST[col].length || 0,
                          selectedValues?.length || 0
                        )}
                        showSearch={false}
                        stateField={col}
                      />
                    )}
                    {isBillRatePageOpen && (
                      <span
                        className="tooltip-with-icon periodTooltip desc"
                        onMouseEnter={() => {
                          const newState = {};
                          newState[`${col}ToolTipDesc`] = true;
                          setShowToolTip({ ...newState });
                        }}
                        onMouseLeave={() => {
                          const newState = {};
                          newState[`${col}ToolTipDesc`] = false;
                          setShowToolTip({ ...newState });
                        }}
                      >
                        <div
                          className={`headerSortUp ${
                            showSortingOptions[`${col}Desc`] ? 'blueBgUp' : ''
                          }`}
                          onClick={() => {
                            const newState = {};
                            newState[`${col}Desc`] = true;
                            newState[`${col}Acc`] = false;
                            setSelectedOption({ value: col, type: 'Desc' });
                            setShowSortingOptions({ ...newState });
                          }}
                        />
                        <div id="tooltip" className={tooltipDescClassName}>
                          <div className="tooltip-inner">
                            {isEqual(headerColumns[col], 'number')
                              ? CONFIG.SORTING_TOOLTIP_TEXT.numberDesc
                              : CONFIG.SORTING_TOOLTIP_TEXT.StringDesc}
                          </div>
                        </div>
                      </span>
                    )}
                    {isBillRatePageOpen && (
                      <span
                        className="tooltip-with-icon periodTooltip acc"
                        onMouseEnter={() => {
                          const newState = {};
                          newState[`${col}ToolTipAcc`] = true;
                          setShowToolTip({ ...newState });
                        }}
                        onMouseLeave={() => {
                          const newState = {};
                          newState[`${col}ToolTipAcc`] = false;
                          setShowToolTip({ ...newState });
                        }}
                      >
                        <div
                          className={`headerSortDown ${
                            showSortingOptions[`${col}Acc`] ? 'blueBgDown' : ''
                          }`}
                          onClick={() => {
                            const newState = { ...showSortingOptions };
                            newState[`${col}Acc`] = true;
                            newState[`${col}Desc`] = false;
                            setSelectedOption({ value: col, type: 'Acc' });
                            setShowSortingOptions({ ...newState });
                          }}
                        />
                        <div id="tooltip" className={tooltipAccClassName}>
                          <div className="tooltip-inner">
                            {isEqual(headerColumns[col], 'number')
                              ? CONFIG.SORTING_TOOLTIP_TEXT.numberAcc
                              : CONFIG.SORTING_TOOLTIP_TEXT.StringAcc}
                          </div>
                        </div>
                      </span>
                    )}
                  </div>
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body className="kt-billability-container__chart__table__body">
          {isEmptyOrNull(apiResponse) ||
          (isBillRatePageOpen && isEmptyOrNull(apiResponse?.billRateDTOs)) ||
          (!isBillRatePageOpen && isEmptyOrNull(apiResponse?.employeeMap)) ? (
            <div>No Record to display</div>
          ) : (
            renderChartDataTable()
          )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {isLoading(apiData) ? (
          <KTBillabilityLoader />
        ) : (
          renderBillabilityChart()
        )}
      </div>
    </div>
  );
};
const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    loggedInUserId: store.homePageData.loggedInUserId,
    detailedViewData: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA
      ),
    },
    detailedViewTotalData: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA_TOTAL
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_CHARGES_HOURS_DATA_TOTAL
      ),
    },
    billRateData: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_BILL_RATE_DATA
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_BILL_RATE_DATA
      ),
    },
    appliedCollection: store.filterDataPt.appliedCollection,
    chargesHoursFilters: store.apiData.chargesHoursFilters,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchChargesData: bindActionCreators(
      actions.apiActions.fetchApiData,
      dispatch
    ),
    saveChargesHoursFilters: bindActionCreators(
      actions.apiActions.saveAppliedChargesHoursFilters,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KTChargesBillRate);
