import React, { PureComponent } from 'react';
import KTCheckbox from 'components/common/KTCheckbox';
import Radio from 'components/common/CommonHtml/radio';
import './AdminFilter.scss';
import { isEmpty, isEqual } from 'utils/common';
import KtSearchSmall from '../KtSearchSmall';

class AdminFilter extends PureComponent {
  constructor(props) {
    super(props);
    const {
      selectedList,
      dataList,
      isAllSelected,
      showAll,
      singleSelect,
      selectedId,
      selectedName,
    } = this.props;
    const data = showAll
      ? [{ label: 'All', value: '-1' }, ...dataList]
      : [...dataList];
    this.state = {
      selectedList: isAllSelected ? this.getAllIds() : selectedList,
      noData: false,
      isAllSelected: singleSelect ? false : isAllSelected,
      dataList: data,
      dataListToBeShown: data,
      selectedName: selectedName,
      selectedId: selectedId,
    };
  }

  getAllIds = () => {
    return [{ value: '-1' }, ...this.props.dataList].map(el => el.value);
  };

  handleRadioChange = (selectedName, selectedId) => {
    const { hideApply } = this.props;
    this.setState({ selectedName, selectedId }, () => {
      hideApply && this.applyFilter();
    });
  };

  toggleAllSelection = (isAllSelected, dataList) => {
    let allSelected;
    let selectedIds;

    if (isAllSelected) {
      selectedIds = {};
      allSelected = false;
    } else {
      dataList = dataList.filter(obj => {
        return obj.value !== '-1';
      });
      selectedIds = dataList.reduce(
        (acc, el) => ({ ...acc, [el.value]: true }),
        {}
      );
      allSelected = true;
    }

    return [allSelected, selectedIds];
  };

  toggleAllIPAorAllFPASelection = (id, selectedIds, allFPA, allIPA) => {
    let selectedItems = isEqual(id, '-11') ? allFPA : allIPA;
    let isAllSelected;

    if (selectedIds[id]) {
      delete selectedIds[id];
      selectedItems.forEach(el => {
        delete selectedIds[el.paId];
      });
      delete selectedIds['-1'];
      isAllSelected = false;
    } else {
      selectedIds[id] = true;
      selectedIds = selectedItems.reduce(
        (acc, el) => ({
          ...acc,
          [el.paId]: true,
        }),
        selectedIds
      );
      isAllSelected = selectedIds['-11'] && selectedIds['-111'];
    }

    return [isAllSelected, selectedIds];
  };

  checkIdInAlllist = (id, allSelected, allOptions) => {
    return allSelected && allOptions?.find(el => id === el.paId?.toString());
  };

  checkAllSelected = (selectedIds, allSelected, allOptions) => {
    return !allSelected && allOptions?.every(el => selectedIds[el.paId]);
  };

  toggleSingleOptionSelection = (id, selectedIds, allFPA, allIPA) => {
    let isAllSelected;
    const selectedAllIPA = selectedIds['-111'];
    const selectedAllFPA = selectedIds['-11'];

    if (selectedIds[id]) {
      if (selectedIds['-1']) {
        delete selectedIds['-1'];
      }
      if (this.checkIdInAlllist(id, selectedAllFPA, allFPA)) {
        delete selectedIds['-11'];
      }
      if (this.checkIdInAlllist(id, selectedAllIPA, allIPA)) {
        delete selectedIds['-111'];
      }

      isAllSelected = false;
      delete selectedIds[id];
    } else {
      selectedIds[id] = true;
      if (this.checkAllSelected(selectedIds, selectedAllFPA, allFPA)) {
        selectedIds['-11'] = true;
      }
      if (this.checkAllSelected(selectedIds, selectedAllIPA, allIPA)) {
        selectedIds['-111'] = true;
      }
      isAllSelected = selectedIds['-11'] && selectedIds['-111'];
    }

    return [isAllSelected, selectedIds];
  };

  handleCheckboxChange = id => {
    const { hideApply, paList } = this.props;
    const { selectedList, dataList } = this.state;
    let selectedIds = selectedList.reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );
    let isAllSelected = this.state.isAllSelected || selectedIds['-1'];
    const allFPA = paList?.filter(item => isEqual(item.taxonomy, 'FPA'));
    const allIPA = paList?.filter(item => isEqual(item.taxonomy, 'IPA'));

    if (isEqual(id, '-1')) {
      [isAllSelected, selectedIds] = this.toggleAllSelection(
        isAllSelected,
        dataList
      );
    } else if (isEqual(id, '-11') || isEqual(id, '-111')) {
      [isAllSelected, selectedIds] = this.toggleAllIPAorAllFPASelection(
        id,
        selectedIds,
        allFPA,
        allIPA
      );
    } else {
      [isAllSelected, selectedIds] = this.toggleSingleOptionSelection(
        id,
        selectedIds,
        allFPA,
        allIPA
      );
    }

    this.setState(
      {
        selectedList: Object.keys(selectedIds),
        isAllSelected,
      },
      () => {
        hideApply && this.applyFilter();
      }
    );
  };

  clearFilter = () => {
    this.setState({
      selectedList: [],
      isAllSelected: false,
      selectedName: '',
      selectedId: '',
    });
    this.props.clearFilter(this.props.stateField, [], 0);
  };

  applyFilter = () => {
    const { dataList, stateField, onChange, singleSelect } = this.props;
    const { selectedList, selectedName, selectedId } = this.state;
    if (singleSelect) {
      return onChange(stateField, selectedId, selectedName);
    } else {
      const selectedListLength = selectedList.length;
      const dataListLength = dataList.length;
      const length =
        selectedListLength > dataListLength
          ? dataListLength
          : selectedListLength;
      return onChange(stateField, selectedList, length);
    }
  };

  showSearchResults = value => {
    if (value) {
      let data = this.state.dataList;
      let searchResultData = [];
      searchResultData = data.filter(el => {
        return el.label.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        dataListToBeShown: searchResultData,
      });
    } else {
      this.setState({
        dataListToBeShown: this.state.dataList,
      });
    }
  };

  render() {
    const {
      selectedList,
      noData,
      dataListToBeShown,
      isAllSelected,
      selectedId,
    } = this.state;
    const {
      isClearRequired,
      singleSelect,
      hideApply,
      showSearch,
      placeHolder,
      searchPosition,
    } = this.props;
    const disabled =
      noData ||
      (singleSelect && !selectedId) ||
      (!singleSelect && isEmpty(selectedList));
    return (
      <div className="admin-filter-container">
        {showSearch && (
          <KtSearchSmall
            containerClassName="filter-list-search"
            onChangeHandler={this.showSearchResults}
            placeholder={placeHolder ? placeHolder : 'Search'}
            searchPosition={searchPosition}
          />
        )}
        <div className="filter-list">
          {dataListToBeShown.map(el => {
            return (
              <div key={el.value} className="filter-display">
                {singleSelect ? (
                  <Radio
                    checked={isEqual(
                      el.value.toString(),
                      selectedId.toString()
                    )}
                    value={el.value}
                    name={el.label}
                    handleChange={this.handleRadioChange}
                    bgColor="#c1cad5"
                    checkedColor="#4f5b6a"
                    className="filter-display__radio"
                  />
                ) : (
                  <KTCheckbox
                    checked={isAllSelected || selectedList.includes(el.value)}
                    checkboxColor="darkest-blue"
                    handleChange={this.handleCheckboxChange}
                    isReturn={true}
                    value={el.value}
                    name={el.label}
                  />
                )}
                <div className="filter-name">{el.label}</div>
              </div>
            );
          })}
        </div>
        {!hideApply && (
          <div className="button-footer">
            {isClearRequired && (
              <div className="clear" onClick={this.clearFilter.bind(this)}>
                Clear
              </div>
            )}
            <div
              className={`apply ${disabled ? 'disabled' : ''}`}
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                this.applyFilter();
              }}
            >
              Apply
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdminFilter;
