import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { batch, connect } from 'react-redux';
import store from 'redux/store';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  isEqual,
  isEmpty,
  isArray,
  isObject,
  showSearch,
  showFilterDropDown,
} from 'utils/common';
import KTDropdownWithRadioBtn from 'components/KTDropdownWithRadioBtn';
import MemberFilter from 'components/KTDashboardHeader/MemberFilter';
import { fetchGet, fetchPost } from 'utils/KtCommonServices';
import {
  updateCalenderDropdownList,
  updateInitialDate,
  updateSelectedSelectionDetails,
  updateSelectedSelectionDetailValue,
  updateSelectedSectorDetails,
  updateSelectedSubSectorDetails,
  updateSaPaList,
  updateManagedBy,
  updateSelectedTeamMembers,
  updateTeamMemberList,
  updateAllTeamMemberSelected,
  updatePaPaList,
  updatePath,
  updateFilters,
  updateCollectionApplied,
  passGeoDetails,
  updateDefaultMemberList,
  updateGeoMarketList,
} from 'redux/actions/headerFilterAction';
import {
  updateToastNotificationDetails,
  setAdminClick,
  checkBrowser,
  updateDisplayName,
  updateIconInformation,
  fetchImpersonatorList,
} from 'redux/actions';
import {
  updateTableauStringData,
  updateTableauFilterParams,
} from 'redux/actions/tableauStackAction';
import {
  updateEmployeeInitialData,
  updateLoggedInUserHighestRole,
  updateIsGeoManager,
  updateUserIsGeoManaged,
  updateIsGeoLead,
  setManagerValue,
} from 'redux/actions/userRoleAction';
import CONFIG, {
  OPTION_ALL,
  allSector,
  allSubSector,
  BUSINESS_COMPARISON_ROLE_ID,
} from 'config/configProps';
import {
  getViewName,
  getStringFromJson,
  getQueryParams,
  getParameterByName,
  getSelectedTimePeriod,
  getIconInformation,
  getApiFailMessage,
  getManagedByOptions,
  getManagedByDefaultValue,
  getParameterFromFilters,
  isGeoLeadRole,
  isGeoManagerRole,
  isSuperAdminRole,
  isImpersonatorRole,
  isInAdminRoleList,
  isInManagerRoleList,
  isInSuperAdminRoleList,
  isInPaAdminRoleList,
  sortPaList,
  sortSubSectorList,
  navigateToUnauthorized,
  selectOption,
  getQuarterOfTheYear,
  selectedDates,
  getEmpIdParam,
  isInGeoAdminRoleList,
  getSectorListWithAll,
  getSubSectorListWithAll,
  returnWithAllValue,
  passAllWithEmpty,
} from 'utils';
import KtSearchSmall from 'components/common/KtSearchSmall';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import Icon from 'components/common/Icons';
import AdminFilter from 'components/common/AdminFilter';
import ToolTipShowEdit from 'components/common/ToolTipShowEdit';
import './KTDashboardHeader.scss';
import 'react-datepicker/dist/react-datepicker.css';

class KTDashboardHeader extends Component {
  constructor(props) {
    super(props);
    const {
      selectedTeamMembers,
      displayName,
      passGeo,
      history,
      isGeoManaged,
      path,
      initialDate,
      filters,
      iconInformation,
      empInitialData,
      managedBy,
    } = this.props;
    const appliedUrlFilters = getQueryParams(history.location.search);
    const d = new Date();
    const geoMarket = getParameterFromFilters(filters, 'geoMarket', OPTION_ALL);
    const managedByUrl = getParameterFromFilters(filters, 'managedBy', '');
    const pa = getParameterFromFilters(filters, 'pa', OPTION_ALL);
    const timePeriod = getParameterFromFilters(
      filters,
      'timePeriod',
      'Last 12 Months'
    );
    const month = getParameterFromFilters(filters, 'month', d.getMonth() + 1);
    const year = getParameterFromFilters(filters, 'year', d.getFullYear());
    const date =
      isEmpty(filters) && isEmpty(getParameterByName('year'))
        ? initialDate
        : new Date(year, month);
    const sector = getParameterFromFilters(filters, 'sector', OPTION_ALL);
    const subSector = getParameterFromFilters(filters, 'subSector', OPTION_ALL);
    const sharedSubSector = getParameterFromFilters(
      filters,
      'sharedSubSector',
      OPTION_ALL
    );
    const role = this.getRoleFromFilter();
    const urlRole =
      !isEmpty(appliedUrlFilters) && isEmpty(getParameterByName('role'))
        ? this.getHighestRoleByFilters(pa, sector, subSector)
        : role;
    const isGeoManager = isGeoManagerRole(urlRole);
    const isGeoLead = isGeoLeadRole(urlRole);
    store.dispatch(updateCalenderDropdownList(timePeriod));
    // Internet Explorer 6-11
    const isIE = !!document.documentMode;
    store.dispatch(checkBrowser(isIE));
    this.arr = CONFIG.TIME_PERIOD_OPTIONS;
    this.state = {
      highestRole: urlRole,
      setDate: date,
      dropdownVale: this.arr,
      selectedTimePeriod: timePeriod,
      empInitialData: empInitialData,
      selectedItemForManager: '',
      initialDataList: [],
      /*state for geo,pa,sector,subSector,sharedSubSector start*/
      geoList: [],
      pAList: [],
      sectorList: [allSector],
      subSectorList: [allSubSector],
      showGeoMarketList: false,
      showPaList: false,
      showSectorList: false,
      showSubSectorList: false,
      selectedGeo: geoMarket,
      selectedPa: pa,
      selectedPas: [],
      selectedPaId: '',
      selectedPaIds: [],
      isAllPaSelected: false,
      selectedSector: sector,
      selectedSectors: [],
      selectedSectorId: '',
      selectedSectorIds: [],
      isAllSectorSelected: false,
      selectedSubSector: subSector,
      selectedSubSectors: [],
      selectedSubSectorId: '',
      selectedSubSectorIds: [],
      isAllSubSectorSelected: false,
      selectedSharedSubSector: sharedSubSector,
      selectedSharedSubSectors: [],
      geoListToBeShown: [],
      paListToBeShown: [],
      sectorListToBeShown: [],
      subSectorListToBeShown: [],
      isPaClicked: false,
      isSectorClicked: false,
      fetchPaList: false,
      fetchSectorList: false,
      fetchSubSectorList: false,
      fetchMemberList: false,
      /*state for geo,pa,sector,subSector,sharedSubSector end*/
      selectedDataForManager: {},
      showTooltip: false,
      fromSuperAdmin: false,
      showManagedByList: false,
      selectedManagedBy: managedBy,
      showUsers: false,
      selectedTeamMembers: selectedTeamMembers,
      displayName: displayName,
      filters: {},
      selectedPath: path ? path : OPTION_ALL,
      showPathList: false,
      isFilterApplied: !isEmpty(appliedUrlFilters),
      searchObject: {
        pa,
        sector,
        subSector,
        sharedSubSector,
        month,
        year,
        timePeriod,
        role: urlRole,
        managedBy: managedByUrl,
        geoMarket,
      },
      refreshMemberList: false,
      passGeo: passGeo,
      /* state for roles start*/
      isAdmin: isInAdminRoleList(urlRole),
      isSuperAdmin: isSuperAdminRole(urlRole),
      isImpersonator: isImpersonatorRole(urlRole),
      isManager: isInManagerRoleList(urlRole),
      isGeoManager: isGeoManager,
      isGeoManaged: isGeoManaged,
      isGeoLead: isGeoLead,
      highestRoleIsGeo: isGeoManager || isGeoLead,
      /* state for roles end*/
      geoLeadManagerList: [],
      selectedValue: {},
      managedBy: managedByUrl,
      tooltipName: '',
      showPeriodTooltip: false,
    };
    this.paListContainer = React.createRef();
    this.sectorListContainer = React.createRef();
    this.subSectorListContainer = React.createRef();
    this.managedByContainer = React.createRef();
    this.userContainer = React.createRef();
    this.pathContainer = React.createRef();
    this.geoMarketListContainer = React.createRef();
    isEmpty(iconInformation) && this.getIconInformation();
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    let filteredManagedBy = [];
    const { selectedManagedBy, isGeoLead } = state;

    selectedManagedBy.forEach(managedBy => {
      const matched = getManagedByOptions(isGeoLead).find(
        option => option.value === managedBy
      );
      if (matched) {
        filteredManagedBy.push(managedBy);
      } else {
        if (!isGeoLead && managedBy === 'Geo Managed')
          filteredManagedBy.push('PA Managed');
      }
    });

    if (isEmpty(filteredManagedBy)) {
      filteredManagedBy = getManagedByDefaultValue(isGeoLead);
    }

    if (!isEqual(selectedManagedBy, filteredManagedBy)) {
      newState = { selectedManagedBy: filteredManagedBy };
      store.dispatch(updateManagedBy(filteredManagedBy));
    }

    return isEmpty(newState) ? null : newState;
  }

  componentDidMount() {
    const { highestRole, passGeo, isGeoLead, setDate } = this.state;
    const { memberDetails, employeeInitialData } = this.props;
    store.dispatch(updateLoggedInUserHighestRole(highestRole));
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach(el =>
      el.childNodes[0].setAttribute('readOnly', true)
    );
    this.addEventListeners();
    this.onLoadChange(setDate, null, true);
    this.setState({ userSelectedManagedBy: !isEmpty(memberDetails) }, () =>
      this.getDataFromConfiguration()
    );

    let selectedManagedBy = [];
    if (isEmpty(highestRole)) {
      selectedManagedBy = getManagedByDefaultValue(isGeoLead);
    }
    if (isEmpty(highestRole) || (!isGeoLead && passGeo)) {
      this.setState({ selectedManagedBy });
      store.dispatch(updateManagedBy(selectedManagedBy));
    }

    if (
      !isEmpty(memberDetails) &&
      memberDetails?.employeeIdHRO !== employeeInitialData.employeeIdHRO
    ) {
      this.makeEmpCall();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.memberDetails, this.props.memberDetails)) {
      store.dispatch(updateSaPaList(undefined));
      this.makeEmpCall();
    } else if (prevProps.fromAdmin !== this.props.fromAdmin) {
      isEmpty(this.props.memberDetails)
        ? this.getDataFromConfiguration()
        : this.makeEmpCall();
    }

    const locationChanged =
      this.props.location.pathname !== prevProps.location.pathname;
    if (
      !this.props.fromAdmin &&
      (!isEqual(this.props.filters, prevProps.filters) ||
        (locationChanged && !this.props.location.search))
    ) {
      this.props.history.replace({
        search: getStringFromJson(this.props.filters, '||'),
      });
    }
  }

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount() {
    document.removeEventListener(
      'click',
      this.handleClickOutsideForPAListDropDown
    );
    document.removeEventListener(
      'click',
      this.handleClickOutsideForListForSector
    );
    document.removeEventListener(
      'click',
      this.handleClickOutsideForListForSubSector
    );
    document.removeEventListener('click', this.handleClickOutsideForManagedBy);
    document.removeEventListener('click', this.handleClickOutsideForUserList);
    document.removeEventListener('click', this.handleClickOutsideForPathList);
    document.removeEventListener(
      'click',
      this.handleClickOutsideForGeoMarketList
    );
  }

  getRoleFromFilter = () => {
    const { filters, loggedInUserHighestRole, empInitialData } = this.props;

    if (isEmpty(filters) && isEmpty(getParameterByName('role'))) {
      return typeof loggedInUserHighestRole === 'undefined'
        ? this.getHighestRole(empInitialData)
        : loggedInUserHighestRole;
    }

    return getParameterByName('role') || filters?.role;
  };

  getUserCallUrl = () => {
    const { memberDetails } = this.props;
    const url = !isEmpty(memberDetails)
      ? `${CONFIG.API_URLS.EMP}?employeeIdHRO=${memberDetails.employeeIdHRO}`
      : CONFIG.API_URLS.EMP;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    return [url, baseUrl];
  };

  makeEmpCall = () => {
    const { memberDetails } = this.props;
    const [userDetailsUrl, baseUrl] = this.getUserCallUrl();
    this.setState({ userSelectedManagedBy: false });
    fetchGet(userDetailsUrl, baseUrl).then(data => {
      store.dispatch(fetchImpersonatorList(true));
      store.dispatch(updateEmployeeInitialData(data));
      store.dispatch(updateSelectedSelectionDetails({}));
      store.dispatch(updateSelectedSelectionDetailValue(''));
      if (isEmpty(memberDetails)) {
        store.dispatch(fetchImpersonatorList(false));
        this.getDataFromConfiguration(data, false, true);
      } else {
        this.setState({ selectedPas: [data.paTeam], selectedPa: data.paTeam });
        this.getDataFromConfiguration(data, true);
      }
    });
  };

  getHighestRoleByFilters = (pa, sector, subSector) => {
    const { employeeInitialData } = this.props;
    let urlRoleName = '';
    if (isEmpty(employeeInitialData)) {
      const [userDetailsUrl, baseUrl] = this.getUserCallUrl();
      fetchGet(userDetailsUrl, baseUrl).then(data => {
        urlRoleName = this.getEmployeeRole(data, pa, sector, subSector);
      });
    } else {
      urlRoleName = this.getEmployeeRole(
        employeeInitialData,
        pa,
        sector,
        subSector
      );
    }
    return urlRoleName;
  };

  getEmployeeRole = (employeeData, pa, sector, subSector) => {
    let userRole = '';
    employeeData.employeeRoleDTO.forEach(item => {
      if (
        CONFIG.ADMIN_ROLES.includes(item.roleName) ||
        (isEqual(item.paTeam, pa) &&
          isEqual(item.sector, sector) &&
          isEqual(item.subSector, subSector))
      ) {
        userRole = item.roleName;
      }
    });

    return userRole;
  };

  getIconInformation = () => {
    const apiUrl = CONFIG.API_URLS.ALL_ICON_INFORMATION;
    fetchGet(apiUrl).then(result => {
      store.dispatch(updateIconInformation(result));
    });
  };

  addEventListeners = () => {
    document.addEventListener(
      'click',
      this.handleClickOutsideForPAListDropDown
    );
    document.addEventListener('click', this.handleClickOutsideForListForSector);
    document.addEventListener(
      'click',
      this.handleClickOutsideForListForSubSector
    );
    document.addEventListener('click', this.handleClickOutsideForManagedBy);
    document.addEventListener('click', this.handleClickOutsideForUserList);
    document.addEventListener('click', this.handleClickOutsideForPathList);
    document.addEventListener('click', this.handleClickOutsideForGeoMarketList);
  };

  getMemberList = (filterDto, selectedPath) => {
    const { passGeo } = this.props;
    let userListUrl = CONFIG.API_URLS.KT_CARD_USERS;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    const { selectedManagedBy, selectedTimePeriod, setDate } = this.state;
    const selectedDate = getSelectedTimePeriod(setDate);
    let dataToBePosted = {
      geoRegion: filterDto.geoRegion,
      paId: filterDto.paId,
      practiceArea: filterDto.paTeam,
      role: filterDto.roleName,
      sector: isEqual(filterDto.sector, OPTION_ALL) ? null : filterDto.sector,
      selectedManagedBy: passGeo ? [] : selectedManagedBy,
      sharedSubSector: filterDto.sharedSubSector,
      subSector: isEqual(filterDto.subSector, OPTION_ALL)
        ? null
        : filterDto.subSector,
      subSectorId: filterDto.subSectorId,
      timePeriod: selectedTimePeriod,
      topicSectorId: filterDto.topicSectorId,
      year: selectedDate.year,
      month: selectedDate.month,
      path: isEqual(selectedPath, OPTION_ALL) ? 'ALL' : selectedPath,
      paIds: returnWithAllValue(filterDto.paIds),
      topicSectorIds: returnWithAllValue(filterDto.topicSectorIds),
      subSectorIds: returnWithAllValue(filterDto.subSectorIds),
      sharedSubSectors: filterDto.sharedSubSectors,
      sectors: returnWithAllValue(filterDto.sectors),
      subSectors: returnWithAllValue(filterDto.subSectors),
      practiceAreas: returnWithAllValue(filterDto.paTeams),
    };

    return fetchPost(userListUrl, dataToBePosted, baseUrl)
      .then(async result => {
        let memberList = [];
        let userId = [];
        memberList = result.data;
        memberList.forEach(el => {
          userId.push(el.employeeIdHRO);
        });
        await store.dispatch(updateTeamMemberList(memberList));
        await store.dispatch(updateDefaultMemberList(userId));
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
  };

  onLoadChange = (date, testData, initialLoad) => {
    const { calenderDropdownVal, tableauStringData } = this.props;
    const { selectedTimePeriod, selectedPath } = this.state;
    let defaultTimePeriod = calenderDropdownVal
      ? calenderDropdownVal
      : selectedTimePeriod;
    defaultTimePeriod = initialLoad ? selectedTimePeriod : 'Last 12 Months';
    if (!moment(date).isValid()) {
      return false;
    }
    let quarterVal = getQuarterOfTheYear(date);
    const dates = getSelectedTimePeriod(date);
    let searchObjectCopy = { ...this.state.searchObject };
    searchObjectCopy.month = dates.month;
    searchObjectCopy.year = dates.year;
    searchObjectCopy.timePeriod = defaultTimePeriod;
    this.setState(
      {
        setDate: date,
        dropdownVale: [
          ...this.arr,
          `Quarter ${quarterVal}`,
          moment(date).format('MMMM YYYY'),
          ...CONFIG.TIME_PERIOD_MONTH,
        ],
        selectedTimePeriod: defaultTimePeriod,
        searchObject: searchObjectCopy,
      },
      () => {
        batch(() => {
          store.dispatch(updateFilters(searchObjectCopy));
          store.dispatch(updateInitialDate(this.state.setDate));
          store.dispatch(updateCalenderDropdownList(defaultTimePeriod));
          if (!initialLoad) {
            this.getMemberList(tableauStringData, selectedPath).then(() => {
              this.setTableauParamString();
            });
          }
        });
      }
    );
  };

  getDataFromConfiguration = (empData, impersonatorRole, getRole) => {
    const { empInitialData, employeeInitialData, memberDetails } = this.props;
    const empInitialDataFromStore =
      empData || empInitialData || employeeInitialData;
    // avoid backend call if we have employee initial data in store
    if (isEmpty(empInitialDataFromStore)) {
      const userDetailsUrl = !isEmpty(memberDetails)
        ? `${CONFIG.API_URLS.EMP}?employeeIdHRO=${memberDetails.employeeIdHRO}`
        : CONFIG.API_URLS.EMP;
      const baseUrl = CONFIG.KT_USER_BASE_URL;
      fetchGet(userDetailsUrl, baseUrl).then(data => {
        this.setInitialValue(data, impersonatorRole, getRole, isEmpty(empData));
      });
    } else {
      this.setInitialValue(
        empInitialDataFromStore,
        impersonatorRole,
        getRole,
        isEmpty(empData)
      );
    }
  };

  setInitialValue = (empData, impersonatorRole, getRole, isDataAvailable) => {
    const { memberDetails } = this.props;
    const assignedRoleDTO = [
      {
        paId: empData.paId,
        topicSectorId: empData.topicSectorId,
        subSectorId: empData.subSectorId,
        paTeam: empData.paTeam,
        sector: empData.sector,
        subSector: empData.subSector,
        sharedSubSector: empData.sharedSubSector,
        geoRegion: empData.geoRegion,
        managedBy: empData.managedBy,
        slotting: empData.slotting,
        roleId: empData.roleId,
        roleName: empData.roleName,
      },
    ];
    let initialDataList = [];
    // add the team member option only if the user is kt member
    if (empData.geoMember && isEmpty(empData.employeeRoleDTO)) {
      initialDataList = assignedRoleDTO;
    } else if (!isEmpty(empData.employeeRoleDTO)) {
      initialDataList = empData.employeeRoleDTO;
    } else {
      initialDataList = assignedRoleDTO.concat(empData.employeeRoleDTO);
    }
    initialDataList =
      initialDataList.filter(
        roleData => BUSINESS_COMPARISON_ROLE_ID !== roleData.roleId
      ) || [];
    let rolesList = [];
    if (isObject(empData)) {
      initialDataList.forEach(el => {
        rolesList.push(el.roleName);
      });
    }
    this.setState(
      {
        highestRoleIsGeo:
          rolesList.includes('Geo Manager') || rolesList.includes('Geo Lead'),
      },
      () => {
        if (impersonatorRole || !isEmpty(memberDetails)) {
          this.updateInitialValueForImpersonator(
            empData,
            initialDataList,
            isDataAvailable
          );
        } else {
          this.updateInitialValue(empData, initialDataList, getRole);
        }
      }
    );
  };

  getGeoMarketForImpersonateView = (isDataAvailable, data, isGeoLead) => {
    const { selectedGeo, highestRoleIsGeo } = this.state;
    const { selectedSectionDetails } = this.props;
    if (isDataAvailable) {
      return selectedSectionDetails.geoRegion;
    }

    if (isGeoLead || highestRoleIsGeo) {
      return data.geoRegion;
    }
    return selectedGeo;
  };

  getManagedByForImpersonatorView = (isDataAvailable, data, isGeoLead) => {
    const { managedBy, userSelectedManagedBy } = this.state;
    const { selectedSectionDetails } = this.props;

    if (userSelectedManagedBy && this.props.managedBy) {
      return this.props.managedBy;
    }

    if (isGeoLead) {
      return getManagedByDefaultValue(isGeoLead);
    }

    if (isDataAvailable) {
      return selectedSectionDetails.managedBy;
    }
    return managedBy;
  };

  updateInitialValueForImpersonatorCallback = (highestRoleIsGeo, role) => {
    if (highestRoleIsGeo) {
      this.getGeoMarketList(role);
    } else if (isInAdminRoleList(role) || isInManagerRoleList(role)) {
      this.getPaList(role);
    } else if (isEmpty(role)) {
      this.setParameterValueFromFilters();
    } else {
      this.navigateToUnauthorized();
    }
  };

  updateInitialValueForImpersonator = (
    data,
    initialDataList,
    isDataAvailable
  ) => {
    const { selectedPa, highestRole, highestRoleIsGeo } = this.state;
    const { userTypes, selectedSectionDetails } = this.props;
    const role = isDataAvailable ? highestRole : getViewName(data, userTypes);
    const isGeoLead = isGeoLeadRole(role);
    let paName =
      isGeoLead || !isEqual(selectedPa, OPTION_ALL) ? selectedPa : data.paTeam;
    paName = CONFIG.PA_NAME_ALIAS_MAP[paName] || paName;
    const geoMarket = this.getGeoMarketForImpersonateView(
      isDataAvailable,
      data,
      isGeoLead
    );
    const managedByValue = this.getManagedByForImpersonatorView(
      isDataAvailable,
      data,
      isGeoLead
    );
    const geoUserIndex = CONFIG.GEO_USER_TYPES.indexOf(managedByValue);
    const isGeoManaged = isEqual(geoUserIndex, 0);
    const isGeoManager = isGeoManagerRole(role);
    const passGeo = !isEmpty(geoMarket) || isGeoLead;
    const sectorName = isDataAvailable
      ? selectedSectionDetails.sector
      : data.sector;
    const subSectorName = isDataAvailable
      ? selectedSectionDetails.subSector
      : data.subSector;
    const isAdmin = isInAdminRoleList(role);
    const isManager = isInManagerRoleList(role);
    this.setState(
      {
        empInitialData: data,
        initialDataList,
        selectedItemForManager: this.getDropdownValue(
          paName,
          sectorName,
          subSectorName
        ),
        highestRole: role,
        passGeo,
        geoUserIndex,
        isAdmin,
        isSuperAdmin: isSuperAdminRole(role),
        isImpersonator: isImpersonatorRole(role),
        selectedPa: paName,
        isGeoManaged,
        isGeoManager,
        isGeoLead,
        selectedGeo: geoMarket,
        managedBy: managedByValue,
        selectedManagedBy: Array.isArray(managedByValue)
          ? managedByValue
          : [managedByValue],
        selectedTeamMembers: [],
        displayName: OPTION_ALL,
        isManager,
      },
      () => {
        store.dispatch(updateEmployeeInitialData(data));
        store.dispatch(passGeoDetails(passGeo));
        store.dispatch(updateUserIsGeoManaged(isGeoManaged));
        store.dispatch(updateIsGeoManager(isGeoManager));
        store.dispatch(updateIsGeoLead(isGeoLead));
        this.updateInitialValueForImpersonatorCallback(highestRoleIsGeo, role);
        store.dispatch(updateSelectedTeamMembers([]));
        store.dispatch(updateDisplayName(OPTION_ALL));
      }
    );
  };

  getRoleInRealView = (getRole, data) => {
    const { userTypes } = this.props;
    const { highestRole } = this.state;
    let roleName = highestRole;
    if (getRole) {
      roleName = getViewName(data, userTypes);
    } else if (typeof highestRole === 'undefined') {
      roleName = this.getHighestRole();
    }

    return roleName;
  };

  getPaInRealView = (getRole, data, isGeoLead) => {
    const { selectedPa, isFilterApplied } = this.state;
    let paVal = selectedPa;
    if (isGeoLead && data.paId === -1 && !selectedPa) {
      paVal = OPTION_ALL;
    } else if (
      (isEqual(selectedPa, OPTION_ALL) && !isFilterApplied) ||
      getRole
    ) {
      paVal = data.paTeam ? data.paTeam : selectedPa;
    }

    return paVal;
  };

  getSectorInRealView = (getRole, data) => {
    const { selectedSector, isFilterApplied, isAllSectorSelected } = this.state;
    let sectorVal = selectedSector;
    if ((isAllSectorSelected && !isFilterApplied) || getRole) {
      sectorVal = isEqual(data.topicSectorId, -1) ? OPTION_ALL : data.sector;
    }
    return sectorVal;
  };

  getSubSectorInRealView = (getRole, data) => {
    const { selectedSubSector, isFilterApplied } = this.state;
    let subSectorVal = selectedSubSector;
    if (
      (isEqual(selectedSubSector, OPTION_ALL) && !isFilterApplied) ||
      getRole
    ) {
      subSectorVal = data.subSector ? data.subSector : OPTION_ALL;
    }

    return subSectorVal;
  };

  getEmpData = ({
    data,
    pa,
    sectorName,
    subSectorName,
    role,
    isAdmin,
    isManager,
  }) => {
    const empData = isObject(data)
      ? data.employeeRoleDTO.filter(
          item =>
            isEqual(item.paTeam, pa) &&
            isEqual(item.sector, sectorName) &&
            (isEqual(item.subSector, subSectorName) || !item.subSector) &&
            isEqual(item.roleName, role)
        )
      : {};

    if (isAdmin || isEmpty(empData) || isManager) {
      return data;
    }

    return empData;
  };

  getGeoMarket = (selectedGeo, empData) => {
    const { isManager, isGeoManager } = this.state;
    let geoRegion = empData.geoRegion;
    if (isEqual(selectedGeo, 'No Geo')) geoRegion = 'No Geo';
    else if (isArray(empData)) geoRegion = empData[0].geoRegion;
    else if (isManager || isGeoManager)
      geoRegion = empData.employeeRoleDTO[0].geoRegion;

    return geoRegion;
  };

  isGeoManagedUser = empData => {
    const geoUserIndex = CONFIG.GEO_USER_TYPES.indexOf(
      isArray(empData) ? empData[0].managedBy : empData.managedBy
    );
    return geoUserIndex === 0;
  };

  getManagedBy = empData => {
    return isArray(empData) ? empData[0].managedBy : empData.managedBy;
  };

  getPassgeo = (geoMarket, isGeoLead) => {
    return geoMarket || isGeoLead;
  };

  updateInitialValue = (data, initialDataList, getRole) => {
    const { selectedSectionDetailOptionValue } = this.props;
    const { highestRoleIsGeo, selectedGeo } = this.state;
    const role = this.getRoleInRealView(getRole, data);
    const isGeoLead = isGeoLeadRole(role);
    const pa = this.getPaInRealView(getRole, data, isGeoLead);
    const sector = this.getSectorInRealView(getRole, data);
    const subSector = this.getSubSectorInRealView(getRole, data);
    const sectorName = isEqual(sector, OPTION_ALL) ? '' : sector;
    const subSectorName = isEqual(subSector, OPTION_ALL) ? '' : subSector;
    const paName = CONFIG.PA_NAME_ALIAS_MAP[pa] || pa;
    const isAdmin = isInAdminRoleList(role);
    const isManager = isInManagerRoleList(role);
    const empData = this.getEmpData({
      data,
      pa,
      sectorName,
      subSectorName,
      role,
      isAdmin,
      isManager,
    });
    const geoMarket = this.getGeoMarket(selectedGeo, empData);
    const managedBy = this.getManagedBy(empData);
    const isGeoManaged = this.isGeoManagedUser(empData);
    const isGeoManager = isGeoManagerRole(role);
    const passGeo = this.getPassgeo(geoMarket, isGeoLead);
    const selectedDropDownValue = this.getDropdownValue(
      paName,
      sector,
      subSector
    );
    this.setState(
      {
        empInitialData: data,
        initialDataList,
        selectedItemForManager: selectedSectionDetailOptionValue
          ? selectedSectionDetailOptionValue
          : selectedDropDownValue,
        highestRole: role,
        passGeo,
        isGeoLead,
        isAdmin,
        isSuperAdmin: isSuperAdminRole(role),
        isImpersonator: isImpersonatorRole(role),
        isGeoManager,
        isGeoManaged,
        selectedGeo: geoMarket,
        selectedPa: paName,
        managedBy,
        isManager,
      },
      () => {
        const { userTypes, ktUserDetails } = this.props;
        const highestRole = getViewName(data, userTypes, ktUserDetails) || '';
        store.dispatch(updateEmployeeInitialData(data));
        store.dispatch(passGeoDetails(passGeo));
        store.dispatch(updateUserIsGeoManaged(isGeoManaged));
        store.dispatch(updateIsGeoManager(isGeoManager));
        store.dispatch(updateIsGeoLead(isGeoLead));
        if (highestRoleIsGeo) {
          this.getGeoMarketList(role);
        } else if (
          isEqual(role, highestRole) &&
          (isInAdminRoleList(highestRole) || isManager)
        ) {
          this.getPaList(role);
        } else if (isEmpty(role)) {
          this.setParameterValueFromFilters();
        } else {
          this.navigateToUnauthorized();
        }
      }
    );
  };

  getDropdownValue = (pa, sector, subSector) => {
    let dropdownValue = '';
    if (!isEmpty(subSector) && !isEqual(subSector, OPTION_ALL)) {
      if (isEqual(subSector, 'Unspecified'))
        dropdownValue = `${sector}/${subSector}`;
      else dropdownValue = subSector;
    } else if (!isEmpty(sector) && !isEqual(sector, OPTION_ALL)) {
      if (isEqual(sector, 'Unspecified')) dropdownValue = `${pa}/${sector}`;
      else dropdownValue = sector;
    } else {
      dropdownValue = pa;
    }
    return dropdownValue;
  };

  setParameterValueFromFilters = () => {
    const { initialDataList } = this.state;
    !isEmpty(initialDataList) && this.loadMemberView(initialDataList[0], true);
  };

  createSearchObject = (pa, sector, subSector, sharedSubSector, role) => {
    const { selectedTimePeriod, selectedGeo, managedBy } = this.state;
    const dates = getSelectedTimePeriod(this.state.setDate);
    const paName = CONFIG.PA_NAME_ALIAS_MAP[pa] || pa;
    const searchObject = {
      geoMarket: selectedGeo,
      pa: paName,
      sector,
      subSector,
      sharedSubSector: sharedSubSector,
      timePeriod: selectedTimePeriod,
      month: dates.month,
      year: dates.year,
      role,
      managedBy,
    };
    this.setState({ searchObject });
    store.dispatch(updateFilters(searchObject));
  };

  onSelectionChange = async item => {
    const { selectedPath, searchObject } = this.state;
    const { tableauStringData } = this.props;
    let searchObjectCopy = { ...searchObject };
    searchObjectCopy.timePeriod = item;
    this.setState(
      {
        selectedTimePeriod: item,
        searchObject: searchObjectCopy,
      },
      () => {
        this.getMemberList(tableauStringData, selectedPath).then(() => {
          batch(() => {
            store.dispatch(updateCalenderDropdownList(item));
            this.setTableauParamString();
          });
        });
      }
    );
    store.dispatch(updateFilters(searchObjectCopy));
  };

  getHighestRole = empData => {
    const { loggedInUserHighestRole, userTypes, ktUserDetails } = this.props;
    const data = empData ? empData : this.state.empInitialData;
    let highestRole = '';
    if (loggedInUserHighestRole) {
      highestRole = loggedInUserHighestRole;
    } else {
      highestRole = getViewName(data, userTypes, ktUserDetails);
      store.dispatch(updateLoggedInUserHighestRole(highestRole));
    }
    const isAdmin = CONFIG.ADMIN_ROLES.includes(highestRole);
    const isSuperAdmin = isEqual(highestRole, 'Super Admin');
    const isImpersonator = isEqual(highestRole, CONFIG.IMPERSONATOR);
    const isManager = isInManagerRoleList(highestRole);
    this.setState({
      highestRole,
      isAdmin,
      isSuperAdmin,
      isImpersonator,
      isManager,
    });
    return highestRole;
  };

  /**
   * get geo market list for geo lead
   */

  getGeoMarketList = role => {
    let geoMarketList = [];
    let { empInitialData, isGeoManager } = this.state;
    let geoLeadManagerList = [];
    const employeeRoleDTO =
      empInitialData.employeeRoleDTO.filter(
        roleData => BUSINESS_COMPARISON_ROLE_ID !== roleData.roleId
      ) || [];
    employeeRoleDTO.forEach(item => {
      if (isInGeoAdminRoleList(item.roleName)) {
        const isGeoExist = geoMarketList.find(o => o === item.geoRegion);
        if (!isGeoExist) geoMarketList.push(item.geoRegion);
      } else if (geoMarketList.indexOf('No Geo') < 0 && !isGeoManager) {
        geoMarketList.push('No Geo');
        geoLeadManagerList.push(item);
      } else {
        geoLeadManagerList.push(item);
      }
    });
    store.dispatch(updateGeoMarketList(geoMarketList));
    this.setParameterForGeoMarket(geoMarketList, geoLeadManagerList, role);
  };

  getSelectedGeoMarket = ({
    selectedGeo,
    fetchImpersonatorUserList,
    userSelectedGeo,
    getDataFromStore,
    selectedSectionDetails,
    geoMarketList,
  }) => {
    if (fetchImpersonatorUserList) {
      return userSelectedGeo;
    }
    if (getDataFromStore) {
      return selectedSectionDetails.geoRegion;
    }

    if (isEqual(selectedGeo, OPTION_ALL)) {
      return geoMarketList[0];
    }

    return selectedGeo;
  };

  setParameterForGeoMarket = (geoMarketList, geoLeadManagerList, role) => {
    const { selectedGeo } = this.state;
    const { selectedSectionDetails, fromAdmin, fetchImpersonatorUserList } =
      this.props;
    const getDataFromStore = !isEmpty(selectedSectionDetails) ? true : false;
    let userSelectedGeo = '';
    if (!isEqual(selectedGeo, OPTION_ALL)) {
      const userSelectedGeoList = geoMarketList.filter(item =>
        isEqual(item.toLowerCase(), selectedGeo?.toLowerCase())
      );
      if (isEmpty(userSelectedGeoList)) {
        this.navigateToUnauthorized();
      } else {
        userSelectedGeo = userSelectedGeoList[0];
      }
    }
    const selectedGeoMarket = this.getSelectedGeoMarket({
      fetchImpersonatorUserList,
      userSelectedGeo,
      getDataFromStore,
      selectedSectionDetails,
      selectedGeo,
      geoMarketList,
    });
    this.setState(
      {
        geoList: geoMarketList,
        selectedGeo: selectedGeoMarket,
        geoListToBeShown: geoMarketList,
        geoLeadManagerList: geoLeadManagerList,
      },
      () => {
        !fromAdmin && this.getPaList(role);
      }
    );
  };

  /**
   *
   * get pa list for admin(super admin and pa admin)
   */

  getPaListForAdmin = isGeoMarketClicked => {
    const { highestRoleIsGeo, selectedGeo, geoLeadManagerList, isGeoManager } =
      this.state;
    let paList = [];
    let PAUrl = CONFIG.API_URLS.EMP_PA;
    const { ktSaPaList, employeeInitialData } = this.props;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    PAUrl = highestRoleIsGeo ? `${PAUrl}?geoRegion=${selectedGeo}` : PAUrl;
    PAUrl = isGeoManager
      ? `${PAUrl}&employeeIdHRO=${employeeInitialData.employeeIdHRO}`
      : PAUrl;
    // if user is super admin
    if (ktSaPaList && !highestRoleIsGeo) {
      return this.setParametersForAdmin(ktSaPaList);
    }

    if (highestRoleIsGeo && isEqual(selectedGeo, 'No Geo')) {
      geoLeadManagerList.forEach(item => {
        if (isEmpty(paList.find(({ paId }) => paId === item.paId))) {
          paList.push({
            paId: item.paId,
            practiceArea: item.paTeam,
          });
        }
      });
      if (paList.length > 1) {
        paList.push({
          paId: -1,
          practiceArea: OPTION_ALL,
        });
      }
      const sortedPaList = sortPaList(paList, 'practiceArea');
      store.dispatch(updateSaPaList(undefined));
      return this.setParametersForAdmin(sortedPaList, isGeoMarketClicked);
    }

    fetchGet(PAUrl, baseUrl).then(data => {
      data.forEach(el => {
        if (el.paId && el.practiceArea) {
          paList.push(el);
        }
      });
      if (
        highestRoleIsGeo &&
        !isEqual(selectedGeo, 'No Geo') &&
        paList.length > 1
      ) {
        paList.push({
          paId: -1,
          practiceArea: OPTION_ALL,
        });
      }
      const sortedPaList = sortPaList(paList, 'practiceArea');
      store.dispatch(updateSaPaList(sortedPaList));
      this.setParametersForAdmin(sortedPaList, isGeoMarketClicked);
    });
  };

  getPaList = (role, isGeoMarketClicked) => {
    let paList = [];
    let { empInitialData } = this.state;
    const superAdmin = isInSuperAdminRoleList(role);
    const paAdmin = isInPaAdminRoleList(role);
    const impersonator = isImpersonatorRole(role);
    const isManager = isInManagerRoleList(role);
    const isGeoAdminUser = isInGeoAdminRoleList(role);
    if (superAdmin || impersonator || isGeoAdminUser) {
      this.getPaListForAdmin(isGeoMarketClicked);
    } else if (paAdmin || isManager) {
      // if user is pa admin
      empInitialData.employeeRoleDTO.forEach(item => {
        const isPaExist = paList.find(o => o.practiceArea === item?.paTeam);
        if (
          (isInPaAdminRoleList(item.roleName) ||
            isInManagerRoleList(item.roleName)) &&
          !isObject(isPaExist)
        ) {
          paList.push({
            paId: item.paId,
            practiceArea: item?.paTeam,
          });
        }
      });
      const sortedPaList = sortPaList(paList, 'practiceArea');
      store.dispatch(updatePaPaList(sortedPaList));
      this.setParametersForAdmin(sortedPaList);
    }
  };

  /**
   *
   * set state of default parameters based on store for super admin
   */
  getPaForSuperAdmin = ({ isGeoMarketClicked, sortedPaList }) => {
    const { selectedPa, highestRoleIsGeo, isManager } = this.state;
    const { selectedSectionDetails, fetchImpersonatorUserList } = this.props;
    const getDataFromStore = !isEmpty(selectedSectionDetails);
    let userSelectedPaId = '';
    let paIds = [],
      paNames = [];
    let paName = CONFIG.ALIAS_TO_PA_NAME_MAP[selectedPa] || selectedPa;
    if (!isEqual(selectedPa, OPTION_ALL)) {
      const userSelectedPaList = sortedPaList.filter(item =>
        isEqual(item.practiceArea.toLowerCase(), paName.toLowerCase())
      );
      if (isEmpty(userSelectedPaList)) {
        if (isManager) {
          let pa = sortedPaList[0].practiceArea;
          userSelectedPaId = sortedPaList[0].paId;
          paIds.push(userSelectedPaId);
          paNames.push(pa);
          paName = CONFIG.ALIAS_TO_PA_NAME_MAP[pa] || pa;
        } else {
          this.navigateToUnauthorized();
        }
      } else {
        userSelectedPaId = userSelectedPaList[0].paId;
        paIds.push(userSelectedPaList[0].paId);
        paNames.push(userSelectedPaList[0].practiceArea);
      }
    }
    sortedPaList.forEach(paDetails => {
      if (paDetails.paId !== -1) {
        paIds.push(paDetails.paId);
        paNames.push(paDetails.practiceArea);
      }
    });
    if (isGeoMarketClicked) {
      return [
        sortedPaList[0].practiceArea,
        sortedPaList[0].paId,
        paNames,
        paIds,
      ];
    }
    if (fetchImpersonatorUserList) {
      return [paName, userSelectedPaId, [paName], [userSelectedPaId]];
    }

    if (getDataFromStore) {
      return [
        selectedSectionDetails.paTeam,
        selectedSectionDetails.paId,
        selectedSectionDetails.paTeams,
        selectedSectionDetails.paIds,
      ];
    }
    if (isEqual(selectedPa, OPTION_ALL)) {
      if (highestRoleIsGeo) {
        return [
          sortedPaList[0].practiceArea,
          sortedPaList[0].paId,
          paNames,
          paIds,
        ];
      } else {
        return [
          sortedPaList[0].practiceArea,
          sortedPaList[0].paId,
          [sortedPaList[0].practiceArea],
          [sortedPaList[0].paId],
        ];
      }
    } else {
      return [paName, userSelectedPaId, [paName], [userSelectedPaId]];
    }
  };

  setParametersForAdmin = (sortedPaList, isGeoMarketClicked) => {
    const { selectedGeo } = this.state;
    const { fromAdmin } = this.props;

    const [selectedPa, selectedPaId, selectedPas, selectedPaIds] =
      this.getPaForSuperAdmin({
        isGeoMarketClicked,
        sortedPaList,
      });
    this.setState(
      {
        selectedPa,
        selectedPaId,
        pAList: sortedPaList,
        paListToBeShown: sortedPaList,
        fetchPaList: true,
        selectedPaIds,
        selectedPas,
      },
      () => {
        const isPaClicked = false;
        const fromSuperAdmin = true;
        const isSectorClicked = false;
        if (selectedPaId > 0) {
          if (isEqual(selectedGeo, 'No Geo')) {
            !fromAdmin &&
              this.getSectorsOfSelectedPa(isGeoMarketClicked, fromSuperAdmin);
          } else {
            !fromAdmin &&
              this.getSectorsOfSelectedPa(isPaClicked, fromSuperAdmin).then(
                () => {
                  this.getSubSectorsOfSelectedSector(
                    isSectorClicked,
                    fromSuperAdmin
                  );
                }
              );
          }
        } else {
          const sectorList = [allSector];
          const subSectorList = [allSubSector];
          this.setState(
            {
              selectedSectors: [],
              selectedSectorIds: [],
              selectedSector: OPTION_ALL,
              selectedSectorId: -1,
              fetchSectorList: true,
              isAllSectorSelected: true,
              selectedSubSector: OPTION_ALL,
              selectedSubSectorId: -1,
              selectedSubSectors: [],
              selectedSubSectorIds: [],
              fetchSubSectorList: true,
              isAllSubSectorSelected: true,
              sectorList,
              subSectorList,
              sectorListToBeShown: sectorList,
              subSectorListToBeShown: subSectorList,
            },
            () => {
              if (!isGeoMarketClicked) this.setParameterValue(true);
            }
          );
        }
      }
    );
  };

  /**
   * This method will redirect the user Access required page of UIA Portal
   */
  navigateToUnauthorized = () => {
    const { ktUserDetails } = this.state;
    this.setState({ searchObject: {} }, () => {
      navigateToUnauthorized(this.props.history, store, ktUserDetails);
    });
  };

  showPaListHandler() {
    this.setState({
      showPaList: true,
    });
  }

  showGeoMarketListHandler() {
    this.setState({
      showGeoMarketList: true,
    });
  }

  showManagedByList() {
    this.setState({
      showManagedByList: true,
    });
  }

  showUsers() {
    this.setState({
      showUsers: true,
    });
  }

  showPath() {
    this.setState({
      showPathList: true,
    });
  }

  geoMarketClickHandler = async geoMarket => {
    store.dispatch(setAdminClick(false));
    this.setState(
      {
        showGeoMarketList: false,
        selectedGeo: geoMarket,
        fetchPaList: false,
        fetchSectorList: false,
        fetchSubSectorList: false,
        highestRole: !isEqual(geoMarket, 'No Geo')
          ? 'Geo Lead'
          : this.state.highestRole,
        selectedValue: !isEqual(geoMarket, 'No Geo')
          ? {}
          : this.state.selectedValue,
        passGeo: !isEqual(geoMarket, 'No Geo') ? true : this.state.passGeo,
        selectedPa: OPTION_ALL,
        isGeoLead: isEqual(geoMarket, 'No Geo') ? false : true,
      },
      () => {
        this.getPaList(this.state.highestRole, true);
      }
    );
  };

  paListClickHandler = async (practiceArea, id) => {
    const { paListToBeShown, isGeoManager } = this.state;
    store.dispatch(setAdminClick(false));
    if (isEqual(id, -1)) {
      let paIds = [],
        paNames = [];
      paListToBeShown.forEach(el => {
        if (el.paId !== -1) {
          paIds.push(el.paId);
          paNames.push(el.practiceArea);
        }
      });
      const sectorList = [allSector];
      const subSectorList = [allSubSector];
      this.setState(
        {
          isAllPaSelected: true,
          fetchPaList: true,
          selectedSectors: [],
          selectedSectorIds: [],
          selectedSector: OPTION_ALL,
          selectedSectorId: -1,
          fetchSectorList: true,
          isAllSectorSelected: true,
          selectedSubSector: OPTION_ALL,
          selectedSubSectorId: -1,
          selectedSubSectors: [],
          selectedSubSectorIds: [],
          fetchSubSectorList: true,
          isAllSubSectorSelected: true,
          sectorList,
          subSectorList,
          sectorListToBeShown: sectorList,
          subSectorListToBeShown: subSectorList,
          showPaList: false,
          selectedPa: practiceArea,
          selectedPaId: id,
          selectedPaIds: paIds,
          selectedPas: paNames,
        },
        () => {
          if (isGeoManager) {
            this.getSectorsOfSelectedPa(true);
          }
        }
      );
    } else {
      this.setState(
        {
          isAllPaSelected: false,
          fetchPaList: true,
          showPaList: false,
          selectedPa: practiceArea,
          selectedPaId: id,
          fetchSectorList: false,
          fetchSubSectorList: false,
          selectedPaIds: [id],
          selectedPas: [practiceArea],
        },
        () => {
          this.getSectorsOfSelectedPa(true);
        }
      );
    }
  };

  getSubSectorListForNoGeo = (
    isPaClicked,
    fromSuperAdmin,
    isCollectionApplied
  ) => {
    const { selectedPaId, geoLeadManagerList } = this.state;
    let { selectedSectionDetails } = this.props;
    const getDataFromStore = !isEmpty(selectedSectionDetails);
    let selectedValue = {};
    let data = [];
    geoLeadManagerList.forEach(el => {
      if (isEqual(selectedPaId, el.paId)) {
        selectedValue = el;
        if (
          isEmpty(
            data.find(
              ({ subSector }) => subSector.topicSectorId === el.topicSectorId
            )
          )
        ) {
          if (el.topicSectorId) {
            data.push({
              topicSectorId: el.topicSectorId,
              sector: el.sector,
              sharedSubSector: el.sharedSubSector,
            });
          } else {
            data.unshift(allSector);
          }
        }
      }
    });
    const { sector, topicSectorId, sharedSubSector } =
      getDataFromStore && !isPaClicked ? selectedSectionDetails : data[0];
    const selectedSectorData = {
      sector,
      topicSectorId,
      sharedSubSector,
    };
    store.dispatch(updateSelectedSectorDetails(selectedSectorData));
    this.setState(
      {
        sectorList: data,
        sectorListToBeShown: data,
        selectedSector: sector,
        selectedSectorId: topicSectorId,
        isPaClicked,
        fromSuperAdmin,
        fetchSectorList: true,
        selectedValue,
        selectedSharedSubSector: sharedSubSector,
        passGeo: isEmpty(selectedValue?.geoRegion) ? false : true,
      },
      () => {
        this.getSubSectorsOfSelectedSector(
          isPaClicked,
          false,
          isCollectionApplied
        );
      }
    );
  };

  getSelectedSector = (
    isPaClicked,
    fromSuperAdmin,
    isCollectionApplied,
    data
  ) => {
    const {
      empInitialData,
      selectedSector,
      selectedSectorId,
      selectedSharedSubSector,
      isManager,
      highestRoleIsGeo,
      selectedSectors,
      selectedSectorIds,
      selectedSharedSubSectors,
    } = this.state;
    let sectors = [],
      sectorIds = [],
      sharedSubSectors = [];
    const { selectedSectionDetails, fetchImpersonatorUserList } = this.props;
    const getDataFromStore = !isEmpty(selectedSectionDetails);
    const passAllValue = isManager || highestRoleIsGeo;
    if (isCollectionApplied) {
      return [
        selectedSector,
        selectedSectorId,
        selectedSharedSubSector,
        selectedSectors,
        selectedSectorIds,
        selectedSharedSubSectors,
      ];
    }

    if (isPaClicked || (fetchImpersonatorUserList && !getDataFromStore)) {
      if (data.length === 1 || !passAllValue) {
        return [
          data[0].sector,
          data[0].topicSectorId,
          data[0].sharedSubSector,
          [data[0].sector],
          [data[0].topicSectorId],
          [data[0]?.sharedSubSector],
        ];
      } else if (data.length > 1 && passAllValue) {
        data.forEach(el => {
          if (el.topicSectorId !== -1) {
            sectors.push(el.sector);
            sectorIds.push(el.topicSectorId);
            if (el.sharedSubSector) sharedSubSectors.push(el.sharedSubSector);
          }
        });
        return [
          data[0].sector,
          data[0].topicSectorId,
          data[0].sharedSubSector,
          sectors,
          sectorIds,
          sharedSubSectors,
        ];
      }
    }

    if (getDataFromStore) {
      const selectedSectionSector =
        selectedSectionDetails.sector === null
          ? OPTION_ALL
          : selectedSectionDetails.sector;
      return [
        selectedSectionSector,
        selectedSectionDetails.topicSectorId,
        selectedSectionDetails.sharedSubSector,
        [selectedSectionSector],
        [selectedSectionDetails.topicSectorId],
        [selectedSectionDetails?.sharedSubSector],
      ];
    }

    if (!isEqual(selectedSector, OPTION_ALL)) {
      if (isEmpty(selectedSector)) {
        return [OPTION_ALL, 0, '', [], [], []];
      }

      const selectedSectorList = data.filter(item =>
        isEqual(item.sector.toLowerCase(), selectedSector.toLowerCase())
      );
      return [
        selectedSector,
        selectedSectorList[0]?.topicSectorId,
        selectedSectorList[0]?.sharedSubSector,
        [selectedSector],
        [selectedSectorList[0]?.topicSectorId],
        [selectedSectorList[0]?.sharedSubSector],
      ];
    }
    if (isEqual(empInitialData.topicSectorId, -1) || fromSuperAdmin) {
      let sectors, sectorIds, sharedSubSectors;
      if (passAllValue) {
        [sectors, sectorIds, sharedSubSectors] = getSectorListWithAll(
          data,
          OPTION_ALL,
          -1,
          null,
          true
        );
      } else {
        sectors = [data[0].sector];
        sectorIds = [data[0].topicSectorId];
        sharedSubSectors = [data[0]?.sharedSubSector];
      }
      return [
        data[0].sector,
        data[0].topicSectorId,
        data[0].sharedSubSector,
        sectors,
        sectorIds,
        sharedSubSectors,
      ];
    }

    return [
      selectedSector,
      selectedSectorId,
      selectedSharedSubSector,
      sectors,
      sectorIds,
      sharedSubSectors,
    ];
  };

  getSectorsOfSelectedPa = (
    isPaClicked,
    fromSuperAdmin,
    isCollectionApplied
  ) => {
    //safe guard
    const { selectedPaIds, selectedGeo } = this.state;
    if (isEmpty(selectedPaIds)) {
      return;
    }
    let data = [];
    data.unshift(allSector);
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    const url = this.getSectorUrl();
    if (isEqual(selectedGeo, 'No Geo')) {
      this.getSubSectorListForNoGeo(
        isPaClicked,
        fromSuperAdmin,
        isCollectionApplied
      );
    } else {
      return fetchGet(url, baseUrl).then(result => {
        result.forEach(el => {
          if (el.topicSectorId) {
            data.push(el);
          }
        });
        let [
          newSelectedSector,
          newSelectedSectorId,
          newSelectedSharedSubSector,
          sectors,
          topicSectorIds,
          sharedSubSectors,
        ] = this.getSelectedSector(
          isPaClicked,
          fromSuperAdmin,
          isCollectionApplied,
          data
        );

        if (!isPaClicked) {
          let selectedSectorData = {
            sector: newSelectedSector,
            topicSectorId: newSelectedSectorId,
            sharedSubSector: newSelectedSharedSubSector,
            sectors,
            topicSectorIds,
            sharedSubSectors,
          };
          store.dispatch(updateSelectedSectorDetails(selectedSectorData));
        }
        this.setState(
          {
            sectorList: data,
            sectorListToBeShown: data,
            selectedSector: newSelectedSector,
            selectedSectorId: newSelectedSectorId,
            selectedSectors: sectors,
            selectedSectorIds: topicSectorIds,
            isPaClicked,
            fromSuperAdmin,
            fetchSectorList: true,
            selectedSharedSubSector: newSelectedSharedSubSector,
            selectedSharedSubSectors: sharedSubSectors,
            isAllSectorSelected:
              sectors.length > 1 || topicSectorIds.includes(0),
          },
          () => {
            return (
              isPaClicked &&
              this.getSubSectorsOfSelectedSector(
                true,
                false,
                isCollectionApplied
              )
            );
          }
        );
      });
    }
  };

  getSectorUrl = () => {
    const {
      selectedPaId,
      selectedPaIds,
      selectedGeo,
      highestRoleIsGeo,
      isManager,
      isGeoManager,
    } = this.state;
    const { employeeInitialData } = this.props;
    const apiUrl = CONFIG.API_URLS.EMP_SECTOR;
    let url = `${apiUrl}?id=${selectedPaId}`;
    if (isGeoManager && selectedPaIds.length > 1) {
      let urlSuffix = `?geoRegion=${selectedGeo}&employeeIdHRO=${employeeInitialData.employeeIdHRO}`;
      selectedPaIds.forEach(id => {
        urlSuffix = `${urlSuffix}&id=${id}`;
      });
      url = `${apiUrl}${urlSuffix}`;
    } else if (highestRoleIsGeo && isGeoManager)
      url = `${url}&geoRegion=${selectedGeo}&employeeIdHRO=${employeeInitialData.employeeIdHRO}`;
    else if (highestRoleIsGeo) url = `${url}&geoRegion=${selectedGeo}`;
    else if (isManager)
      url = `${url}&employeeIdHRO=${employeeInitialData.employeeIdHRO}`;
    return url;
  };

  sectorListClickHandler = async (
    sectorName,
    topicSectorId,
    sharedSubSector
  ) => {
    const { sectorList, highestRoleIsGeo, isManager } = this.state;
    store.dispatch(setAdminClick(false));
    const [selectedSectors, selectedSectorIds, selectedSharedSubSectors] =
      getSectorListWithAll(
        sectorList,
        sectorName,
        topicSectorId,
        sharedSubSector,
        highestRoleIsGeo || isManager
      );
    this.setState(
      {
        showSectorList: false,
        selectedSector: sectorName,
        selectedSectorId: topicSectorId,
        fetchSubSectorList: false,
        sectorListToBeShown: this.state.sectorList,
        selectedSharedSubSector: sharedSubSector,
        selectedSectors,
        selectedSectorIds,
        selectedSharedSubSectors,
      },
      () => {
        this.getSubSectorsOfSelectedSector(true);
      }
    );
  };

  getSubSectorsOfSelectedSector = (
    isSectorClicked,
    fromSuperAdmin,
    isCollectionApplied
  ) => {
    //safe guard
    const {
      selectedSectorId,
      highestRoleIsGeo,
      selectedGeo,
      geoLeadManagerList,
      isManager,
      isGeoManager,
      selectedSectorIds,
      selectedSector,
    } = this.state;
    const { employeeInitialData } = this.props;
    let data = [];
    data.unshift(allSubSector);
    if (selectedSectorId <= 0 && !isManager) {
      return this.setSelectedSubSector(data, isSectorClicked, fromSuperAdmin);
    }
    const apiUrl = CONFIG.API_URLS.EMP_SUB_SECTOR;
    let url = `${apiUrl}?id=${selectedSectorId}`;
    if (isEqual(selectedSector, OPTION_ALL)) {
      let urlSuffix = `?employeeIdHRO=${employeeInitialData.employeeIdHRO}`;
      if (isGeoManager) {
        urlSuffix = `${urlSuffix}&geoRegion=${selectedGeo}`;
      }
      selectedSectorIds.forEach(id => {
        urlSuffix = `${urlSuffix}&id=${id}`;
      });
      url = `${apiUrl}${urlSuffix}`;
    } else if (highestRoleIsGeo && isGeoManager)
      url = `${url}&geoRegion=${selectedGeo}&employeeIdHRO=${employeeInitialData.employeeIdHRO}`;
    else if (highestRoleIsGeo) url = `${url}&geoRegion=${selectedGeo}`;
    else if (isManager)
      url = `${url}&employeeIdHRO=${employeeInitialData.employeeIdHRO}`;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    if (isEqual(selectedGeo, 'No Geo')) {
      geoLeadManagerList.forEach(el => {
        if (isEqual(el.topicSectorId, selectedSectorId)) {
          if (el.subSectorId) {
            data.push({
              subSectorId: el.subSectorId,
              subSector: el.subSector,
              sharedSubSector: el.sharedSubSector,
            });
          }
        }
      });
      return this.setSelectedSubSector(
        data,
        isSectorClicked,
        fromSuperAdmin,
        isCollectionApplied
      );
    }

    fetchGet(url, baseUrl).then(result => {
      result.forEach(el => {
        if (el.subSectorId) {
          data.push(el);
        }
      });
      let sortedSubSectorList = sortSubSectorList(data);
      this.setSelectedSubSector(
        sortedSubSectorList,
        isSectorClicked,
        fromSuperAdmin,
        isCollectionApplied
      );
    });
  };

  getSelectedSubSector = ({
    data,
    isSectorClicked,
    fromSuperAdmin,
    isCollectionApplied,
  }) => {
    const { selectedSectionDetails, fetchImpersonatorUserList } = this.props;
    const getDataFromStore = !isEmpty(selectedSectionDetails);
    const {
      empInitialData,
      selectedSharedSubSector,
      selectedSubSector,
      selectedSubSectorId,
      isPaClicked,
      highestRoleIsGeo,
      isManager,
      selectedSharedSubSectors,
      selectedSubSectors,
      selectedSubSectorIds,
    } = this.state;
    if (isCollectionApplied) {
      return [
        selectedSubSector,
        selectedSubSectorId,
        selectedSharedSubSector,
        selectedSubSectors,
        selectedSubSectorIds,
        selectedSharedSubSectors,
      ];
    }
    const passAllValue = highestRoleIsGeo || isManager;
    let newSelectedSharedSubSector = selectedSharedSubSector
      ? selectedSharedSubSector
      : data[0].sharedSubSector;
    let subSectors = [],
      subSectorIds = [],
      sharedSubSectors = [];
    if (
      isSectorClicked ||
      isPaClicked ||
      (fetchImpersonatorUserList && !getDataFromStore)
    ) {
      if (data.length === 1 || !passAllValue) {
        return [
          data[0].subSector,
          data[0].subSectorId,
          newSelectedSharedSubSector,
          [data[0].subSector],
          [data[0].subSectorId],
          [newSelectedSharedSubSector],
        ];
      } else if (data.length > 1 && passAllValue) {
        data.forEach(el => {
          if (el.subSectorId !== -1) {
            subSectors.push(el.subSector);
            subSectorIds.push(el.subSectorId);
            if (el.sharedSubSector) sharedSubSectors.push(el.sharedSubSector);
          }
        });
        return [
          data[0].subSector,
          data[0].subSectorId,
          data[0].sharedSubSector,
          subSectors,
          subSectorIds,
          sharedSubSectors,
        ];
      }
    }

    newSelectedSharedSubSector = selectedSharedSubSector
      ? selectedSharedSubSector
      : selectedSectionDetails.sharedSubSector;
    if (getDataFromStore) {
      const selectedSectionSubSector =
        selectedSectionDetails.subSector === null
          ? OPTION_ALL
          : selectedSectionDetails.subSector;
      return [
        selectedSectionSubSector,
        selectedSectionDetails.subSectorId,
        newSelectedSharedSubSector,
        [selectedSectionSubSector],
        [selectedSectionDetails.subSectorId],
        [newSelectedSharedSubSector],
      ];
    }

    if (!isEqual(selectedSubSector, OPTION_ALL)) {
      if (isEmpty(selectedSubSector)) {
        return [OPTION_ALL, 0, selectedSharedSubSector, [], [], []];
      }

      const selectedSubSectorList = data.filter(item =>
        isEqual(item.subSector.toLowerCase(), selectedSubSector.toLowerCase())
      );
      return [
        selectedSubSector,
        selectedSubSectorList[0].subSectorId,
        selectedSharedSubSector,
        [selectedSubSector],
        [selectedSubSectorList[0].subSectorId],
        selectedSharedSubSector ? [selectedSharedSubSector] : [],
      ];
    }

    newSelectedSharedSubSector = selectedSharedSubSector
      ? selectedSharedSubSector
      : data[0]?.sharedSubSector;
    if (isEqual(empInitialData.topicSectorId, -1) || fromSuperAdmin) {
      return [
        data[0].subSector,
        data[0].subSectorId,
        newSelectedSharedSubSector,
        [data[0].subSector],
        [data[0].subSectorId],
        [newSelectedSharedSubSector],
      ];
    }

    return [
      selectedSubSector,
      selectedSubSectorId,
      selectedSharedSubSector,
      subSectors,
      subSectorIds,
      sharedSubSectors,
    ];
  };

  setSelectedSubSector = (
    data,
    isSectorClicked,
    fromSuperAdmin,
    isCollectionApplied
  ) => {
    const {
      selectedSharedSubSector,
      selectedSubSector,
      selectedSubSectorId,
      selectedSector,
    } = this.state;
    const [
      newSelectedSubSector,
      newSelectedSubSectorId,
      newSelectedSharedSubSector,
    ] = this.getSelectedSubSector({
      data,
      isSectorClicked,
      fromSuperAdmin,
      isCollectionApplied,
    });
    this.setState({
      subSectorList: data,
      subSectorListToBeShown: isEqual(selectedSector, OPTION_ALL)
        ? [allSubSector]
        : data,
      selectedSubSector: newSelectedSubSector,
      selectedSubSectorId: newSelectedSubSectorId,
      selectedSharedSubSector: newSelectedSharedSubSector,
      isSectorClicked,
      fetchSubSectorList: true,
    });
    if (!isSectorClicked) {
      let setSubSector = isEqual(selectedSubSector, OPTION_ALL)
        ? ''
        : selectedSubSector;
      let setSubSectorID = isEqual(selectedSubSectorId, -1)
        ? 0
        : selectedSubSectorId;
      let setSharedSubSector = isEqual(selectedSubSector, OPTION_ALL)
        ? null
        : selectedSharedSubSector;
      let selectedSubSectorData = {
        subSector: setSubSector,
        subSectorId: setSubSectorID,
        sharedSubSector: setSharedSubSector,
      };
      store.dispatch(updateSelectedSubSectorDetails(selectedSubSectorData));
    }
    if (!this.state.isPaClicked && !isSectorClicked) {
      this.setParameterValue(true);
    }
  };

  showSearchResultForGeoMarketList = value => {
    if (value) {
      let data = this.state.geoList;
      let geoSearchResultData = [];

      geoSearchResultData = data.filter(el => {
        return el.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        geoListToBeShown: geoSearchResultData,
      });
    } else {
      this.setState({
        geoListToBeShown: this.state.geoList,
      });
    }
  };

  showSearchResultForPAList = value => {
    if (value) {
      let data = this.state.pAList;
      let pASearchResultData = [];

      pASearchResultData = data.filter(el => {
        return el.practiceArea.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        paListToBeShown: pASearchResultData,
      });
    } else {
      this.setState({
        paListToBeShown: this.state.pAList,
      });
    }
  };

  showSearchResultForSectorList = value => {
    if (value) {
      let data = this.state.sectorList;
      let sectorSearchedResult = [];
      sectorSearchedResult = data.filter(el => {
        return el.sector.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({
        sectorListToBeShown: sectorSearchedResult,
      });
    } else {
      this.setState({
        sectorListToBeShown: this.state.sectorList,
      });
    }
  };

  showSearchResultForSubSectorList = value => {
    if (value) {
      let data = this.state.subSectorList;
      let sectorSearchedResult = [];

      sectorSearchedResult = data.filter(el => {
        return el.subSector.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({
        subSectorListToBeShown: sectorSearchedResult,
      });
    } else {
      this.setState({
        subSectorListToBeShown: this.state.subSectorList,
      });
    }
  };

  loadManagedByListMarkup = () => {
    const { selectedManagedBy, isGeoLead } = this.state;

    return (
      <AdminFilter
        dataList={getManagedByOptions(isGeoLead)}
        onChange={this.managedByClickHandler}
        selectedList={selectedManagedBy}
        stateField="selectedManagedBy"
        isAllSelected={isEqual(selectedManagedBy.length, 3)}
        showAll={true}
      />
    );
  };

  loadPathListMarkup = () => {
    const { selectedPath } = this.state;
    const pathData = CONFIG.PATH;
    let pathMarkup = [];
    pathData.forEach(item => {
      const activeClass = isEqual(selectedPath, item) ? 'active' : '';
      const onClick = !activeClass
        ? this.pathClickHandler.bind(this, item)
        : null;
      pathMarkup.push(
        <li
          className={`kt-result-item ${activeClass}`}
          key={item}
          onClick={onClick}
        >
          {item}
        </li>
      );
    });
    return pathMarkup;
  };

  managedByClickHandler = async (stateField, managedBy) => {
    const { tableauStringData } = this.props;
    store.dispatch(setAdminClick(false));
    if (managedBy.includes('-1')) {
      managedBy.shift();
    }
    this.setState(
      {
        showManagedByList: false,
        selectedManagedBy: managedBy,
        selectedTeamMembers: [],
        displayName: OPTION_ALL,
        selectedPath: OPTION_ALL,
        refreshMemberList: true,
      },
      () => {
        this.getMemberList(tableauStringData, OPTION_ALL).then(() => {
          batch(() => {
            store.dispatch(updateDisplayName(OPTION_ALL));
            store.dispatch(updateAllTeamMemberSelected(true));
            store.dispatch(updatePath(OPTION_ALL));
            store.dispatch(updateSelectedTeamMembers([]));
            store.dispatch(updateManagedBy(managedBy));
            this.setTableauParamString(true, {
              ...tableauStringData,
              managedBy,
              path: OPTION_ALL,
            });
          });
        });
      }
    );
  };

  pathClickHandler = async path => {
    const { tableauStringData } = this.props;
    this.setState(
      {
        showPathList: false,
        selectedPath: path,
        selectedTeamMembers: [],
        displayName: OPTION_ALL,
        refreshMemberList: true,
      },
      () => {
        this.getMemberList(tableauStringData, path).then(() => {
          batch(() => {
            store.dispatch(setAdminClick(false));
            store.dispatch(updateDisplayName(OPTION_ALL));
            store.dispatch(updateAllTeamMemberSelected(true));
            store.dispatch(updateSelectedTeamMembers([]));
            store.dispatch(updatePath(path));
            this.setTableauParamString(true, { ...tableauStringData, path });
          });
        });
      }
    );
  };

  loadGeoListMarkup(listData) {
    const { selectedGeo } = this.state;
    let listMarkup = [];
    listData &&
      listData.forEach(item => {
        const activeClass = isEqual(selectedGeo, item) ? 'active' : '';
        const onClick = !activeClass
          ? this.geoMarketClickHandler.bind(this, item)
          : null;
        listMarkup.push(
          <li
            className={`kt-result-item ${activeClass}`}
            key={item?.toString()}
            onClick={onClick}
          >
            {item}
          </li>
        );
      });
    return listMarkup;
  }

  loadPaListMarkup(listData) {
    const { selectedPaId } = this.state;
    let listMarkup = [];
    listData &&
      listData.forEach(item => {
        const activeClass = isEqual(selectedPaId, item.paId) ? 'active' : '';
        const onClick = !activeClass
          ? this.paListClickHandler.bind(this, item.practiceArea, item.paId)
          : null;
        listMarkup.push(
          <li
            className={`kt-result-item ${activeClass}`}
            key={item.practiceArea?.toString()}
            onClick={onClick}
          >
            {CONFIG.PA_NAME_ALIAS_MAP[item.practiceArea] || item.practiceArea}
          </li>
        );
      });
    return listMarkup;
  }

  loadSectorListMarkup(listData) {
    const { selectedSectorId } = this.state;
    return (
      listData &&
      listData.map((item, index) => {
        if (item.topicSectorId) {
          const activeClass = isEqual(selectedSectorId, item.topicSectorId)
            ? 'active'
            : '';
          const onClick = !activeClass
            ? this.sectorListClickHandler.bind(
                this,
                item.sector,
                item.topicSectorId,
                item.sharedSubSector
              )
            : null;
          return (
            <li
              className={`kt-result-item ${activeClass}`}
              key={index}
              onClick={onClick}
            >
              {item.sector}
            </li>
          );
        }

        return null;
      })
    );
  }

  loadSubSectorListMarkup(listData) {
    const { selectedSubSectorId } = this.state;
    return (
      listData &&
      listData.map(item => {
        if (item.subSectorId) {
          const activeClass = isEqual(selectedSubSectorId, item.subSectorId)
            ? 'active'
            : '';
          const onClick = !activeClass
            ? this.subSectorListClickHandler.bind(
                this,
                item.subSector,
                item.subSectorId,
                item.sharedSubSector
              )
            : null;
          return (
            <li
              className={`kt-result-item ${activeClass}`}
              key={item.subSector}
              onClick={onClick}
            >
              {item.subSector}
            </li>
          );
        }
        return null;
      })
    );
  }

  subSectorListClickHandler = async (
    subSector,
    subSectorId,
    sharedSubSector
  ) => {
    store.dispatch(setAdminClick(false));
    this.setState({
      showSubSectorList: false,
      selectedSubSector: subSector,
      selectedSubSectorId: subSectorId,
      selectedSharedSubSector: sharedSubSector,
      subSectorListToBeShown: this.state.subSectorList,
    });
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForManagedBy = event => {
    if (
      this.managedByContainer.current &&
      !this.managedByContainer.current.contains(event.target)
    ) {
      this.setState({
        showManagedByList: false,
      });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForUserList = event => {
    if (
      this.userContainer.current &&
      !this.userContainer.current.contains(event.target)
    ) {
      this.setState({
        showUsers: false,
      });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForPathList = event => {
    if (
      this.pathContainer.current &&
      !this.pathContainer.current.contains(event.target)
    ) {
      this.setState({
        showPathList: false,
      });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForPAListDropDown = event => {
    if (
      this.paListContainer.current &&
      !this.paListContainer.current.contains(event.target)
    ) {
      this.setState({
        showPaList: false,
        paListToBeShown: this.state.pAList,
      });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForGeoMarketList = event => {
    if (
      this.geoMarketListContainer.current &&
      !this.geoMarketListContainer.current.contains(event.target)
    ) {
      this.setState({
        showGeoMarketList: false,
        geoListToBeShown: this.state.geoList,
      });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForListForSector = event => {
    if (
      this.sectorListContainer.current &&
      !this.sectorListContainer.current.contains(event.target)
    ) {
      this.setState({
        showSectorList: false,
        sectorListToBeShown: this.state.sectorList,
      });
    }
  };

  handleClickOutsideForListForSubSector = event => {
    if (
      this.subSectorListContainer.current &&
      !this.subSectorListContainer.current.contains(event.target)
    ) {
      this.setState({
        showSubSectorList: false,
        subSectorListToBeShown: this.state.subSectorList,
      });
    }
  };

  showSectorListHandler = () => {
    this.setState({
      showSectorList: true,
    });
  };

  showSubSectorListHandler = () => {
    this.setState({
      showSubSectorList: true,
    });
  };

  renderGeoMorketList = () => {
    const {
      highestRoleIsGeo,
      showGeoMarketList,
      geoListToBeShown,
      selectedGeo,
    } = this.state;
    const geoListMarkup = this.loadGeoListMarkup(geoListToBeShown);
    const showDropDown = showFilterDropDown(geoListToBeShown);
    const disabledClass = !showDropDown ? 'disabled' : '';

    return highestRoleIsGeo ? (
      <div
        className={`kt-drop-down-with-search-main-container pR10 ${disabledClass}`}
        ref={this.geoMarketListContainer}
      >
        <p className="selection-text">Geo Market</p>
        <div
          className="kt-drop-down-with-search-main-container-dropdown-content"
          onClick={this.showGeoMarketListHandler.bind(this)}
        >
          <span className="selection-text-2">{selectedGeo}</span>
          {showDropDown && (
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
          )}
        </div>
        {showGeoMarketList && (
          <div className="kt-drop-down-with-search-container">
            {showSearch(geoListToBeShown) && (
              <div>
                <KtSearchSmall
                  containerClassName="pa-list-search"
                  onChangeHandler={this.showSearchResultForGeoMarketList}
                  placeholder="Search"
                />
              </div>
            )}
            <ul className="kt-result-list">{geoListMarkup}</ul>
          </div>
        )}
      </div>
    ) : null;
  };

  renderPaList = () => {
    const {
      paListToBeShown,
      showPaList,
      highestRoleIsGeo,
      fetchPaList,
      selectedPa,
    } = this.state;
    const paListMarkup = this.loadPaListMarkup(paListToBeShown);
    const paSpanClassName = fetchPaList
      ? 'selection-text-2'
      : 'selection-text-2 show-loader';
    const selectedPaAlias = CONFIG.PA_NAME_ALIAS_MAP[selectedPa] || selectedPa;
    const showDropDown = showFilterDropDown(paListToBeShown);
    return (
      <div
        className={`kt-drop-down-with-search-main-container  pR10
        ${!showDropDown ? 'disabled' : ''} ${highestRoleIsGeo ? 'pL10' : ''}`}
        ref={this.paListContainer}
      >
        <p className="selection-text">PA</p>
        <div
          className="kt-drop-down-with-search-main-container-dropdown-content"
          onClick={this.showPaListHandler.bind(this)}
        >
          <span className={paSpanClassName}>
            {fetchPaList ? (
              selectedPaAlias
            ) : (
              <KtLabelWithSkeleton isLoading={1} showLine />
            )}
          </span>
          {showDropDown && (
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
          )}
        </div>
        {showPaList && (
          <div className="kt-drop-down-with-search-container">
            {showSearch(paListToBeShown) && (
              <div>
                <KtSearchSmall
                  containerClassName="pa-list-search"
                  onChangeHandler={this.showSearchResultForPAList}
                  placeholder="Search"
                />
              </div>
            )}
            <ul className="kt-result-list">{paListMarkup}</ul>
          </div>
        )}
      </div>
    );
  };

  renderSectorList = () => {
    const { sectorListToBeShown, fetchSectorList, selectedSector } = this.state;
    const sectorListMarkup = this.loadSectorListMarkup(sectorListToBeShown);

    const sectorSpanClassName = fetchSectorList
      ? 'selection-text-2'
      : 'selection-text-2 show-loader';
    const showDropDown = showFilterDropDown(sectorListToBeShown);

    return (
      <div
        className={`kt-drop-down-with-search-main-container pR10 pL10 ${
          !showDropDown ? 'disabled' : ''
        }`}
        ref={this.sectorListContainer}
      >
        <p className="selection-text">Topic/Sector</p>
        <div
          className="kt-drop-down-with-search-main-container-dropdown-content"
          onClick={this.showSectorListHandler}
        >
          <span className={sectorSpanClassName}>
            {fetchSectorList ? (
              selectedSector
            ) : (
              <KtLabelWithSkeleton isLoading={1} showLine />
            )}
          </span>
          {showDropDown && (
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
          )}
        </div>
        {this.state.showSectorList && (
          <div className="kt-drop-down-with-search-container">
            {showSearch(sectorListToBeShown) && (
              <div>
                <KtSearchSmall
                  containerClassName="pa-list-search"
                  onChangeHandler={this.showSearchResultForSectorList}
                  placeholder="Search"
                />
              </div>
            )}
            <ul className="kt-result-list">{sectorListMarkup}</ul>
          </div>
        )}
      </div>
    );
  };

  renderSubSectorList = () => {
    const {
      subSectorListToBeShown,
      fetchSubSectorList,
      showSubSectorList,
      selectedSubSector,
      selectedSector,
    } = this.state;
    const subSectorListMarkup = this.loadSubSectorListMarkup(
      subSectorListToBeShown
    );
    const subSectorSpanClassName = fetchSubSectorList
      ? 'selection-text-2'
      : 'selection-text-2 show-loader';
    const showDropDown =
      showFilterDropDown(subSectorListToBeShown) &&
      !isEqual(selectedSector, OPTION_ALL);
    return (
      <div
        className={`kt-drop-down-with-search-main-container pR10 no-border pL10 ${
          !showDropDown ? 'disabled' : ''
        }`}
        ref={this.subSectorListContainer}
      >
        <p className="selection-text">Sub-Topic/Segment</p>
        <div
          className="kt-drop-down-with-search-main-container-dropdown-content"
          onClick={this.showSubSectorListHandler}
        >
          <span className={subSectorSpanClassName}>
            {fetchSubSectorList ? (
              selectedSubSector
            ) : (
              <KtLabelWithSkeleton isLoading={1} showLine />
            )}
          </span>
          {showDropDown && (
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
          )}
        </div>
        {showSubSectorList && (
          <div className="kt-drop-down-with-search-container">
            {showSearch(subSectorListToBeShown) && (
              <div>
                <KtSearchSmall
                  containerClassName="pa-list-search"
                  onChangeHandler={this.showSearchResultForSubSectorList}
                  placeholder="Search"
                />
              </div>
            )}
            <ul className="kt-result-list">{subSectorListMarkup}</ul>
          </div>
        )}
      </div>
    );
  };

  getHeadingMarkup() {
    const { highestRole } = this.state;
    const { iconInformation } = this.props;

    const geoMarketList = this.renderGeoMorketList();
    const paList = this.renderPaList();

    const sectorList = this.renderSectorList();

    const subSectorList = this.renderSubSectorList();

    const iconInfo = !isEmpty(
      getIconInformation('Area', iconInformation, highestRole)
    ) ? (
      <div
        className="tooltip-with-icon areaMargin"
        onClick={() => {
          this.setState({ showTooltip: true, tooltipName: 'Area' });
        }}
      >
        <Icon
          name="info-tableau"
          class="dropdown-info-icon"
          alt="switch-mode-on-off-info"
          src="/img/Information.svg"
        />
      </div>
    ) : null;

    const goButtonMarkup = (
      <button
        className="go-button"
        onClick={() => this.setParameterValue(false)}
      >
        Go
      </button>
    );

    return (
      <>
        {geoMarketList} {paList} {sectorList} {subSectorList}
        {iconInfo} {goButtonMarkup}
      </>
    );
  }

  setParameterValue = isDefaultLoad => {
    let data = {};
    let {
      selectedPa,
      selectedPaId,
      selectedPas,
      selectedPaIds,
      selectedSectorId,
      selectedSectorIds,
      selectedSubSectorId,
      selectedSector,
      selectedSectors,
      selectedSubSector,
      selectedSharedSubSector,
      highestRole,
      selectedValue,
      passGeo,
      empInitialData,
      isGeoLead,
      selectedGeo,
      managedBy,
      highestRoleIsGeo,
      subSectorList,
      isManager,
    } = this.state;
    const getSelectedOption = selected => {
      if (selected === OPTION_ALL) {
        return selectOption(isGeoLead, OPTION_ALL, null);
      }

      return selected;
    };
    const selectedPaIdValue = selectOption(
      isEqual(selectedPa, OPTION_ALL),
      0,
      selectedPaId
    );
    const selectedPAValue = getSelectedOption(selectedPa);
    const selectedSubSectorValue = getSelectedOption(selectedSubSector);
    const selectedSubSectorID = selectOption(
      isEqual(selectedSubSector, OPTION_ALL),
      0,
      selectedSubSectorId
    );
    const selectedSectorValue = getSelectedOption(selectedSector);
    const selectedSectorID = selectOption(
      isEqual(selectedSector, OPTION_ALL),
      0,
      selectedSectorId
    );
    const selectedSharedSubSectorValue = selectOption(
      isEqual(selectedSharedSubSector, OPTION_ALL),
      null,
      selectedSharedSubSector
    );
    const roleName = selectOption(
      !isEmpty(selectedValue),
      selectedValue.roleName,
      highestRole
    );
    const passGeoToApi = selectOption(
      !isEmpty(selectedValue),
      !isEmpty(selectedValue.geoRegion),
      passGeo
    );
    const geoRegion = selectOption(
      isEqual(selectedGeo, 'No Geo'),
      null,
      selectedGeo
    );
    const managedByValue = selectOption(
      isEqual(selectedGeo, 'No Geo') && !isGeoLead,
      null,
      managedBy
    );
    const prevSelectedPaId = selectedPaId;
    const [subSectors, subSectorIds, sharedSubSectors] =
      getSubSectorListWithAll(
        subSectorList,
        selectedSubSector,
        selectedSubSectorId,
        selectedSharedSubSector,
        highestRoleIsGeo || isManager
      );
    const dataToPost = {
      paIds: selectedPaIds.includes(-1) ? [] : selectedPaIds,
      paId: selectedPaIdValue,
      topicSectorId: selectedSectorID,
      topicSectorIds: selectedSectorIds.includes(-1) ? [] : selectedSectorIds,
      subSectorId: selectedSubSectorID,
      roleName: roleName,
      roleId: null,
      sharedSubSectors:
        sharedSubSectors.includes('') || sharedSubSectors.includes(null)
          ? []
          : sharedSubSectors,
      employeeIdHRO: empInitialData.employeeIdHRO,
      subSectorIds: subSectorIds.includes(-1) ? [] : subSectorIds,
      paTeams: returnWithAllValue(selectedPas),
      subSectors: returnWithAllValue(subSectors),
      sectors: returnWithAllValue(selectedSectors),
    };
    data = {
      ...dataToPost,
      paTeam: selectedPAValue,
      sector: selectedSectorValue,
      subSector: selectedSubSectorValue,
      sharedSubSector: selectedSharedSubSectorValue,
      geoRegion: selectedGeo,
      managedBy: managedByValue,
      paTeams: returnWithAllValue(selectedPas),
      sectors: returnWithAllValue(selectedSectors),
      subSectors: returnWithAllValue(subSectors),
    };
    const selectedSubSectorData = {
      subSector: selectedSubSectorValue,
      subSectorId: selectedSubSectorID,
      sharedSubSector: selectedSharedSubSectorValue,
    };
    const selectedSectorData = {
      sector: selectedSectorValue,
      topicSectorId: selectedSectorID,
    };
    const selectedManagedBy = selectOption(
      isDefaultLoad,
      this.state.selectedManagedBy,
      getManagedByDefaultValue(isGeoLead)
    );
    const selectedPath = selectOption(
      isDefaultLoad,
      this.state.selectedPath,
      OPTION_ALL
    );
    const tableauStringData = {
      ...dataToPost,
      paTeam: selectedPa,
      sector: selectedSector,
      subSector: selectedSubSector,
      managedBy: selectedManagedBy,
      path: selectedPath,
      geoRegion: geoRegion,
      passGeo: passGeoToApi,
      prevSelectedPaId,
      paTeams: selectedPas,
      sectors: selectedSectors,
    };
    const selectedItemForManager = this.getDropdownValue(
      data.paTeam,
      selectedSector,
      selectedSubSector
    );
    const refreshMemberList = selectOption(
      isDefaultLoad,
      this.state.refreshMemberList,
      true
    );

    this.setState(
      {
        selectedItemForManager,
        refreshMemberList,
        selectedTeamMembers: selectOption(
          isDefaultLoad,
          this.state.selectedTeamMembers,
          []
        ),
        displayName: selectOption(
          isDefaultLoad,
          this.state.displayName,
          OPTION_ALL
        ),
        selectedPath,
        selectedManagedBy,
        highestRole: roleName,
        passGeo: passGeoToApi,
        managedBy: managedByValue,
      },
      async () => {
        this.getMemberList(tableauStringData, selectedPath).then(() => {
          batch(() => {
            if (isDefaultLoad) {
              store.dispatch(setAdminClick(true));
            } else {
              store.dispatch(setAdminClick(true));
              store.dispatch(updateSelectedTeamMembers([]));
            }
            store.dispatch(passGeoDetails(passGeoToApi));
            store.dispatch(updateLoggedInUserHighestRole(roleName));
            store.dispatch(
              updateDisplayName(
                selectOption(isDefaultLoad, this.state.displayName, OPTION_ALL)
              )
            );
            store.dispatch(
              updateSelectedSelectionDetailValue(selectedItemForManager)
            );
            store.dispatch(updateSelectedSelectionDetails(data));
            store.dispatch(
              updateSelectedSubSectorDetails(selectedSubSectorData)
            );
            store.dispatch(updateSelectedSectorDetails(selectedSectorData));
            store.dispatch(updatePath(selectedPath));
            store.dispatch(updateManagedBy(selectedManagedBy));
            !this.props.fromAdmin &&
              this.createSearchObject(
                selectedPAValue,
                selectedSectorValue,
                selectedSubSectorValue,
                selectedSharedSubSectorValue,
                roleName
              );
            this.setTableauParamString(false, tableauStringData);
          });
        });
      }
    );
  };

  loadMemberView = async (data, initialLoad) => {
    const { empInitialData, selectedTeamMembers } = this.state;
    const { path } = this.props;
    const paName = CONFIG.PA_NAME_ALIAS_MAP[data.paTeam] || data.paTeam;
    const selectedTeamMembersToPass = selectOption(
      initialLoad,
      selectedTeamMembers,
      []
    );
    const selectedPathToPass = selectOption(
      initialLoad && !isEmpty(path),
      path,
      OPTION_ALL
    );
    const passGeo = !isEmpty(data.geoRegion);
    const dataToPost = {
      paTeams: [data.paTeam],
      sectors: [data.sector],
      sharedSubSectors: data.sharedSubSector ? [data.sharedSubSector] : [],
      paIds: [data.paId],
      topicSectorIds: [data.topicSectorId],
      subSectorIds: data.subSectorId ? [data.subSectorId] : [],
    };
    const tableauStringData = {
      ...dataToPost,
      paId: data.paId,
      topicSectorId: data.topicSectorId,
      subSectorId: data.subSectorId,
      paTeam: data.paTeam,
      sector: data.sector,
      subSector: data.subSector,
      roleId: data.roleId,
      roleName: data.roleName,
      sharedSubSector: data.sharedSubSector,
      employeeIdHRO: empInitialData.employeeIdHRO,
      managedBy: data.managedBy,
      geoRegion: data.geoRegion,
      path: selectedPathToPass,
      passGeo,
      subSectors: isEmpty(data.subSector) ? ['All'] : [data.subSector],
    };
    const selectionData = {
      ...data,
      subSectors: data.subSector ? [data.subSector] : [],
      ...dataToPost,
    };
    const displayNameToShow = OPTION_ALL;
    const selectedItemForManager = this.getDropdownValue(
      paName,
      data.sector,
      data.subSector
    );
    this.setState(
      {
        selectedDataForManager: Object.assign({}, data),
        selectedItemForManager,
        selectedTeamMembers: selectedTeamMembersToPass,
        refreshMemberList: true,
        displayName: displayNameToShow,
        selectedPath: selectedPathToPass,
        highestRole: data.roleName,
        selectedGeo: data.geoRegion,
        passGeo,
        managedBy: data.managedBy,
        selectedManagedBy: [data.managedBy],
      },
      () => {
        batch(() => {
          store.dispatch(updateTeamMemberList({}));
          store.dispatch(updateDefaultMemberList([]));
          store.dispatch(updateManagedBy(data.managedBy));
          store.dispatch(updatePath(selectedPathToPass));
          store.dispatch(updateSelectedSelectionDetails(selectionData));
          store.dispatch(updateSelectedTeamMembers(selectedTeamMembersToPass));
          store.dispatch(passGeoDetails(passGeo));
          !this.props.fromAdmin &&
            this.createSearchObject(
              paName,
              data.sector,
              data.subSector,
              data.sharedSubSector,
              data.roleName
            );
          store.dispatch(updateDisplayName(displayNameToShow));
          store.dispatch(
            updateSelectedSelectionDetailValue(selectedItemForManager)
          );
          store.dispatch(updateLoggedInUserHighestRole(''));
          this.setTableauParamString(false, tableauStringData, true);
        });
      }
    );
  };

  getUserType = geoRegion => (geoRegion ? geoRegion : 'PAKT');

  getManagerListActiveCalssname = ({
    selectedItemForManagerAlias,
    dropDownValue,
    role,
    selectedRole,
  }) => {
    return isEqual(selectedItemForManagerAlias, dropDownValue) &&
      isEqual(selectedRole, role)
      ? 'selected active'
      : '';
  };

  getHeadingMarkupForManager(geoRegion) {
    const { selectedItemForManager, selectedGeo, highestRole } = this.state;
    const geoMarket = selectedGeo ? selectedGeo : geoRegion;
    const { iconInformation } = this.props;
    return (
      <>
        <p className="select-area">Team</p>
        <div style={{ display: 'inline-flex' }}>
          <div className="kt-drop-down-with-search-main-container manager-view pR10 disabled">
            <p>
              <span className="selected-text">
                {geoMarket
                  ? `${geoMarket} | ${selectedItemForManager}`
                  : `PAKT | ${selectedItemForManager}`}
              </span>
            </p>
          </div>
          {!isEmpty(
            getIconInformation('Area', iconInformation, highestRole)
          ) ? (
            <div
              className="tooltip-with-icon"
              onClick={() => {
                this.setState({ showTooltip: true, tooltipName: 'Area' });
              }}
            >
              <Icon
                name="info-tableau"
                class="dropdown-info-icon"
                alt="switch-mode-on-off-info"
                src="/img/Information.svg"
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }

  getSelectionContentForHeader(empData) {
    const { highestRole, empInitialData, passGeo, isGeoManaged, searchObject } =
      this.state;
    const userData = selectOption(isEmpty(empData), empInitialData, empData);
    const paName = CONFIG.PA_NAME_ALIAS_MAP[userData.paTeam] || userData.paTeam;
    let headingMarkUp = this.getDropdownValue(
      paName,
      userData.sector,
      userData.subSector
    );
    headingMarkUp = `PAKT | ${headingMarkUp}`;
    let showArea = true;

    if (highestRole) {
      return {
        showArea: false,
        headingMarkUp: this.getHeadingMarkup(),
      };
    }

    if (passGeo) {
      if (isGeoManaged) {
        return {
          headingMarkUp: empInitialData.paTeam
            ? `${empInitialData.geoRegion} | ${searchObject.pa}`
            : empInitialData.geoRegion,
        };
      }

      return {
        headingMarkUp: this.getHeadingMarkupForManager(
          empInitialData.geoRegion
        ),
        showArea: false,
      };
    }

    return {
      headingMarkUp,
      showArea,
    };
  }

  handleMemberFilterChange = async (
    selectedTeamMembers,
    isAllSelected,
    collectionName
  ) => {
    const displayName = collectionName
      ? collectionName
      : this.getDisplayName(selectedTeamMembers, isAllSelected);
    this.setState(
      { selectedTeamMembers, showUsers: false, displayName },
      () => {
        batch(() => {
          store.dispatch(setAdminClick(false));
          store.dispatch(updateSelectedTeamMembers(selectedTeamMembers));
          store.dispatch(updateDisplayName(displayName));
          store.dispatch(updateCollectionApplied(!isEmpty(collectionName)));
          this.setTableauParamString();
        });
      }
    );
  };

  getDisplayName = (selectedTeamMembers, isAllSelected) => {
    const { memberList } = this.props;
    const allSelected = isAllSelected ?? this.props.isAllSelected;
    const getDisplayNameText = () => {
      if (isEmpty(selectedTeamMembers) || allSelected) {
        return OPTION_ALL;
      }

      if (isEqual(selectedTeamMembers.length, 1)) {
        return memberList.filter(
          item => selectedTeamMembers.indexOf(item.employeeIdHRO) >= 0
        )[0]?.employeeName;
      }

      return `${selectedTeamMembers.length} Selected`;
    };
    const displayName = getDisplayNameText();
    store.dispatch(updateDisplayName(displayName));
    return displayName;
  };

  closeMemberFilter = () => {
    this.setState({ showUsers: false });
  };

  applyCollections = appliedCollection => {
    const appliedFilters = appliedCollection.userSelectedFilterDTO;
    const { loggedInUserHighestRole } = this.props;
    const { fromSuperAdmin, passGeo } = this.state;
    let data = {};
    const selectedPaIds = appliedFilters.paIds;
    const selectedPaId = selectedPaIds.length > 1 ? -1 : selectedPaIds[0];
    const selectedPas = appliedFilters.practiceAreas;
    const selectedPa = selectedPaIds.length > 1 ? OPTION_ALL : selectedPas[0];
    const selectedSubSectors = appliedFilters.subSectors;
    const checkIfAllSubSector =
      selectedSubSectors.length > 1 || isEmpty(selectedSubSectors);
    const selectedSubSector = checkIfAllSubSector
      ? OPTION_ALL
      : selectedSubSectors[0];
    const selectedSubSectorIds = appliedFilters.subSectorIds;
    const selectedSubSectorId = checkIfAllSubSector
      ? 0
      : selectedSubSectorIds[0];

    const selectedSectors = appliedFilters.sectors;
    const checkIfAllSector =
      selectedSectors.length > 1 || isEmpty(selectedSectors);
    const selectedSector = checkIfAllSector ? OPTION_ALL : selectedSectors[0];
    const selectedSectorIds = appliedFilters.topicSectorIds;
    const selectedSectorId = checkIfAllSector ? 0 : selectedSectorIds[0];

    const selectedSharedSubSectors = appliedFilters.sharedSubSectors;
    const selectedSharedSubSector =
      selectedSharedSubSectors.length > 1 || isEmpty(selectedSharedSubSectors)
        ? null
        : selectedSharedSubSectors[0];
    const selectedTeamMembers = appliedFilters.selectedUserId;
    const geoRegion = appliedFilters.geoRegion;
    const managedBy = appliedFilters.managedBy;
    const selectedManagedBy = appliedFilters.selectedManagedBy;
    const prevSelectedPaId = this.state.selectedPaId;
    data = {
      paId: selectedPaId,
      paIds: selectedPaIds,
      topicSectorId: selectedSectorId,
      topicSectorIds: selectedSectorIds,
      subSectorId: selectedSubSectorId,
      subSectorIds: selectedSubSectorIds,
      paTeam: selectedPa,
      sector: selectedSector,
      subSector: selectedSubSector,
      paTeams: selectedPas,
      sectors: selectedSectors,
      subSectors: selectedSubSectors,
      roleId: null,
      roleName: loggedInUserHighestRole,
      sharedSubSector: selectedSharedSubSector,
      sharedSubSectors: selectedSharedSubSectors,
      geoRegion,
      managedBy,
      prevSelectedPaId,
    };
    let selectedSubSectorData = {
      subSector: selectedSubSector,
      subSectorId: selectedSubSectorId,
      sharedSubSector: selectedSharedSubSector,
    };
    let selectedSectorData = {
      sector: selectedSector,
      topicSectorId: selectedSectorId,
    };
    const paName = CONFIG.PA_NAME_ALIAS_MAP[data.paTeam] || data.paTeam;
    let selectedItemForManager = this.getDropdownValue(
      paName,
      selectedSector,
      selectedSubSector
    );
    const displayName = appliedCollection.name;
    const selectedPath =
      isEmpty(appliedFilters.path) || isEqual(appliedFilters.path, 'ALL')
        ? OPTION_ALL
        : appliedFilters.path;
    this.setState(
      {
        selectedItemForManager,
        refreshMemberList: true,
        selectedPaId,
        selectedSectorId,
        selectedSubSectorId,
        selectedPa,
        selectedSector,
        selectedSubSector,
        selectedSharedSubSector,
        selectedTeamMembers,
        showUsers: false,
        managedBy: managedBy,
        displayName,
        selectedManagedBy,
        selectedPath,
        selectedGeo: geoRegion,
        selectedPas,
        selectedSectors,
        selectedSubSectors,
        selectedPaIds,
        selectedSectorIds,
        selectedSubSectorIds,
        selectedSharedSubSectors,
      },
      async () => {
        const isCollectionApplied = true;
        this.getMemberList(data, selectedPath).then(() => {
          batch(() => {
            store.dispatch(updateCollectionApplied(true));
            store.dispatch(updateDisplayName(displayName));

            this.getSectorsOfSelectedPa(
              true,
              fromSuperAdmin,
              isCollectionApplied
            );
            store.dispatch(setAdminClick(false));
            store.dispatch(updateSelectedTeamMembers(selectedTeamMembers));
            store.dispatch(
              updateSelectedSelectionDetailValue(selectedItemForManager)
            );
            store.dispatch(updateSelectedSelectionDetails(data));
            store.dispatch(
              updateSelectedSubSectorDetails(selectedSubSectorData)
            );
            store.dispatch(updateSelectedSectorDetails(selectedSectorData));
            store.dispatch(updateManagedBy(managedBy));
            store.dispatch(updatePath(selectedPath));
            store.dispatch(
              updateAllTeamMemberSelected(isEmpty(selectedTeamMembers))
            );
            store.dispatch(updateManagedBy(selectedManagedBy));
            !this.props.fromAdmin &&
              this.createSearchObject(
                appliedFilters.practiceArea,
                appliedFilters.sector,
                appliedFilters.subSector,
                appliedFilters.sharedSubSector,
                loggedInUserHighestRole
              );
            this.setTableauParamString(false, {
              ...data,
              managedBy: selectedManagedBy,
              path: selectedPath,
              passGeo,
            });
          });
        });
      }
    );
  };

  goToHome = () => {
    this.props.history.push({
      pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
    });
  };

  closeToolTipBox = () => {
    this.setState({ showTooltip: false });
  };

  getSelectedManagedBy = () => {
    const { selectedManagedBy, isGeoLead } = this.state;
    const managedByLength = selectedManagedBy.length;
    const selectedManagedByInfo = getManagedByOptions(isGeoLead).find(option =>
      isEqual([option.value], selectedManagedBy)
    );
    if (managedByLength === 1) return selectedManagedByInfo?.label;

    if (isEqual(selectedManagedBy, getManagedByDefaultValue())) {
      return 'PA KT';
    }

    if (managedByLength === getManagedByOptions(isGeoLead).length) {
      return OPTION_ALL;
    }

    return `${managedByLength} Selected`;
  };

  datePickerInput = ({ value, onClick }, ref) => (
    <button ref={ref} className="example-custom-input" onClick={onClick}>
      {value}
    </button>
  );

  setTableauParamString = (loadAllTeamMember, data, managerDropdownChange) => {
    const { isGeoLead } = this.state;
    const {
      tableauStringData,
      memberList,
      loggedInUserHighestRole,
      employeeInitialData,
    } = this.props;
    const {
      selectedTeamMembers,
      selectedTimePeriod: calenderDropdownVal,
      setDate: initialDate,
      passGeo,
    } = this.state;
    const tableauData = {
      ...(data ? data : tableauStringData),
      initialDate,
      calenderDropdownVal,
      passGeo,
      selectedTeamMembers,
    };
    store.dispatch(updateTableauStringData(tableauData));
    store.dispatch(setManagerValue(tableauData.roleName ? true : false));
    const { startDate, endDate } = selectedDates(
      new Date(),
      initialDate,
      calenderDropdownVal
    );
    let updateData = {};
    let paNames = [];
    tableauData.paTeams.forEach(el => {
      const paName = CONFIG.ALIAS_TO_PA_NAME_MAP[el] || el || '';
      paNames.push(paName);
    });
    updateData = {
      'PA Value': paNames.toString(),
      subSectorId: passAllWithEmpty(tableauData.subSectors),
      StartDate: startDate,
      EndDate: endDate,
      'Sector Value': passAllWithEmpty(tableauData.sectors),
      id: getEmpIdParam({
        selectedTeamMembers,
        loadAllTeamMember,
        memberList,
        loggedInUserHighestRole: managerDropdownChange
          ? tableauData.roleName
          : loggedInUserHighestRole,
        employeeInitialData,
      }),
      business: 'All',
      path: tableauData.path,
      geoRegion: passGeo ? tableauData.geoRegion : '',
      managedBy: !passGeo || isGeoLead ? tableauData.managedBy : '',
    };

    const str = Object.values(updateData).join('|');
    store.dispatch(updateTableauFilterParams(str));
  };

  renderHeaderMarkup = () => {
    const { fromAdmin, apiCallStatus } = this.props;
    const { isAdmin, highestRole, filters, isManager } = this.state;
    let isLoading = apiCallStatus.fetchHeadingMarkUp;
    let headingMarkUp = {};
    if (fromAdmin && isAdmin) {
      headingMarkUp.headingMarkUp = highestRole;
    } else {
      headingMarkUp = this.getSelectionContentForHeader(filters);
    }

    if (isLoading) {
      return <KtLabelWithSkeleton isLoading={1} showLabel size="xx-large" />;
    }

    return (
      <div
        className={`separator KTDropdown-white header-heading-container ${
          isAdmin || isManager ? 'align-item-center' : ''
        }`}
      >
        {headingMarkUp.showArea ? <p className="select-area">Team</p> : null}
        {headingMarkUp.geoRegion
          ? headingMarkUp.geoRegion
          : headingMarkUp.headingMarkUp}
      </div>
    );
  };

  renderPathContainer = isMember => {
    const { showPathList, selectedPath } = this.state;

    return isMember ? null : (
      <div className="path-container">
        <div
          className="kt-drop-down-with-search-main-container"
          ref={this.pathContainer}
        >
          <p className="select-period">Path</p>
          <p onClick={this.showPath.bind(this)} className="select-text-1 mR10">
            <span className="selection-text-2">{selectedPath}</span>
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
          </p>
          {showPathList && (
            <div className="kt-drop-down-with-search-container path-dropdown">
              <ul className="kt-result-list">{this.loadPathListMarkup()}</ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderManagedByContainer = isMember => {
    const { iconInformation } = this.props;
    const { highestRole, passGeo, showManagedByList, isGeoLead } = this.state;

    return !(isMember || (!isGeoLead && passGeo)) ? (
      <div className="managed-by-container">
        <div
          className="kt-drop-down-with-search-main-container"
          ref={this.managedByContainer}
        >
          <p className="select-period">Managed By</p>
          <div
            onClick={this.showManagedByList.bind(this)}
            className="select-text-1"
          >
            <span className="selection-text-2">
              {this.getSelectedManagedBy()}
            </span>
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
            {!isEmpty(
              getIconInformation('ManagedBy', iconInformation, highestRole)
            ) && (
              <div
                className="tooltip-with-icon ddlMargin"
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    showTooltip: true,
                    tooltipName: 'Managed By',
                  });
                }}
              >
                <Icon
                  name="info-tableau"
                  class="dropdown-info-icon"
                  alt="switch-mode-on-off-info"
                  src="/img/Information.svg"
                />
              </div>
            )}
          </div>
          {showManagedByList && (
            <div className="kt-drop-down-with-search-container managed-by-dropdown">
              <ul className="kt-result-list">
                {this.loadManagedByListMarkup()}
              </ul>
            </div>
          )}
        </div>
      </div>
    ) : null;
  };

  renderMemberCollectionContainer = isMember => {
    let { iconInformation } = this.props;
    const {
      selectedTeamMembers,
      highestRole,
      showUsers,
      refreshMemberList,
      displayName,
    } = this.state;

    return isMember ? null : (
      <div className="member-container">
        <div
          className="kt-drop-down-with-search-main-container"
          ref={this.userContainer}
        >
          <p className="select-period">Member/Collection</p>
          <p onClick={this.showUsers.bind(this)} className="select-text-1 mR10">
            <span className="selection-text-2">{displayName}</span>
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
            {!isEmpty(
              getIconInformation('Member', iconInformation, highestRole)
            ) && (
              <div
                className="tooltip-with-icon ddlMargin"
                onClick={e => {
                  e.stopPropagation();
                  this.setState({ showTooltip: true, tooltipName: 'Member' });
                }}
              >
                <Icon
                  name="info-tableau"
                  class="dropdown-info-icon"
                  alt="switch-mode-on-off-info"
                  src="/img/Information.svg"
                />
              </div>
            )}
          </p>
          {showUsers && (
            <MemberFilter
              onChange={this.handleMemberFilterChange}
              selectedUsers={selectedTeamMembers}
              isApiCallRequired={refreshMemberList}
              closeMemberFilter={this.closeMemberFilter}
              applyCollections={this.applyCollections}
              saveCollection={true}
            />
          )}
        </div>
      </div>
    );
  };

  renderTimePeriodContainer = () => {
    const { showPeriodTooltip } = this.state;

    const CustomDatePickerInput = React.forwardRef(this.datePickerInput);
    const periodTooltipName = showPeriodTooltip ? 'on' : 'off';

    return (
      <>
        <span
          className="tooltip-with-icon periodTooltip"
          onMouseEnter={() => {
            this.setState({ showPeriodTooltip: !showPeriodTooltip });
          }}
          onMouseLeave={() => {
            this.setState({ showPeriodTooltip: !showPeriodTooltip });
          }}
        >
          <Icon
            name="info-tableau"
            class="dropdown-info-icon"
            alt="switch-mode-on-off-info"
            src="/img/Information.svg"
          />
          <div id="tooltip" className={periodTooltipName}>
            <div className="tooltip-inner">
              The date range can be selected for last 2 years.
            </div>
          </div>
        </span>
        <div className="mR10">
          <p className="select-period">Period</p>
          <DatePicker
            selected={this.state.setDate}
            minDate={moment().subtract(2, 'years').toDate()}
            maxDate={new Date()}
            onChange={this.onLoadChange}
            dateFormat="MMMM yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            showMonthYearPicker
            // eslint-disable-next-line react/jsx-no-bind
            onKeyDown={e => e.preventDefault()}
            customInput={<CustomDatePickerInput />}
          />
        </div>
      </>
    );
  };

  render() {
    const { fromAdmin, showLogo } = this.props;
    const {
      showTooltip,
      highestRole,
      dropdownVale,
      isSuperAdmin,
      isImpersonator,
      tooltipName,
    } = this.state;
    const isMember = isEmpty(highestRole);

    return (
      <>
        <div className="KtHeader-containerOne">
          {showLogo && (
            <img
              src="/img/kt-logo.svg"
              alt="kt dashboard logo"
              className="kt-logo-clickable"
              onClick={this.goToHome}
            />
          )}
          <div className="KtScorecard-homeSection">
            {this.renderHeaderMarkup()}
          </div>
          {!fromAdmin && (
            <div className="KtScorecard-profileSection">
              {this.renderPathContainer(isMember)}
              {this.renderManagedByContainer(isMember)}
              {this.renderMemberCollectionContainer(isMember)}
              {this.renderTimePeriodContainer()}
              <KTDropdownWithRadioBtn
                onSelectionChange={this.onSelectionChange}
                dropdownData={dropdownVale}
              />
            </div>
          )}
        </div>
        {showTooltip && (
          <ToolTipShowEdit
            showPopup={showTooltip}
            handlePopupClose={this.closeToolTipBox}
            popupHeading="Edit Info Buttons"
            popupSelectedText={tooltipName}
            showEditIcon={isSuperAdmin || isImpersonator}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = function (reduxStore) {
  return {
    apiCallStatus: reduxStore.apiCallsInProgress,
    calenderDropdownVal: reduxStore.headerFilterData.calenderDropdownVal,
    initialDate: reduxStore.headerFilterData.initialDate,
    selectedSectionDetailOptionValue:
      reduxStore.headerFilterData.selectedSectionDetailOptionValue,
    selectedSectionDetails: reduxStore.headerFilterData.selectedSectionDetails,
    ktSaPaList: reduxStore.headerFilterData.ktSaPaList,
    managedBy: reduxStore.headerFilterData.managedBy,
    path: reduxStore.headerFilterData.path,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    isAllSelected: reduxStore.headerFilterData.isAllSelected,
    memberList: reduxStore.headerFilterData.memberList,
    filters: reduxStore.headerFilterData.filters,
    passGeo: reduxStore.headerFilterData.passGeo,
    isCollectionApplied: reduxStore.headerFilterData.isCollectionApplied,
    displayName: reduxStore.ktScorecard.displayName,
    ktUserDetails: reduxStore.userRoleData.ktUserDetails,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    userTypes: reduxStore.userRoleData.userTypes,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    memberDetails: reduxStore.ktScorecard.memberDetails,
    isGeoManaged: reduxStore.userRoleData.isGeoManaged,
    fetchImpersonatorUserList: reduxStore.ktScorecard.fetchImpersonatorList,
    tableauStringData: reduxStore.tableauStackData.tableauStringData,
    iconInformation: reduxStore.ktScorecard.iconInformation,
  };
};
export default connect(mapStateToProps)(withRouter(KTDashboardHeader));
