import React, { useState, useEffect, useRef } from 'react';
import actions from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/common/icon/Icon';
import { isEqual, formatSelectedDate, isEmpty } from 'utils/common';
import { isEmptyOrNull, selectedDates } from 'utils';
import CONFIG from 'config/configProps';
import { onFiltersDropDownOpen, onFiltersInteractions } from 'config/analytics';
import KTDateRangePicker from 'components/common/KTDateRangePicker';
import moment from 'moment';
import './KTTimePeriod.scss';
import 'react-datepicker/dist/react-datepicker.css';

const KTTimePeriod = props => {
  const dispatch = useDispatch();
  const selectedAppliedDates = useSelector(
    state => state.filterDataPt.selectedDateFilter
  );

  const { onSelectionChange, isAnalystViewCal } = props;
  const { startDate, endDate } = selectedDates(
    new Date(),
    '',
    'Last 12 months',
    true
  );
  const refContainer = useRef();
  const [isDropdownOpen, toggleDropdown] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [monthVal, updateMonthVal] = useState(12);
  const [weekVal, updateWeekVal] = useState('');
  const [activeItem, setActiveItem] = useState('MONTHLY');
  const [timePeriodVal, setTimePeriodVal] = useState('Last 12 months');
  const [startDateVal, setStartDate] = useState(startDate);
  const [endDateVal, setEndDate] = useState(endDate);
  const [isDateCustom, setIsDateCustom] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = useState(startDate);
  const [calendarEndDate, setCalendarEndDate] = useState(endDate);
  const [isError, setIsError] = useState(false);

  function openDropdown() {
    toggleDropdown(!isDropdownOpen);
  }

  const getActiveItem = item => {
    return isEqual(item, 'TTM') ? 'MONTHLY' : item;
  };

  useEffect(() => {
    setStartDate(selectedAppliedDates?.startDate || startDate);
    setEndDate(selectedAppliedDates?.endDate || endDate);
    setCalendarStartDate(selectedAppliedDates?.startDate || startDate);
    setCalendarEndDate(selectedAppliedDates?.endDate || endDate);
    updateMonthVal(
      selectedAppliedDates?.monthVal || isEmptyOrNull(selectedAppliedDates)
        ? 12
        : ''
    );
    updateWeekVal(selectedAppliedDates?.weekVal || '');
    setActiveItem(
      isEqual(selectedAppliedDates?.timePeriodVal, 'TTM')
        ? 'TTM'
        : selectedAppliedDates?.dateFilterType || 'MONTHLY'
    );
    setTimePeriodVal(selectedAppliedDates?.timePeriodVal || 'Last 12 months');
  }, [selectedAppliedDates]);

  useEffect(() => {
    onFiltersDropDownOpen(`Date Filter Opened`);
  }, []);

  useEffect(() => {
    !isAnalystViewCal &&
      onSelectionChange(startDateVal, endDateVal, getActiveItem(activeItem));
    const handleClickOutside = event => {
      if (
        refContainer.current &&
        !refContainer.current.contains(event.target)
      ) {
        toggleDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onSelectionChange]);

  const onInputChange = (value, item) => {
    if (isEqual(item, 'months')) {
      updateMonthVal(value);
      updateWeekVal('');
    } else {
      updateWeekVal(value);
      updateMonthVal('');
    }
    setTimePeriodVal(`Last ${value} ${item}`);
  };

  const onClickHandle = item => {
    setActiveItem(item);
    setShowCalender(isEqual(item, 'CUSTOM') && !showCalender);
    updateWeekVal('');
    updateMonthVal('');
    setTimePeriodVal(item);
    setIsDateCustom(false);
  };

  const applyFilter = () => {
    const activeItemSelected = getActiveItem(activeItem);
    let appliedFilterObject = {
      monthVal,
      weekVal,
      dateFilterType: activeItemSelected,
      timePeriodVal,
    };
    let startDate,
      endDate = '';
    if (!isDateCustom) {
      const dateVal = selectedDates(new Date(), '', timePeriodVal, true);
      startDate = dateVal.startDate;
      endDate = dateVal.endDate;
    } else {
      startDate = formatSelectedDate(calendarStartDate, 'YYYY-MM-DD');
      let selectedYear = moment(calendarEndDate).format('YYYY');
      let selectedMonth = moment(calendarEndDate).format('MM');
      let lastDayOfMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      endDate = `${selectedYear}-${selectedMonth}-${lastDayOfMonth}`;
    }
    setStartDate(startDate);
    setEndDate(endDate);
    onSelectionChange(
      startDate,
      endDate,
      activeItemSelected,
      timePeriodVal,
      true
    );
    appliedFilterObject = { ...appliedFilterObject, startDate, endDate };
    dispatch(actions.protoTypeActions.updateSelectedDate(appliedFilterObject));
    onFiltersInteractions('Date', `Date Filter applied`);
    toggleDropdown(false);
    setShowCalender(false);
  };

  const listItem = (item, value, activeValue) => {
    const isActive = isEqual(activeItem, activeValue);
    return (
      <div
        className={`date-calender-container__left__list-item ${
          isActive ? 'active' : ''
        }`}
        onClick={() => onClickHandle(activeValue)}
      >
        <input type="radio" name="dateType" value="" checked={isActive} />
        <span>Last</span>
        <span>
          <input
            type="text"
            onChange={e => onInputChange(e.target.value, item)}
            readOnly={!isActive}
            value={value}
            name={item}
          />
        </span>
        <span>{item}</span>
      </div>
    );
  };

  const onLoadChange = (date, name) => {
    setIsError(false);
    if (isEqual(name, 'startDate')) {
      setCalendarStartDate(date);
    } else {
      setCalendarEndDate(date);
      if (moment(calendarStartDate) > date) setIsError(true);
    }
    toggleDropdown(true);
    setIsDateCustom(true);
  };

  const timeFormat = CONFIG.DATE_TIME_FORMAT_DISPLAY;
  return (
    <div
      className={`dropdownWith-btn ${props.disabled ? 'disabledMain' : ''} 
    ${props.className ? props.className : ''}`}
      ref={refContainer}
    >
      <div className="dropdown-selected-option" onClick={openDropdown}>
        {props.showDateHeading && (
          <img
            src="/img/calendarNew.png"
            alt="calendar-icon"
            className="calendar-icon"
          />
        )}
        <span className="flex1">{`${formatSelectedDate(
          startDateVal,
          timeFormat[activeItem]
        )} - ${formatSelectedDate(endDateVal, timeFormat[activeItem])}`}</span>
        <Icon name={`angle ${isDropdownOpen ? 'up' : 'down'}`} />
      </div>
      {isDropdownOpen && (
        <div
          className={`date-calender-container ${
            props.dateContainerclassName ? props.dateContainerclassName : ''
          }`}
        >
          <div className="date-calender-container__left">
            {listItem('months', monthVal, 'MONTHLY')}
            {listItem('weeks', weekVal, 'WEEKLY')}
            {CONFIG.PROTOTYPE_TIME_PERIOD_OPTIONS.map(item => {
              const isActive = isEqual(activeItem, item);
              return (
                <div
                  className={`date-calender-container__left__list-item ${
                    isActive ? 'active' : ''
                  }`}
                  onClick={() => onClickHandle(item)}
                  key={item}
                >
                  <input
                    type="radio"
                    name="dateType"
                    value=""
                    checked={isActive}
                  />
                  <span>
                    {CONFIG.PROTOTYPE_TIME_PERIOD_OPTIONS_DISPLAY_NAME[item]}
                  </span>
                </div>
              );
            })}
            {!showCalender && !isEqual(activeItem, 'CUSTOM') && (
              <div className="button-footer">
                <div className="apply" onClick={() => applyFilter()}>
                  Apply
                </div>
              </div>
            )}
          </div>
          {showCalender && (
            <div className="custom-calender">
              <div className="enabled">
                <KTDateRangePicker
                  onDateChange={onLoadChange}
                  filterValue={calendarStartDate}
                  filterTitle="From:"
                  name="startDate"
                  containerClass="fromDateCalender"
                  testId="fromDateCalender"
                  isError={isError}
                />
                <KTDateRangePicker
                  onDateChange={onLoadChange}
                  filterValue={calendarEndDate}
                  filterTitle="To:"
                  name="endDate"
                  containerClass="toDateCalender"
                  testId="toDateCalender"
                  isError={isError}
                  displayError={isError}
                />
              </div>
              {isError ? (
                <div className="error-container">
                  <img
                    src="/img/red-info.png"
                    alt="information"
                    className="kt-info"
                  />
                  <div className="text">Error</div>
                </div>
              ) : null}
              <div className="button-footer">
                <div
                  className={`apply ${isError ? 'disabled' : ''}`}
                  onClick={() => applyFilter()}
                >
                  Apply
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KTTimePeriod;
