import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import store from 'redux/store';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import { fetchPost } from 'utils/KtCommonServices';
import CONFIG_KTS from 'config/configProps';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import PieChartWrapper from 'components/KTTableauStackWrapper/PieChartWrapper';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import {
  updateToastNotificationDetails,
  showTooltipPopup,
} from 'redux/actions';
import {
  getApiFailMessage,
  shouldGetTableauStackApiData,
  selectUserIdAsTeamMembers,
} from 'utils';
import { cloneDeep } from 'utils/common';
class KTPulse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pieChartRawData: {},
      dataLoaded: false,
      showTooltip: false,
    };
  }

  componentDidMount() {
    if (this.props.tableauParamString) {
      this.getApiData();
    }
  }
  /**
   *
   * React Life cycle method
   */
  componentDidUpdate(prevProps) {
    const fromRmsKTPulse = true;
    if (shouldGetTableauStackApiData(this.props, prevProps, fromRmsKTPulse)) {
      this.getApiData();
    }
  }

  getApiData = () => {
    this.setState({ dataLoaded: false });
    const {
      isRmsData,
      isComingSoon,
      isAllSelected,
      isManager,
      selectedTeamMembers,
      allPaList,
    } = this.props;
    let apiIdentifier = isRmsData
      ? API_CALL_IDENTIFIERS.FETCH_REQUEST_SATISFACTION_DATA
      : API_CALL_IDENTIFIERS.FETCH_KT_PULSE_DATA;
    let url;
    const apiRequestData = cloneDeep(this.props.apiRequestData);
    if (apiRequestData.geoRegion) {
      const paData = allPaList?.find(
        paInfo => apiRequestData.paId === paInfo.paId
      );
      apiRequestData['paCode'] = paData?.paCode;
    }
    if (isRmsData) {
      url = CONFIG_KTS.API_URLS.RMS_DATA;
      if (
        selectUserIdAsTeamMembers(isAllSelected, isManager, selectedTeamMembers)
      ) {
        apiRequestData['selectedUserId'] = selectedTeamMembers;
      }
    } else {
      url = CONFIG_KTS.API_URLS.PULSE_CHECK;
    }

    store.dispatch(beginAPICall(apiIdentifier));
    if (isComingSoon) {
      store.dispatch(endAPICall(apiIdentifier));
      this.setState({ dataLoaded: true });
    } else {
      fetchPost(url, apiRequestData)
        .then(result => {
          this.setState({ pieChartRawData: result.data });
          this.setState({ dataLoaded: true });
        })
        .catch(() => {
          store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
        })
        .finally(() => store.dispatch(endAPICall(apiIdentifier)));
    }
  };
  showToolTip = () => {
    const { heading } = this.props;
    store.dispatch(
      showTooltipPopup({ showTooltip: true, tooltipName: heading })
    );
  };

  render() {
    const {
      className,
      apiCallStatus,
      isRmsData,
      InfoText,
      heading,
      isComingSoon,
    } = this.props;
    const { pieChartRawData, dataLoaded } = this.state;
    let isLoading = apiCallStatus.fetchKtPulseData;
    const wrapperHeading = isRmsData
      ? 'RMS Request Satisfaction'
      : 'Team Satisfaction';
    return (
      <div
        className={`kt-pulse ${
          isComingSoon ? 'coming-soon-box' : ''
        } ${className} ${isRmsData ? 'mL10' : ''}`}
      >
        {isComingSoon ? <span>Coming Soon!</span> : null}
        <div className="heading">
          <div className="card-heading">
            <span className="heading-text">{heading}</span>
          </div>
          {pieChartRawData && pieChartRawData.maxDate && !isComingSoon ? (
            <div className="last-updated">{`Latest update for ${pieChartRawData.maxDate}`}</div>
          ) : null}
        </div>
        {!isComingSoon && (
          <div>
            {isLoading || !dataLoaded ? (
              <div>
                <KtLabelWithSkeleton isLoading showLabel size="large" />
                <br />
                <KtLabelWithSkeleton isLoading isImage imageCount={1} />
                <br />
                <KtLabelWithSkeleton isLoading showLabel size="large" />
                <KtLabelWithSkeleton isLoading showLabel size="large" />
                <KtLabelWithSkeleton isLoading showLabel size="large" />
              </div>
            ) : (
              <div className="tableau-card-body-chart">
                <div className="pulse-heading">{wrapperHeading}</div>
                {pieChartRawData && (
                  <PieChartWrapper
                    pieChartRawData={pieChartRawData}
                    className="pulse-survey-heading"
                    InfoText={InfoText}
                    showToolTip={this.showToolTip}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    apiCallStatus: reduxStore.apiCallsInProgress,
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    isAllSelected: reduxStore.headerFilterData.isAllSelected,
    isManager: reduxStore.userRoleData.isManager,
    tableauStringData: reduxStore.tableauStackData.tableauStringData,
    allPaList: reduxStore.headerFilterData.allPaList,
  };
};
export default connect(mapStateToProps)(withRouter(KTPulse));
