import React from 'react';
import { components } from 'react-select';
import { Label } from 'semantic-ui-react';

const Option = props => {
  const { label, data } = props;
  return (
    <components.Option {...props} data-testid="dropdown-option">
      <div data-testid="dropdown-option-username">{label}</div>
      {data?.userRoles?.map((role, index) => (
        <Label
          key={`${role}-${index}`}
          size="small"
          style={{ color: '#000' }}
          data-testid="dropdown-option-user-role"
        >
          {role}
        </Label>
      ))}
    </components.Option>
  );
};

export default Option;
