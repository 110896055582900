import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LineSkeleton = ({ showLine, width, height }) => {
  return showLine ? (
    <Skeleton width={width} height={height ? height : 3.5} duration={1} />
  ) : null;
};

export default LineSkeleton;
