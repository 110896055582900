import React from 'react';
import { connect } from 'react-redux';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import KtKnPlaceholder from 'components/KTTableauStackWrapper/KTKnDeck/KtKnPlaceholder';
import Icon from 'components/common/Icons';
import TopicLoader from 'components/KTTableauStackWrapper/TopicTableau/TopicLoader';
import FTELoader from 'components/KTTableauStackWrapper/KTFte/FteLoader';
import RMSLoader from 'components/KTTableauStackWrapper/RMSLoader';
import {
  isGeoLeadRole,
  isGeoManagerRole,
  isAllSectorSubSectorSelected,
  isAllPa,
} from 'utils';

const getBaseClassname = (isAdmin, isManager) => {
  if (isAdmin) {
    return 'admin';
  }

  if (isManager) {
    return 'manager';
  }

  return 'member';
};

const getKTEngagementClassName = ({
  isGeoLead,
  passGeo,
  isGeoManager,
  isManager,
  isAdmin,
}) => {
  if (isGeoLead && passGeo) {
    return 'admin-kt-pulse';
  }

  if (isGeoManager) {
    return 'geo-manager-kt-pulse';
  }

  if (passGeo && !isManager) {
    return 'geo-kt-pulse';
  }
  if (isAdmin) {
    return 'admin-kt-pulse';
  }

  return isManager ? 'manager-kt-pulse' : 'member-kt-pulse';
};

const getFteClassName = ({
  isGeoLead,
  passGeo,
  isGeoManager,
  isManager,
  isAdmin,
}) => {
  if (isGeoManager || isGeoLead) {
    return 'geo-manager-size-in-fte';
  }

  if (passGeo && !isManager) {
    return 'geo-size-in-fte';
  }

  if (isAdmin) {
    return 'admin-size-in-fte';
  }

  return isManager ? 'manager-size-in-fte' : 'member-size-in-fte';
};

const getOtherContentClassName = isManager => {
  return isManager
    ? 'kt-other-deck manager-kn-deck'
    : 'kt-other-deck member-kn-deck';
};

const getMostDownloadClassName = isManager => {
  return isManager ? 'manager-most-download' : 'member-most-download';
};

const getKtKnClassName = isManager => {
  return isManager ? 'kt-kn-deck manager-kn-deck' : 'kt-kn-deck member-kn-deck';
};

const TableauStackWrapperPlaceholder = props => {
  const {
    isManager,
    isAdmin,
    passGeo,
    loggedInUserHighestRole,
    apiRequestData,
  } = props;
  const isGeoManager = isGeoManagerRole(loggedInUserHighestRole);
  const isGeoLead = isGeoLeadRole(loggedInUserHighestRole);
  let baseClassName = getBaseClassname(isAdmin, isManager);
  let KTEngagementClassName = getKTEngagementClassName({
    isGeoLead,
    passGeo,
    isGeoManager,
    isManager,
    isAdmin,
  });
  let KTRequestSatisfactionClassName = `kt-pulse ${baseClassName}-kt-pulse mL10`;
  let FteClassName = getFteClassName({
    isGeoLead,
    passGeo,
    isGeoManager,
    isManager,
    isAdmin,
  });
  let KtKnClassName = getKtKnClassName(isManager);
  let otherContentClassName = getOtherContentClassName(isManager);
  let mostDownloadClassName = getMostDownloadClassName(isManager);
  const isAllSelected = isAllSectorSubSectorSelected(apiRequestData);
  const isAllPaSelected = isAllPa(apiRequestData);
  const showAdminHeading = isAdmin && isAllSelected;
  const showPaAdminHeading = isAdmin && isAllPaSelected;
  return (
    <div className="tableau-stack-wrapper">
      <TopicLoader
        heading="Billing"
        isManager={props.isManager}
        showAdminHeading={showAdminHeading}
        isTopic={true}
        showPaAdminHeading={showPaAdminHeading}
        isGeoLead={isGeoLead}
      />

      <div className="tile-stack">
        <div className="tile-stack-header">
          <div className="header-left-text">
            <div className="heading">
              Content & IP Created or Contributed by KT
            </div>
            <div className="dates">
              <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
            </div>
          </div>
        </div>
        <div className="tile-stack-body">
          <div className="tableau-card-container">
            <div className={KtKnClassName}>
              <div className="card-heading">
                <span className="heading-text">KN Decks</span>
                <span className="heading-image">
                  <Icon
                    name="tablue-arrow"
                    class=""
                    alt="arrow"
                    src="/img/tablue-arrow.svg"
                  />
                </span>
              </div>
              <div className="tableau-card-body">
                <KtKnPlaceholder isLoading={1} section="KN Decks" />
              </div>
            </div>

            <div className={otherContentClassName}>
              <div className="card-heading">
                <span className="heading-text">Other Content</span>
                <span className="heading-image">
                  <Icon
                    name="tablue-arrow"
                    class=""
                    alt="arrow"
                    src="/img/tablue-arrow.svg"
                  />
                </span>
              </div>
              <div className="tableau-card-body">
                <KtKnPlaceholder isLoading={1} section="Other Content" />
              </div>
            </div>

            <div className={`kt-most-download ${mostDownloadClassName}`}>
              <div className="card-heading">
                <span className="heading-text">Top 10 - Most Downloaded</span>
              </div>
              <div className="tableau-card-body">
                <KtKnPlaceholder isLoading={1} section="Most Downloaded" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tile-stack">
        <div className="tile-stack-header">
          <div className="header-left-text">
            <div className="heading">Team</div>
            <div className="dates">
              <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
            </div>
          </div>
        </div>
        <div className="tile-stack-body">
          <div className="tableau-card-container">
            <RMSLoader
              isLoading
              isManager={isManager}
              tableauCardBodyClass={`${KTEngagementClassName} kt-pulse`}
              heading="KT Pulse Check"
              lastUpdatedLoaded={true}
            />
            <RMSLoader
              isLoading
              isManager={isManager}
              tableauCardBodyClass={KTRequestSatisfactionClassName}
              heading="CT Satisfaction with KT"
              isRmsData={true}
            />

            <div className={`${FteClassName} size-in-fte`}>
              <div className="card-heading">
                <span className="heading-text">Team Size</span>
                <span className="heading-image">
                  <Icon
                    name="tablue-arrow"
                    class=""
                    alt="arrow"
                    src="/img/tablue-arrow.svg"
                  />
                </span>
              </div>
              <FTELoader />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    isManager: store.userRoleData.isManager,
    loggedInUserHighestRole: store.userRoleData.loggedInUserHighestRole,
    apiRequestData: store.tableauStackData.apiRequestData,
    passGeo: store.headerFilterData.passGeo,
  };
};
export default connect(mapStateToProps)(TableauStackWrapperPlaceholder);
