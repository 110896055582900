import React from 'react';
import CONFIG from 'config/configProps';
import KtDropdown from 'components/common/KtDropdown';
import TrendsChart from './TrendsChart';

const KTPulse = props => {
  const colors = CONFIG.KT_PULSE_CHECK_COLORS;
  const legends = CONFIG.KT_PULSE_CHECK_LEGENDS;
  const dropdownOptions = Object.keys(CONFIG.KT_PULSE_CHECK_QUESTIONS);

  const changeFilterHandler = selectedItem => {
    props.onQuestionChange(selectedItem);
  };

  return (
    <div
      className={`home_page_main_split_row_kt_pulse highchart_container main_conatiner`}
    >
      <div className="heading">
        <div className="heading_text">KT PULSE CHECK</div>
        <div className="heading_sortby">
          <KtDropdown
            dropdownOptionsList={dropdownOptions}
            selectedOptionName={props.selectedQuestion}
            changeEventHandler={changeFilterHandler}
          />
        </div>
      </div>
      <TrendsChart
        colors={colors}
        legends={legends}
        data={props.data}
        isPulseData={true}
        section="KT Pulse Check"
        actualOrder={true}
      />
    </div>
  );
};
export default KTPulse;
