import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import { isEqual, isEmpty } from 'utils/common';
import KtBreadcrumb from 'components/common/KtBreadcrumb';
import Icon from 'components/common/Icons';

import { isImpersonatorRole } from 'utils';
import ImpersonatorList from 'components/KTDashboardHeader/ImpersonatorList';
import './KTSwitchHeader.scss';
class KTDashboardSwitchHeader extends Component {
  constructor(props) {
    super(props);
    const { setDateHeader3, memberDetails, impersonatorList } = this.props;
    this.state = {
      employeeID: '',
      showTooltip: false,
      setDateHeader3: setDateHeader3,
      showMembers: false,
      memberDetails: memberDetails,
      isImpersonator: !isEmpty(impersonatorList),
    };
    this.impersonatorContainer = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.setDateHeader3, state.setDateHeader3)) {
      return {
        setDateHeader3: props.setDateHeader3,
      };
    }
    return null;
  }

  /**
   *
   * React life cycle method
   */
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideForMemberList);
    const {
      setDateHeader3,
      employeeInitialData,
      impersonatorList,
      loggedInUserHighestRole,
    } = this.props;
    const empInitialData = this.props.empInitialData
      ? this.props.empInitialData
      : employeeInitialData;
    const emIDFromStore = empInitialData.employeeIdHRO;
    if (emIDFromStore) {
      this.setState({
        employeeID: emIDFromStore,
        setDateHeader3: setDateHeader3,
        isImpersonator:
          isImpersonatorRole(loggedInUserHighestRole) ||
          !isEmpty(impersonatorList),
      });
    }
  }

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideForMemberList);
  }

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForMemberList = event => {
    if (
      this.impersonatorContainer.current &&
      !this.impersonatorContainer.current.contains(event.target)
    ) {
      this.setState({ showMembers: false });
    }
  };

  goToHome = () => {
    const { onHomeDashboardLoad, history } = this.props;
    const pathName = window.location.pathname.toLowerCase();
    const homePageUrl = CONFIG.KT_SCORECARD_CONST.HOME_URL;
    if (isEqual(pathName, homePageUrl.toLowerCase())) {
      return;
    }
    history.push({
      pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
    });
    onHomeDashboardLoad && onHomeDashboardLoad();
  };

  showMembers = () => {
    this.setState({ showMembers: true });
  };

  closeMemberFilter = () => {
    this.setState({ showMembers: false });
  };

  applyMemberView = memberDetails => {
    this.setState({ memberDetails, showMembers: false });
  };

  clearMemberFilter = () => {
    this.setState({ memberDetails: {}, showMembers: false });
  };

  displayBreadcrumbs = () => {
    const { history } = this.props;
    const {
      location: { pathname },
    } = history;
    const breadcrumbData = CONFIG.BREADCRUMBS[pathname];

    return <KtBreadcrumb navigationData={breadcrumbData} />;
  };

  displayImpersonatorList = () => {
    const { showMembers, isImpersonator } = this.state;
    const { memberDetails, hideUserFilter } = this.props;
    const selectedText = !isEmpty(memberDetails)
      ? memberDetails?.employeeName
      : 'All';

    return isImpersonator && !hideUserFilter ? (
      <div className="impersonator-container">
        <div
          className="kt-drop-down-with-search-main-container pR10"
          ref={this.impersonatorContainer}
        >
          <p className="impersonator">User Validation</p>
          <p
            onClick={this.showMembers.bind(this)}
            className="select-impersonator"
          >
            <span className="text">{selectedText}</span>
            <Icon
              name="kt-scorecard-white-arrow"
              class="kt-drop-arrow kt-header-dropdown"
              alt=""
              src="/img/kt-scorecard-white-arrow.svg"
            />
          </p>
          {showMembers && (
            <ImpersonatorList
              closeMemberFilter={this.closeMemberFilter}
              memberDetails={memberDetails}
              applyMemberView={this.applyMemberView}
              clearMemberFilter={this.clearMemberFilter}
            />
          )}
        </div>
      </div>
    ) : null;
  };

  render() {
    const { setDateHeader3 } = this.state;

    return (
      <div>
        <div className="KtHeader-containerTwo">
          <img
            src="/img/kt-logo.svg"
            alt="kt dashboard logo"
            className="kt-logo-clickable"
            onClick={this.goToHome}
          />
          {this.displayBreadcrumbs()}
          <div className="header-history">
            <div className="header3-date">
              <h1 className="header3-date-text">{setDateHeader3}</h1>
            </div>
            {this.displayImpersonatorList()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    setDateHeader3: reduxStore.ktScorecard.setDateHeader3,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    memberDetails: reduxStore.ktScorecard.memberDetails,
    impersonatorList: reduxStore.ktScorecard.impersonatorList,
  };
};
export default connect(mapStateToProps)(withRouter(KTDashboardSwitchHeader));
