import React, { Fragment } from 'react';

const TableauLoader = ({ isLoading, className }) => {
  return (
    <Fragment>
      {isLoading ? (
        <div className={`tableauLoader d-flex ${className}`}>
          <img
            src="/img/tableau_logo.png"
            alt="tableau"
            className="tableau-logo"
          />
          <p>
            Please wait, while dashboard is loading
            <Fragment>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
            </Fragment>
          </p>
        </div>
      ) : null}
    </Fragment>
  );
};

export default TableauLoader;
