import { connect } from 'react-redux';
import MultiSelectHeader from './MultiSelectHeader';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.filterSectionSelectors.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    paList: getPropsForSeedData(state, API_CALL_IDENTIFIERS.FETCH_PA_LIST),
    sectorList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_SECTOR_LIST
    ),
    subSectorList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_SUB_SECTOR_LIST
    ),
    geoMarketList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_GEO_MARKET_LIST
    ),
    memberList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_MEMBER_LIST
    ),
    pathList: getPropsForSeedData(state, API_CALL_IDENTIFIERS.FETCH_PATH_LIST),
    managedByList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_MANAGED_BY_LIST
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchFiltersData: bindActionCreators(
        actions.seedDataActions.fetchSeedDataFilters,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectHeader);
