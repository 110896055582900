import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './KtBreadcrumb.scss';
import Icon from 'components/common/Icons';

const KtBreadcrumb = ({ navigationData }) => {
  const getBreadcrumbList = () => {
    if (navigationData?.length) {
      return navigationData.map(({ pageHeading, pageUrl }, index) => {
        const lastBreadCrumb = navigationData.length === index + 1;
        return (
          <span
            className={lastBreadCrumb ? 'last-breadcrumb-name' : ''}
            key={pageHeading.toString()}
          >
            {lastBreadCrumb ? (
              pageHeading
            ) : (
              <Fragment>
                <Link to={{ pathname: pageUrl }} className="kt-breadcrumb">
                  {pageHeading}
                </Link>
                <Icon
                  name="comments_arrow"
                  class="right-arrow-home breadcrumb-arrow-img"
                  alt="comments_arrow"
                  src="/img/kt-breadcrumb-arrow.svg"
                />
              </Fragment>
            )}
          </span>
        );
      });
    }

    return null;
  };

  return <div className="kt-global-breadcrumb">{getBreadcrumbList()}</div>;
};

export default KtBreadcrumb;
