import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

import { isEqual } from 'utils/common';
import { isFetchRequired } from 'utils';
import FilterSection from './FilterSection';
import './index.scss';

class MultiSelectHeader extends PureComponent {
  /**
   * constructor of the Component.
   * @param {*} props
   */
  constructor(props) {
    super(props);
    const { loggedInEmployeeData } = this.props;
    this.state = {
      dirty: props.dirty,
      dataObj: {
        paId: [],
        sectorId: [],
        subSectorId: [],
        geoRegion: [],
        managedBy: [],
        path: [],
        paAdminId: loggedInEmployeeData.employeeIdHRO,
      },
    };
  }

  componentDidMount() {
    const {
      actions: { fetchFiltersData },
      paList,
      geoMarketList,
      memberList,
      pathList,
      managedByList,
    } = this.props;
    const { dataObj } = this.state;
    fetchFiltersData({
      fetchPaList: isFetchRequired(paList),
      fetchGeoMarketList: isFetchRequired(geoMarketList),
      fetchMemberList: isFetchRequired(memberList),
      fetchPathList: isFetchRequired(pathList),
      fetchManagedByList: isFetchRequired(managedByList),
      postData: dataObj,
    });
  }

  handleFilterSelectionChange = (dataObj, stateField, fetchMemberList) => {
    const {
      actions: { fetchFiltersData },
      loggedInEmployeeData,
    } = this.props;
    fetchFiltersData({
      fetchSectorList: isEqual(stateField, 'paId'),
      fetchSubSectorList:
        isEqual(stateField, 'sectorId') || isEqual(stateField, 'paId'),
      fetchMemberList:
        !isEqual(stateField, 'selectedMembers') || fetchMemberList,
      postData: { ...dataObj, paAdminId: loggedInEmployeeData.employeeIdHRO },
    });
  };

  /**
   * Render LIFECYCLE METHOD
   */
  render() {
    const {
      paList,
      sectorList,
      subSectorList,
      geoMarketList,
      managedByList,
      pathList,
      memberList,
      hierarchyList,
      metricList,
    } = this.props;
    const { dataObj } = this.state;
    return (
      <FilterSection
        paList={paList}
        sectorList={sectorList}
        subSectorList={subSectorList}
        geoMarketList={geoMarketList}
        managedByList={managedByList}
        pathList={pathList}
        memberList={memberList}
        hierarchyList={hierarchyList}
        metricList={metricList}
        onSelectionChanges={this.handleFilterSelectionChange}
        dataObject={dataObj}
      />
    );
  }
}

export default withRouter(MultiSelectHeader);
