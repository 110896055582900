import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';

import { getUserList } from 'redux/actions/adminActions';
import { dropdownFormat, sortData } from 'utils';
import Option from './Option';

const TeamManagerList = ({
  className,
  defaultTeamManager,
  onChange,
  testId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teamManager, setTeamManager] = useState(
    defaultTeamManager
      ? { value: defaultTeamManager, label: defaultTeamManager }
      : null
  );
  const [managerList, setManagerList] = useState([]);

  useEffect(() => {
    const formatMangerList = userList => {
      const dropdownOptions = Object.keys(userList)?.reduce((acc, userId) => {
        const userName = userList[userId][0]?.userName;
        const userRoles = userList[userId]?.map(({ role }) => role);
        return [
          ...acc,
          {
            ...dropdownFormat(userName, userName),
            userRoles: [...new Set(userRoles)],
          },
        ];
      }, []);

      return sortData(dropdownOptions || [], 'string', 'label');
    };

    setLoading(true);
    dispatch(getUserList())
      .then(userList => {
        setManagerList(formatMangerList(userList));
      })
      .finally(() => setLoading(false));
  }, [dispatch, defaultTeamManager]);

  const handleDropdownChange = selectedItem => {
    setTeamManager(selectedItem);
    onChange(selectedItem?.value || '');
  };

  return (
    <Select
      className={`team-manager-list ${className}`}
      classNamePrefix={`team-manager-list`}
      hideSelectedOptions={false}
      isClearable={true}
      placeholder="Select Team Manager"
      options={managerList}
      value={teamManager}
      onChange={handleDropdownChange}
      components={{ Option }}
      menuPlacement="auto"
      isLoading={loading}
      data-testid={testId}
      blurInputOnSelect
    />
  );
};

export default TeamManagerList;
