import React, { Component } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual } from 'utils/common';
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.setInitialValue();
    this.state = {
      currentPage: parseInt(props.currentPage),
    };
  }

  /**
   * Helper method for creating a range of numbers
   * range(1, 5) => [1, 2, 3, 4, 5]
   */
  range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const { currentPage } = this.state;
    const totalPages = this.totalPages;
    const pageNeighbours = this.pageNeighbours;
    if (totalPages > 5) {
      let startPage = 1;
      if (currentPage === totalPages - pageNeighbours) {
        startPage = currentPage - pageNeighbours;
      } else if (currentPage === totalPages - 1) {
        startPage = currentPage - 3;
      } else if (currentPage === totalPages) {
        startPage = currentPage - 4;
      } else {
        startPage = Math.max(1, currentPage - pageNeighbours);
      }
      let endPage;
      if (currentPage < 4) {
        endPage = 5;
      } else {
        endPage = Math.min(currentPage + 2, totalPages);
      }
      let pages = this.range(startPage, endPage);
      return [...pages];
    }
    return this.range(1, totalPages);
  };

  handleClick = page => {
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    let prevIndex = this.totalPages;
    if (!isEqual(1, this.state.currentPage)) {
      prevIndex = this.state.currentPage - 1;
    }
    this.gotoPage(prevIndex);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    let nextIndex = 1;
    if (!isEqual(this.totalPages, this.state.currentPage)) {
      nextIndex = this.state.currentPage + 1;
    }
    this.gotoPage(nextIndex);
  };

  gotoPage = page => {
    const { makeHttpRequestWithPage = f => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));
    this.setState({ currentPage }, () => makeHttpRequestWithPage(currentPage));
  };

  setInitialValue = () => {
    const {
      totalRecords = null,
      perPageRecords = 10,
      pageNeighbours = 2,
    } = this.props;

    this.perPageRecords =
      typeof perPageRecords === 'number' ? perPageRecords : 10;
    this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
    // pageNeighbours can be: 0, 1 or 2
    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.perPageRecords);
  };

  render() {
    const { currentPage } = this.state;
    this.setInitialValue();
    if (!this.totalRecords || this.totalPages === 1) return null;
    const pageNumbers = this.fetchPageNumbers();
    return (
      <div className="slider-pagination-container">
        <ul className="slider-pagination">
          <div
            className="controls left"
            aria-label="Previous"
            onClick={this.handleMoveLeft}
          >
            <span aria-hidden="true">
              <img
                src="img/kt-down-right-arrow.svg"
                className="transform"
                alt="Previous"
              />
            </span>
            <span className="sr-only">Previous</span>
          </div>
          {pageNumbers.map((page, index) => {
            return (
              <li
                key={index}
                className={`${currentPage === page ? 'active' : ''}`}
                onClick={() => this.handleClick(page)}
              />
            );
          })}
          <div
            className="controls right"
            aria-label="Next"
            onClick={this.handleMoveRight}
          >
            <span aria-hidden="true">
              <img src="img/kt-down-right-arrow.svg" alt="Next" />
            </span>
            <span className="sr-only">Next</span>
          </div>
        </ul>
      </div>
    );
  }
}

/**
 *
 * React Redux linking method
 */
const mapStateToProps = function (store) {
  return store;
};

export default withRouter(connect(mapStateToProps)(Pagination));
