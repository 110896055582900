import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import './Button.scss';

export default function Button({ children, variant, ...props }) {
  return (
    <div className="buttonContainer">
      <SemanticButton className={variant} {...props}>
        {children}
      </SemanticButton>
    </div>
  );
}
