import { connect } from 'react-redux';
import KtSideBar from './KtSideBar';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    userDetails: state.homePageData.userDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateUserDetails: bindActionCreators(
        actions.homepageActions.updateUserDetails,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KtSideBar);
