import React from 'react';
import { isEmptyOrNull } from 'utils';
import KTHomeLoader from './KTHomeLoader';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isLoading } from 'utils/common';

const KnMaterials = props => {
  const data = props.data.data;
  const knMaterialData = data?.results || [];
  return (
    <div
      className={`home_page_main_split_row_kn_material main_conatiner ${
        props.isLineManager ? 'lineManagerTile' : ''
      }`}
    >
      <div className="heading">
        <div className="heading_text">KN MATERIAL</div>
        {/* <img
          name="download"
          class="download-icon"
          alt="downloadIcon"
          src="/img/download.png"
        /> */}
      </div>
      <div className="chart__table">
        <div className="chart__table__header">
          <div className="downloadTitle">Material Type</div>
          <div className="downloadCount">Count</div>
        </div>
        <div className="chart__table__body data_table_body">
          {isLoading(props.data) ? (
            <KTHomeLoader isLoading={1} section="Most Downloaded" />
          ) : isEmptyOrNull(knMaterialData) ? (
            <div className="static-text">No data to display</div>
          ) : (
            Object.keys(knMaterialData).map((key, index) => {
              return (
                <div className="data_table_body_row" key={`${key}_${index}`}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="tooltip-basic">
                        <Popover.Content>{key}</Popover.Content>
                      </Popover>
                    }
                  >
                    <div className="downloadTitle knTitle">{key}</div>
                  </OverlayTrigger>
                  <div className="downloadCount count">
                    {knMaterialData[key]}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default KnMaterials;
