import React from 'react';
import { Table } from 'semantic-ui-react';

import TableHeader from './TableHeader';
import TableBody from './TableBody';

const DataTable = ({ id, className, columns, loading, dataList }) => {
  return (
    <Table
      id={id}
      className={className}
      selectable
      striped
      sortable
      celled
      collapsing
    >
      <TableHeader columns={columns} />
      <TableBody loading={loading} dataList={dataList} columns={columns} />
    </Table>
  );
};

export default DataTable;
