/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'redux/store';
import KTSearch from 'components/KTSearch';
import KtAdminListPlaceholder from './KtAdminListPlaceholder';
import {
  updateToastNotificationDetails,
  updateGeoRegionList,
} from 'redux/actions';
import { fetchGet, fetchPost, fetchDelete } from 'utils/KtCommonServices';
import KtAdminListCard from 'components/KtAdminListCard';
import KtPaAdminConfirmationPopup from 'components/common/KtPaAdminConfirmationPopup';
import { getApiFailMessage, dropdownFormat, sortPaList } from 'utils';
import './KtAdminListing.scss';
import { globalAdobeAnalytics } from 'config/analytics';
import AdminFilter from 'components/common/AdminFilter';
import { cloneDeep, isEmpty, isEqual, isArray } from 'utils/common';
import WithTooltip from 'components/common/withTooltip';
import Icon from 'components/common/icon';
import CONFIG from 'config/configProps';
class KtAdminListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataToBeShown: [],
      ktDataToBeShown: [],
      nonKtDataToBeShown: [],
      selectedRolesId: [],
      modalDataDeleteConfirmation: {},
      openModalDeleteConfirmation: false,
      fetchAdminList: false,
      fetchTypesMarkup: false,
      showTooltip: false,
      allDepartmentsList: [
        { label: 'KT', value: 'KT' },
        { label: 'Non-KT', value: 'Non-KT' },
      ],
      geoMarketList: [],
      rolesDropDown: [],
      paList: [],
      showUsersType: false,
      showDepartment: false,
      showGeoMarket: false,
      showManagedBy: false,
      showPa: false,
      selectedPa: [],
      selectedUserType: [],
      selectedDepartment: [],
      selectedGeoMarket: [],
      selectedManagedBy: [],
      isFilterApplied: false,
      selectedPaLength: 0,
      selectedUserTypeLength: 0,
      selectedDepartmentLength: 0,
      selectedGeoMarketLength: 0,
      selectedManagedByLength: 0,
      url: 'mailto:KTDashboardSupport@bcg.com?subject=KT Dashboard help request',
    };
    this.usersTypeContainer = React.createRef();
    this.departmentContainer = React.createRef();
    this.paContainer = React.createRef();
    this.geoMarketContainer = React.createRef();
    this.managedByContainer = React.createRef();
  }

  /**
   * React life cycle method
   */
  async componentDidMount() {
    window.scrollTo(0, 0);
    const { loggedInUserHighestRole, userTypes } = this.props;
    globalAdobeAnalytics('KTDashboard-Legacy-AdminListing');
    if (isEqual(loggedInUserHighestRole, '') || !loggedInUserHighestRole) {
      this.props.history.push({
        pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
      });
    }
    if (
      !isEqual(loggedInUserHighestRole, userTypes[0]) &&
      !isEqual(loggedInUserHighestRole, userTypes[1]) &&
      !isEqual(loggedInUserHighestRole, userTypes[8])
    ) {
      this.props.history.push({
        pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
      });
    }
    this.setState({ fetchAdminList: false });
    this.getFilterData();
  }

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount() {
    document.removeEventListener(
      'click',
      this.handleClickOutsideForUsersTypeList
    );
    document.removeEventListener('click', this.handleClickOutsideForDepartment);
    document.removeEventListener('click', this.handleClickOutsideForPa);
    document.removeEventListener(
      'click',
      this.handleClickOutsideForGeoMarketList
    );
    document.removeEventListener(
      'click',
      this.handleClickOutsideForManagedByList
    );
  }

  getFilterData = () => {
    document.addEventListener('click', this.handleClickOutsideForUsersTypeList);
    document.addEventListener('click', this.handleClickOutsideForDepartment);
    document.addEventListener('click', this.handleClickOutsideForPa);
    document.addEventListener('click', this.handleClickOutsideForGeoMarketList);
    document.addEventListener('click', this.handleClickOutsideForManagedByList);
    this.getRoles();
    this.getPaList();
    this.getGeoMarketList();
  };

  getRoles = () => {
    const { userTypeData } = this.props;
    const responseArray = [];
    userTypeData.forEach(el => {
      responseArray.push(dropdownFormat('' + el.id, el.name));
    });
    this.setState({ rolesDropDown: responseArray });
  };

  /**
   * This method will returns the PA list
   */
  getPaList() {
    const { ktSaPaList, loggedInUserHighestRole, userTypes } = this.props;
    let paList = [];
    if (
      isEqual(loggedInUserHighestRole, userTypes[0]) &&
      !isEmpty(ktSaPaList)
    ) {
      ktSaPaList.forEach(el => {
        const paTeam =
          CONFIG.PA_NAME_ALIAS_MAP[el.practiceArea] || el.practiceArea;
        paList.push(dropdownFormat('' + el.paId, paTeam));
      });
    } else {
      let PAUrl = CONFIG.API_URLS.EMP_PA;
      const baseUrl = CONFIG.KT_USER_BASE_URL;
      fetchGet(PAUrl, baseUrl).then(data => {
        data.forEach(el => {
          if (el.paId && el.practiceArea) {
            const paTeam =
              CONFIG.PA_NAME_ALIAS_MAP[el.practiceArea] || el.practiceArea;
            paList.push(dropdownFormat('' + el.paId, paTeam));
          }
        });
        paList = sortPaList(paList);
      });
    }
    this.setState({ paList });
  }

  getGeoMarketList = () => {
    const { geoRegionList } = this.props;
    if (!isEmpty(geoRegionList)) {
      this.setState({ geoMarketList: geoRegionList });
    } else {
      let geoList = [];
      const url = CONFIG.API_URLS.GET_GEO_REGION;
      const baseUrl = CONFIG.KT_USER_BASE_URL;
      fetchGet(url, baseUrl).then(data => {
        data.forEach(el => {
          geoList.push(dropdownFormat('' + el, el));
          store.dispatch(updateGeoRegionList(geoList));
        });
        this.setState({ geoMarketList: geoList });
      });
    }
  };

  clearDropdownCache = () => {
    const url = CONFIG.API_URLS.CLEAR_CACHE;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    fetchDelete(url, '', baseUrl).then(() => {
      const notificationDetailsObj = {
        showToastNotification: true,
        notificationHeader: 'Success',
        notificationBody: 'Successfully cleared all cached data',
        notificationClassName: 'custom-notification',
      };
      store.dispatch(updateToastNotificationDetails(notificationDetailsObj));
      store.dispatch(updateGeoRegionList([]));
      this.setState({ geoMarketList: [] });
      this.setState({ paList: [] }, () => {
        this.getRoles();
        this.getPaList();
        this.getGeoMarketList();
      });
    });
  };

  handleGoToAdmin = () => {
    let pageUrlList = CONFIG.KT_SCORECARD_CONST;
    this.props.history.push({
      pathname: pageUrlList.HOME_URL,
    });
  };

  showUsersType() {
    this.setState({ showUsersType: !this.state.showUsersType });
  }

  showDepartment() {
    this.setState({ showDepartment: !this.state.showDepartment });
  }

  showPa() {
    this.setState({ showPa: !this.state.showPa });
  }

  showGeoMarket() {
    this.setState({ showGeoMarket: !this.state.showGeoMarket });
  }

  showManagedBy() {
    this.setState({ showManagedBy: !this.state.showManagedBy });
  }
  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForUsersTypeList = event => {
    if (
      this.usersTypeContainer.current &&
      !this.usersTypeContainer.current.contains(event.target)
    ) {
      this.setState({ showUsersType: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForDepartment = event => {
    if (
      this.departmentContainer.current &&
      !this.departmentContainer.current.contains(event.target)
    ) {
      this.setState({ showDepartment: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForPa = event => {
    if (
      this.paContainer.current &&
      !this.paContainer.current.contains(event.target)
    ) {
      this.setState({ showPa: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForGeoMarketList = event => {
    if (
      this.geoMarketContainer.current &&
      !this.geoMarketContainer.current.contains(event.target)
    ) {
      this.setState({ showGeoMarket: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForManagedByList = event => {
    if (
      this.managedByContainer.current &&
      !this.managedByContainer.current.contains(event.target)
    ) {
      this.setState({ showManagedBy: false });
    }
  };

  handleAdminFilterChange = (stateField, newValue, length) => {
    const newState = { ...this.state };
    newState[stateField] = newValue;
    newState[`${stateField}Length`] = length;
    this.setState(
      {
        ...newState,
        showPa: false,
        showUsersType: false,
        showDepartment: false,
        isFilterApplied: true,
        fetchAdminList: false,
        showGeoMarket: false,
        showManagedBy: false,
      },
      () => {
        this.getUserListAPICall();
      }
    );
  };

  isFilterNotApplied = () => {
    const {
      selectedDepartment,
      selectedPa,
      selectedUserType,
      selectedGeoMarket,
      selectedManagedBy,
    } = this.state;

    return (
      isEmpty(selectedDepartment) &&
      isEmpty(selectedPa) &&
      isEmpty(selectedUserType) &&
      isEmpty(selectedGeoMarket) &&
      isEmpty(selectedManagedBy)
    );
  };

  filterDataTobeShown = dataToBeShown => {
    const nonKtDataToBeShown = [];
    const ktDataToBeShown = [];

    for (const keyValue in dataToBeShown) {
      if (isEqual(dataToBeShown[keyValue][0].employeeType, 'KT')) {
        ktDataToBeShown.push(dataToBeShown[keyValue]);
      } else {
        nonKtDataToBeShown.push(dataToBeShown[keyValue]);
      }
    }

    return { ktDataToBeShown, nonKtDataToBeShown };
  };
  getUserListAPICall = (displayNotification, notificationMessage) => {
    const {
      selectedDepartment,
      selectedPa,
      selectedUserType,
      selectedGeoMarket,
      selectedManagedBy,
    } = this.state;
    const notificationHeading = 'Success';
    const notificationDetailsObj = {
      showToastNotification: true,
      notificationHeader: notificationHeading,
      notificationBody: notificationMessage,
      notificationClassName: 'custom-notification',
    };
    if (this.isFilterNotApplied()) {
      this.setState({ isFilterApplied: false, fetchAdminList: true });
      if (displayNotification) {
        // dispatch an action to display toast notification
        store.dispatch(updateToastNotificationDetails(notificationDetailsObj));
      }
      return;
    }

    const dataToBePost = {
      practiceAreaId: selectedPa,
      roleId: selectedUserType,
      department: selectedDepartment,
      selectedManagedBy: selectedManagedBy,
      geoRegion: selectedGeoMarket,
    };
    let userDetailsUrl = CONFIG.API_URLS.GET_USER_DETAILS;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    return fetchPost(userDetailsUrl, dataToBePost, baseUrl)
      .then(async userData => {
        const dataToBeShown = cloneDeep(userData.data);
        const { ktDataToBeShown, nonKtDataToBeShown } =
          this.filterDataTobeShown(dataToBeShown);
        this.setState({
          dataToBeShown,
          ktDataToBeShown,
          nonKtDataToBeShown,
          fetchAdminList: true,
        });
        if (displayNotification) {
          // dispatch an action to display toast notification
          store.dispatch(
            updateToastNotificationDetails(notificationDetailsObj)
          );
        }
      })
      .catch(error => {
        let message = '';
        if (displayNotification) {
          message = CONFIG.KT_SCORECARD_CONST.DEFAULT_ERROR_MESSAGE;
        } else {
          message =
            error.response.data && error.response.data.errorMessage
              ? error.response.data.errorMessage
              : CONFIG.KT_SCORECARD_CONST.DEFAULT_ERROR_MESSAGE_FOR_API_FAIL;
        }
        store.dispatch(
          updateToastNotificationDetails(getApiFailMessage(false, message))
        );
      });
  };

  /**
   * This method will return the admin card's markup
   */
  getCardMarkUp(dataToBeShown) {
    let cardsMarkUp = [];
    dataToBeShown.forEach(el => {
      cardsMarkUp.push(
        <KtAdminListCard
          data={el}
          employeeId={el[0].employeedIdHRO}
          handleDeleteCardClick={this.handleDeleteCardClick}
          dataRefreshHandler={this.getUserListAPICall}
          key={el[0].employeedIdHRO}
        />
      );
    });
    return cardsMarkUp;
  }

  handleDeleteCardClick = data => {
    this.setState({
      modalDataDeleteConfirmation: data,
      openModalDeleteConfirmation: true,
    });
  };

  closeModalDeleteConfirmation = () => {
    this.setState({
      modalDataDeleteConfirmation: {},
      openModalDeleteConfirmation: false,
    });
  };

  createFilterPills = (selectedFilter, selectedValue, options, stateName) => {
    const appliedFilter = this.getElementsById(selectedValue, options);
    if (isEmpty(appliedFilter)) {
      return null;
    }

    return (
      <div className="AppliedFilterContainer">
        <div className="filterLabel">
          {selectedValue.length > 1 ? (
            WithTooltip(
              <div className="filter-text">
                <span>{`${selectedFilter} : ${appliedFilter.text}`}</span>
              </div>
            )(appliedFilter.toolTip, { inverted: true })
          ) : (
            <div className="filter-text">
              <span>{`${selectedFilter} : ${appliedFilter.text}`}</span>
            </div>
          )}
          <Icon
            name="delete"
            id={stateName}
            onClick={this.removeFilter}
            tooltipText={'Remove'}
          />
        </div>
      </div>
    );
  };

  showDefaultFilter = selectedFilter => {
    return (
      <div className="AppliedFilterContainer">
        <div className="filterLabel">
          <div className="filter-text">
            <span>{`${selectedFilter} : All`}</span>
          </div>
        </div>
      </div>
    );
  };

  removeFilter = (event, { id }) => {
    const newState = { ...this.state };
    newState[id] = [];
    this.setState(
      {
        ...newState,
        fetchAdminList: false,
      },
      () => {
        this.getUserListAPICall();
      }
    );
  };

  getElementsById = (Ids, availableOptions) => {
    let matchingElements = [];
    const IdCollection = isArray(Ids) ? Ids : [Ids];
    if (availableOptions) {
      matchingElements = availableOptions.filter(({ value }) =>
        IdCollection.includes('' + value)
      );
      const length = matchingElements.length;
      let allValues = matchingElements.map(({ label }) => label).join(' , ');
      let labelString = `${length} Selected`;
      if (length === 1) {
        [allValues, labelString] = ['', allValues];
      }
      return length > 0 ? this.outputFormat(labelString, allValues) : null;
    }
    return null;
  };

  outputFormat = (value, toolTip) => ({ text: value, toolTip });

  renderAppliedFilter = (selectedFilter, selectedValue, options, stateName) => {
    return isEmpty(selectedValue)
      ? this.showDefaultFilter(selectedFilter)
      : this.createFilterPills(
          selectedFilter,
          selectedValue,
          options,
          stateName
        );
  };

  renderFilter = ({
    type,
    showFilter,
    dataList,
    selectedList,
    selectedListLength,
    onChange,
    stateField,
    isAllSelected,
    showAll,
    elementRef,
    onClick,
  }) => {
    const { isFilterApplied } = this.state;

    return (
      <div
        className={`${showFilter ? 'blueBg' : ''} kt-admin-filter-dropdown`}
        ref={elementRef}
      >
        <p onClick={onClick} className="select-filter">
          <span className="selection-text-2">
            {isEmpty(selectedList) ? type : `(${selectedListLength}) ${type}`}
          </span>
          <img
            src="/img/kt-admin-arrow.svg"
            className={`kt-admin-arrow ${
              showFilter ? 'transform-90' : 'transform90'
            }`}
            alt=""
          />
        </p>
        {showFilter && (
          <AdminFilter
            {...{
              dataList,
              selectedList,
              onChange,
              stateField,
              isAllSelected: isFilterApplied && isAllSelected,
              showAll,
            }}
          />
        )}
      </div>
    );
  };

  render() {
    let {
      selectedDepartment,
      selectedUserType,
      selectedGeoMarket,
      modalDataDeleteConfirmation,
      showTooltip,
      selectedManagedBy,
      rolesDropDown,
      showUsersType,
      showDepartment,
      showManagedBy,
      showGeoMarket,
      allDepartmentsList,
      showPa,
      paList,
      selectedPa,
      isFilterApplied,
      geoMarketList,
      fetchAdminList,
      openModalDeleteConfirmation,
      selectedManagedByLength,
      selectedPaLength,
      selectedUserTypeLength,
      selectedDepartmentLength,
      ktDataToBeShown,
      nonKtDataToBeShown,
      selectedGeoMarketLength,
    } = this.state;
    const { showKTScorecardPageContent, employeeInitialData } = this.props;
    const tooltipName = showTooltip ? 'on' : 'off';
    return (
      showKTScorecardPageContent && (
        <div className="kt-main-container kt-admin-listing-container">
          <div>
            <span className="text-1">Review Access Permission</span>
            <span className="tooltip-with-icon positionRelative">
              <img
                src="/img/kt-info-icon.svg"
                alt="information"
                className="kt-info"
                onMouseEnter={() => {
                  this.setState({ showTooltip: !showTooltip });
                }}
                onMouseLeave={() => {
                  this.setState({ showTooltip: !showTooltip });
                }}
              />
              <div id="tooltip" className={tooltipName}>
                <div className="tooltip-inner">
                  To grant special permissions to Members, search the user and
                  click on the user.
                </div>
              </div>
            </span>
          </div>
          <div className="startText">
            Use filters to review access permissions for your PA. Use the search
            bar to find permissions for an individual.
            <div>
              If you wish to add or update permissions, please contact
              <a className="admin-help" href={CONFIG.MAIL_URL}>
                KT Dashboard Support
              </a>
              .
            </div>
          </div>
          <div className="search-add-button-container">
            <KTSearch
              placeholder="Search All Members"
              fromAdminListing={true}
              dataRefreshHandler={this.getUserListAPICall}
            />
          </div>
          <div>
            <div className="admin-level-filers-container">
              {this.renderFilter({
                type: 'PA',
                elementRef: this.paContainer,
                onClick: this.showPa.bind(this),
                showFilter: showPa,
                dataList: paList,
                onChange: this.handleAdminFilterChange,
                selectedList: selectedPa,
                selectedListLength: selectedPaLength,
                stateField: 'selectedPa',
                isAllSelected: isEmpty(selectedPa),
                showAll: true,
              })}
              {this.renderFilter({
                type: 'User Type',
                elementRef: this.usersTypeContainer,
                onClick: this.showUsersType.bind(this),
                showFilter: showUsersType,
                dataList: rolesDropDown,
                onChange: this.handleAdminFilterChange,
                selectedList: selectedUserType,
                selectedListLength: selectedUserTypeLength,
                stateField: 'selectedUserType',
                isAllSelected: isEmpty(selectedUserType),
                showAll: true,
              })}
              {this.renderFilter({
                type: 'Department',
                elementRef: this.departmentContainer,
                onClick: this.showDepartment.bind(this),
                showFilter: showDepartment,
                dataList: allDepartmentsList,
                onChange: this.handleAdminFilterChange,
                selectedList: selectedDepartment,
                selectedListLength: selectedDepartmentLength,
                stateField: 'selectedDepartment',
                isAllSelected: isEmpty(selectedDepartment),
                showAll: true,
              })}
              {this.renderFilter({
                type: 'Geo Market',
                elementRef: this.geoMarketContainer,
                onClick: this.showGeoMarket.bind(this),
                showFilter: showGeoMarket,
                dataList: geoMarketList,
                onChange: this.handleAdminFilterChange,
                selectedList: selectedGeoMarket,
                selectedListLength: selectedGeoMarketLength,
                stateField: 'selectedGeoMarket',
                isAllSelected: isEmpty(selectedGeoMarket),
                showAll: true,
              })}
              {this.renderFilter({
                type: 'Managed By',
                elementRef: this.managedByContainer,
                onClick: this.showManagedBy.bind(this),
                showFilter: showManagedBy,
                dataList: CONFIG.MANAGED_BY_ADMIN,
                onChange: this.handleAdminFilterChange,
                selectedList: selectedManagedBy,
                selectedListLength: selectedManagedByLength,
                stateField: 'selectedManagedBy',
                isAllSelected: isEmpty(selectedManagedBy),
                showAll: true,
              })}
              {WithTooltip(
                <button
                  className="btn-clear-cache"
                  onClick={this.clearDropdownCache}
                >
                  Clear Cache
                  <Icon name="trash alternate outline" />
                </button>
              )('Clear all cached dropdown options', { inverted: true })}
            </div>
            {isFilterApplied && (
              <>
                <div className="info-container">
                  <span className="text-1">Filters Applied: </span>
                  {this.renderAppliedFilter(
                    'PA',
                    selectedPa,
                    paList,
                    'selectedPa'
                  )}
                  {this.renderAppliedFilter(
                    'User Type',
                    selectedUserType,
                    rolesDropDown,
                    'selectedUserType'
                  )}
                  {this.renderAppliedFilter(
                    'Department',
                    selectedDepartment,
                    allDepartmentsList,
                    'selectedDepartment'
                  )}
                  {this.renderAppliedFilter(
                    'Geo Market',
                    selectedGeoMarket,
                    geoMarketList,
                    'selectedGeoMarket'
                  )}
                  {this.renderAppliedFilter(
                    'Managed By',
                    selectedManagedBy,
                    CONFIG.MANAGED_BY_ADMIN,
                    'selectedManagedBy'
                  )}
                </div>
                {fetchAdminList && (
                  <div className="cards-container">
                    {!isEmpty(ktDataToBeShown) && (
                      <div>
                        <div className="heading">KT Users</div>
                        <div className="cards-kt-container">
                          {this.getCardMarkUp(ktDataToBeShown)}
                        </div>
                      </div>
                    )}
                    {!isEmpty(nonKtDataToBeShown) && (
                      <div>
                        <div className="heading">Non-KT Users</div>
                        <div className="cards-non-kt-container">
                          {this.getCardMarkUp(nonKtDataToBeShown)}
                        </div>
                      </div>
                    )}
                    {openModalDeleteConfirmation && (
                      <KtPaAdminConfirmationPopup
                        modalData={modalDataDeleteConfirmation}
                        onHide={this.closeModalDeleteConfirmation}
                        dataRefresh={this.getUserListAPICall}
                        loggedInUserEmailId={employeeInitialData.email}
                      />
                    )}
                  </div>
                )}
                {!fetchAdminList && (
                  <div className="cards-container-placeholder">
                    <KtAdminListPlaceholder />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )
    );
  }
}
/**
 *
 * React Redux linking method
 */
const mapStateToProps = function (reduxStore) {
  return {
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    showKTScorecardPageContent:
      reduxStore.ktScorecard.showKTScorecardPageContent,
    ktSaPaList: reduxStore.headerFilterData.ktSaPaList,
    userTypeData: reduxStore.userRoleData.userTypeData,
    userTypes: reduxStore.userRoleData.userTypes,
    geoRegionList: reduxStore.ktScorecard.geoRegionList,
  };
};

export default connect(mapStateToProps)(KtAdminListing);
