import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { isEqual, isEmpty } from 'utils/common';
import CONFIG from 'config/configProps';
const colors = CONFIG.CASE_BILLABILITY_COLORS;

const casesColors = {
  ...CONFIG.CASE_ENGAGEMENT_DEPTH_COLORS,
  dummy: '#D8D8D8',
};
const MAX_STACK_SIZE = 30;

export default class KTCaseBillabilityTeam extends React.PureComponent {
  render() {
    const sectionCategories = this.props.sectionCategories || [];
    const casesCategories = this.props.casesCategories || [];
    const casesKey = Object.keys(casesCategories);
    const chartData =
      this.props.sectionCategories &&
      sectionCategories.length >= 1 &&
      !isEmpty(this.props.rawData.userMap) &&
      transformRawDataToChartSeries(sectionCategories, this.props, casesKey);
    const caseChartOptions =
      chartData &&
      getCaseChart(
        chartData.chartALabels,
        chartData.chartBLabels,
        chartData.caseChartSeries,
        this.props.avgTargetValue
      );
    const metricChartOptions =
      chartData &&
      getMetricChart(chartData.metricChartSeries, chartData.chartALabels);
    const height =
      ((chartData && chartData.chartALabels) || []).length * MAX_STACK_SIZE;
    return (
      <div className="metric_chart_wrapper">
        <div
          className={`metric_charts ${
            !caseChartOptions ? 'chart-no-record-txt' : ''
          }`}
        >
          {caseChartOptions && metricChartOptions && (
            <React.Fragment>
              <div className="total_back_case" />
              <div className="total_back_metric" />
            </React.Fragment>
          )}
          {caseChartOptions ? (
            <>
              {caseChartOptions && (
                <HighchartsReact
                  options={caseChartOptions}
                  allowChartUpdate={false}
                  highcharts={Highcharts}
                  containerProps={{
                    style: {
                      height: height,
                      width: '70%',
                      position: 'relative',
                    },
                  }}
                />
              )}
              {metricChartOptions && (
                <HighchartsReact
                  options={metricChartOptions}
                  allowChartUpdate={false}
                  highcharts={Highcharts}
                  containerProps={{
                    style: {
                      height: height,
                      width: '30%',
                      position: 'relative',
                    },
                  }}
                />
              )}
            </>
          ) : (
            'No Records To Display'
          )}
        </div>
        <div className="legends">
          <div className="metric_legends work-split">
            {sectionCategories.map(item => (
              <div className="metric_legend_item" key={item}>
                <div
                  className="legend-icon"
                  style={{ backgroundColor: colors[item] }}
                />
                <div className="heading">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const extractGeoLabelsAndUserlabels = (sortedUsers, passGeo) => {
  const geoLabels = [];
  let userLabels = {};
  const geoFocusRegEx = new RegExp(/^[A-Z]+\sGeo-Focus$/);
  sortedUsers.forEach(el => {
    if (passGeo && geoFocusRegEx.test(el[0])) {
      geoLabels.push(el[0]);
    } else if (!isEqual(el[0], 'Total')) {
      let userObject = {};
      userObject[el[0]] = el[1];
      userLabels = { ...userLabels, ...userObject };
    }
  });

  return {
    geoLabels,
    userLabels: Object.keys(userLabels),
  };
};

const getGraphData = ({ rawData, passGeo, userLabels, geoLabels }) => {
  const graphData = [...userLabels.map(name => rawData.userMap[name])];
  if (passGeo && geoLabels.length) {
    graphData.unshift(...geoLabels.map(name => rawData.userMap[name]));
  } else {
    graphData.unshift(rawData.userMap.Total || {});
  }

  return graphData;
};

const transformRawDataToChartSeries = (sectionCategories, props, casesKey) => {
  const {
    rawData,
    showBackSeries,
    maxTargetValue,
    caseKeyName,
    casesCategories,
    passGeo,
  } = props;
  let totalValues = [];
  let sortedUsers = Object.entries(rawData.userMap).sort(
    (a, b) => b[1].workSplit.Case - a[1].workSplit.Case
  );
  const { geoLabels, userLabels } = extractGeoLabelsAndUserlabels(
    sortedUsers,
    passGeo
  );
  if (userLabels.length === 0) {
    return undefined;
  }

  const labels = ['Total', ...userLabels];
  let metricChartSeries = [];
  let casesChartSeries = [];
  const backSeries = [];
  const caseBackSeries = [];
  const stackObjs = getGraphData({
    userLabels,
    rawData,
    geoLabels,
    passGeo,
  });

  // Total Stack
  const data = {};
  const casesData = {};
  // let dummyData = false;
  sectionCategories.forEach(stackKey => {
    data[stackKey] = [];
  });
  casesKey.forEach(stackKey => {
    casesData[stackKey] = [];
  });
  stackObjs.forEach(stackObjValues => {
    if (stackObjValues.workSplit) {
      const caseBillabilityValues = stackObjValues.workSplit;
      let totalValueForStackObj = 0;
      sectionCategories.forEach(stackKey => {
        const value = Math.round(caseBillabilityValues[stackKey]);
        totalValueForStackObj += value;
        data[stackKey].push(value);
      });
      backSeries.push(
        totalValueForStackObj < maxTargetValue
          ? maxTargetValue - totalValueForStackObj
          : 0
      );
    }
    if (stackObjValues.caseEngagementDepth) {
      const casesValues = stackObjValues.caseEngagementDepth;
      let totalValueForStackObj = 0;
      const total = Math.round(casesValues['total']);
      totalValues.push(total);
      casesKey.forEach(stackKey => {
        const value = Math.round(casesValues[stackKey]);
        totalValueForStackObj += value;
        casesData[stackKey].push(value);
      });
      caseBackSeries.push(
        totalValueForStackObj < maxTargetValue
          ? maxTargetValue - totalValueForStackObj
          : 0
      );
    }
  });

  metricChartSeries = sectionCategories.map(key => ({
    name: key,
    data: data[key],
    type: 'bar',
    color: colors[key],
    pointWidth: 5,
  }));
  casesChartSeries = Object.keys(casesData).map(key => ({
    name: casesCategories[key],
    data: casesData[key],
    type: 'bar',
    color: casesColors[key],
    pointWidth: 5,
  }));

  if (showBackSeries) {
    metricChartSeries.push({
      name: 'dummy',
      data: backSeries,
      type: 'bar',
      color: '#D8D8D8',
      pointWidth: 5,
      enableMouseTracking: false,
    });
    casesChartSeries.push({
      name: 'dummy',
      data: caseBackSeries,
      type: 'bar',
      color: '#D8D8D8',
      pointWidth: 5,
      enableMouseTracking: false,
    });
  }
  return {
    chartALabels: labels,
    chartBLabels: data[caseKeyName],
    caseChartSeries: [
      {
        data: data[caseKeyName].map(value =>
          value > maxTargetValue ? 0 : maxTargetValue - value
        ),
        type: 'bar',
        color: '#D8D8D8',
        pointWidth: 5,
        enableMouseTracking: false,
      },
      {
        data: data[caseKeyName],
        type: 'bar',
        pointWidth: 5,
        color: colors[caseKeyName],
      },
    ],
    metricChartSeries: [...metricChartSeries].reverse(),
    casesChartSeries: [...casesChartSeries].reverse(),
    totalValues: totalValues,
  };
};

const commonChartOptions = {
  chart: {
    type: 'bar',
    spacingTop: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    spacingBottom: 0,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      stacking: 'percent',
      borderWidth: 0,
    },
  },
  tooltip: {
    enabled: true,
    useHTML: true,
    outside: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: null,
    },
    gridLineColor: 'ffffff',
    labels: {
      enabled: false,
    },
  },
};

function labelFormatter(value, position) {
  return `<span
    class="${position === 0 ? 'metricChartHeading' : 'metricChartlabel'}"
  >
    ${value}
  </span>`;
}

function getMetricChart(metricSeries, labels) {
  return {
    ...commonChartOptions,
    xAxis: [
      {
        categories: labels,
        lineColor: 'ffffff',
        labels: {
          y: 3,
          useHTML: true,
          formatter: function () {
            return labelFormatter(this.value, this.pos);
          },
          style: {
            width: 0,
            dispaly: 'none',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    ],
    tooltip: {
      ...commonChartOptions.tooltip,
      formatter: function () {
        return `<span><b> ${this.series.name} : </b>${this.y}%</span>`;
      },
    },
    series: metricSeries,
  };
}

function getCaseChart(labels, rightLabels, series, avgTargetValue) {
  return {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      marginLeft: 72,
    },
    xAxis: [
      {
        categories: labels,
        lineColor: 'ffffff',
        labels: {
          y: 3,
          useHTML: true,
          formatter: function () {
            return labelFormatter(this.value, this.pos);
          },
          style: {
            width: 70,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
      {
        categories: rightLabels,
        lineColor: 'ffffff',
        opposite: true,
        linkedTo: 0,
        labels: {
          y: 3,
          formatter: function () {
            return labelFormatter(this.value, this.pos);
          },
        },
      },
    ],
    tooltip: {
      ...commonChartOptions.tooltip,
      formatter: function () {
        return this.x;
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      plotLines: [
        {
          color: '#6D6767',
          width: 1,
          value: avgTargetValue,
          dashStyle: 'Dash',
          zIndex: 5,
        },
      ],
    },
    series: series,
  };
}
