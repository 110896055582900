import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import KTBillability from './KTBillability';
import { isEmpty, isEqual } from 'utils/common';
import { globalAdobeAnalytics } from 'config/analytics';
import actions from 'redux/actions';
import { isAnalyst, isKtLineManger } from 'utils';

const getHeading = activeIndex => {
  let heading = '',
    keyName = '';
  switch (activeIndex) {
    case 0:
      heading = 'Billability by Business';
      keyName = 'billabilityByBusiness';
      break;
    case 1:
      heading = 'Billability by Team Member';
      keyName = 'billabilityByTeamMember';
      break;
    default:
      heading = '';
      keyName = '';
  }
  return { heading, keyName };
};

const KTBilling = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let path = location.pathname;
  const activeIndex = isEqual(path, CONFIG.PROTOTYPE_URLS.BUSINESS_BILLING)
    ? 0
    : 1;
  const currentPage = getHeading(activeIndex);
  const loggedInUserRole = useSelector(
    state => state.homePageData.loggedInUserRole
  );

  const adobeData = useSelector(state => state.adobeData.adobeAnalyticsData);

  useEffect(() => {
    if (!isEmpty(loggedInUserRole)) {
      if (
        (isAnalyst(loggedInUserRole) || isKtLineManger(loggedInUserRole)) &&
        location.pathname.includes('/business')
      )
        history.push({ pathname: '/' });
    }
  }, [loggedInUserRole]);

  useEffect(() => {
    if (!adobeData?.[currentPage.keyName]) {
      const isScriptLoaded = globalAdobeAnalytics(
        `KTDashboard-${currentPage.heading}`
      );
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData, activeIndex]);

  const updateAdobeData = () => {
    if (!adobeData?.[currentPage.keyName])
      dispatch(
        actions.adobeAnalyticsActions.updateAdobeDetails({
          [currentPage.keyName]: true,
        })
      );
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Column className="Kt_main_body_tabs" width={16}>
        <KTBillability
          selectedIndex={activeIndex}
          history={history}
          pageUrl={`KTDashboard-${currentPage.heading}`}
        />
      </Grid.Column>
    </Grid>
  );
};

export default KTBilling;
