import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
} from 'redux/actions/actionTypes';

import InitialState from './InitialState';
const STATE_SLICE_NAME = 'filterData'; // must match the INitial State Key

/**
 * This reducer will return the state-slice for seed data (filter values)
 * When the action.type is :
 * - FILTER_DATA_FETCH_FAILURE  the action will have  property 'id' and 'error'
 * -  FILTER_DATA_FETCH_SUCCESS the action will have  property 'id' and 'results'
 * . These are pssed along from Dispatcher (Action)
 * @param {*} state state object.
 * @param {*} action action object
 */
export function filterSectionReducer(
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case FILTER_DATA_FETCH_SUCCESS: {
      const newState = { ...state };
      newState[action.id] = { results: action.results, error: '' };
      return newState;
    }

    //invoked when case search api call is failure
    case FILTER_DATA_FETCH_FAILURE: {
      const newState = { ...state };
      newState[action.id] = { results: [], error: action.error };
      return newState;
    }
    //default: return existing state
    default:
      return state;
  }
}

export const selectors = {
  /**
   * Get Seed Data results for specific API Identifier
   */
  getResults: (appState, apiIdentifier) => {
    return (
      appState[STATE_SLICE_NAME][apiIdentifier] &&
      appState[STATE_SLICE_NAME][apiIdentifier].results
    );
  },
  /**
   * Get Errors occured during API Call for a specific API Identifier
   */
  getError: (appState, apiIdentifier) =>
    appState[STATE_SLICE_NAME][apiIdentifier] &&
    appState[STATE_SLICE_NAME][apiIdentifier].error,
};
