import './configAA.js';
import CONFIG from './configProps.js';
let Hashes = require('jshashes');
const adobeData = {
  productName: 'KT Dashboard',
  tabName: '',
  page: {
    pageInfo: {
      pageName: '',
    },
    headerInfo: {
      staffSystemID: '',
    },
  },
  filter: {
    filterCategory: '',
    filterName: '',
  },
  button: {
    buttonName: '',
  },
};

function loadAdobeScript(dataLayer, pageName) {
  const adobePageData = adobeData.page.pageInfo;
  adobePageData.pageName = pageName ? pageName : getPageName();
  window.digitalData = Object.assign(window.digitalData, adobeData);
  if (window._satellite) {
    window._satellite.track(dataLayer);
    window._satellite.pageBottom();
    return true;
  } else {
    return false;
  }
}

function getPageName() {
  const pathName = window.location.pathname;
  let pageName = '';
  switch (pathName) {
    case CONFIG.PROTOTYPE_URLS.HOME:
      pageName = 'KTDashboard-HomePage';
      break;
    case CONFIG.PROTOTYPE_URLS.BILLABILITY:
      pageName = 'KTDashboard-Billability';
      break;
    case CONFIG.PROTOTYPE_URLS.TIMESHEET:
      pageName = 'KTDashboard-Timesheets';
      break;
    case CONFIG.PROTOTYPE_URLS.CHARGES_HOURS:
      pageName = 'KTDashboard-Charges/Hours';
      break;
    default:
      pageName = '';
  }
  return pageName;
}

export function getStaffId(email) {
  const staffSystemID = new Hashes.SHA256().hex(email.toLowerCase());
  adobeData.page.headerInfo.staffSystemID = staffSystemID;
  window.digitalData = Object.assign(window.digitalData, adobeData);
}

export function globalAdobeAnalytics(pageName) {
  return loadAdobeScript('pageView', pageName);
}

export function onDownloadButtonClick(buttonName, pageName) {
  adobeData.button.buttonName = buttonName;
  return loadAdobeScript('buttonInteractions', pageName);
}

export function onFiltersDropDownOpen(filterCategory) {
  adobeData.filter.filterCategory = filterCategory;
  return loadAdobeScript('filterDropdownInteractions');
}

export function onFiltersInteractions(filterName, filterCategory) {
  adobeData.filter.filterName = filterName;
  adobeData.filter.filterCategory = filterCategory;
  return loadAdobeScript('filterInteractions');
}
