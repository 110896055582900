import React from 'react';

const FilterLabel = ({ filterTitle, showSeparator, filterValue }) => {
  return (
    <div>
      {filterTitle ? <span>{filterTitle}</span> : ''}
      {showSeparator ? ':' : ''}
      {filterValue ? <span> {filterValue}</span> : ''}
    </div>
  );
};

export default FilterLabel;
