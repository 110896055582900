import React, { Component } from 'react';
import { withRouter } from 'react-router';
import store from 'redux/store';
import { connect } from 'react-redux';
import CaseBillabilityHighChart from './KTCaseBillabilityTeam';
import {
  getApiFailMessage,
  isGeoLeadRole,
  isGeoManagerRole,
  shouldGetTableauStackApiData,
  selectUserIdAsTeamMembers,
  selectUserIdAsDefaultMember,
  getHeaderTextCaseBillabilitySplit,
  isAllSectorSubSectorSelected,
  isAllPa,
} from 'utils';
import { fetchPost } from 'utils/KtCommonServices';
import CONFIG_KTS from 'config/configProps';
import {
  updateToastNotificationDetails,
  showTooltipPopup,
} from 'redux/actions';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import KTCaseBillabilityTeamPlaceholder from 'components/KTTableauStackWrapper/KTCaseBillabilityTeam/KTCaseBillabilityTeamPlaceholder';
import Icon from 'components/common/Icons';
import { isEmpty, isEqual } from 'utils/common';
import cloneDeep from 'clone-deep';

const CategoriesNames = {
  Case: 'Case',
  CaseWO: 'Case w/o #',
  BD: 'BD',
  Investment: 'Investment',
  Other: 'Other',
  'Innovation Fund': 'Innovation Fund',
};

const casesCategories = {
  lessThan4: '<4 h',
  between4and20: '4-20 h',
  between20and40: '20-40 h',
  between40and80: '40-80 h',
  greaterThan80: '=>80 h',
};

const SectionCategoriesDisplayOrder = CONFIG_KTS.SECTION.reduce(
  (allSections, section) => {
    return {
      ...allSections,
      [section]: Object.values(CategoriesNames),
    };
  },
  {}
);

class KTCaseBillabilityTeam extends Component {
  constructor(props) {
    super(props);
    const { loggedInUserHighestRole } = this.props;
    this.state = {
      chartApiData: undefined,
      selectedValueForTeamMember: '',
      isGeoManager: isGeoManagerRole(loggedInUserHighestRole),
      isGeoLead: isGeoLeadRole(loggedInUserHighestRole),
    };
  }

  componentDidMount() {
    const { tableauParamString } = this.props;
    if (tableauParamString) {
      this.getApiData();
    }
  }

  // /**
  //  *
  //  * React Life cycle method
  //  */
  componentDidUpdate(prevProps) {
    if (shouldGetTableauStackApiData(this.props, prevProps)) {
      this.getApiData();
    }
  }

  getApiData = () => {
    const { isManager, isAllSelected, selectedTeamMembers, defaultMemberList } =
      this.props;
    this.setState({ chartApiData: undefined });
    let apiIdentifier = '';
    let url = '';
    const apiRequestData = cloneDeep(this.props.apiRequestData);
    if (
      !isEmpty(apiRequestData.geoRegion) &&
      isEmpty(apiRequestData.userId) &&
      selectUserIdAsDefaultMember(isAllSelected, selectedTeamMembers)
    ) {
      apiRequestData['selectedUserId'] = defaultMemberList;
    } else if (
      selectUserIdAsTeamMembers(isAllSelected, isManager, selectedTeamMembers)
    ) {
      apiRequestData['selectedUserId'] = selectedTeamMembers;
    }
    apiIdentifier = API_CALL_IDENTIFIERS.FETCH_CASE_BILLABILITY_TEAM;
    url = CONFIG_KTS.API_URLS.CASE_BILLABILITY_TEAM;
    const baseURL = CONFIG_KTS.KT_BILLING_API_URL;
    const selectedValueForTeamMember = !isEmpty(apiRequestData.subSector)
      ? apiRequestData.subSector
      : apiRequestData.sector;
    // fetch the logged in user (manager) details
    store.dispatch(beginAPICall(apiIdentifier));
    fetchPost(url, apiRequestData, baseURL)
      .then(result => {
        this.setState({
          chartApiData: result.data,
          selectedValueForTeamMember,
        });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      })
      .finally(() => store.dispatch(endAPICall(apiIdentifier)));
  };
  renderCardHeading = () => {
    const { isAdmin, apiRequestData, isManager } = this.props;
    const { isGeoLead } = this.state;
    const isAllSelected = isAllSectorSubSectorSelected(apiRequestData);
    const isAllPaSelected = isAllPa(apiRequestData);
    const showAdminHeading = isAdmin && isAllSelected;
    const showPaAdminHeading = isAdmin && isAllPaSelected;
    const parameter = {
      isManager,
      isGeoLead,
      showPaAdminHeading,
      showAdminHeading,
    };
    return getHeaderTextCaseBillabilitySplit(parameter);
  };

  renderToolTip = () => {
    const { InfoText } = this.props;

    return InfoText ? (
      <div
        className="tooltip-with-icon homeTileToolTip"
        onClick={() => {
          store.dispatch(
            showTooltipPopup({
              showTooltip: true,
              tooltipName: 'Case Billability by Team Member',
            })
          );
        }}
      >
        <Icon
          name="info-tableau"
          class="info-icon"
          alt="infoIcon"
          src="/img/info-tableau.svg"
        />
      </div>
    ) : null;
  };

  render() {
    const {
      chartApiData,
      selectedValueForTeamMember,
      isGeoManager,
      isGeoLead,
    } = this.state;
    const { section, passGeo, className, isManager, apiCallStatus } =
      this.props;
    const isLoading = apiCallStatus.fetchCaseBillByTeam;
    const loaderWidth = isManager ? 'large' : 'x-large';

    return (
      <div className={`case-billability-split ${className}`}>
        <div className="card-heading border-bottom">
          <span className="heading-text hide-edit">
            {this.renderCardHeading()}
          </span>
          <span className="heading-middle-text">Work Split</span>
          {/* <span className="heading-right-text">Case Engagement Depth</span> */}
        </div>
        {!isLoading ? (
          <div className="chart-container">
            {chartApiData && (
              <CaseBillabilityHighChart
                rawData={chartApiData}
                avgTargetValue={chartApiData.target}
                maxTargetValue={100}
                caseKeyName={'Case'}
                showBackSeries={isEqual(section, CONFIG_KTS.SECTION[1])}
                sectionCategories={SectionCategoriesDisplayOrder[section] || []}
                isManager={isManager}
                selectedValueForTeamMember={selectedValueForTeamMember}
                passGeo={passGeo && !isGeoManager && !isGeoLead}
                casesCategories={casesCategories}
              />
            )}
          </div>
        ) : (
          <div className="chart-container">
            <KTCaseBillabilityTeamPlaceholder
              isLoading={isLoading}
              width={loaderWidth}
            />
          </div>
        )}
        {this.renderToolTip()}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    apiCallStatus: reduxStore.apiCallsInProgress,
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    passGeo: reduxStore.headerFilterData.passGeo,
    isAllSelected: reduxStore.headerFilterData.isAllSelected,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    isManager: reduxStore.userRoleData.isManager,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    tableauStringData: reduxStore.tableauStackData.tableauStringData,
    defaultMemberList: reduxStore.headerFilterData.defaultMemberList,
  };
};

export default connect(mapStateToProps)(withRouter(KTCaseBillabilityTeam));
