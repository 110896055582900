import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/common/icon/Icon';

import './SubMenu.scss';

const SubMenu = ({ menuItems, className, isActive, onMenuItemClick }) => {
  return (
    <ul className={`sub-menu ${className}`}>
      {menuItems
        .filter(({ showItem }) => showItem)
        .map(item => {
          return (
            <li
              key={item.key}
              className={`sub-menu-item ${isActive(item) ? 'active' : ''}`}
              onClick={onMenuItemClick}
            >
              <Link to={item.link}>
                <Icon name={item.icon} />
                {item.text}
              </Link>
              {/* <Icon name="angle right" /> */}
            </li>
          );
        })}
    </ul>
  );
};

export default SubMenu;
