import React, { useEffect, useRef, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import CONFIG from 'config/configProps';
import { isEqual } from 'utils/common';
import { useSelector } from 'react-redux';
import './index.scss';
import { isAnalyst, isEmptyOrNull } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';

const KtSideBar = () => {
  const history = useHistory();
  const location = useLocation();
  const loggedInUserRole = useSelector(
    state => state.homePageData.loggedInUserRole
  );
  const icons = CONFIG.SIDEBAR_ICONS[loggedInUserRole];
  const [activeIcon, setActiveIcon] = useState(
    isEmptyOrNull(loggedInUserRole) ? '' : icons[0]
  );
  const [showTooltip, setShowTooltip] = useState({});
  const [hoveredIcon, setHoveredIcon] = useState('');
  const target = useRef(null);
  const isAnalystUser = isAnalyst(loggedInUserRole);

  useEffect(() => {
    if (!isEmptyOrNull(loggedInUserRole)) {
      let pathName = location.pathname;
      let activeItem = icons;
      if (!isEqual(pathName, '/'))
        activeItem = icons.filter(icon =>
          pathName.includes(icon.toLowerCase())
        );
      setActiveIcon(!isEmptyOrNull(activeItem) ? activeItem[0] : icons[0]);
    }
  }, [loggedInUserRole, location.pathname]);

  return (
    <Grid.Column width={1} className="Kt_dashboard_body_left">
      {!isEmptyOrNull(loggedInUserRole) &&
        icons.map(icon => {
          const tooltipClassName = showTooltip[icon] ? 'on' : 'off';
          return (
            <div className="sidebar">
              <div
                className={`${icon}_icon sidebar_icon ${
                  isEqual(icon, activeIcon) ? 'active' : ''
                }`}
                onClick={() => {
                  if (isEqual(icon, activeIcon)) return;
                  setActiveIcon(icon);
                  let pathname =
                    isAnalystUser && isEqual(icon, 'People')
                      ? CONFIG.SIDEBAR_DEFAULT_URLS.analyst_people
                      : CONFIG.SIDEBAR_DEFAULT_URLS[icon];
                  history.push({
                    pathname,
                  });
                }}
                onMouseEnter={() => {
                  const newState = {};
                  newState[icon] = true;
                  setShowTooltip({ ...newState });
                  setHoveredIcon(icon);
                }}
                onMouseLeave={() => {
                  const newState = {};
                  newState[icon] = false;
                  setShowTooltip({ ...newState });
                  setHoveredIcon('');
                }}
                key={icon}
                ref={target}
              >
                <img
                  className="sidebar_icon_image"
                  src={`/img/${icon}_sidebar_icon.png`}
                  alt={icon}
                />
              </div>
              <div id="tooltip" className={tooltipClassName}>
                <div className="tooltip-inner">
                  {isEqual(hoveredIcon, 'Business')
                    ? 'Business Comparison'
                    : hoveredIcon}
                </div>
              </div>
            </div>
          );
        })}
    </Grid.Column>
  );
};

export default KtSideBar;
