import React, { useEffect, useState } from 'react';
import Icon from 'components/common/icon/Icon';
import {
  formatNumber,
  getBillabilityTotal,
  isEmptyOrNull,
  sortByObject,
} from 'utils';
import { isEmpty, isEqual, numberWithCommas } from 'utils/common';
import './KTBillabilityBusiness.scss';
import CONFIG from 'config/configProps';

const KTBillabilityPA = props => {
  const { selectedOption, apiData, isMemberView } = props;
  const [showTooltip, setShowToolTip] = useState({});

  useEffect(() => {
    expandCollapseAll();
  }, [selectedOption]);

  const expandCollapseAll = () => {
    const collapsedElements = document.querySelectorAll('.collapsed_section');
    const expandedElements = document.querySelectorAll('.expanded_section');
    if (
      isEqual(selectedOption, CONFIG.PROTOTYPE_BILLABILITY_SORT_BY_OPTIONS[0])
    ) {
      for (const collapsed of collapsedElements) {
        collapsed.classList.remove('hide');
      }
      for (const expanded of expandedElements) {
        expanded.classList.add('hide');
      }
    } else {
      for (const collapsed of collapsedElements) {
        collapsed.classList.add('hide');
      }
      for (const expanded of expandedElements) {
        expanded.classList.remove('hide');
      }
    }
  };

  const addAndRemoveClass = id => {
    const expandedElement = document.getElementById('expanded_' + id);
    const collapsedClassName = document.getElementById(
      'collapsed_' + id
    ).className;
    if (expandedElement.className.indexOf('hide') < 0) {
      document.getElementById('collapsed_' + id).classList.remove('hide');
      document.getElementById('expanded_' + id).classList.add('hide');
    } else if (collapsedClassName.indexOf('hide') < 0) {
      document.getElementById('collapsed_' + id).classList.add('hide');
      document.getElementById('expanded_' + id).classList.remove('hide');
    }
  };

  const renderChartDataTable = () => {
    let sortedData = apiData?.allBillabilityMap || {};
    if (sortedData.length === 0) {
      return undefined;
    }
    sortedData = sortByObject(sortedData, 'caseBillability');
    return sortedData.map(value => {
      return (
        <>
          {renderCollapsedRows(value[1], 0, false, false, 0, value[0])}
          {renderExpendedSection(value[1], 0, false, false, value[0])}
        </>
      );
    });
  };

  const renderExpendedSection = (data, index, isTotal, isMainTotal, key) => {
    let setIndex = data?.children ? index + 1 : index;
    const name = data?.employeeName || key || data[0];
    const dataToShow = data;
    const width = isEqual(index, 2) ? 5 : index;
    const keyId = data?.geoRegionKey || data?.paKey;
    return (
      <div id={`expanded_${keyId}`} className="hide expanded_section">
        <div
          key={name}
          className={`table_row  ${isTotal ? 'total' : ''} ${
            isMainTotal ? 'blue' : 'grey'
          }`}
        >
          <div
            className={`first_columns ${
              dataToShow?.children ? '' : 'sub_topic'
            }`}
            style={{
              width:
                isEqual(index, 1) && !dataToShow.children
                  ? '29.5%'
                  : isEqual(index, 2) && !dataToShow.children
                  ? '22.5%'
                  : `${8 + width}%`,
            }}
          >
            {dataToShow?.children && (
              <Icon
                name={`angle up`}
                onClick={() => addAndRemoveClass(`${keyId}`)}
              />
            )}
            <div>{name}</div>
          </div>
          <div
            className="second_columns"
            style={{
              width:
                isEqual(index, 1) && !dataToShow.children
                  ? '70.5%'
                  : isEqual(index, 2) && !dataToShow.children
                  ? '77.5%'
                  : `${92 - width}%`,
            }}
          >
            {dataToShow?.children &&
              Object.keys(dataToShow.children).map(child => {
                return (
                  <>
                    {renderCollapsedRows(
                      dataToShow.children[child],
                      setIndex,
                      false,
                      false,
                      `${
                        index === 0
                          ? isMemberView
                            ? 9 + width
                            : 6 + width
                          : 12 + width
                      }`,
                      child
                    )}
                    {renderExpendedSection(
                      dataToShow.children[child],
                      setIndex,
                      false,
                      false,
                      child
                    )}
                  </>
                );
              })}

            {isEmpty(dataToShow.children) ? (
              <div className="bar_graph_columns">
                {renderChargeData(
                  dataToShow,
                  keyId,
                  isEqual(index, 2) || isEqual(index, 1) ? 11 : `${8 + width}`
                )}
              </div>
            ) : null}
          </div>
        </div>
        {dataToShow?.children &&
          renderCollapsedRows(
            dataToShow,
            index,
            false,
            true,
            `${index === 0 ? 0 : isEqual(index, 1) ? 5 + width : 8 + width}`
          )}
      </div>
    );
  };

  const calCulateWidth = (value, sum, negativeTotal, isNegativeValue) => {
    let width =
      value < 0 ? negativeTotal * sum : (Math.abs(value) + negativeTotal) * sum;
    return isNegativeValue
      ? `${(width * 10).toFixed(2)}%`
      : `${width.toFixed(2)}%`;
  };

  const renderValue = val => {
    return formatNumber(val) !== 0 ? `${formatNumber(val)}%` : null;
  };

  const renderChart = (values, total, negativeTotal, name) => {
    return (
      !isEmptyOrNull(values) &&
      Object.keys(CONFIG.PROTOTYPE_BILLABILITY_LEGENDS_API_KEY).map(key => {
        const tooltipState = showTooltip[`${name}_${key}_ToolTip`];
        return !isEmptyOrNull(renderValue(values[key])) ? (
          <span
            className={`tooltip-with-icon businessToolTip desc`}
            onMouseEnter={() => {
              const newState = {};
              newState[`${name}_${key}_ToolTip`] = true;
              setShowToolTip({ ...newState });
            }}
            onMouseLeave={() => {
              const newState = {};
              newState[`${name}_${key}_ToolTip`] = false;
              setShowToolTip({ ...newState });
            }}
            style={{
              width: calCulateWidth(values[key], total, negativeTotal, true),
            }}
          >
            <div
              key={key}
              className={`${key} bar_graph
                `}
              style={{
                backgroundColor:
                  CONFIG.PROTOTYPE_BILLABILITY_LEGENDS_API_KEY[key],
              }}
            >
              {renderValue(values[key])}
            </div>
            <div id="tooltip" className={tooltipState ? 'on' : 'off'}>
              <div className="tooltip-inner chart_tooltip">{`${CONFIG.PROTOTYPE_BILLABILITY_LEGENDS[key]} : ${values[key]}%`}</div>
            </div>
          </span>
        ) : null;
      })
    );
  };

  const renderBarChartCell = (data, name, width, isTotal) => {
    let { total, negativeTotal, positiveTotal } = getBillabilityTotal(data);
    let totalPercentage = 100 / total;
    let positiveValues, negativeValues;
    for (let [key, value] of Object.entries(data)) {
      if (value < 0) negativeValues = { ...negativeValues, [key]: value };
      else positiveValues = { ...positiveValues, [key]: value };
    }
    return (
      <div
        className={`bar_chart bar_chart_cell ${isTotal ? 'grey' : ''}`}
        style={{
          width: `${isTotal && isMemberView ? 49 : 44 + 2 * parseInt(width)}%`,
        }}
      >
        <div className="bar_chart_left">
          {renderChart(negativeValues, totalPercentage, negativeTotal, name)}
        </div>
        <div className="bar_chart_right">
          {renderChart(positiveValues, totalPercentage, negativeTotal, name)}
        </div>
        <div className="totalPercentage">{`${numberWithCommas(
          positiveTotal - negativeTotal
        )}%`}</div>
      </div>
    );
  };

  const renderCollapsedRows = (
    data,
    index,
    isMainTotal,
    isTotal,
    width,
    key
  ) => {
    const dataToShow = data;
    const keyId = dataToShow?.geoRegionKey || dataToShow?.paKey;
    const name = data?.employeeName || key || data[0];
    return (
      <div
        key={`name_${keyId}`}
        className={`table_row  ${isTotal ? 'total' : 'collapsed_section'} ${
          isMainTotal ? 'blue' : 'grey'
        }`}
        id={`collapsed_${keyId}`}
      >
        <div
          className={`first_columns collapsed_blank_columns total_columns collapsed ${width} ${
            isMemberView ? 'member' : ''
          }`}
          style={{
            width: `${
              (isMemberView && isTotal ? 44 : isMemberView ? 41 : 35.5) - width
            }%`,
          }}
        >
          {dataToShow?.children && !isTotal && (
            <Icon
              name={`angle down`}
              onClick={() => addAndRemoveClass(`${keyId}`)}
            />
          )}
          <div>{isMainTotal ? 'Grand Total' : isTotal ? 'Total' : name}</div>
        </div>
        {renderChargeData(dataToShow, keyId, 0, index, isTotal)}
      </div>
    );
  };

  const renderChargeData = (data, key, width, index, isTotal) => {
    const widthTo = width ? width : isMemberView ? 8 : 7;
    if (!isEmptyOrNull(data)) {
      return (
        <>
          <div
            className="first_columns case_charges"
            style={{
              width: `${
                isEqual(index, 1) ? 7.5 : isEqual(index, 2) ? 8.5 : widthTo
              }%`,
            }}
          >
            {numberWithCommas(data?.caseChgs || 0)}
          </div>
          <div
            className="first_columns theo_charges"
            style={{
              width: `${
                isEqual(index, 1) ? 8 : isEqual(index, 2) ? 8.5 : widthTo
              }%`,
            }}
          >
            {numberWithCommas(data?.theoCapChgs || 0)}
          </div>
          <div
            className="first_columns theo_charges"
            style={{
              width: `${
                isEqual(index, 1) ? 7.75 : isEqual(index, 2) ? 8.5 : widthTo
              }%`,
            }}
          >
            {`${numberWithCommas(data?.caseBillability || 0)}%`}
          </div>
          {isMemberView && (
            <div
              className="first_columns theo_charges"
              style={{
                width: `${
                  isEqual(index, 1) ? 7.75 : isEqual(index, 2) ? 8.5 : widthTo
                }%`,
              }}
            >
              {`${numberWithCommas(data?.cdBillability || 0)}%`}
            </div>
          )}
          {renderBarChartCell(
            data,
            key,
            isEqual(index, 1) ? 1.75 : isEqual(index, 2) ? 4 : width,
            isTotal
          )}
        </>
      );
    }
  };

  return (
    <>
      {renderChartDataTable()}
      {renderCollapsedRows(apiData?.allBillabilityTotal, 0, true, true)}
    </>
  );
};

export default KTBillabilityPA;
