import React from 'react';
import { isEmptyOrNull } from 'utils';
import KTHomeLoader from './KTHomeLoader';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isLoading, numberWithCommas } from 'utils/common';
import KtDropdown from 'components/common/KtDropdown';
import CONFIG from 'config/configProps';

const KTTopClients = props => {
  const {
    isTopProject,
    showDropDown,
    className,
    isBusinessPage,
    heading,
    selectedQuestion,
  } = props;
  const topClientsData = props.data.data;
  const dropdownOptions = Object.keys(CONFIG.KT_BILLABILITY_QUESTIONS);

  const changeFilterHandler = selectedItem => {
    props.onQuestionChange(selectedItem);
  };

  return (
    <div
      className={`home_page_main_split_row_${
        className
          ? className
          : !isBusinessPage
          ? 'case_projects'
          : 'top_clients'
      } main_conatiner`}
    >
      <div className="heading">
        <div className="heading_text">
          {heading
            ? heading
            : isBusinessPage
            ? 'TOP CLIENTS'
            : 'CASE PROJECTS (+20 HRS)'}
        </div>
        {showDropDown && (
          <div className="heading_sortby">
            <KtDropdown
              dropdownOptionsList={dropdownOptions}
              selectedOptionName={selectedQuestion}
              changeEventHandler={changeFilterHandler}
            />
          </div>
        )}
      </div>
      <div className="chart__table">
        <div className="chart__table__header">
          <div className="topClient">
            {isTopProject ? 'Project Name' : 'Client'}
          </div>
          <div className="charges">
            {isBusinessPage ? 'Charges ($K)' : 'Project ID'}
          </div>

          <div className="topClienthours">Hours</div>
        </div>
        <div className="chart__table__body data_table_body">
          {isLoading(props.data) ? (
            <KTHomeLoader isLoading={1} section="Top Client" />
          ) : isEmptyOrNull(topClientsData.results) ? (
            <div className="static-text">No data to display</div>
          ) : (
            topClientsData.results.map((clientData, index) => {
              return (
                <div
                  className="data_table_body_row"
                  key={`${clientData.hours}_${index}`}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="tooltip-basic">
                        <Popover.Content>
                          {isTopProject
                            ? clientData?.projectName
                            : clientData?.clientName}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <div className="topClient">
                      {isTopProject
                        ? clientData?.projectName
                        : clientData?.clientName}
                    </div>
                  </OverlayTrigger>
                  <div className="charges">
                    {numberWithCommas(
                      isBusinessPage ? clientData.charges : clientData.projectId
                    )}
                  </div>
                  <div className="topClienthours">
                    {numberWithCommas(clientData.hours)}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default KTTopClients;
