import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Modal, Icon, Progress } from 'semantic-ui-react';

import Button from '../button/Button';
import NotificationMessage from './NotificationMessage';
import { getApiFailMessage, getFileUploadErrorMessage } from 'utils';
import { validateFileZise, formatFileSize } from 'utils/common';
import { updateToastNotificationDetails } from 'redux/actions';
import CONFIG from 'config/configProps';

import './UploadModal.scss';

const focusedStyle = {
  borderColor: '#0c2848',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function UploadModal({ open, onModalClose, handleFileUpload }) {
  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState({
    uploading: false,
    percentCompleted: 0,
  });
  const { uploading, percentCompleted } = uploadStatus;
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    accept: CONFIG.ALLOWED_FILE_TYPES_FOR_UPLOAD,
    validator: validateFileZise,
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {formatFileSize(file.size)}
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul style={{ listStyle: 'none', padding: '10px' }}>
        {errors.map((e, index) => (
          <li key={`${e.code}-${index}`}>
            {file.path} - {formatFileSize(file.size)} --&nbsp;
            {getFileUploadErrorMessage(e)}
          </li>
        ))}
      </ul>
    </li>
  ));

  const closeModal = () => onModalClose && onModalClose();

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    setUploadStatus(true);
    handleFileUpload(formData, setUploadStatus)
      .then(response => {
        showSuccessNotification(response);
        closeModal();
      })
      .catch(error => {
        const { status, data } = error;
        dispatch(
          updateToastNotificationDetails(
            getApiFailMessage(status, data?.detailErrorMessage)
          )
        );
      })
      .finally(() => {
        setUploadStatus({
          uploading: false,
          percentCompleted: 0,
        });
      });
  };

  const formatMessage = response => {
    return <NotificationMessage {...(response || {})} />;
  };

  const showSuccessNotification = response => {
    const notificationObj = {
      showToastNotification: true,
      notificationHeader: 'Success',
      notificationBody: formatMessage(response),
      notificationClassName: 'custom-notification',
    };

    dispatch(updateToastNotificationDetails(notificationObj));
  };

  return (
    <>
      {open && (
        <Modal
          className="upload-modal"
          onClose={closeModal}
          open={open}
          closeOnDimmerClick={false}
        >
          <Modal.Header className="upload-modal__header">
            <span className="upload-modal__header__upload-text">
              Upload File ...
            </span>
          </Modal.Header>
          {uploading && (
            <Progress
              percent={percentCompleted}
              color="green"
              size="tiny"
              attached="bottom"
              active
            />
          )}
          <Modal.Content>
            <div
              {...getRootProps({ style, className: 'upload-modal__dropbox' })}
            >
              <input {...getInputProps()} />
              <div>
                <Icon name="file excel outline" size="huge" />
              </div>
              {isDragActive ? (
                <div className="upload-modal__dropbox__drop-text">
                  Drop the files here
                </div>
              ) : (
                <>
                  <div className="upload-modal__dropbox__drop-text">
                    Drag & Drop file into the area to upload ... (Max file size{' '}
                    {formatFileSize(CONFIG.MAX_FILE_SIZE_IN_BYTES, 0)})
                  </div>
                  <div className="upload-modal__dropbox__or-text">OR</div>
                  <Button
                    className="upload-modal__dropbox__upload-btn"
                    icon={<Icon name="upload" />}
                    content="Browse Files"
                  />
                </>
              )}
            </div>
            <aside>
              {fileRejectionItems.length === 1 ? (
                <ul className="upload-modal__file-reject">
                  {fileRejectionItems}
                </ul>
              ) : (
                files.length === 1 && (
                  <ul className="upload-modal__file-accept">{files}</ul>
                )
              )}
            </aside>
          </Modal.Content>
          <Modal.Actions className="footer actions upload-modal__footer">
            <Button
              onClick={closeModal}
              className="upload-modal__footer__cancel-btn"
              icon={<Icon name="cancel" />}
              content="Cancel"
            />
            <Button
              onClick={uploadFile}
              className="upload-modal__footer__submit-btn"
              disabled={files.length <= 0 || uploading}
              content={uploading ? 'Uploading...' : 'Submit'}
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}

export default UploadModal;
