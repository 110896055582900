import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { cloneDeep } from 'utils/common';
import store from 'redux/store';
import KTKnDeck from 'components/KTTableauStackWrapper/KTKnDeck';
import KTMostDownloads from 'components/KTTableauStackWrapper/KTMostDownloads';
import { fetchPost } from 'utils/KtCommonServices';
import CONFIG_KTS from 'config/configProps';
import { updateToastNotificationDetails } from 'redux/actions';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import {
  getApiFailMessage,
  getIconInformation,
  getSelectedUserId,
  shouldGetTableauStackApiData,
} from 'utils';
class ContentTableau extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
    };
  }

  componentDidMount() {
    if (this.props.tableauParamString) {
      this.getApiData();
    }
  }
  /**
   *
   * React Life cycle method
   */
  componentDidUpdate(prevProps) {
    if (shouldGetTableauStackApiData(this.props, prevProps)) {
      this.getApiData();
    }
  }

  getApiData = () => {
    let apiIdentifier = API_CALL_IDENTIFIERS.FETCH_CONTENT_IP_DATA;
    let url = CONFIG_KTS.API_URLS.ANNOTATED_DASHBOARD;
    const baseUrl = CONFIG_KTS.KT_CONTENT_API_URL;
    let {
      apiRequestData,
      defaultMemberList,
      selectedTeamMembers,
      isAllSelected,
      isManager,
    } = this.props;
    const dataToPost = cloneDeep(apiRequestData);

    dataToPost['selectedUserId'] = getSelectedUserId({
      selectedTeamMembers,
      defaultMemberList,
      isAllSelected,
      isManager,
    });

    store.dispatch(beginAPICall(apiIdentifier));
    fetchPost(url, dataToPost, baseUrl)
      .then(result => {
        this.setState({ apiData: result.data });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      })
      .finally(() => store.dispatch(endAPICall(apiIdentifier)));
  };
  render() {
    const { apiData } = this.state;
    let {
      apiCallStatus,
      isManager,
      employeeInitialData,
      iconInformation,
      loggedInUserHighestRole,
    } = this.props;
    let isLoading = apiCallStatus.fetchContentIpData;
    return (
      Object.keys(employeeInitialData || {}).length >= 1 && (
        <div className="tableau-card-container">
          {apiData && (
            <KTKnDeck
              isManager={isManager}
              className={
                isManager
                  ? 'kt-kn-deck manager-kn-deck'
                  : 'kt-kn-deck member-kn-deck'
              }
              category="KN Decks"
              apiData={apiData}
              isLoading={isLoading}
              InfoText={getIconInformation(
                'KN Decks',
                iconInformation,
                loggedInUserHighestRole
              )}
            />
          )}
          {apiData && (
            <KTKnDeck
              isManager={isManager}
              className={
                isManager
                  ? 'kt-other-deck manager-kn-deck'
                  : 'kt-other-deck member-kn-deck'
              }
              category="Other Content"
              apiData={apiData}
              isLoading={isLoading}
              InfoText={getIconInformation(
                'Other Content',
                iconInformation,
                loggedInUserHighestRole
              )}
            />
          )}
          <KTMostDownloads
            className={
              isManager ? 'manager-most-download' : 'member-most-download'
            }
            InfoText={getIconInformation(
              'Top 10 - Most Downloaded',
              iconInformation,
              loggedInUserHighestRole
            )}
          />
        </div>
      )
    );
  }
}
const mapStateToProps = function (reduxStore) {
  return {
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    isManager: reduxStore.userRoleData.isManager,
    apiCallStatus: reduxStore.apiCallsInProgress,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    iconInformation: reduxStore.ktScorecard.iconInformation,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    defaultMemberList: reduxStore.headerFilterData.defaultMemberList,
    isAllSelected: reduxStore.headerFilterData.isAllSelected,
    tableauStringData: reduxStore.tableauStackData.tableauStringData,
  };
};
export default connect(mapStateToProps)(withRouter(ContentTableau));
