import * as types from '../actions/actionTypes';
import CONFIG from 'config/configProps';
import { fetchGet } from 'utils/KtCommonServices';

export function updateCalenderDropdownList(data) {
  return {
    type: types.SET_CALENDER_DROPDOWN_VALUE,
    data,
  };
}

export function updateInitialDate(data) {
  return {
    type: types.INITIAL_DATE,
    data,
  };
}

export function updateSelectedSelectionDetails(data) {
  return {
    type: types.UPDATE_SELECTED_SECTION_DETAILS,
    data,
  };
}

export function updateSelectedSelectionDetailValue(data) {
  return {
    type: types.UPDATE_SELECTED_SECTION_DETAIL_VALUE,
    data,
  };
}

export function updateSelectedSectorDetails(data) {
  return {
    type: types.UPDATE_SECTOR_DETAILS,
    data,
  };
}

export function updateSelectedSubSectorDetails(data) {
  return {
    type: types.UPDATE_SUB_SECTOR_DETAILS,
    data,
  };
}

export function updateManagedBy(managedBy) {
  return { type: types.UPDATE_MANAGED_BY, managedBy };
}

export function updatePath(path) {
  return { type: types.UPDATE_PATH, path };
}

export function updateSaPaList(paList) {
  return {
    type: types.UPDATE_SA_PA_LIST,
    paList,
  };
}

export function updatePaPaList(paList) {
  return {
    type: types.UPDATE_PA_PA_LIST,
    paList,
  };
}

export function setAllPaList(paList) {
  return {
    type: types.SET_ALL_PA_LIST,
    paList,
  };
}

export function updateGeoMarketList(geoMarketList) {
  return { type: types.UPDATE_GEO_MARKET_LIST, geoMarketList };
}

export function updateSelectedTeamMembers(selectedTeamMembers) {
  return { type: types.UPDATE_SELECTED_TEAM_MEMBERS, selectedTeamMembers };
}

export function updateTeamMemberList(memberList) {
  return { type: types.UPDATE_TEAM_MEMBERS_LIST, memberList };
}

export function updateAllTeamMemberSelected(isAllSelected) {
  return { type: types.ALL_TEAM_MEMBER_SELECTED, isAllSelected };
}

export function updateDefaultMemberList(defaultMemberList) {
  return {
    type: types.UPDATE_DEFAULT_MEMBER_LIST,
    defaultMemberList,
  };
}

export function updateFilters(filters) {
  return { type: types.UPDATE_FILTERS, filters };
}

export function updateCollectionApplied(value) {
  return { type: types.IS_COLLECTION_APPLIED, value };
}

export function passGeoDetails(passGeo) {
  return { type: types.PASS_GEO_DETAILS, passGeo };
}

export function setPaList(paList, geoRegion) {
  return {
    type: types.SET_PA_LIST,
    paList,
    geoRegion,
  };
}

export const getPaList = selectedGeo => dispatch => {
  const PAUrl = CONFIG.API_URLS.EMP_PA;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = selectedGeo ? `${PAUrl}?geoRegion=${selectedGeo}` : PAUrl;

  return fetchGet(endpoint, baseUrl).then(response => {
    response?.length > 0 && dispatch(setPaList(response, selectedGeo || 'all'));
  });
};

export const setSectorList = (sectorList, selectedId) => {
  return {
    type: types.SET_SECTOR_LIST,
    sectorList,
    selectedId,
  };
};

export const getSectorList = (selectedPaId, selectedGeo) => dispatch => {
  const url = `${CONFIG.API_URLS.EMP_SECTOR}?id=${selectedPaId}`;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = selectedGeo ? `${url}&geoRegion=${selectedGeo}` : url;
  const selectedId = selectedPaId + (selectedGeo ? `_${selectedGeo}` : '');

  return fetchGet(endpoint, baseUrl).then(response => {
    if (!response?.length) {
      response = [{ topicSectorId: -1, sector: 'All' }];
    }
    dispatch(setSectorList(response, selectedId));
  });
};

export const setSubSectorList = (subSectorList, selectedId) => {
  return {
    type: types.SET_SUB_SECTOR_LIST,
    subSectorList,
    selectedId,
  };
};

export const getSubSectorList = (selectedSectorId, selectedGeo) => dispatch => {
  const defaultOption = [{ subSectorId: -1, subSector: 'All' }];
  const url = `${CONFIG.API_URLS.EMP_SUB_SECTOR}?id=${selectedSectorId}`;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = selectedGeo ? `${url}&geoRegion=${selectedGeo}` : url;
  const selectedId = selectedSectorId + (selectedGeo ? `_${selectedGeo}` : '');
  if (selectedSectorId === -1) {
    dispatch(setSubSectorList(defaultOption, selectedId));
  }

  return fetchGet(endpoint, baseUrl).then(response => {
    if (!response?.length) {
      response = defaultOption;
    }
    dispatch(setSubSectorList(response, selectedId));
  });
};
