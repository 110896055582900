import React from 'react';

class Tabs extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: this.props.activeTabIndex ? this.props.activeTabIndex : 0,
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tabIndex) {
    const { activeTabIndex } = this.state;
    this.setState({
      activeTabIndex: tabIndex === activeTabIndex ? activeTabIndex : tabIndex,
    });
  }

  // Encapsulate <Tabs/> Header
  renderTabHeader() {
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex,
      });
    });
  }

  // Render current active tab content
  renderActiveTabContent() {
    const { children } = this.props;

    const { activeTabIndex } = this.state;
    // for more than 1 tab
    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
    // for only 1 tab
    else if (children) {
      return children.props.children;
    }
  }

  render() {
    const { className } = this.props;
    return (
      <div className={`${className ? className : ''} tabs`}>
        <ul className="tabs-nav navbar-left">{this.renderTabHeader()}</ul>
        <div className="tabs-active-content">
          {this.renderActiveTabContent()}
        </div>
      </div>
    );
  }
}

export default Tabs;
