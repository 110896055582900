/* eslint-disable func-names */
import { UPDATE_ABODE_ANALYTICS_DETAILS } from './actionTypes';

function updateAdobeDetails(adobeDetails) {
  return {
    type: UPDATE_ABODE_ANALYTICS_DETAILS,
    adobeDetails,
  };
}

export const adobeAnalyticsActions = {
  updateAdobeDetails,
};
