import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect, batch } from 'react-redux';
import store from 'redux/store';
import MemberSearch from '../../common/MemberSearch/MemberSearch';
import { getApiFailMessage, dropdownFormat, sortPaList } from 'utils';
import CONFIG_KTS from 'config/configProps';
import { fetchGet } from 'utils/KtCommonServices';
import {
  updateDefaultMemberList,
  updateSelectedSelectionDetails,
  updateSelectedTeamMembers,
  updateTeamMemberList,
} from 'redux/actions/headerFilterAction';
import {
  updateToastNotificationDetails,
  updateImpersonatorMemberList,
  updatedSelectedMemberByImpersonator,
  updateGeoRegionList,
} from 'redux/actions';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import AdminFilter from 'components/common/AdminFilter';
import Radio from 'components/common/CommonHtml/radio';
import './ImpersonatorList.scss';
import { isEmpty, isEqual, showSearch } from 'utils/common';

class ImpersonatorList extends PureComponent {
  constructor(props) {
    super(props);
    const {
      impersonatorList,
      memberDetails,
      fetchImpersonatorList,
      selectedSectionDetails,
      employeeInitialData,
      geoRegionList,
    } = this.props;
    const employeeDetails = isEmpty(selectedSectionDetails)
      ? employeeInitialData
      : selectedSectionDetails;
    const prevSelectedPaId = selectedSectionDetails?.prevSelectedPaId;
    const selectedPa =
      employeeDetails?.paId && !isEqual(employeeDetails?.paId, -1)
        ? employeeDetails.paId
        : '';

    this.state = {
      showUsers: true,
      impersonatorList: impersonatorList,
      noData: false,
      selectedUser: !isEmpty(memberDetails) ? memberDetails.employeeIdHRO : [],
      filteredImpersonatorList: impersonatorList,
      selectedEmployeeName: memberDetails.employeeName,
      showPa: false,
      selectedPa,
      selectedPaName:
        CONFIG_KTS.PA_NAME_ALIAS_MAP[employeeDetails.paTeam] ||
        (isEqual(employeeDetails.paTeam, 'All') ? '' : employeeDetails.paTeam),
      paList: [],
      showGeo: false,
      geoRegionList: geoRegionList,
      selectedGeo: isEqual(employeeDetails?.geoRegion, 'No Geo')
        ? ''
        : employeeDetails?.geoRegion || '',
      isLoading: true,
    };
    if (
      isEmpty(impersonatorList) ||
      fetchImpersonatorList ||
      (prevSelectedPaId && !isEqual(selectedPa, prevSelectedPaId))
    ) {
      if (!isEmpty(selectedSectionDetails)) {
        store.dispatch(
          updateSelectedSelectionDetails({
            ...selectedSectionDetails,
            prevSelectedPaId: selectedPa,
          })
        );
      }
      this.getImpersonatorList();
    } else {
      this.state.isLoading = false;
    }
    this.paContainer = React.createRef();
    this.geoContainer = React.createRef();
    document.addEventListener('click', this.handleClickOutsideForPa);
    document.addEventListener('click', this.handleClickOutsideForGeo);
  }

  componentDidMount() {
    this.getGeoRegionList();
    this.getPaList();
  }

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideForPa);
    document.removeEventListener('click', this.handleClickOutsideForGeo);
  }

  getGeoRegionList = () => {
    const { geoRegionList } = this.props;
    if (!isEmpty(geoRegionList)) {
      this.setState({ geoRegionList });
    } else {
      let geoList = [];
      const url = CONFIG_KTS.API_URLS.GET_GEO_REGION;
      const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
      fetchGet(url, baseUrl).then(data => {
        data.forEach(el => {
          geoList.push(dropdownFormat('' + el, el));
        });
        store.dispatch(updateGeoRegionList(geoList));
        this.setState({ geoRegionList: geoList });
      });
    }
  };

  /**
   * This method will returns the PA list
   */
  getPaList = () => {
    const { ktSaPaList } = this.props;
    let paList = [];
    if (!isEmpty(ktSaPaList)) {
      ktSaPaList.forEach(el => {
        let paTeam =
          CONFIG_KTS.PA_NAME_ALIAS_MAP[el.practiceArea] || el.practiceArea;

        if (!isEqual(el.paId, -1))
          paList.push(dropdownFormat('' + el.paId, paTeam));
      });
      this.setState({ paList }, () => this.setDefaultSelectedPa());
    } else {
      let PAUrl = CONFIG_KTS.API_URLS.EMP_PA;
      const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
      fetchGet(PAUrl, baseUrl).then(data => {
        data.forEach(el => {
          if (el.paId && el.practiceArea) {
            const paTeam =
              CONFIG_KTS.PA_NAME_ALIAS_MAP[el.practiceArea] || el.practiceArea;
            paList.push(dropdownFormat('' + el.paId, paTeam));
          }
        });
        paList = sortPaList(paList);
        this.setState({ paList }, () => this.setDefaultSelectedPa());
      });
    }
  };

  setDefaultSelectedPa = () => {
    const { selectedPa, selectedGeo, paList } = this.state;
    const selectedPaOrGeo = selectedPa || selectedGeo;
    if (!isEmpty(paList) && !selectedPaOrGeo) {
      this.setState(
        {
          selectedPa: paList[0].value,
          selectedPaName: paList[0].label,
        },
        this.getImpersonatorList
      );
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForPa = event => {
    if (
      this.paContainer.current &&
      !this.paContainer.current.contains(event.target)
    ) {
      this.setState({ showPa: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForGeo = event => {
    if (
      this.geoContainer.current &&
      !this.geoContainer.current.contains(event.target)
    ) {
      this.setState({ showGeo: false });
    }
  };

  getImpersonatorList = () => {
    const { selectedPa, selectedPaName, selectedGeo } = this.state;
    if (!(selectedPa || selectedGeo)) {
      return;
    }

    this.setState({ isLoading: true, fetchingUserList: true });
    const paNameToPass =
      CONFIG_KTS.PA_NAME_ALIAS_MAP[selectedPaName] || selectedPaName;
    let userListUrl = CONFIG_KTS.API_URLS.IMPERSONATOR_USER_LIST;
    const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
    if (selectedPa && selectedGeo) {
      userListUrl = `${userListUrl}paId=${selectedPa}&geoRegion=${selectedGeo}`;
    } else if (selectedGeo) {
      userListUrl = `${userListUrl}geoRegion=${selectedGeo}`;
    } else {
      userListUrl = `${userListUrl}paId=${selectedPa}`;
    }
    fetchGet(userListUrl, baseUrl)
      .then(result => {
        const impersonatorList = result;
        this.setState({
          impersonatorList,
          noData: isEmpty(impersonatorList),
          filteredImpersonatorList: impersonatorList,
          selectedPaName: paNameToPass,
          isLoading: false,
        });
        store.dispatch(updateImpersonatorMemberList(impersonatorList));
      })
      .catch(() => {
        this.setState({ isLoading: false });
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
  };

  handleRadioClick = (employeeName, employeeId) => {
    this.handleSearchClick(employeeId, employeeName);
  };

  handleSearchClick = (employeeId, employeeName) => {
    this.setState({
      selectedUser: employeeId,
      selectedEmployeeName: employeeName,
    });
  };

  displayUserList = impersonatorList => {
    const { selectedUser } = this.state;
    return impersonatorList.map(el => {
      return (
        <div key={el.employeeIdHRO} className="user-display">
          <Radio
            checked={isEqual(selectedUser, el.employeeIdHRO)}
            name={el.employeeName}
            value={el.employeeIdHRO}
            handleChange={this.handleRadioClick}
            bgColor="#c1cad5"
            checkedColor="#4f5b6a"
          />
          <div className="m01 user-name">{el.employeeName}</div>
        </div>
      );
    });
  };

  handleSearch = () => {
    this.setState({ filteredImpersonatorList: [] });
  };

  clearFilter = stateField => {
    const newState = { ...this.state };
    newState[stateField] = '';
    newState[`${stateField}Name`] = '';
    this.setState({ ...newState, selectedEmployeeName: '' }, () => {
      if (!this.state.selectedGeo && !this.state.selectedPa) {
        this.setState({
          impersonatorList: {},
          noData: true,
          filteredImpersonatorList: [],
        });
        store.dispatch(updateImpersonatorMemberList({}));
      } else {
        this.getImpersonatorList();
      }
    });
  };

  renderFilter = ({
    type,
    showFilter,
    dataList,
    selectedList,
    onChange,
    stateField,
    elementRef,
    className,
    onClick,
    selectedId,
    clearFilter,
  }) => {
    return (
      <div
        className={`${
          showFilter ? 'blueBg' : ''
        } kt-admin-filter-dropdown ${className}`}
        ref={elementRef}
      >
        <p onClick={onClick} className="select-filter padding05">
          <span className="selection-text-2">
            {isEmpty(selectedList) ? type : selectedList}
          </span>
          <img
            src="/img/kt-admin-arrow.svg"
            className={`kt-admin-arrow ${
              showFilter ? 'transform-90' : 'transform90'
            }`}
            alt=""
          />
        </p>
        {showFilter && !isEmpty(dataList) && (
          <AdminFilter
            {...{
              dataList: dataList,
              onChange: onChange,
              selectedName: selectedList,
              selectedId: selectedId,
              stateField: stateField,
              showAll: false,
              singleSelect: true,
              isClearRequired: true,
              clearFilter: clearFilter,
            }}
          />
        )}
      </div>
    );
  };

  renderMemberSearch = () => {
    const { impersonatorList } = this.state;

    return isEmpty(impersonatorList) || !showSearch(impersonatorList) ? null : (
      <MemberSearch
        onClick={this.handleSearchClick}
        searchList={impersonatorList}
        onSearch={this.handleSearch}
        placeholder="Search Team Members"
      />
    );
  };

  renderPlaceholder = () => {
    const { isLoading } = this.state;

    return isLoading
      ? [...Array(5)].map((e, i) => (
          <LabelWithSkeleton
            isLoading={isLoading}
            showLine
            size="medium"
            key={i}
          />
        ))
      : null;
  };

  renderNodata = () => {
    const { isLoading, noData } = this.state;

    return !isLoading && noData ? (
      <div className="no-data">No Users Present</div>
    ) : null;
  };

  renderUserList = () => {
    const {
      isLoading,
      noData,
      filteredImpersonatorList,
      impersonatorList,
      selectedUser,
    } = this.state;

    if (isLoading || noData) {
      return null;
    }

    let userList = !isEmpty(filteredImpersonatorList)
      ? filteredImpersonatorList
      : impersonatorList;
    userList = userList.reduce((acc, item) => {
      if (selectedUser.indexOf(item.employeeIdHRO) >= 0) {
        return [item, ...acc];
      } else {
        return [...acc, item];
      }
    }, []);

    return userList ? (
      <div className="show-user-list">
        <div className="user-list">{this.displayUserList(userList)}</div>
      </div>
    ) : null;
  };

  showUsers = () => {
    const {
      noData,
      selectedUser,
      showPa,
      selectedPaName,
      selectedPa,
      paList,
      showGeo,
      selectedGeo,
      geoRegionList,
    } = this.state;

    return (
      <div>
        <div className="admin-level-filers-container">
          {this.renderFilter({
            type: 'PA',
            elementRef: this.paContainer,
            onClick: this.showPa.bind(this),
            showFilter: showPa,
            dataList: paList,
            onChange: this.handleFilterChange,
            selectedList: selectedPaName,
            selectedId: selectedPa,
            stateField: 'selectedPa',
            clearFilter: this.clearFilter,
            className: 'm0',
          })}
          {this.renderFilter({
            type: 'GEO',
            elementRef: this.geoContainer,
            onClick: this.showGeo.bind(this),
            showFilter: showGeo,
            dataList: geoRegionList,
            onChange: this.handleFilterChange,
            selectedList: selectedGeo,
            selectedId: selectedGeo,
            stateField: 'selectedGeo',
            clearFilter: this.clearFilter,
            className: 'mL05',
          })}
        </div>
        {this.renderMemberSearch()}
        {this.renderPlaceholder()}
        {this.renderNodata()}
        {this.renderUserList()}
        <div className="button-footer">
          <div className="clear" onClick={this.clearMemberFilter}>
            Clear
          </div>
          <div className="apply-button">
            <div
              className={`apply ${
                noData || isEmpty(selectedUser) ? 'disabled' : ''
              }`}
              onClick={this.applyFilter}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    );
  };

  showPa() {
    this.setState({ showPa: !this.state.showPa });
  }

  showGeo() {
    this.setState({ showGeo: !this.state.showGeo });
  }

  handleFilterChange = (stateField, newValue, newName) => {
    const newState = { ...this.state };
    newState[stateField] = newValue;
    newState[`${stateField}Name`] = newName;
    this.setState({ ...newState, showPa: false, showGeo: false }, () => {
      this.getImpersonatorList();
    });
  };

  applyFilter = () => {
    const { selectedEmployeeName, selectedUser } = this.state;
    const memberDetails = {
      employeeName: selectedEmployeeName,
      employeeIdHRO: selectedUser,
    };
    batch(() => {
      store.dispatch(updatedSelectedMemberByImpersonator(memberDetails));
      store.dispatch(updateDefaultMemberList([selectedUser]));
      store.dispatch(updateSelectedTeamMembers([]));
      store.dispatch(updateTeamMemberList(memberDetails));
    });
    this.props.applyMemberView();
  };

  clearMemberFilter = () => {
    this.setState({ selectedUser: '', selectedEmployeeName: '' });
    store.dispatch(updatedSelectedMemberByImpersonator({}));
    this.props.clearMemberFilter();
  };

  render() {
    const { showUsers } = this.state;
    return (
      <div className="kt-drop-down-with-search-container team-member-container">
        {showUsers ? this.showUsers() : null}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    impersonatorList: reduxStore.ktScorecard.impersonatorList,
    selectedSectionDetails: reduxStore.headerFilterData.selectedSectionDetails,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    ktSaPaList: reduxStore.headerFilterData.ktSaPaList,
    geoRegionList: reduxStore.ktScorecard.geoRegionList,
    fetchImpersonatorList: reduxStore.ktScorecard.fetchImpersonatorList,
  };
};
export default connect(mapStateToProps)(withRouter(ImpersonatorList));
