import React, { Fragment } from 'react';
import { times } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
const KtAdminListPlaceholder = () => {
  return (
    <Fragment>
      {times(4, i => (
        <div className="admin-list-card-container container-padding" key={i}>
          <div className="profile-img-container">
            <LabelWithSkeleton isLoading isAdminImage />
          </div>
          <div className="details-container">
            <LabelWithSkeleton isLoading size="large" showLine />
            <LabelWithSkeleton isLoading size="large" showLine />
            <LabelWithSkeleton isLoading size="small" showLine />
            <br />
            <br />
            <br />
            <br />
            <LabelWithSkeleton isLoading size="large" showLine />
          </div>
        </div>
      ))}
    </Fragment>
  );
};
export default KtAdminListPlaceholder;
