/* eslint-disable no-unused-vars */
import axios from 'axios';
import CONFIG from 'config/configProps';
import { isEqual } from 'utils/common';

/**
 * This method will return the request header object
 * @param {String} externalApplication
 */
function requestHeaders(externalApplication) {
  let headersVal = {
    'Content-Type': 'application/json',
  };
  if (isEqual(externalApplication, CONFIG.EXTERNAL_APPLICATION.PEOPLE_API)) {
    headersVal['x-api-key'] = CONFIG.PEOPLE_X_API_KEY;
    headersVal['psid'] = '';
    headersVal['accept'] = '*/*';
  } else {
    headersVal['x-api-key'] = CONFIG.X_API_KEY;
  }
  return headersVal;
}

/**
 * Get method
 * @param {String} urlVal
 * @param {String} exactBaseUrl
 * @param {String} externalApplication
 */
export function fetchGet(urlVal, exactBaseUrl, externalApplication = '') {
  let baseURL = exactBaseUrl ? exactBaseUrl : CONFIG.KT_SCORECARD_API_URL;
  let config = {
    baseURL: baseURL,
    headers: requestHeaders(externalApplication),
    // timeout: 40000,
  };

  return axios
    .get(urlVal, config)
    .then(response => {
      return response.data;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Post method
 * @param {String} urlVal
 * @param {Object} postData
 * @param {String} baseUrl
 * @param {String} externalApplication
 * @param {Object} extraConfig
 */
export function fetchPost(
  url,
  postData,
  baseUrl,
  externalApplication,
  extraConfig = {}
) {
  let baseURL = baseUrl ? baseUrl : CONFIG.KT_SCORECARD_API_URL;
  url = `${baseURL}${url}`;
  const headers = requestHeaders(externalApplication);
  const config = {
    ...extraConfig,
    headers: {
      ...headers,
      ...(extraConfig?.headers || {}),
    },
    // timeout: 40000,
  };
  return axios
    .post(url, postData, config)
    .then(response => {
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Delete method
 * @param {String} urlVal
 * @param {*}      id
 * @param {String} baseUrl
 */
export function fetchDelete(url, id = '', baseUrl = '') {
  let baseURL = baseUrl ? baseUrl : CONFIG.KT_SCORECARD_API_URL;
  let newIdValue = JSON.stringify({
    id: id.toString(),
  });

  let config = {
    baseURL: baseURL,
    headers: requestHeaders(),
    data: newIdValue,
    // timeout: 40000,
  };

  return axios
    .delete(url, config)
    .then(response => {
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Put method
 * @param {String} urlVal
 * @param {Object} postData
 * @param {String} exactBaseUrl
 * @param {String} externalApplication
 */
export function fetchPut(
  url,
  postData,
  exactBaseUrl,
  externalApplication = ''
) {
  let baseURL = exactBaseUrl ? exactBaseUrl : CONFIG.KT_SCORECARD_API_URL;
  let config = {
    baseURL: baseURL,
    headers: requestHeaders(externalApplication),
    // timeout: 40000,
  };

  return axios
    .put(url, postData, config)
    .then(response => {
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Post method
 * @param {String} urlVal
 * @param {Object} postData
 */
export function fetchPostFormData(url, postData) {
  let baseURL = CONFIG.KT_SCORECARD_API_URL;
  let header = requestHeaders();
  delete header['Content-Type'];

  let params = new FormData();
  Object.keys(postData).forEach(el => {
    params.append(el, postData[el]);
  });

  url = `${baseURL}${url}`;
  return axios
    .post(url, params, {
      headers: header,
    })
    .then(response => {
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Put method
 * @param {String} urlVal
 * @param {Object} postData
 */
export function fetchPutFormData(url, postData) {
  let baseURL = CONFIG.KT_SCORECARD_API_URL;
  let header = requestHeaders();
  delete header['Content-Type'];
  let params = new FormData();
  Object.keys(postData).forEach(el => {
    params.append(el, postData[el]);
  });

  url = `${baseURL}${url}`;

  return axios
    .put(url, params, {
      headers: header,
    })
    .then(response => {
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/**
 * Delete method
 * @param {String} urlVal
 * @param {String} exactBaseUrl
 */
export function fetchDeleteWithId(url, exactBaseUrl) {
  let baseURL = exactBaseUrl ? exactBaseUrl : CONFIG.KT_SCORECARD_API_URL;
  let URL = `${baseURL}${url}`;

  let config = {
    headers: requestHeaders(),
    // timeout: 40000,
  };

  return axios
    .delete(URL, config)
    .then(response => {
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
