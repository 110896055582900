import React, { PureComponent, Fragment } from 'react';
import { TitleComponent } from './Title';

const withTitle = ({ component: Component, title }) => {
  return class Title extends PureComponent {
    render() {
      return (
        <Fragment>
          <TitleComponent title={title} />
          <Component {...this.props} />
        </Fragment>
      );
    }
  };
};
export default withTitle;
