import * as React from 'react';
export default ({ labelTxt, checked, handleChange, partialChecked }) => {
  return (
    <label className="reactCustomCheckbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={event => {
          handleChange(event);
          event.stopPropagation();
        }}
      />
      <label>{labelTxt}</label>
      <span
        className={`checkmark ${
          !checked && partialChecked ? 'partialChecked' : ''
        }`}
      >
        {partialChecked && <div className="partialCheckContainer" />}
      </span>
    </label>
  );
};
