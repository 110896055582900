import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import KtEditInfo from 'components/KtEditInfo';
import './index.scss';

const ToolTipShowEdit = ({
  popupClassName,
  showPopup,
  handlePopupClose,
  popupSelectedText,
  popupHeading,
  textAreaValue,
  handleTextAreaChange,
  textAreaPlaceholder,
  showEditIcon,
  popupBody,
  showTextArea,
  hideEditInfo,
  popupBodyClassName,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const enableEditMode = () => setIsEditMode(true);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vCenter"
      centered
      show={showPopup}
      onHide={handlePopupClose}
      dialogClassName={`tooltip-popup ${popupClassName}`}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vCenter"
          className="popup-heading"
        >
          {isEditMode ? popupHeading : popupSelectedText}
        </Modal.Title>
        {showEditIcon && (
          <div className="edit-icon-container">
            <img
              src="/img/kt-tile-edit.svg"
              className="edit-icon"
              alt="edit-icon"
              onClick={enableEditMode}
            />
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        {hideEditInfo ? null : (
          <KtEditInfo
            heading={popupSelectedText}
            isEditModeOn={isEditMode}
            onHide={handlePopupClose}
          />
        )}
        <div className={popupBodyClassName}>{popupBody}</div>
        {showTextArea && (
          <div>
            <div>{textAreaValue}</div>
            <textarea
              className="textarea"
              maxLength="170"
              onChange={handleTextAreaChange}
              placeholder={textAreaPlaceholder}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ToolTipShowEdit;
