import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import store from 'redux/store';
import { fetchPost } from 'utils/KtCommonServices';
import {
  getApiFailMessage,
  getPaName,
  shouldGetTableauStackApiData,
  selectUserIdAsTeamMembers,
  selectUserIdAsDefaultMember,
} from 'utils';
import CONFIG_KTS from 'config/configProps';
import {
  updateToastNotificationDetails,
  showTooltipPopup,
} from 'redux/actions';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import CaseBillabilityLoader from './KTCaseBillabilityLoader';
import Icon from 'components/common/Icons';
import { isEqual, isEmpty, isArray, isObject, cloneDeep } from 'utils/common';
import { Grid } from 'semantic-ui-react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

class KTCaseBillability extends Component {
  constructor(props) {
    super(props);
    const { section, loggedInUserHighestRole } = this.props;
    this.state = {
      apiData: undefined,
      lastUpdated: '',
      isCF: isEqual(section, CONFIG_KTS.SECTION[1]),
      isGeoManager: isEqual(loggedInUserHighestRole, 'Geo Manager'),
      isGeoLead: isEqual(loggedInUserHighestRole, 'Geo Lead'),
      showMemberView: false,
    };
  }

  componentDidMount() {
    const { tableauParamString } = this.props;
    if (tableauParamString) {
      this.getApiData();
    }
  }

  /**
   *
   * React Life cycle method
   */
  componentDidUpdate(prevProps) {
    if (shouldGetTableauStackApiData(this.props, prevProps)) {
      this.getApiData();
    }
  }

  getApiData = () => {
    const {
      isManager,
      isAllSelected,
      selectedTeamMembers,
      loggedInUserHighestRole,
      defaultMemberList,
    } = this.props;
    this.setState({ apiData: undefined, lastUpdated: '' });
    let apiData = [];
    const apiRequestData = cloneDeep(this.props.apiRequestData);
    const isGeoManagerUser =
      !isEmpty(apiRequestData.geoRegion) && isEmpty(apiRequestData.userId);

    if (
      isGeoManagerUser &&
      selectUserIdAsDefaultMember(isAllSelected, selectedTeamMembers)
    ) {
      apiRequestData['selectedUserId'] = defaultMemberList;
    } else if (
      selectUserIdAsTeamMembers(isAllSelected, isManager, selectedTeamMembers)
    ) {
      apiRequestData['selectedUserId'] = selectedTeamMembers;
    }

    let apiIdentifier,
      url = '';
    apiIdentifier = API_CALL_IDENTIFIERS.FETCH_CASE_BILLABILITY;
    url = CONFIG_KTS.API_URLS.CASE_BILLABILITY;
    const baseURL = CONFIG_KTS.KT_BILLING_API_URL;
    // fetch the logged in user (manager) details
    store.dispatch(beginAPICall(apiIdentifier));
    fetchPost(url, apiRequestData, baseURL)
      .then(result => {
        const isTeamMember =
          isEmpty(loggedInUserHighestRole) &&
          isEmpty(result.data.caseBillabilityDTOs);
        if (isTeamMember) {
          apiData = result.data;
        } else {
          apiData = result.data.caseBillabilityDTOs;
        }
        this.setState({
          apiData,
          lastUpdated: result.data.lastUpdated,
          showMemberView: isTeamMember,
        });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      })
      .finally(() => store.dispatch(endAPICall(apiIdentifier)));
  };
  displayHeading = () => {
    const { apiData, isCF } = this.state;
    const firstIndexValue = Math.round(apiData[0].metricValue);
    const showFirstGreenValue =
      (firstIndexValue >= 35 && !isCF) || (firstIndexValue >= 55 && isCF);
    const showFirstRedValue =
      firstIndexValue !== 0 &&
      ((firstIndexValue < 35 && !isCF) || (firstIndexValue < 55 && isCF));
    return (
      <div className="team-member-heading">
        {showFirstGreenValue && (
          <div className="totalMetricValue">{`${firstIndexValue}%`}</div>
        )}
        {showFirstRedValue && (
          <div className="totalMetricValue redValue">{`${firstIndexValue}%`}</div>
        )}
        {!showFirstGreenValue && !showFirstRedValue && (
          <div className="totalMetricName">-</div>
        )}
      </div>
    );
  };

  displayHeadingMember = () => {
    const { apiData } = this.state;
    const threshold = apiData.threshold;
    const totalValue = Math.round(apiData.userTotal);
    const className = totalValue < threshold ? 'redValue' : '';
    if (!isNaN(totalValue)) {
      return (
        <div className="team-member-heading">
          <div
            className={`${className} totalMetricValue`}
          >{`${totalValue}%`}</div>
        </div>
      );
    }
  };

  renderHeader = () => {
    const { lastUpdated } = this.state;

    return (
      <>
        <div className="card-heading">
          <div className="heading-text">Case Billability</div>
        </div>
        {!isEmpty(lastUpdated) && (
          <div className="card-second-heading">
            <div className="last-updated">Last updated {lastUpdated}</div>
          </div>
        )}
      </>
    );
  };

  renderTableauCardHeader = () => {
    const { apiData, lastUpdated, showMemberView } = this.state;
    const { isManager } = this.props;
    const metricName = isArray(apiData) ? apiData[0]?.metricName : null;

    return (
      <div className={`body-heading ${!isEmpty(lastUpdated)} ? pt05 : ""`}>
        <div className="team-member-heading">
          {isManager ? (
            <div className="totalMetricName">{metricName}</div>
          ) : (
            <div className="totalMetricName">You</div>
          )}
        </div>
        {showMemberView
          ? this.displayHeadingMember()
          : isArray(apiData) && this.displayHeading()}
      </div>
    );
  };

  renderBodyContent = () => {
    const { showMemberView } = this.state;

    return showMemberView ? this.renderMemberView() : this.renderManagerView();
  };

  renderSlottingData = slottigData => {
    return !isEmpty(slottigData)
      ? Object.values(slottigData).map((value, index) => {
          return (
            <div className="slotting" key={index}>
              {`${Math.round(value)}%`}
            </div>
          );
        })
      : null;
  };

  renderMetricName = (metricName, popoverClass) => {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Popover id="tooltip-basic">
            <Popover.Content>{metricName}</Popover.Content>
          </Popover>
        }
        key={metricName.toString()}
      >
        <div className={`popContent ${popoverClass}`}>
          {metricName.length > 18 ? (
            <div>{metricName.substring(0, 18)}...</div>
          ) : (
            metricName
          )}
        </div>
      </OverlayTrigger>
    );
  };

  renderMemberView = () => {
    const { apiData } = this.state;

    return isObject(apiData.data) ? (
      <div>
        <div className="body-content member-body-padding">
          <div className="metricName">{`${apiData.userSlotting} Threshold`}</div>
          <div className="metricValue">{`>=${apiData.threshold}%`}</div>
        </div>
        <div className="slotting-heading">
          <div className="pR20">{apiData.userSlotting}</div>
          <div className="pR15">All</div>
        </div>
        <Grid className="display-slotting">
          <Grid.Row className="margin0">
            <Grid.Column className="left-section">
              {Object.keys(apiData.data[apiData.userSlotting]).map(key => {
                const keyVal = getPaName(key);
                return this.renderMetricName(keyVal);
              })}
            </Grid.Column>
            <Grid.Column className="pr20">
              {this.renderSlottingData(apiData.data[apiData.userSlotting])}
            </Grid.Column>
            <Grid.Column>
              {this.renderSlottingData(apiData.data['All'])}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    ) : (
      <KtLabelWithSkeleton isLoading={true} showLabelGraphValue />
    );
  };

  renderManagerView = () => {
    const { apiData, isGeoManager, isGeoLead } = this.state;
    const { isManager } = this.props;
    const maxIndex = !isManager ? apiData.length - 1 : apiData.length;
    const billabilityData = isArray(apiData) ? apiData.slice(1, maxIndex) : '';
    const paddingclass =
      isGeoManager || isGeoLead ? 'geo-body-padding' : 'manager-body-padding';

    return billabilityData
      ? billabilityData.map((el, index) => {
          const metricName = getPaName(el.metricName);

          if (el.metricName) {
            return (
              <div
                className={`${paddingclass} body-content`}
                key={`${el.metricName.toString()}-${index}`}
              >
                {this.renderMetricName(metricName, 'metricName')}
                <div className="metricValue">
                  {el.metricName.includes('Threshold') && '>='}
                  {`${Math.round(el.metricValue)}%`}
                </div>
              </div>
            );
          }
          return null;
        })
      : null;
  };

  renderTooltip = () => {
    const { InfoText } = this.props;

    return InfoText ? (
      <div
        className="tooltip-with-icon homeTileToolTip"
        onClick={() => {
          store.dispatch(
            showTooltipPopup({
              showTooltip: true,
              tooltipName: 'Case Billability',
            })
          );
        }}
      >
        <Icon
          name="info-tableau"
          class="info-icon"
          alt="infoIcon"
          src="/img/info-tableau.svg"
        />
      </div>
    ) : null;
  };

  render() {
    const { apiData } = this.state;
    const { className, apiCallStatus } = this.props;
    const isLoading = apiCallStatus.fetchCaseBillability;

    return (
      <div className={`case-billability ${className}`}>
        {this.renderHeader()}
        {!isLoading ? (
          !isEmpty(apiData) && (
            <div className="tableau-card-body positionRelative height75">
              {this.renderTableauCardHeader()}
              {this.renderBodyContent()}
            </div>
          )
        ) : (
          <CaseBillabilityLoader
            isLoading={isLoading}
            width="medium"
            showLabelValue
          />
        )}
        <a
          className="totalMetricName cka-dashboard"
          href={CONFIG_KTS.CKA_DASHBOARD_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Legacy Dashboard
        </a>
        {this.renderTooltip()}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    apiCallStatus: reduxStore.apiCallsInProgress,
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    isAllSelected: reduxStore.headerFilterData.isAllSelected,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    isManager: reduxStore.userRoleData.isManager,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    tableauStringData: reduxStore.tableauStackData.tableauStringData,
    defaultMemberList: reduxStore.headerFilterData.defaultMemberList,
  };
};

export default connect(mapStateToProps)(withRouter(KTCaseBillability));
