import React from 'react';
import './KtLoader.scss';

function KtLoader(props) {
  const popupClassName = `kt-small-loader ${
    props.loaderClassName ? props.loaderClassName : ''
  }`;

  return <div className={popupClassName}>Loading...</div>;
}

export default KtLoader;
