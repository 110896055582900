import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import KtGrantDeletePermissionModal from 'components/common/KtGrantDeletePermissionModal';
import Pagination from 'components/common/SliderPagination';
import { isEqual, isEmpty } from 'utils/common';
import './KtAdminListCard.scss';
import CONFIG from 'config/configProps';
class KtAdminListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalGrantDelete: false,
      requireModelData: {},
      activeIndex: 0,
      currentPage: 1,
    };
  }

  handleSliderChange = async activePage => {
    this.setState({
      currentPage: activePage,
      activeIndex: activePage - 1,
    });
  };

  showDeleteIcon = cardData => {
    return (
      <div className="details-container">
        <p className="user-name">
          <span style={{ cursor: 'pointer' }}>{cardData.userName}</span>
          {isEqual(cardData.role, 'Team Member') ||
          isEqual(cardData.role, 'Geo Team Member')
            ? null
            : this.showImageIcon(cardData)}
        </p>
        <p className="practice-area">
          {CONFIG.PA_NAME_ALIAS_MAP[cardData.practiceArea] ||
            cardData.practiceArea}
        </p>
        <p className="sector">{cardData.sector ? cardData.sector : 'All'}</p>
        <p className="subSector">
          {cardData.subSector ? cardData.subSector : 'All'}
        </p>
        {cardData.geoRegion && (
          <p className="subSector">{cardData.geoRegion}</p>
        )}
        <label className="role-button">{cardData.role}</label>
      </div>
    );
  };

  showImageIcon = cardData => {
    let {
      loggedInUserHighestRole,
      employeeInitialData,
      ktPaPaList,
      userTypes,
    } = this.props;
    const loggedInUserEmailId = employeeInitialData.email;
    const isSuperAdmin = isEqual(loggedInUserHighestRole, userTypes[0]);
    const isPaAdmin = isEqual(userTypes[1], loggedInUserHighestRole);
    const isUserSuperAdmin = isEqual(userTypes[0], cardData.role);
    const isUserPaAdmin = isEqual(userTypes[1], cardData.role);
    const isPaHasDeletePermission = isPaAdmin
      ? ktPaPaList.filter(item =>
          isEqual(item.practiceArea, cardData.practiceArea)
        )
      : [];
    const imageIcon = (
      <img
        src="./img/kt-delete-icon.svg"
        alt="delete"
        className="delete-icon"
        onClick={this.handleDeleteCardClick}
      />
    );
    if (isSuperAdmin && !isUserSuperAdmin) {
      return imageIcon;
    }
    if (
      isSuperAdmin &&
      isUserSuperAdmin &&
      isEqual(loggedInUserEmailId, cardData.creatorEmail)
    ) {
      return imageIcon;
    }
    if (
      isPaAdmin &&
      !isUserSuperAdmin &&
      !isUserPaAdmin &&
      !isEmpty(isPaHasDeletePermission)
    ) {
      return imageIcon;
    }
    if (
      isPaAdmin &&
      isUserPaAdmin &&
      isEqual(loggedInUserEmailId, cardData.creatorEmail)
    ) {
      return imageIcon;
    }
  };

  getCardMarkup() {
    let { data } = this.props;
    const { activeIndex, currentPage } = this.state;
    let markup;
    if (data.length === 1) {
      let cardData = data[0];
      markup = (
        <div
          className={`admin-list-card-container ${
            cardData.employeeType === 'KT' ? 'kt-card' : 'non-kt-card'
          }`}
          onClick={this.handleCardClick}
        >
          <div className="profile-img-container" />
          {this.showDeleteIcon(cardData)}
        </div>
      );
    } else if (data.length > 1) {
      let cardData = data;
      markup = (
        <div className={`admin-list-card-container`}>
          <div
            className={`slider-item ${
              cardData[activeIndex].employeeType === 'KT'
                ? 'kt-item'
                : 'non-kt-item'
            }`}
            onClick={this.handleCardClick}
          >
            <div className="profile-img-container" />
            {this.showDeleteIcon(cardData[activeIndex])}
          </div>
          <Pagination
            currentPage={currentPage}
            perPageRecords={1}
            makeHttpRequestWithPage={this.handleSliderChange}
            totalRecords={data.length}
          />
        </div>
      );
    }

    return markup;
  }

  handleDeleteCardClick = async event => {
    event.stopPropagation();

    const { handleDeleteCardClick, data } = this.props;
    this.setState(
      {
        currentPage: 1,
      },
      () => handleDeleteCardClick(data)
    );
  };

  handleCardClick = async () => {
    let requireModelData = {};
    const { data } = this.props;
    requireModelData.searchedUserDetails = this.props.data;
    const typeKt = isEqual(data[0].employeeType, 'KT');
    requireModelData.typeKt = typeKt;
    this.setState({
      openModalGrantDelete: true,
      requireModelData,
      currentPage: 1,
    });
  };

  closeModalGrantDelete = () => {
    this.setState({
      requireModelData: {},
      openModalGrantDelete: false,
    });
  };

  /**
   * React Life cycle method
   * Which will re-render the screen on every state update
   */
  render() {
    let cardMarkup = this.getCardMarkup();
    return (
      <Fragment>
        {cardMarkup}
        {this.state.openModalGrantDelete && (
          <KtGrantDeletePermissionModal
            deleteFromAdminCard={true}
            modalData={this.state.requireModelData}
            onHide={this.closeModalGrantDelete}
            dataRefreshHandler={this.props.dataRefreshHandler}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    employeeInitialData: store.userRoleData.employeeInitialData,
    loggedInUserHighestRole: store.userRoleData.loggedInUserHighestRole,
    userTypes: store.userRoleData.userTypes,
    ktPaPaList: store.headerFilterData.ktPaPaList,
  };
};

export default connect(mapStateToProps)(KtAdminListCard);
