import React from 'react';
import { isEmptyOrNull } from 'utils';
import { isLoading } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KTHourlyBillRate = props => {
  const data = props.data.data;
  const loading = isLoading(props.data);
  const billRate = !isEmptyOrNull(data?.results)
    ? data?.results?.billRate
    : null;
  const noData = isEmptyOrNull(billRate);
  return (
    <div className={`home_page_main_split_row_timesheet_admin main_conatiner`}>
      <div className="heading">
        <div className="heading_text">HOURLY BILL RATE</div>
      </div>
      {loading ? (
        <LabelWithSkeleton isLoading={1} size="small" showLine />
      ) : !noData ? (
        <div className="timesheet_total">
          <div className="timesheet_total_val">
            <div className="total_values">
              <div>{`$${billRate.usdBillRate}`}</div>
              <div className="dollarSign">USD</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default KTHourlyBillRate;
