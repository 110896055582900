import React, { Component } from 'react';
import store from 'redux/store';
import { connect } from 'react-redux';
import RichTextEditor from 'components/common/KtRichTextEditor/RichTextEditor';
import KtDropdown from 'components/common/KtDropdown';
import CONFIG_KTS from 'config/configProps';
import {
  fetchGet,
  fetchPut,
  fetchDelete,
  fetchPost,
} from 'utils/KtCommonServices';
import {
  updateToastNotificationDetails,
  updateIconInformation,
} from 'redux/actions';
import { isEqual, isEmpty } from 'utils/common';
import html from 'react-inner-html';
import './KtEditInfo.scss';

class KtEditInfo extends Component {
  constructor(props) {
    super(props);
    const { loggedInUserHighestRole } = this.props;
    this.state = {
      selectedOptionName: this.props.heading,
      selectedInformation: '',
      iconInformation: this.props.iconInformation,
      isUpdate: false,
      selectedId: '',
      updateEditor: false,
      textContent: '',
      selectedRoleName: loggedInUserHighestRole
        ? loggedInUserHighestRole
        : '---Select Role---',
      rolesDropdown: ['---Select Role---', ...this.props.userTypes],
    };
  }

  componentDidMount = () => {
    if (isEmpty(this.props.iconInformation)) {
      this.getIconInformation();
    } else {
      this.setIconInformation();
    }
  };

  getIconInformation = () => {
    const apiUrl = CONFIG_KTS.API_URLS.ALL_ICON_INFORMATION;
    fetchGet(apiUrl).then(result => {
      store.dispatch(updateIconInformation(result));
      this.setState({ iconInformation: result }, () => {
        this.setIconInformation();
      });
    });
  };

  changeEventHandler = selectedOptionName => {
    this.setState({ selectedOptionName, updateEditor: false }, () => {
      this.setIconInformation();
    });
  };

  changeRoleHandler = selectedRoleName => {
    this.setState({ selectedRoleName, updateEditor: false }, () => {
      this.setIconInformation();
    });
  };

  setIconInformation = () => {
    const { iconInformation, selectedRoleName, selectedOptionName } =
      this.state;
    let selectedInformation = '';
    let selectedId = '';
    let selectedOptionDetails = isEqual(selectedRoleName, '---Select Role---')
      ? iconInformation.filter(
          item =>
            isEqual(item.name, selectedOptionName) && isEqual(item.role, null)
        )
      : iconInformation.filter(
          item =>
            isEqual(item.name, selectedOptionName) &&
            isEqual(item.role, selectedRoleName)
        );
    selectedOptionDetails = isEmpty(selectedOptionDetails)
      ? iconInformation.filter(
          item =>
            isEqual(item.name, selectedOptionName) && isEqual(item.role, null)
        )
      : selectedOptionDetails;
    if (!isEmpty(selectedOptionDetails)) {
      selectedInformation = selectedOptionDetails[0].htmlText;
      selectedId = selectedOptionDetails[0].id;
    }
    this.setState({
      selectedInformation,
      isUpdate: !isEmpty(selectedInformation),
      selectedId,
      updateEditor: true,
      textContent: selectedInformation,
    });
  };

  saveInformation = () => {
    const {
      selectedOptionName,
      selectedInformation,
      isUpdate,
      selectedId,
      textContent,
      selectedRoleName,
    } = this.state;
    if (isEmpty(textContent)) {
      return;
    }
    const apiUrl = CONFIG_KTS.API_URLS.ICON_INFORMATION;
    const dataToBePosted = {
      name: selectedOptionName,
      htmlText: selectedInformation,
      role: selectedRoleName,
    };
    if (isUpdate) {
      dataToBePosted['id'] = selectedId;
    }
    const methodName = isUpdate ? fetchPut : fetchPost;
    methodName(apiUrl, dataToBePosted)
      .then(result => {
        this.props.onHide();
        this.getIconInformation();
        this.createToasterMessage('Success', result.data);
      })
      .catch(() => {
        this.props.onHide();
        this.createToasterMessage();
      });
  };

  deleteInformation = () => {
    const { selectedId, textContent, iconInformation } = this.state;
    const selectedOptionDetails = iconInformation.filter(item =>
      isEqual(item.id, selectedId)
    );
    if (isEmpty(textContent) || isEmpty(selectedOptionDetails)) {
      return;
    }
    const apiUrl = `${CONFIG_KTS.API_URLS.ICON_INFORMATION}${selectedId}`;
    fetchDelete(apiUrl)
      .then(result => {
        this.getIconInformation();
        this.setState({ selectedInformation: '', textContent: '' });
        this.createToasterMessage('Success', result.data);
        this.props.onHide();
      })
      .catch(() => {
        this.createToasterMessage();
        this.props.onHide();
      });
  };

  createToasterMessage = (notificationHeading, notificationMessage) => {
    let message = CONFIG_KTS.KT_SCORECARD_CONST.DEFAULT_ERROR_MESSAGE;
    let notificationDetailsObj = {
      showToastNotification: true,
      notificationHeader: notificationHeading ? notificationHeading : 'Error',
      notificationBody: notificationMessage ? notificationMessage : message,
      notificationClassName: 'information-notification',
    };
    // dispatch an action to display toast notification
    store.dispatch(updateToastNotificationDetails(notificationDetailsObj));
  };

  getHTMLTextFromEditor = (htmlContent, textContent) => {
    this.setState({ selectedInformation: htmlContent, textContent });
  };

  /**
   * React Life Cycle method - re-render the view on state update
   */
  render() {
    const {
      selectedOptionName,
      selectedInformation,
      updateEditor,
      selectedRoleName,
      rolesDropdown,
    } = this.state;
    const { isEditModeOn } = this.props;
    const information = !isEmpty(selectedInformation)
      ? selectedInformation.replaceAll('<p></p>', '<br>')
      : selectedInformation;
    return (
      <div className="edit-container">
        {isEditModeOn ? (
          <div>
            <div className="dropdown-container">
              <div className="edit-dropdown role-dropdown">
                <KtDropdown
                  dropdownOptionsList={rolesDropdown}
                  selectedOptionName={selectedRoleName}
                  changeEventHandler={this.changeRoleHandler}
                />
              </div>
              <div className="edit-dropdown">
                <KtDropdown
                  dropdownOptionsList={CONFIG_KTS.INFO_EDIT_HEADING}
                  selectedOptionName={selectedOptionName}
                  changeEventHandler={this.changeEventHandler}
                />
              </div>
            </div>
            <div className="editor">
              {updateEditor && (
                <RichTextEditor
                  getHTMLTextHandler={this.getHTMLTextFromEditor}
                  valueProp={selectedInformation}
                  editorWrapperClass="information-editor"
                  editorTextEditorClass="information-editor-text-area"
                  editorPlaceHolder="Enter information"
                  className="information-rectangle"
                />
              )}
            </div>
            <div className="button-container">
              <div
                className="button save-button"
                onClick={() => this.saveInformation()}
              >
                Save
              </div>
              <div
                className="button delete-button"
                onClick={() => this.deleteInformation()}
              >
                Delete
              </div>
            </div>
          </div>
        ) : (
          <div {...html(information)} />
        )}
      </div>
    );
  }
}
const mapStateToProps = function (reduxStore) {
  return {
    iconInformation: reduxStore.ktScorecard.iconInformation,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    userTypes: reduxStore.userRoleData.userTypes,
  };
};
export default connect(mapStateToProps)(KtEditInfo);
