import React from 'react';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
const FteLoader = () => {
  return (
    <div className="chart-container">
      <KtLabelWithSkeleton isLoading={1} showSectionsFte />
      <KtLabelWithSkeleton isLoading={1} showSectionsFte />
      <KtLabelWithSkeleton isLoading={1} showSectionsFte />
      <KtLabelWithSkeleton isLoading={1} showSectionsFte />
      <br />
      <br />
      <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
      <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
      <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
      <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
    </div>
  );
};
export default FteLoader;
