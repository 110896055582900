import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { isEmpty, debounce, isEqual } from 'utils/common';
import CONFIG from 'config/configProps';
import { getTotalCaseBillabilitySplit } from 'redux/actions/tableauStackAction';
import AuthorizeHOC from 'components/common/authorizeHOC';
// import SparkLineTableRows from 'components/common/SparkLineTableRows';
import KtEmployeeBillabilityPlaceholder from './KtEmployeeBillabilityPlaceholder';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './KtEmployeeBillability.scss';
import { isEmptyOrNull } from 'utils';
import { globalAdobeAnalytics } from 'config/analytics';
const MAX_STACK_SIZE = 30;
const commonChartOptions = {
  chart: {
    type: 'bar',
    spacingTop: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    spacingBottom: 0,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      stacking: 'percent',
      borderWidth: 0,
    },
  },
  tooltip: {
    enabled: true,
    useHTML: true,
    outside: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: null,
    },
    gridLineColor: 'ffffff',
    labels: {
      enabled: true,
    },
  },
};

const KtEmployeeBillability = props => {
  const {
    tableauStringData,
    tableauParamString,
    defaultMemberList,
    history,
    dispatch,
    loggedInUserHighestRole,
  } = props;
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState({});
  const [isCDPage, setIsCDPage] = useState(false);
  const [isCaseEngagementPage, setIsCaseEngagementPage] = useState(false);
  const [pageName, setPageName] = useState('');
  const [legends, setLegends] = useState({});
  const [noData, setNoData] = useState(false);

  const getTotalBillability = useCallback(
    (query, isCDPage, isCaseEngagementPage) => {
      if (!isEmpty(query)) {
        setLoading(true);
        getLegends(isCDPage, isCaseEngagementPage);
        dispatch(
          getTotalCaseBillabilitySplit(query, isCDPage, isCaseEngagementPage)
        )
          .then(billabilityData => {
            setChartData(billabilityData || {});
          })
          .finally(() => setLoading(false));
      }
    },
    [dispatch]
  );

  const delayedQuery = useCallback(
    debounce(
      (query, isCDPage, isCaseEngagementPage) =>
        getTotalBillability(query, isCDPage, isCaseEngagementPage),
      500
    ), // (*)
    []
  );

  useEffect(() => {
    const page = history.location.pathname;
    const CONFIG_VAL = CONFIG.KT_SCORECARD_CONST;
    const isCDPage = isEqual(page, CONFIG_VAL.CD_BILLABILITY_URL);
    const isCaseEngagementPage = isEqual(
      page,
      CONFIG_VAL.CASE_ENGAGEMENT_DEPTH_URL
    );
    setIsCDPage(isCDPage);
    setIsCaseEngagementPage(isCaseEngagementPage);
    const pageTitle = isCDPage
      ? 'CD Billability'
      : isCaseEngagementPage
      ? 'Case Engagement'
      : 'Billability';
    if (page !== pageName) {
      setPageName(page);
      globalAdobeAnalytics(`KTDashboard-Legacy-${pageTitle}`);
    }
    delayedQuery(postData, isCDPage, isCaseEngagementPage);
  }, [history.location, pageName, postData, delayedQuery]);

  useEffect(() => {
    const {
      initialDate,
      calenderDropdownVal: timePeriod,
      selectedTeamMembers,
      employeeIdHRO,
    } = tableauStringData;
    const allParamsExist = initialDate && timePeriod;
    if (isEmpty(defaultMemberList) && loggedInUserHighestRole) {
      setNoData(true);
      return;
    } else {
      setNoData(false);
    }
    const teamMemberList = isEmpty(selectedTeamMembers)
      ? defaultMemberList
      : selectedTeamMembers;
    if (allParamsExist) {
      setLoading(true);
      setPostData({
        month: initialDate.getMonth() + 1,
        year: initialDate.getFullYear(),
        timePeriod,
        selectedUserId: loggedInUserHighestRole
          ? teamMemberList
          : [employeeIdHRO],
      });
    }
  }, [tableauStringData, defaultMemberList, loggedInUserHighestRole]);

  const getLegends = (isCDPage, isCaseEngagementPage) => {
    let legends = {};
    if (isCDPage) legends = CONFIG.CD_BILLABILITY_COLORS;
    else if (isCaseEngagementPage)
      legends = CONFIG.CASE_ENGAGEMENT_DEPTH_COLORS;
    else legends = CONFIG.CASE_BILLABILITY_COLORS;
    setLegends(legends);
  };

  const transformRawDataToChartSeries = chartData => {
    let sortedUsers = chartData?.users || {};
    if (sortedUsers.length === 0) {
      return undefined;
    }
    sortedUsers = Object.keys(sortedUsers)
      .sort()
      .reduce((r, k) => ((r[k] = sortedUsers[k]), r), {});
    const labels = [...Object.keys(sortedUsers)];
    let metricChartSeries = [];
    let data = {};
    const sectionCategories = Object.keys(legends);
    sectionCategories.forEach(stackKey => {
      data[stackKey] = [];
    });
    let modifiedData = getDataByCategory(labels, sectionCategories, data);
    const { theoData, isAllValuesNull } = modifiedData;
    data = modifiedData.data;
    metricChartSeries = sectionCategories.map(key => ({
      name: isCaseEngagementPage
        ? CONFIG.CASE_ENGAGEMENT_DEPTH_ALIAS[key]
        : key,
      data: data[key],
      type: 'bar',
      color: legends[key],
      pointWidth: 25,
    }));
    return {
      chartALabels: labels,
      metricChartSeries: [...metricChartSeries].reverse(),
      isAllValuesNull,
      theoData,
    };
  };

  const getDataByCategory = (labels, sectionCategories, data) => {
    let theoData = [];
    let isAllValuesNull = true;
    const stackObjs = [...labels.map(name => chartData.users[name])];
    stackObjs.forEach((stackObjValues, i) => {
      const sumValues = Object.values(stackObjValues).reduce(
        (a, b) => a + b,
        0
      );
      if (sumValues === 0) {
        labels.splice(i, 1);
      } else {
        sectionCategories.forEach(stackKey => {
          const value = stackObjValues[stackKey];
          data[stackKey].push(value);
          if (value) {
            isAllValuesNull = false;
          }
        });
        const theoName =
          !isCDPage && isCaseEngagementPage ? 'theoCapChgs' : 'Theo Cap Chgs';
        theoData.push(stackObjValues[theoName] + 'K');
      }
    });
    return { data, theoData, isAllValuesNull };
  };

  const renderChartLegends = () => {
    return (
      <div className="kt-total-billability-container__chart__legends">
        {!isCDPage && !isCaseEngagementPage && (
          <div className="kt-total-billability-container__chart__legends__note">
            Note : This shows ALL historicial billability for each individual,
            including historical billability where individuals may have been
            aligned to different PA, Geo Market, Topic, Sector, Managed By in
            the filters shown above.
          </div>
        )}
        {isCaseEngagementPage && (
          <div className="kt-total-billability-container__chart__legends__note">
            {`Note : Engagement depth is calculated as Case charges which fall in
            an hours-category as a percentage of theo cap. For example, if a
            person had 1000 hours theo cap, and billed 100 hours to 4 case codes
            (25 hours each), then the '20-40h' category would show 10%. Totals
            represent total Case billability.`}
          </div>
        )}
        <div>
          <div>
            {Object.entries(legends).map(([label, backgroundColor]) => {
              return (
                <span
                  key={label}
                  className="kt-total-billability-container__chart__legends__item"
                >
                  <label
                    style={{ backgroundColor }}
                    className="kt-total-billability-container__chart__legends__item__mark"
                  >
                    &nbsp;
                  </label>
                  {isCDPage && isEqual(label, 'Investment')
                    ? 'Client Investment'
                    : isCaseEngagementPage
                    ? CONFIG.CASE_ENGAGEMENT_DEPTH_ALIAS[label]
                    : label}
                  {isEqual(label, 'Other') && <sup>^1</sup>}
                </span>
              );
            })}
          </div>
          {!isCDPage && !isCaseEngagementPage && (
            <div className="kt-total-billability-container__chart__legends__note floatRight">
              “1. Other = Training, recruiting, holidays, vacation, etc. (X
              codes)”
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderChartDataRows = () => {
    const { metricChartSeries, chartALabels, isAllValuesNull, theoData } =
      transformRawDataToChartSeries(chartData);
    const metricChartOptions =
      chartData && getMetricChart(metricChartSeries, chartALabels, theoData);
    const height = ((chartData && chartALabels) || []).length * MAX_STACK_SIZE;
    return isAllValuesNull || noData ? (
      <div className="no-record">No Records to Display</div>
    ) : (
      <div className="spark-line-table__body">
        {metricChartOptions && (
          <HighchartsReact
            options={metricChartOptions}
            allowChartUpdate={false}
            highcharts={Highcharts}
            containerProps={{
              style: {
                height: height,
                width: '1000px',
                position: 'relative',
              },
            }}
          />
        )}
      </div>
    );
  };

  const renderBillabilityChart = () => {
    return (
      <Table basic celled sortable compact id="spark-line-table">
        <Table.Header>
          <Table.Row className="table_row">
            <Table.HeaderCell className="header1">
              Employee Name
            </Table.HeaderCell>
            {!isCDPage && (
              <Table.HeaderCell className="header2">
                Theo Cap Chgs
              </Table.HeaderCell>
            )}
            <Table.HeaderCell className="header3" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading || isEmptyOrNull(legends) ? (
            <KtEmployeeBillabilityPlaceholder isCDPage={isCDPage} />
          ) : (
            renderChartDataRows()
          )}
        </Table.Body>
      </Table>
    );
  };

  const getMetricChart = (metricSeries, labels, theoData) => {
    return {
      ...commonChartOptions,
      xAxis: [
        {
          categories: theoData,
          lineColor: 'ffffff',
          labels: {
            y: 3,
            formatter: function () {
              return isCDPage ? '' : this.value;
            },
            style: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: '#747070',
              fontWeight: '500',
              fontFamily: 'Tahoma',
              fontSize: '1.2rem',
            },
          },
        },
        {
          categories: labels,
          lineColor: 'ffffff',
          linkedTo: 0,
          labels: {
            y: 3,
            formatter: function () {
              return this.value;
            },
            style: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: '$kt-heading-color',
              fontWeight: '500',
              fontFamily: 'Tahoma',
              fontSize: '1.2rem',
              width: isCDPage ? 100 : 80,
            },
          },
        },
      ],
      plotOptions: {
        series: {
          stacking: 'normal',
        },
        bar: {
          pointPadding: 25,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${
                !isNaN(this.y) && this.y !== 0 ? Math.round(this.y) + '%' : ''
              }`;
            },
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          formatter: function () {
            return '';
          },
        },
        stackLabels: {
          enabled: isCDPage || isCaseEngagementPage,
          formatter: function () {
            let sum = 0;
            let series = this.axis.series;

            for (let i in series) {
              sum += series[i].yData[this.x];
            }
            if ((sum < 0 && this.isNegative) || (sum >= 0 && !this.isNegative))
              return `${!isNaN(sum) && sum > 0 ? Math.round(sum) + '%' : ''}`;
          },
        },
      },
      tooltip: {
        ...commonChartOptions.tooltip,
        formatter: function () {
          const name =
            isCDPage && isEqual(this.series.name, 'Investment')
              ? 'Client Investment'
              : this.series.name;
          return `<span><b> ${name} : </b>${
            Math.floor(this.y * 100) / 100
          }%</span>`;
        },
        enabled: metricSeries[0].name,
      },
      series: metricSeries,
    };
  };

  return (
    <div className="kt-main-container  kt-total-billability-container">
      {tableauParamString && (
        <div className="kt-total-billability-container__chart">
          <h2 className="kt-total-billability-container__chart__header">
            {isCDPage
              ? 'CD Billability By Team Member'
              : isCaseEngagementPage
              ? 'Case Engagement Depth By Team Member'
              : 'Billability By Team Member'}
          </h2>
          {isCDPage ? (
            <h3 className="kt-total-billability-container__chart__header2">
              <p>
                CD Billability = Case (Client (C) and Global Pro Bono (B)) +
                Case without codes (15112-15) +
              </p>
              <p>Client Investment (F) Codes + Innovation Fund Chgs</p>
            </h3>
          ) : null}
          {renderChartLegends()}
          {renderBillabilityChart()}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    defaultMemberList: store.headerFilterData.defaultMemberList,
    tableauParamString: store.tableauStackData.tableauParamString,
    tableauStringData: store.tableauStackData.tableauStringData,
    loggedInUserHighestRole: store.userRoleData.loggedInUserHighestRole,
  };
};
export default connect(mapStateToProps)(AuthorizeHOC(KtEmployeeBillability));
