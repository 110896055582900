import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isEqual } from 'utils/common';
import KTCaseBillability from 'components/KTTableauStackWrapper/KTCaseBillability';
import KTCaseBillabilityTeam from 'components/KTTableauStackWrapper/KTCaseBillabilityTeam';
import CONFIG_KTS from 'config/configProps';
import { getIconInformation } from 'utils';
class TopicTableauCard extends PureComponent {
  render() {
    const {
      employeeInitialData,
      section,
      isManager,
      isAdmin,
      iconInformation,
      loggedInUserHighestRole,
    } = this.props;
    return (
      Object.keys(employeeInitialData || {}).length >= 1 && (
        <div
          className={`tableau-card-container ${
            !isEqual(section, CONFIG_KTS.SECTION[1]) ? 'ta-container' : ''
          }`}
        >
          <KTCaseBillability
            section={section}
            className={
              isManager ? 'manager-case-billability' : 'member-case-billability'
            }
            isAdmin={isAdmin}
            InfoText={getIconInformation(
              'Case Billability',
              iconInformation,
              loggedInUserHighestRole
            )}
          />
          <KTCaseBillabilityTeam
            section={section}
            className={
              isManager
                ? 'manager-case-billability-split'
                : 'member-case-billability-split'
            }
            isAdmin={isAdmin}
            InfoText={getIconInformation(
              'Case Billability by Team Member',
              iconInformation,
              loggedInUserHighestRole
            )}
          />
        </div>
      )
    );
  }
}
const mapStateToProps = function (store) {
  return {
    employeeInitialData: store.userRoleData.employeeInitialData,
    isManager: store.userRoleData.isManager,
    loggedInUserHighestRole: store.userRoleData.loggedInUserHighestRole,
    iconInformation: store.ktScorecard.iconInformation,
  };
};
export default connect(mapStateToProps)(withRouter(TopicTableauCard));
