import React from 'react';
import { Table } from 'semantic-ui-react';
import { sortByNumber, sortByString } from 'utils';
import { isEqual } from 'utils/common';
import { numberWithCommas } from 'utils/common';

const formatData = (apiData, selectedOption) => {
  let orderBy = isEqual(selectedOption.type, 'Acc')
    ? 'ascending'
    : 'descending';
  if (isEqual(selectedOption.value, 'Name')) {
    apiData = sortByString(apiData, 'employeeName', orderBy);
  } else if (isEqual(selectedOption.value, 'Bill Rate (Local)')) {
    apiData = sortByNumber(apiData, 'localBillRate', orderBy);
  } else if (isEqual(selectedOption.value, 'Job Title')) {
    apiData = sortByString(apiData, 'jobTitle', orderBy);
  } else if (isEqual(selectedOption.value, 'Home Office')) {
    apiData = sortByString(apiData, 'homeOffice', orderBy);
  } else {
    apiData = sortByNumber(apiData, 'usdBillRate', orderBy);
  }
  return apiData;
};

const KTBillRate = props => {
  let { apiData, selectedOption } = props;
  apiData = apiData?.billRateDTOs || [];

  apiData = formatData(apiData, selectedOption);

  return apiData.map(el => {
    return (
      <Table.Row
        key={el.name}
        className="kt-billability-container__chart__table__body_main"
      >
        <Table.Cell className="name_columns team_member_name">
          {el.employeeName}
        </Table.Cell>
        <Table.Cell className="name_columns job_title">
          {el.jobTitle}
        </Table.Cell>
        <Table.Cell className="name_columns home_office">
          {el.homeOffice}
        </Table.Cell>
        <Table.Cell className="name_columns digit_columns">{`${numberWithCommas(
          el.localBillRate
        )} (${el.localCurrencyCode})`}</Table.Cell>
        <Table.Cell className="name_columns digit_columns">
          {numberWithCommas(el.usdBillRate)}
        </Table.Cell>
      </Table.Row>
    );
  });
};

export default KTBillRate;
