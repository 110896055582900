import { connect } from 'react-redux';
import PrototypeDesign from './PrototypeDesign';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.ktHomeSelectors.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    lastUpdatedDate: state.filterDataPt.lastUpdatedDate,
    isFilterOpen: state.filterDataPt.isFilterOpen,
    userDetails: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_USER_DETAILS
    ),
    selectedUserByImpersonator: state.homePageData.selectedUserByImpersonator,
    impersonatedUserDetails: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_IMPERSONATED_USER_DETAILS
    ),
    loggedInUserRole: state.homePageData.loggedInUserRole,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateLastUpdatedDate: bindActionCreators(
        actions.protoTypeActions.updateLastUpdatedDate,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrototypeDesign);
