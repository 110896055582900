import React from 'react';

import Button from 'components/common/button/Button';
import KtLoader from 'components/common/KtLoader';
import { isChangeAlignment, isEditUser } from 'utils/EditUser';

const EditUserAction = props => {
  const { loading, action, onCancel, onSave, onActivate } = props;
  const showActivate = !isEditUser(action) && !isChangeAlignment(action);
  const saveDisabled = loading?.save || loading?.getDetails;
  const activateDisabled = loading.activate || loading?.getDetails;
  const cancelDisabled = saveDisabled || activateDisabled;

  return (
    <>
      <Button
        className="edit-user-container__card__action__cancel-btn"
        onClick={onCancel}
        disabled={cancelDisabled}
        data-testid="cancel-btn"
      >
        Cancel
      </Button>
      <Button
        className="edit-user-container__card__action__save-btn"
        onClick={onSave(action || 'save_as_draft')}
        disabled={saveDisabled}
        data-testid="save-btn"
      >
        {!action ? 'Save As Draft' : 'Save'}
        {loading && loading[action] && (
          <KtLoader loaderClassName="edit-user-container__card__action__btn-loader" />
        )}
      </Button>
      {showActivate && (
        <Button
          className="edit-user-container__card__action__activate-btn"
          onClick={onActivate('activate')}
          disabled={activateDisabled}
          data-testid="activate-btn"
        >
          Activate
          {loading?.activate && (
            <KtLoader loaderClassName="edit-user-container__card__action__btn-loader" />
          )}
        </Button>
      )}
    </>
  );
};

export default EditUserAction;
