import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isEqual } from 'utils/common';
import store from 'redux/store';
import TableauStack from 'components/KTTableauStackWrapper/TableauStack';
import CONFIG, { OPTION_ALL } from 'config/configProps';
import { showTooltipPopup } from 'redux/actions';
import { updateApiRequestData } from 'redux/actions/tableauStackAction';
import {
  isGeoLeadRole,
  isImpersonatorRole,
  isInAdminRoleList,
  isInPaAdminRoleList,
  isSuperAdminRole,
  isInSuperAdminGroupOfOktaForKtScorecard,
  returnWithAllValue,
} from 'utils';
import TableauStackWrapperPlaceholder from './TableauStackWrapperPlaceholder';
import ToolTipShowEdit from 'components/common/ToolTipShowEdit';

class TableauStackWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
    };
  }

  componentDidMount() {
    if (this.props.tableauParamString) {
      this.setParameters(true);
    }
  }

  /**
   *
   * React Life cycle method
   */
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const {
      tableauParamString,
      selectedSectionDetailOptionValue,
      loggedInUserHighestRole,
    } = this.props;
    const isAdmin = CONFIG.ADMIN_ROLES.includes(loggedInUserHighestRole);
    const isTableauParamStringChanged = !isEqual(
      tableauParamString,
      prevProps.tableauParamString
    );
    const isNotAdminOrSectionSelected =
      !isAdmin || !isEmpty(selectedSectionDetailOptionValue);

    if (isTableauParamStringChanged && isNotAdminOrSectionSelected) {
      this.setParameters(false);
    }
  }

  getDateFormatted = val => {
    const monthShort = CONFIG.MONTH_SORT_NAMES;
    let date = val.split('-');
    let m = `0${date[1]}`.slice(-2);
    const month = monthShort[parseInt(m) - 1];
    const year = parseInt(date[0]);
    return `${month} ${year}`;
  };

  getTaxonomy = paId => {
    const { allPaList } = this.props;
    const paData = allPaList?.find(paInfo => paId === paInfo.paId);
    return paData?.taxonomy;
  };

  setParameters = initialLoad => {
    const {
      selectedSectionDetails,
      selectedSubSectorDetails,
      employeeInitialData,
      selectedSectorDetails,
      loggedInUserHighestRole,
    } = this.props;
    let data;
    if (Object.keys(selectedSectionDetails || {}).length > 0) {
      data = selectedSectionDetails;
    } else if (
      Object.keys(selectedSubSectorDetails || {}).length &&
      employeeInitialData.topicSectorId === -1
    ) {
      data = {
        paId: employeeInitialData.paId,
        topicSectorId: selectedSectorDetails.topicSectorId,
        subSectorId: selectedSubSectorDetails.subSectorId,
        paTeam: employeeInitialData.paTeam,
        sector: selectedSectorDetails.sector,
        subSector: selectedSubSectorDetails.subSector, //TBD
        sharedSubSector: selectedSubSectorDetails.sharedSubSector,
      };
    } else if (
      employeeInitialData.paId !== -1 ||
      isGeoLeadRole(loggedInUserHighestRole)
    ) {
      data = employeeInitialData;
    }

    if (!data) {
      return;
    }

    const apiRequestData = this.getApiRequestData(data) || {};
    const isApiRequestDataNotChanged =
      !initialLoad && isEqual(apiRequestData, this.props.apiRequestData);

    // if there in no request data and api request data not changed
    // don't move forward
    if (isEmpty(apiRequestData) || isApiRequestDataNotChanged) return;

    this.setState({ isDataLoaded: true });
  };

  getSelectedValue = seletedItem => {
    return seletedItem === OPTION_ALL ? null : seletedItem;
  };

  getApiRequestData = selectedSectionDetails => {
    const {
      calenderDropdownVal,
      loggedInUserHighestRole,
      initialDate,
      isManager,
      apiRequestData,
      employeeInitialData,
      path,
      managedBy,
      passGeo,
      isGeoLead,
    } = this.props;
    let apiData = {};
    if (selectedSectionDetails) {
      apiData = {
        practiceArea: this.getSelectedValue(selectedSectionDetails.paTeam),
        sector: this.getSelectedValue(selectedSectionDetails.sector),
        subSector:
          isEmpty(selectedSectionDetails.subSector) ||
          isEqual(selectedSectionDetails.subSector, OPTION_ALL)
            ? null
            : selectedSectionDetails.subSector,
        paId: selectedSectionDetails.paId,
        topicSectorId: selectedSectionDetails.topicSectorId,
        subSectorId: selectedSectionDetails.subSectorId,
        practiceAreas: returnWithAllValue(selectedSectionDetails.paTeams),
        paIds: selectedSectionDetails.paIds,
        topicSectorIds: selectedSectionDetails.topicSectorIds,
        sectors: returnWithAllValue(selectedSectionDetails.sectors),
        subSectorIds: selectedSectionDetails.subSectorIds,
        subSectors: returnWithAllValue(selectedSectionDetails.subSectors),
        month: initialDate.getMonth() + 1,
        year: initialDate.getFullYear(),
        timePeriod: calenderDropdownVal,
        role: loggedInUserHighestRole,
        sharedSubSector: selectedSectionDetails.sharedSubSector,
        sharedSubSectors: selectedSectionDetails.sharedSubSectors,
        path: isEmpty(path) ? OPTION_ALL.toUpperCase() : path.toUpperCase(),
        geoRegion: isEqual(selectedSectionDetails.geoRegion, 'No Geo')
          ? null
          : selectedSectionDetails.geoRegion,
      };

      if (!isManager) {
        apiData['userId'] = employeeInitialData.employeeIdHRO;
        apiData['managedBy'] = selectedSectionDetails.managedBy;
      } else if (!passGeo || isGeoLead) {
        apiData['selectedManagedBy'] = managedBy;
      }
    } else {
      apiData = cloneDeep(apiRequestData || {});
    }
    apiData['taxonomy'] = this.getTaxonomy(apiData.paId);
    if (!isEqual(apiRequestData, apiData)) {
      store.dispatch(updateApiRequestData(apiData));
    }
    return apiData;
  };

  closeToolTipBox = () => {
    store.dispatch(showTooltipPopup({}));
  };

  showToolTipEditIcon = () => {
    const { loggedInUserHighestRole } = this.props;
    return (
      isSuperAdminRole(loggedInUserHighestRole) ||
      isImpersonatorRole(loggedInUserHighestRole)
    );
  };

  render() {
    const { isDataLoaded } = this.state;
    const {
      loggedInUserHighestRole,
      selectedSubSectorDetails,
      ktUserDetails,
      path,
      tooltipData,
    } = this.props;
    let tableauParamString = this.props.tableauParamString;
    tableauParamString = tableauParamString.split('|');
    let startDate = this.getDateFormatted(tableauParamString[2]);
    let endDate = this.getDateFormatted(tableauParamString[3]);
    let checkSelectedSubSector = false;
    if (
      (isInPaAdminRoleList(loggedInUserHighestRole) &&
        Object.keys(selectedSubSectorDetails || {}).length >= 1) ||
      !isInPaAdminRoleList(loggedInUserHighestRole)
    ) {
      checkSelectedSubSector = true;
    }
    const isAdmin =
      isInAdminRoleList(loggedInUserHighestRole) ||
      isInSuperAdminGroupOfOktaForKtScorecard(ktUserDetails);
    const memberViewClass = !isAdmin ? 'manager-member-view' : '';

    if (isDataLoaded && checkSelectedSubSector) {
      return (
        <div className={`${memberViewClass} tableau-stack-wrapper`}>
          <TableauStack
            pageHeading="Billing"
            section={path}
            endDate={endDate}
            startDate={startDate}
            isTopicClient={true}
            isAdmin={isAdmin}
          />
          <TableauStack
            pageHeading="Team"
            endDate={endDate}
            startDate={startDate}
            isTeam={true}
            isAdmin={isAdmin}
          />
          <TableauStack
            pageHeading="Content & IP Created or Contributed by KT"
            endDate={endDate}
            startDate={startDate}
            isContent={true}
            isAdmin={isAdmin}
          />
          {!isEmpty(tooltipData) && (
            <ToolTipShowEdit
              showPopup={tooltipData.showTooltip}
              handlePopupClose={this.closeToolTipBox}
              popupHeading="Edit Info Buttons"
              popupSelectedText={tooltipData.tooltipName}
              showEditIcon={this.showToolTipEditIcon()}
            />
          )}
        </div>
      );
    }

    return <TableauStackWrapperPlaceholder isAdmin={isAdmin} />;
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    selectedSectorDetails:
      reduxStore.headerFilterData.selectedSectorDetailsFromHeader,
    selectedSubSectorDetails:
      reduxStore.headerFilterData.selectedSubSectorDetailsFromHeader,
    selectedSectionDetails: reduxStore.headerFilterData.selectedSectionDetails,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    calenderDropdownVal: reduxStore.headerFilterData.calenderDropdownVal,
    initialDate: reduxStore.headerFilterData.initialDate,
    isManager: reduxStore.userRoleData.isManager,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    selectedSectionDetailOptionValue:
      reduxStore.headerFilterData.selectedSectionDetailOptionValue,
    managedBy: reduxStore.headerFilterData.managedBy,
    path: reduxStore.headerFilterData.path,
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    ktUserDetails: reduxStore.userRoleData.ktUserDetails,
    passGeo: reduxStore.headerFilterData.passGeo,
    tooltipData: reduxStore.ktScorecard.tooltipData,
    isGeoLead: reduxStore.userRoleData.isGeoLead,
    allPaList: reduxStore.headerFilterData.allPaList,
  };
};
export default connect(mapStateToProps)(TableauStackWrapper);
