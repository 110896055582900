import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ImageGraphSkeleton = ({ isImageGraph }) => {
  return isImageGraph ? (
    <>
      <Skeleton width={20} height={80} rectangle duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={20} height={80} rectangle duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={20} height={80} rectangle duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={20} height={80} rectangle duration={1} />
    </>
  ) : null;
};

export default ImageGraphSkeleton;
