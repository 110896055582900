import React, { Component } from 'react';
import { withRouter } from 'react-router';
import FTSizeChart from './FTSizeChart';
import { connect } from 'react-redux';
import { fetchPost } from 'utils/KtCommonServices';
import CONFIG_KTS from 'config/configProps';
import store from 'redux/store';
import {
  updateToastNotificationDetails,
  showTooltipPopup,
} from 'redux/actions';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import FTELoader from './FteLoader';
import Icon from 'components/common/Icons';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { getApiFailMessage } from 'utils';
import { isEqual } from 'utils/common';

class KTFte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartApiData: undefined,
    };
  }

  componentDidMount() {
    if (this.props.tableauParamString) {
      this.getApiData();
    }
  }

  /**
   *
   * React Life cycle method
   */
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { apiRequestData } = this.props;
    if (!isEqual(apiRequestData, prevProps.apiRequestData)) {
      this.getApiData();
    }
  }

  getApiData = () => {
    const { apiRequestData } = this.props;
    let apiIdentifier = API_CALL_IDENTIFIERS.FETCH_FTE_DATA;
    const url = CONFIG_KTS.API_URLS.FTE_DASHBOARD;
    // fetch the logged in user (manager) details
    store.dispatch(beginAPICall(apiIdentifier));
    fetchPost(url, apiRequestData, CONFIG_KTS.KT_USER_BASE_URL)
      .then(result => {
        this.setState({ chartApiData: result.data });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      })
      .finally(() => store.dispatch(endAPICall(apiIdentifier)));
  };

  renderFTEChart = () => {
    const { passGeo } = this.props;
    const { chartApiData } = this.state;

    if (chartApiData) {
      return passGeo ? (
        <FTSizeChart rawData={chartApiData} geoChart={true} />
      ) : (
        <FTSizeChart
          ktLegends={{
            totalKey: 'Total',
            legends: ['TA', 'CF'],
          }}
          extendedLegends={['DRS FTE', 'PA Mgr.', 'Omnia']}
          legendsKeys={['Hires', 'Departures', 'Open Roles', 'LOA']}
          rawData={chartApiData}
        />
      );
    }

    return null;
  };

  render() {
    const { InfoText, apiCallStatus } = this.props;
    let isLoading = apiCallStatus.fetchFteData;
    return (
      <div className={`size-in-fte ${this.props.className}`}>
        <div className="heading">
          <div className="card-heading">
            <div>
              <div className="heading-text">
                Team Size
                {InfoText ? (
                  <div
                    className="tooltip-with-icon"
                    onClick={() => {
                      store.dispatch(
                        showTooltipPopup({
                          showTooltip: true,
                          tooltipName: 'Team Size',
                        })
                      );
                    }}
                  >
                    <Icon
                      name="info-tableau"
                      class="info-icon"
                      alt="infoIcon"
                      src="/img/info-tableau.svg"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="last-updated">
            For newest month in period selected
          </div>
        </div>
        {!isLoading ? (
          <div className="chart-container">{this.renderFTEChart()}</div>
        ) : (
          <FTELoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    apiCallStatus: reduxStore.apiCallsInProgress,
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    passGeo: reduxStore.headerFilterData.passGeo,
  };
};

export default connect(mapStateToProps)(withRouter(KTFte));
