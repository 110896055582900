import React from 'react';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KtKnPlaceholder = props => {
  return (
    <div className="tableau-card-body">
      <KtLabelWithSkeleton isLoading={props.isLoading} showLine size="large" />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLine
        size="xx-large"
      />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLine
        size="xx-large"
      />
      <KtLabelWithSkeleton
        isLoading={props.isLoading}
        showLine
        size="xx-large"
      />
      <br />
      <br />
      {props.section === 'KN Decks' && (
        <>
          <KtLabelWithSkeleton
            isLoading={props.isLoading}
            showLabel
            size="xs"
          />
          <KtLabelWithSkeleton
            isLoading={props.isLoading}
            showLine
            size="xx-large"
          />
          <KtLabelWithSkeleton
            isLoading={props.isLoading}
            showLine
            size="xx-large"
          />
          <br />
          <br />
          <KtLabelWithSkeleton
            isLoading={props.isLoading}
            showLabel
            size="xs"
          />
          <KtLabelWithSkeleton
            isLoading={props.isLoading}
            showLine
            size="xx-large"
          />
          <KtLabelWithSkeleton
            isLoading={props.isLoading}
            showLine
            size="xx-large"
          />
        </>
      )}
    </div>
  );
};
export default KtKnPlaceholder;
