import { SAVE_PEOPLE_DETAILS } from 'redux/actions/actionTypes';

import InitialState from './InitialState';
const STATE_SLICE_NAME = 'peopleByHrId'; // MUST match INITIAL STate key

/**
 * This reducer will return the state-slice for people details
 * . These are pssed along from Dispatcher (Action)
 * @param {*} state state object.
 * @param {*} action action object
 */
export function peopleReducer(
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  if (action.type === SAVE_PEOPLE_DETAILS) {
    if (!state[action.hrId]) {
      const currentStateOfPeople = { ...state };
      currentStateOfPeople[action.hrId] = action.detail;
      return currentStateOfPeople;
    } else return state;
  } else {
    return state;
  }
}

export const selectors = {
  getAllResults: appState => appState[STATE_SLICE_NAME],
  getDetailByHrId: appState => hrid => appState[STATE_SLICE_NAME][hrid],
};
