/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Grid } from 'semantic-ui-react';
import { isEmpty, isEqual, xorBy } from 'utils/common';
import CONFIG, { OPTION_ALL, TEAM_SIZE_METRIC } from 'config/configProps';
import {
  dropdownFormat,
  getEmpIdParam,
  isEmptyOrNull,
  selectedDates,
} from 'utils';
import AdminFilter from '../AdminFilter';
import MemberFilter from './MemberFilter';
import Button from 'components/common/button';
import { updateToastNotificationDetails } from 'redux/actions';
import { updateTableauParamsForBC } from 'redux/actions/tableauStackAction';

class FilterSection extends Component {
  constructor(props) {
    super(props);
    this.timePeriodOptions = CONFIG.TIME_PERIOD_OPTIONS;
    this.state = {
      showPa: false,
      paId: [],
      paIdLength: 0,
      showSector: false,
      paIdAll: false,
      sectorId: [],
      sectorIdAll: false,
      sectorIdLength: 0,
      showSubSector: false,
      subSectorId: [],
      subSectorIdLength: 0,
      subSectorIdAll: false,
      showGeoMarket: false,
      geoRegion: [],
      geoRegionLength: 0,
      geoRegionAll: false,
      showManagedBy: false,
      managedBy: [],
      managedByLength: 0,
      managedByAll: false,
      showPath: false,
      path: [],
      pathLength: 0,
      pathAll: false,
      selectedHierarchy: '',
      showHierarchy: false,
      selectedMetric: '',
      showMetric: false,
      selectedTimePeriod: 'Last 12 Months',
      setDate: new Date(),
      dropdownValue: this.timePeriodOptions,
      showTimePeriod: false,
      showMemberCollection: false,
      collectionName: '',
      selectedMembers: [],
      selectedMembersLength: 0,
      selectedMembersAll: false,
      dataObject: this.props.dataObject,
    };
    this.paContainer = React.createRef();
    this.sectorContainer = React.createRef();
    this.subSectorContainer = React.createRef();
    this.geoMarketContainer = React.createRef();
    this.managedByContainer = React.createRef();
    this.pathContainer = React.createRef();
    this.hierarchyContainer = React.createRef();
    this.metricContainer = React.createRef();
    this.timePeriodContainer = React.createRef();
    this.memberCollectionContainer = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutsideForPaList);
    document.addEventListener('click', this.handleClickOutsideForSectorList);
    document.addEventListener('click', this.handleClickOutsideForSubSectorList);
    document.addEventListener('click', this.handleClickOutsideForGeoMarketList);
    document.addEventListener('click', this.handleClickOutsideForManagedByList);
    document.addEventListener('click', this.handleClickOutsideForPathList);
    document.addEventListener('click', this.handleClickOutsideForHierarchyList);
    document.addEventListener('click', this.handleClickOutsideForMetricList);
    document.addEventListener('click', this.handleClickOutsideForTimePeriod);
    document.addEventListener(
      'click',
      this.handleClickOutsideForMemberCollection
    );
    this.onLoadChange(this.state.setDate, {}, true);
  };

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutsideForPaList);
    document.removeEventListener('click', this.handleClickOutsideForSectorList);
    document.removeEventListener(
      'click',
      this.handleClickOutsideForSubSectorList
    );
    document.removeEventListener(
      'click',
      this.handleClickOutsideForGeoMarketList
    );
    document.removeEventListener(
      'click',
      this.handleClickOutsideForManagedByList
    );
    document.removeEventListener('click', this.handleClickOutsideForPathList);
    document.removeEventListener(
      'click',
      this.handleClickOutsideForHierarchyList
    );
    document.removeEventListener('click', this.handleClickOutsideForMetricList);
    document.removeEventListener('click', this.handleClickOutsideForTimePeriod);
    document.removeEventListener(
      'click',
      this.handleClickOutsideForMemberCollection
    );
  };

  closeNotification = async () => {
    const notificationDetailsObj = this.props.toastNotificationDetails;
    notificationDetailsObj.showToastNotification = false;
    await this.props.dispatch(
      updateToastNotificationDetails(notificationDetailsObj)
    );
  };

  showNotification = async message => {
    const notificationDetailsObj = {
      showToastNotification: true,
      notificationHeader: 'Error',
      notificationBody: message,
      notificationClassName: 'custom-notification',
    };
    await this.props.dispatch(
      updateToastNotificationDetails(notificationDetailsObj)
    );
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForPaList = event => {
    if (
      this.paContainer.current &&
      !this.paContainer.current.contains(event.target)
    ) {
      this.setState({ showPa: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForSectorList = event => {
    if (
      this.sectorContainer.current &&
      !this.sectorContainer.current.contains(event.target)
    ) {
      this.setState({ showSector: false });
    }
  };
  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForSubSectorList = event => {
    if (
      this.subSectorContainer.current &&
      !this.subSectorContainer.current.contains(event.target)
    ) {
      this.setState({ showSubSector: false });
    }
  };
  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForGeoMarketList = event => {
    if (
      this.geoMarketContainer.current &&
      !this.geoMarketContainer.current.contains(event.target)
    ) {
      this.setState({ showGeoMarket: false });
    }
  };
  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForManagedByList = event => {
    if (
      this.managedByContainer.current &&
      !this.managedByContainer.current.contains(event.target)
    ) {
      this.setState({ showManagedBy: false });
    }
  };
  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForPathList = event => {
    if (
      this.pathContainer.current &&
      !this.pathContainer.current.contains(event.target)
    ) {
      this.setState({ showPath: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForHierarchyList = event => {
    if (
      this.hierarchyContainer.current &&
      !this.hierarchyContainer.current.contains(event.target)
    ) {
      this.setState({ showHierarchy: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForMetricList = event => {
    if (
      this.metricContainer.current &&
      !this.metricContainer.current.contains(event.target)
    ) {
      this.setState({ showMetric: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForTimePeriod = event => {
    if (
      this.timePeriodContainer.current &&
      !this.timePeriodContainer.current.contains(event.target)
    ) {
      this.setState({ showTimePeriod: false });
    }
  };

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForMemberCollection = event => {
    if (
      this.memberCollectionContainer.current &&
      !this.memberCollectionContainer.current.contains(event.target)
    ) {
      this.setState({ showMemberCollection: false });
    }
  };

  quarterOfTheYear = date => {
    let month = date.getMonth() + 1;
    return Math.ceil(month / 3);
  };

  onLoadChange = (date, testData, initialLoad) => {
    const { selectedTimePeriod } = this.state;
    const defaultTimePeriod = initialLoad
      ? selectedTimePeriod
      : 'Last 12 Months';
    if (!moment(date).isValid()) {
      return false;
    }
    let quarterVal = this.quarterOfTheYear(date);
    this.setState({
      setDate: date,
      dropdownValue: [
        ...this.timePeriodOptions,
        `Quarter ${quarterVal}`,
        moment(date).format('MMMM YYYY'),
        ...CONFIG.TIME_PERIOD_MONTH,
      ],
      selectedTimePeriod: defaultTimePeriod,
    });
  };

  getDataValue = (data, field) => {
    switch (field) {
      case 'PA':
        return data?.map(({ paId, practiceArea }) =>
          dropdownFormat(
            '' + paId,
            CONFIG.PA_NAME_ALIAS_MAP[practiceArea] || practiceArea
          )
        );
      case 'Topic/Sector': {
        if (isEmpty(this.state.paId)) return [];

        return data?.map(({ topicSectorId, sectorWithPaCode }) =>
          dropdownFormat('' + topicSectorId, sectorWithPaCode)
        );
      }
      case 'Sub-Topic/Segment': {
        if (isEmpty(this.state.paId)) return [];

        return data?.map(({ subSectorId, subSectorwithPACode }) =>
          dropdownFormat('' + subSectorId, subSectorwithPACode)
        );
      }
      case 'Managed By':
        return data?.map(item =>
          dropdownFormat(
            item,
            item === 'PA & Geo Managed' ? 'Co-Managed' : item
          )
        );
      case 'Geo Market':
      case 'Path':
      case 'Time Period':
        return data?.map(el => dropdownFormat('' + el, el));
      default:
        return data;
    }
  };

  showPa = () => {
    this.setState({ showPa: !this.state.showPa });
  };

  showSector = () => {
    this.setState({ showSector: !this.state.showSector });
  };

  showSubSector = () => {
    this.setState({ showSubSector: !this.state.showSubSector });
  };

  showGeoMarket = () => {
    this.setState({ showGeoMarket: !this.state.showGeoMarket });
  };

  showManagedBy = () => {
    this.setState({ showManagedBy: !this.state.showManagedBy });
  };

  showPath = () => {
    this.setState({ showPath: !this.state.showPath });
  };

  showHierarchy = () => {
    this.setState({ showHierarchy: !this.state.showHierarchy });
  };

  showMetric = () => {
    this.setState({ showMetric: !this.state.showMetric });
  };

  showTimePeriod = () => {
    this.setState({ showTimePeriod: !this.state.showTimePeriod });
  };

  toggleMemberCollection = () => {
    const { selectedMetric } = this.state;
    if (isEqual(selectedMetric, 'Billability'))
      this.setState({ showMemberCollection: !this.state.showMemberCollection });
    else this.setState({ showMemberCollection: false });
  };

  resetState = (state, stateField = []) => {
    stateField.forEach(field => {
      state[field] = [];
      state[`${field}All`] = false;
      state[`${field}Length`] = 0;
    });
    return state;
  };

  handleFilterChanges = (stateField, newValue, length) => {
    let newState = { ...this.state };
    newState[stateField] = newValue;
    newState[`${stateField}Length`] = length;
    newState[`${stateField}All`] = newValue.includes('-1');
    if (stateField !== 'selectedMembers') {
      let stateToReset = [];
      stateToReset = ['selectedMembers'];
      if (stateField === 'paId') {
        stateToReset = ['selectedMembers', 'sectorId', 'subSectorId'];
      } else if (stateField === 'sectorId') {
        stateToReset = ['selectedMembers', 'subSectorId'];
      }
      newState = this.resetState({ ...newState }, stateToReset);
    }
    this.setState(
      { ...newState, collectionName: '', showMemberCollection: false },
      () => {
        this.filterData(newState, stateField);
        if (isEqual(stateField, 'selectedMembers'))
          this.handleApplyFilterClick();
      }
    );
  };

  filterData = (state, stateField) => {
    let dataObj = {};
    dataObj = {
      paId: isEqual(stateField, '-1') ? [] : state.paId,
      sectorId: isEqual(stateField, '-1') ? [] : state.sectorId,
      subSectorId: isEqual(stateField, '-1') ? [] : state.subSectorId,
      geoRegion: state.geoRegion,
      managedBy: state.managedBy,
      path: state.path,
    };
    this.setState({ dataObject: dataObj });
    this.props.onSelectionChanges(dataObj, stateField);
  };

  handleRadioFilterChanges = (stateField, newValue) => {
    const newState = { ...this.state };
    newState[stateField] = newValue;
    this.setState({ ...newState });
  };

  handleClearFilterClick = () => {
    this.setState(
      {
        paId: [],
        paIdAll: false,
        paIdLength: 0,
        sectorId: [],
        sectorIdAll: false,
        sectorIdLength: 0,
        subSectorId: [],
        subSectorIdAll: false,
        subSectorIdLength: 0,
        geoRegion: [],
        geoRegionAll: false,
        geoRegionLength: 0,
        path: [],
        pathAll: false,
        pathLength: 0,
        managedBy: [],
        managedByAll: false,
        managedByLength: 0,
        selectedMetric: '',
        selectedHierarchy: '',
        selectedTimePeriod: 'Last 12 Months',
        collectionName: '',
        setDate: new Date(),
        selectedMembers: [],
        selectedMembersAll: false,
        selectedMembersLength: 0,
      },
      () => {
        this.filterData({}, 'paId');
        this.props.dispatch(updateTableauParamsForBC({}));
      }
    );
  };

  updateFilter = appliedCollection => {
    const {
      paId,
      sectorId,
      subSectorId,
      geoRegion,
      hierarchy,
      metricType,
      selectedUserId,
      managedBy,
      path,
    } = appliedCollection.userFilterDTO;

    const { geoMarketList, managedByList, pathList } = this.props;
    const filterIds = (allList, selectedList, idField) => {
      let filteredList =
        selectedList?.filter(selected =>
          allList?.find(item => {
            const value = idField ? item[idField] : item;
            return value.toString() === selected.toString();
          })
        ) || [];

      if (filteredList?.length === allList?.length) {
        filteredList.unshift('-1');
      }

      return filteredList;
    };
    const newState = {
      ...this.state,
      collectionName: appliedCollection.name,
      paId: paId.map(id => id.toString()),
      sectorId: sectorId.map(id => id.toString()),
      subSectorId: subSectorId.map(id => id.toString()),
      geoRegion: filterIds(geoMarketList?.data, geoRegion),
      managedBy: filterIds(managedByList?.data, managedBy),
      path: filterIds(pathList?.data, path),
      selectedHierarchy: hierarchy,
      selectedMetric: metricType,
      selectedMembers: selectedUserId,
      showMemberCollection: false,
    };
    [
      'paId',
      'sectorId',
      'subSectorId',
      'geoRegion',
      'managedBy',
      'path',
      'selectedMembers',
    ].forEach(field => {
      newState[`${field}Length`] = newState[field]?.length;
      newState[`${field}All`] = newState[field]?.includes('-1');
    });

    const dataObj = {
      paId: newState.paId,
      sectorId: newState.sectorId,
      subSectorId: newState.subSectorId,
      geoRegion: newState.geoRegion,
      managedBy: newState.managedBy,
      path: newState.path,
    };
    newState['dataObject'] = dataObj;
    this.setState(newState, () => {
      this.props.onSelectionChanges(dataObj, 'paId', true);
      this.handleApplyFilterClick();
    });
  };

  handleApplyFilterClick = () => {
    const { selectedMetric, selectedHierarchy, paId, sectorId, subSectorId } =
      this.state;
    const { paList, sectorList, subSectorList } = this.props;
    if (isEmpty(selectedHierarchy)) {
      this.showNotification('Please select Starting Axis');
      return;
    } else if (isEmpty(selectedMetric)) {
      this.showNotification('Please select a Metric');
      return;
    }
    this.closeNotification();
    const paIdList =
      paList?.data?.filter(item => paId?.includes(item.paId.toString())) || [];
    let sectorIdList =
      sectorList?.data?.filter(item =>
        sectorId?.includes(item.topicSectorId.toString())
      ) || [];
    let paNames = [];
    let sectorNames = [];
    let subSectorNames = [];
    paIdList.forEach(el => el.paCode && paNames.push(el.paCode));
    if (!isEmpty(sectorIdList)) {
      const distPaWithSector = xorBy(paIdList, sectorIdList, 'paId');
      sectorIdList.forEach(el => {
        el.topicSectorCode &&
          sectorNames.push(`${el.paCode}-${el.topicSectorCode}`);
      });
      distPaWithSector.forEach(el => sectorNames.push(`${el.paCode}-All`));
    }
    const subSectorIdList = subSectorList?.data?.filter(item =>
      subSectorId?.includes(item.subSectorId.toString())
    );
    if (!isEmpty(subSectorIdList)) {
      subSectorIdList.forEach(
        el =>
          el.subTopicSectorCode &&
          subSectorNames.push(`${el.paCode}-${el.subTopicSectorCode}`)
      );
      const distSubSector = xorBy(paIdList, subSectorIdList, 'paId');
      let uniquePa = [];
      distSubSector.forEach(el => {
        uniquePa.indexOf(el.paCode) < 0 && uniquePa.push(el.paCode);
      });
      uniquePa.forEach(el => {
        subSectorNames.push(`${el}-All`);
      });
    }
    this.passTableauString(paNames, sectorNames, subSectorNames);
  };

  passTableauString = (paNames, sectorNames, subSectorNames) => {
    const {
      geoRegion,
      managedBy,
      path,
      selectedMetric,
      selectedHierarchy,
      paIdAll,
      sectorIdAll,
      subSectorIdAll,
      geoRegionAll,
      pathAll,
      managedByAll,
      setDate,
      selectedTimePeriod,
      selectedMembers,
      selectedMembersAll,
    } = this.state;
    const dateSelected = selectedDates(new Date(), setDate, selectedTimePeriod);
    const startDate = dateSelected.startDate;
    const endDate = dateSelected.endDate;
    const getGeoRegion = () => {
      let returnGeoRegion = '';
      if (selectedHierarchy === 'PA KT Axis') {
        return returnGeoRegion;
      }

      returnGeoRegion =
        !isEmpty(geoRegion) && !geoRegionAll ? geoRegion : OPTION_ALL;

      return returnGeoRegion;
    };
    const data = {
      pa: !isEmpty(paNames) && !paIdAll ? paNames : 'All',
      sector: !isEmpty(sectorNames) && !sectorIdAll ? sectorNames : 'All',
      subSector:
        !isEmpty(subSectorNames) && !subSectorIdAll ? subSectorNames : 'All',
      startDate,
      endDate,
      id: isEqual(selectedMetric, 'Billability')
        ? getEmpIdParam({
            selectedTeamMembers: selectedMembers,
            loadAllTeamMember: selectedMembersAll,
            passSelectedIDs: true,
          })
        : '',
      path: !isEmpty(path) && !pathAll ? path : 'All',
      geoRegion: getGeoRegion(),
      managedBy: !isEmpty(managedBy) && !managedByAll ? managedBy : 'All',
    };
    // PA Value|Sector Value|Sub Sector Value|StartDate|EndDate|id|path|geoRegion|managedBy
    const paramString = Object.values(data).join('|');
    this.props.dispatch(
      updateTableauParamsForBC({
        paramString,
        selectedMetric,
        selectedHierarchy,
      })
    );
  };

  datePickerInput = ({ value, onClick }, ref) => {
    const handleOnClick = e => {
      e.stopPropagation();
      this.setState({ showTimePeriod: false });
      onClick(e);
    };
    return (
      <button
        className="example-custom-input"
        onClick={handleOnClick}
        ref={ref}
      >
        {value}
      </button>
    );
  };

  onCollectionDelete = () => {
    this.setState({
      showMemberCollection: false,
    });
  };

  renderFilter = ({
    type,
    showFilter,
    dataList,
    selectedList,
    selectedListLength,
    onChange,
    stateField,
    isAllSelected,
    showAll,
    elementRef,
    onClick,
    paList,
    singleSelect,
    selectedId,
  }) => {
    return (
      <div className="business-comparison-filter-dropdown" ref={elementRef}>
        <p onClick={onClick} className="select-filter">
          <span className="selection-text-2">
            {singleSelect && selectedId}
            {!singleSelect && isAllSelected
              ? 'All '
              : Boolean(selectedListLength) && `(${selectedListLength}) `}
            {!selectedId && type}
          </span>
          <img
            src="/img/business-arrow.svg"
            className={`kt-admin-arrow ${
              showFilter ? 'transform-90' : 'transform90'
            }`}
            alt=""
          />
        </p>
        {showFilter && (
          <AdminFilter
            {...{
              dataList,
              onChange,
              selectedList,
              stateField,
              showAll,
              hideApply: true,
              paList,
              singleSelect,
              selectedId,
            }}
          />
        )}
      </div>
    );
  };

  getDisplayName = () => {
    const { selectedMembers, selectedMembersAll, collectionName } = this.state;
    let displayName = 'Member/ Collection';
    if (collectionName) displayName = collectionName;
    else if (selectedMembersAll) displayName = 'All';
    else if (selectedMembers.length)
      displayName = `(${selectedMembers?.length || 0})Members`;
    return displayName;
  };

  showError = () => {
    this.setState({ showMemberCollection: false });
    this.showNotification('Please select Starting Axis');
    return;
  };

  render() {
    const {
      paList,
      sectorList,
      subSectorList,
      geoMarketList,
      managedByList,
      pathList,
      memberList,
    } = this.props;
    const {
      showPa,
      paIdLength,
      paId,
      showSector,
      sectorId,
      sectorIdLength,
      showSubSector,
      subSectorId,
      subSectorIdLength,
      geoRegion,
      geoRegionLength,
      showGeoMarket,
      showManagedBy,
      managedBy,
      managedByLength,
      showPath,
      path,
      pathLength,
      selectedHierarchy,
      showHierarchy,
      selectedMetric,
      showMetric,
      paIdAll,
      sectorIdAll,
      subSectorIdAll,
      geoRegionAll,
      pathAll,
      managedByAll,
      dropdownValue,
      setDate,
      selectedTimePeriod,
      showTimePeriod,
      showMemberCollection,
      dataObject,
    } = this.state;
    const CustomDatePickerInput = React.forwardRef(this.datePickerInput);
    const showTimePeriodFilter = selectedMetric !== TEAM_SIZE_METRIC;
    return (
      <div className="business-comparison-header">
        <Grid className="basicFilter_container">
          <Grid.Row className="required-metric-row">
            <div className="required-fields">Required</div>
            <Grid.Column>
              {this.renderFilter({
                type: 'Starting Axis',
                elementRef: this.hierarchyContainer,
                onClick: this.showHierarchy.bind(this),
                showFilter: showHierarchy,
                dataList: CONFIG.HIERARCHY_LIST,
                onChange: this.handleRadioFilterChanges,
                selectedId: selectedHierarchy,
                stateField: 'selectedHierarchy',
                singleSelect: true,
              })}
            </Grid.Column>
            <Grid.Column>
              {this.renderFilter({
                type: 'Metric',
                elementRef: this.metricContainer,
                onClick: this.showMetric.bind(this),
                showFilter: showMetric,
                dataList: CONFIG.METRIC_LIST,
                onChange: this.handleRadioFilterChanges,
                selectedId: selectedMetric,
                stateField: 'selectedMetric',
                singleSelect: true,
              })}
            </Grid.Column>
            <Grid.Column className="action-buttons-container">
              <Button
                primary
                onClick={this.handleClearFilterClick}
                className="clear"
              >
                Clear
              </Button>
              <Button
                primary
                onClick={this.handleApplyFilterClick}
                className="apply"
                disabled={!(selectedHierarchy && selectedMetric)}
              >
                Apply
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="filter-row">
            <Grid.Column>
              {this.renderFilter({
                type: 'PA',
                elementRef: this.paContainer,
                onClick: this.showPa.bind(this),
                showFilter: showPa,
                dataList: this.getDataValue(paList.data, 'PA'),
                onChange: this.handleFilterChanges,
                selectedList: paId,
                selectedListLength: paIdLength,
                stateField: 'paId',
                isAllSelected: paIdAll,
                showAll: true,
                paList: paList.data,
              })}
            </Grid.Column>
            <Grid.Column>
              {this.renderFilter({
                type: 'Topic/Sector',
                elementRef: this.sectorContainer,
                onClick: this.showSector.bind(this),
                showFilter: showSector,
                dataList: this.getDataValue(sectorList.data, 'Topic/Sector'),
                onChange: this.handleFilterChanges,
                selectedList: sectorId,
                selectedListLength: sectorIdLength,
                stateField: 'sectorId',
                isAllSelected: sectorIdAll,
                showAll: true,
              })}
            </Grid.Column>
            <Grid.Column>
              {this.renderFilter({
                type: 'Sub-Topic/Segment',
                elementRef: this.subSectorContainer,
                onClick: this.showSubSector.bind(this),
                showFilter: showSubSector,
                dataList: this.getDataValue(
                  subSectorList.data,
                  'Sub-Topic/Segment'
                ),
                onChange: this.handleFilterChanges,
                selectedList: subSectorId,
                selectedListLength: subSectorIdLength,
                stateField: 'subSectorId',
                isAllSelected: subSectorIdAll,
                showAll: true,
              })}
            </Grid.Column>
            <Grid.Column>
              {this.renderFilter({
                type: 'Geo Market',
                elementRef: this.geoMarketContainer,
                onClick: this.showGeoMarket.bind(this),
                showFilter: showGeoMarket,
                dataList: this.getDataValue(geoMarketList.data, 'Geo Market'),
                onChange: this.handleFilterChanges,
                selectedList: geoRegion,
                selectedListLength: geoRegionLength,
                stateField: 'geoRegion',
                isAllSelected: geoRegionAll,
                showAll: true,
              })}
            </Grid.Column>
            <Grid.Column>
              {this.renderFilter({
                type: 'Managed By',
                elementRef: this.managedByContainer,
                onClick: this.showManagedBy.bind(this),
                showFilter: showManagedBy,
                dataList: this.getDataValue(managedByList.data, 'Managed By'),
                onChange: this.handleFilterChanges,
                selectedList: managedBy,
                selectedListLength: managedByLength,
                stateField: 'managedBy',
                isAllSelected: managedByAll,
                showAll: true,
              })}
            </Grid.Column>
            <Grid.Column>
              {this.renderFilter({
                type: 'Path',
                elementRef: this.pathContainer,
                onClick: this.showPath.bind(this),
                showFilter: showPath,
                dataList: this.getDataValue(pathList.data, 'Path'),
                onChange: this.handleFilterChanges,
                selectedList: path,
                selectedListLength: pathLength,
                stateField: 'path',
                isAllSelected: pathAll,
                showAll: true,
              })}
            </Grid.Column>
            <Grid.Column>
              <div
                className="business-comparison-filter-dropdown"
                ref={this.memberCollectionContainer}
              >
                <p
                  onClick={this.toggleMemberCollection.bind(this)}
                  className="select-filter"
                >
                  <span className="selection-text-2">
                    {this.getDisplayName()}
                  </span>
                  <img
                    src="/img/business-arrow.svg"
                    className={`kt-admin-arrow ${
                      showMemberCollection ? 'transform-90' : 'transform90'
                    }`}
                    alt=""
                  />
                </p>
                {showMemberCollection && !memberList.isLoading ? (
                  <MemberFilter
                    onChange={this.handleFilterChanges}
                    selectedUsers={[]}
                    saveCollection={true}
                    memberList={memberList.data}
                    isLoading={Boolean(memberList.isLoading)}
                    filterDto={{
                      ...dataObject,
                      hierarchy: !isEmptyOrNull(selectedHierarchy)
                        ? isEqual(selectedHierarchy, 'PA KT Axis')
                          ? 'PA'
                          : 'geo'
                        : selectedHierarchy,
                      metricType: 'Billability',
                    }}
                    showError={this.showError}
                    updateFilter={this.updateFilter}
                    onCollectionDelete={this.onCollectionDelete}
                  />
                ) : null}
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                className="business-comparison-filter-dropdown"
                ref={this.timePeriodContainer}
              >
                <div className="select-filter date-picker-container">
                  <span className="mR10" onClick={e => e.stopPropagation()}>
                    <DatePicker
                      selected={setDate}
                      minDate={moment().subtract(2, 'years').toDate()}
                      maxDate={new Date()}
                      onChange={this.onLoadChange}
                      dateFormat="MMMM yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      showMonthYearPicker
                      onKeyDown={e => e.preventDefault()}
                      customInput={<CustomDatePickerInput />}
                    />
                  </span>
                  {showTimePeriodFilter && (
                    <span onClick={this.showTimePeriod.bind(this)}>
                      <span className="selection-text-2">
                        {isEmpty(selectedTimePeriod)
                          ? 'Time Period'
                          : `${selectedTimePeriod}`}
                      </span>
                      <img
                        src="/img/business-arrow.svg"
                        className={`kt-admin-arrow ${
                          showTimePeriod ? 'transform-90' : 'transform90'
                        }`}
                        alt=""
                      />
                    </span>
                  )}
                </div>
                {showTimePeriod && showTimePeriodFilter && (
                  <AdminFilter
                    dataList={this.getDataValue(dropdownValue, 'Time Period')}
                    onChange={this.handleRadioFilterChanges}
                    selectedId={selectedTimePeriod || ''}
                    stateField="selectedTimePeriod"
                    singleSelect={true}
                    showAll={false}
                    hideApply={true}
                  />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    toastNotificationDetails: state.ktScorecard.toastNotificationDetails,
  };
};

export default connect(mapStateToProps)(withRouter(FilterSection));
