/**
 * The INITIAL State of the Application
 */
const InitialState = {
  /**
   *  this key is responsible for storing the API calls in progress at the moment.The data is saved as
   * {"apiIdentifier" : <countOfActiveCalls>, ... }
   */
  apiCallsInProgress: {},

  /**
   * this key is responsible for storing the Case Details for the specific Case Number the user requested for.
   */
  loggedInUserDetails: { userDetails: {}, error: '' },
  /**
   * this key is responsible for storing the people information with HrId as a Key
   */
  peopleByHrId: {},
  /**
   * this key is responsible for storing the Practice Areas Assigned to a project
   */
  assignedPracticeAreas: {},
  /**
   * key for Storing Application Notifications
   */
  appNotifications: {
    /**
     * object with key as notificationId
     *
     *  example: notificationId:{ message,timestamp, notificationType,group}
     */
  },
  actionItems: [],
  peopleImage: {},
  /**
   * this key is responsible for saving the Seed Data for Filters
   * - In most cases data is stored as array of {value:'',label:''}
   */
  filterData: {
    paList: { results: {}, error: '' },
    sectorList: { results: {}, error: '' },
    subSectorList: { results: {}, error: '' },
    geoMarketList: { results: {}, error: '' },
    memberList: { results: {}, error: '' },
    pathList: { results: {}, error: '' },
  },
  /**
   * this key is responsible for saving the Seed Data for Filters
   * - In most cases data is stored as array of {value:'',label:''}
   */
  filterDataPt: {
    lastUpdatedDate: '',
    paList: { results: {}, error: '' },
    geoMarketList: { results: {}, error: '' },
    memberList: { results: {}, error: '' },
    pathList: { results: {}, error: '' },
    filters: {},
    isFilterOpen: true,
    apiData: {},
  },

  /**
   * this key is responsible for saving the Seed Data for KT HomePage
   * - In most cases data is stored as array of {value:'',label:''}
   */
  homePageData: {},

  apiData: {},

  /**
   * this key is responsible for saving the general data required by all places
   */
  ktScorecard: {
    userHrId: '',
    deckData: [],
    setDateHeader3: '',
    dropdownGlobalHeaderValue: 'Team',
    dropdownMemberGlobalHeaderValue: '',
    backToListView: false,
    toastNotificationDetails: {
      showToastNotification: false,
      notificationHeader: 'Error',
      notificationBody: 'Something Went Wrong',
      notificationClassName: 'custom-notification',
    },
    closeGrantModal: false,
    showKTScorecardPageContent: false,
    isAdminButtonClicked: true,
    checkBrowser: false,
    collectionData: {},
    displayName: 'All',
    iconInformation: {},
    impersonatorList: [],
    memberDetails: {},
    geoRegionList: [],
    fetchImpersonatorList: false,
    tooltipData: {},
    isMenuOpen: true,
    headerHeight: 0,
    sidebarPathName: '',
  },

  /**
   * this key is responsible for saving the filter data and slected options in the filter
   */
  headerFilterData: {
    calenderDropdownVal: '',
    initialDate: new Date(),
    selectedSectionDetails: {},
    selectedSectionDetailOptionValue: '',
    selectedSectorDetailsFromHeader: {},
    selectedSubSectorDetailsFromHeader: {},
    managedBy: [],
    path: 'All',
    selectedTeamMembers: [],
    memberList: [],
    isAllSelected: true,
    defaultMemberList: [],
    ktSaPaList: undefined,
    ktPaPaList: undefined,
    allPaList: undefined,
    geoMarketList: undefined,
    filters: {},
    isCollectionApplied: false,
    passGeo: false,
    paList: {},
    sectorList: {},
    subSectorList: {},
  },

  /**
   * this key is responsible for saving the dashboard graph card data and deep dive view data
   */
  tableauStackData: {
    totalRecords: 0,
    apiRequestData: {},
    loadHomeEditPage: false,
    tableauStringData: {},
    tableauParamString: '',
    tableauParamsForBC: {},
  },

  /**
   * this key is responsible for saving the user role related data
   */
  userRoleData: {
    employeeInitialData: {},
    loggedInEmployeeData: {},
    loggedInKtUserDetails: {},
    ktUserDetails: {},
    userTypes: [],
    userTypeData: [],
    userHighestRole: undefined, // don't change this value
    loggedInUserHighestRole: undefined, // don't change this value
    isGeoManager: false,
    isGeoManaged: false,
    isGeoLead: false,
    isManager: false,
    haveAccessForKTFromOkta: false,
  },

  /**
   * this key is responsible for saving the self-service portal related data
   */
  selfServiceData: {
    userList: [],
  },

  /**
   * this key is responsible for saving the adobe analytics related data
   */
  adobeData: {
    adobeAnalyticsData: {},
  },
};

export default InitialState;
