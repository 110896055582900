import CONFIG from 'config/configProps';
import { fetchPost } from 'utils/KtCommonServices';

export const getUserList =
  ({
    practiceAreaId = [],
    roleId = [],
    department = ['KT'],
    selectedManagedBy = [],
    geoRegion = [],
  } = {}) =>
  () => {
    const dataToBePost = {
      practiceAreaId,
      roleId,
      department,
      selectedManagedBy,
      geoRegion,
    };
    const userDetailsUrl = CONFIG.API_URLS.GET_USER_DETAILS;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    return fetchPost(userDetailsUrl, dataToBePost, baseUrl).then(response => {
      return response?.data;
    });
  };
