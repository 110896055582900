import { combineReducers } from 'redux';
import ktScorecardReducer from './ktScorecardReducer';
import headerFilterReducer from './headerFilterReducer';
import tableauStackReducer from './tableauStackReducer';
import userRoleReducer from './userRoleReducer';
import selfServiceReducer from './selfServiceReducer';
import {
  apiCallStatusReducer,
  selectors as apiCallStatusSelectors,
} from './apiCallStatusReducer';

import { peopleReducer, selectors as PeopleSelector } from './peopleReducer';
import {
  filterSectionReducer,
  selectors as filterSectionSelectors,
} from './filterSectionReducer';
import {
  PeopleImagesReducer,
  selectors as PeopleImageSelector,
} from './peopleImagesReducer';
import {
  filterSectionReducerPt,
  selectors as filterSectionSelectorsPt,
} from './filterSectionReducerPt';

import { ktHomeReducer, selectors as ktHomeSelectors } from './ktHomeReducer';
import { AdobeAnalyticsReducer } from './adobeAnalyticsReducer';
import { apiSectionReducer, selectors as apiSelector } from './apiReducers';

export const reducers = combineReducers({
  ktScorecard: ktScorecardReducer,
  apiCallsInProgress: apiCallStatusReducer,
  peopleByHrId: peopleReducer,
  peopleImage: PeopleImagesReducer,
  filterData: filterSectionReducer,
  headerFilterData: headerFilterReducer,
  tableauStackData: tableauStackReducer,
  userRoleData: userRoleReducer,
  selfServiceData: selfServiceReducer,
  filterDataPt: filterSectionReducerPt,
  homePageData: ktHomeReducer,
  adobeData: AdobeAnalyticsReducer,
  apiData: apiSectionReducer,
});

export const selectors = {
  apiCallStatusSelectors,
  PeopleSelector,
  PeopleImageSelector,
  filterSectionSelectors,
  filterSectionSelectorsPt,
  ktHomeSelectors,
  apiSelector,
};
