import React, { Component } from 'react';

class LineInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      blur: false,
    };
  }

  render() {
    const {
      handleFocus,
      handleBlur,
      handleChange,
      handleKeyDownChange,
      handleClick,
      tab,
      max,
      isReadyOnly,
      ...restProps
    } = this.props;
    return (
      <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDownChange}
        onClick={handleClick}
        tabIndex={tab}
        maxLength={max}
        autoFocus={tab === 1}
        readOnly={isReadyOnly}
        {...restProps}
        autoComplete="Off"
      />
    );
  }
}

export default LineInput;
