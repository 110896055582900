import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CONFIG from 'config/configProps';
import './KTDateRangePicker.scss';
import { formatSelectedDate } from 'utils/common';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import Icon from 'components/common/icon';
import classnames from 'classnames';

export default class KTDateRangePicker extends Component {
  static propTypes = {
    onDateChange: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(props.filterValue),
      inputValue: formatSelectedDate(
        props.filterValue,
        CONFIG.DATE_TIME_FORMAT.CUSTOM_DATE_TIME
      ),
      showMonthCalender: false,
    };
    this.calenderRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideForCalender);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      value: new Date(nextProps.filterValue),
      inputValue: formatSelectedDate(
        nextProps.filterValue,
        CONFIG.DATE_TIME_FORMAT.CUSTOM_DATE_TIME
      ),
    };
  }

  /**
   *
   * React Life cycle method
   */
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideForCalender);
  }

  /**
   *
   * This method update the state when user click on outside the drop down
   */
  handleClickOutsideForCalender = event => {
    if (
      this.calenderRef.current &&
      !this.calenderRef.current.contains(event.target)
    ) {
      this.setState({
        showMonthCalender: false,
      });
    }
  };

  onDateSelectionChange = selectedDate => {
    event.stopImmediatePropagation();
    if (!selectedDate) {
      this.setState({ value: '' });
      this.props.onDateChange('', '');
    } else {
      const formattedDate = formatSelectedDate(
        selectedDate,
        CONFIG.DATE_TIME_FORMAT.CUSTOM_DATE_TIME
      );
      this.setState(
        {
          value: selectedDate,
          inputValue: formattedDate,
          showMonthCalender: false,
        },
        () => this.props.onDateChange(selectedDate, this.props.name)
      );
    }
  };

  handleCalenderClick = () => {
    this.setState({ showMonthCalender: !this.state.showMonthCalender });
  };

  render() {
    const { filterTitle, containerClass, testId, isError, displayError } =
      this.props;
    const { value, inputValue, showMonthCalender } = this.state;
    const minimumDate = moment().subtract(2, 'year');
    const maximumDate = new Date();
    return (
      <div
        className={`dateRangeContainer ${
          containerClass ? containerClass : ''
        } ${isError ? '' : 'marginBottom10'}`}
        data-testid={testId}
        ref={this.calenderRef}
      >
        <div className="title">{filterTitle}</div>
        <div
          className={`${displayError ? 'errorMessage' : ''} filterContainer`}
          onClick={() => this.handleCalenderClick()}
        >
          <div className="value">{inputValue}</div>
          <Icon
            className={classnames(['iconDate'])}
            name="calendar alternate outline"
            data-testid="change-date"
            customClassName={showMonthCalender ? 'active' : ''}
          />
        </div>
        {showMonthCalender && (
          <div className="calendarContainer">
            <Calendar
              calendarType="ISO 8601"
              minDate={moment(minimumDate).toDate()}
              returnValue={'start'}
              selectRange={false}
              showNeighboringMonth
              value={value}
              view="year"
              formatMonth={(locale, date) => formatSelectedDate(date, 'MMM')}
              maxDate={moment(maximumDate).toDate()}
              data-testid="calender-container"
              tileClassName={({ date }) => {
                const formattedDate = formatSelectedDate(
                  date,
                  CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_API
                );
                if (formattedDate === value) {
                  return 'highlight';
                }
              }}
              onClickMonth={this.onDateSelectionChange}
            />
          </div>
        )}
      </div>
    );
  }
}
