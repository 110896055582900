const data = {
  productName: '',
  tabName: '',
  page: {
    pageInfo: {
      pageName: '',
    },
    headerInfo: {
      staffSystemID: '',
    },
  },
  filter: {
    filterCategory: '',
    filterName: '',
  },
  button: {
    buttonName: '',
  },
};
window.digitalData = data;
export default window.digitalData;
