import React, { Component } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import AppliedFilterLabel from 'components/common/AppliedFilterLabel';
import PropTypes from 'prop-types';
import { isEmptyOrNull } from 'utils';
import CONFIG from 'config/configProps';
import Icon from 'components/common/icon';
import CustomCalendar from '../customCalender';
import './CalendarDateRange.scss';
import { formatSelectedDate } from 'utils/common';
export default class CalendarDateRange extends Component {
  static propTypes = {
    // filterTitle: PropTypes.string.isRequired,
    onDateChange: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      value: props.filterValue,
      selectedDateArray: [],
    };
  }
  static getDerivedStateFromProps(nextProps) {
    return {
      value: nextProps.filterValue,
      selectedDateArray: !isEmptyOrNull(nextProps.filterValue)
        ? nextProps.filterValue?.split('/')?.map(dateStr => dateStr)
        : [],
    };
  }

  handleDelete = () => {
    this.setState({
      value: '',
      selectedDateArray: [],
    });
    this.props.onDateChange('', '');
  };

  onDateSelectionChange = selectedDateArray => {
    const { isSingleSelect } = this.props;
    if (!selectedDateArray) {
      this.setState({ value: '', selectedDateArray: [] });
      this.props.onDateChange('', '');
    } else if (selectedDateArray.length === 2) {
      const startDate = formatSelectedDate(
        selectedDateArray[0],
        this.props.dateFormat || CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_API
      );
      const endDate = formatSelectedDate(
        selectedDateArray[1],
        this.props.dateFormat || CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_API
      );
      const formattedDate = `${startDate}-${endDate}`;
      let formattedSelectedValues = Array.isArray(selectedDateArray)
        ? selectedDateArray.map(date =>
            moment(date).format(
              this.props.dateFormat || CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_API
            )
          )
        : [];
      this.setState({
        value: formattedDate,
        selectedDateArray: formattedSelectedValues,
      });

      this.props.onDateChange(startDate, endDate, formattedDate);
    } else if (isSingleSelect) {
      const formattedDate = formatSelectedDate(
        selectedDateArray,
        this.props.dateFormat || CONFIG.DATE_TIME_FORMAT.KT_SCORECARD
      );
      const formattedDateTime = moment(selectedDateArray).format(
        CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_DATE_TIME
      );
      this.setState({
        value: formattedDate,
        selectedDateArray: formattedDate,
      });
      this.props.onDateChange(formattedDate, formattedDateTime);
    }
  };

  showDeleteICon = () => {
    const { hideCalenderIcon } = this.props;
    const { selectedDateArray } = this.state;
    return !hideCalenderIcon ? (
      <Icon
        className={classnames([
          'iconDate',
          { alignTop: selectedDateArray.length === 0 },
        ])}
        name="delete"
        onClick={this.handleDelete}
        tooltipText="Clear Date"
        data-testid="clear-date"
      />
    ) : null;
  };

  render() {
    const {
      filterTitle,
      handleLabelClick,
      getIsVisible,
      minDate,
      containerClass,
      hideDeleteIcon,
      isSingleSelect,
      maxDate,
      testId,
      isLabelRequired,
      hideCalenderIcon,
    } = this.props;
    const { selectedDateArray, value } = this.state;
    const minimumDate = minDate ? minDate : moment().subtract(2, 'year');
    const maximumDate = maxDate ? maxDate : new Date();
    return (
      <div
        className={`dateContainer ${containerClass ? containerClass : ''}`}
        data-testid={testId}
      >
        {filterTitle ? <span className="title">{filterTitle}</span> : null}
        {isLabelRequired ? (
          <AppliedFilterLabel
            invert
            showSeparator={false}
            filterValue={value}
            onClick={handleLabelClick}
            data-testid="selected-date"
          />
        ) : null}
        {!hideCalenderIcon &&
        (selectedDateArray.length === 0 || hideDeleteIcon) ? (
          <Icon
            className={classnames([
              'iconDate',
              { alignTop: selectedDateArray.length === 0 },
            ])}
            name="calendar alternate outline"
            onClick={handleLabelClick}
            tooltipText="Change Date"
            data-testid="change-date"
          />
        ) : (
          this.showDeleteICon()
        )}
        {getIsVisible && (
          <CustomCalendar
            minDate={moment(minimumDate).toDate()}
            onChange={this.onDateSelectionChange}
            isRange={isSingleSelect ? false : true}
            selectedValueArray={
              selectedDateArray.length > 0 ? selectedDateArray : undefined
            }
            maxDate={moment(maximumDate).toDate()}
          />
        )}
      </div>
    );
  }
}
