import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { selectors } from 'redux/reducers/peopleImagesReducer';
import PeopleImage from './PeopleImage';

const mapStateToProps = (state, ownProps) => ({
  peopleImageBytes: selectors.getImageByHrId(state)(ownProps.hrId),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    fetchPeopleImage: bindActionCreators(
      actions.peopleActions.fetchPeopleImage,
      dispatch
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleImage);
