/* eslint-disable react/jsx-no-bind */
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.scss';
import Calendar from 'react-calendar';
import moment from 'moment';
import { isArray } from 'utils/common';

const currentDate = moment().toDate();

export default function CustomCalendar({
  isRange,
  onChange,
  selectedValueArray,
  minDate,
  maxDate = currentDate,
}) {
  const currentSelectedDates = isArray(selectedValueArray)
    ? selectedValueArray
    : [currentDate, currentDate];
  return (
    <div className="calendarContainer">
      <Calendar
        calendarType="ISO 8601"
        minDate={minDate}
        returnValue={isRange ? 'range' : 'start'}
        selectRange={isRange ? true : false}
        onChange={onChange}
        showNeighboringMonth
        value={currentSelectedDates}
        view="month"
        maxDate={maxDate}
        tileContent={({ date }) => {
          if (
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
          )
            return <span className="current"> </span>;
          else return null;
        }}
        data-testid="calender-container"
      />
    </div>
  );
}
