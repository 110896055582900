import React, { useState } from 'react';
import { isEmptyOrNull } from 'utils';
import { Modal } from 'semantic-ui-react';
import Button from 'components/common/button/Button';
import { isEmpty, isEqual } from 'utils/common';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/actions';
import KTCheckbox from 'components/common/KTCheckbox';
import DropDownFilter from 'components/common/AdminFilter/KtDdFilters';
import './index.scss';
import CONFIG from 'config/configProps';

const SaveCollection = props => {
  const collectionList = useSelector(
    state => state.filterDataPt.fetchCollectionData?.results || {}
  );

  const { selectedDropDownList, memberList, isEditModeOn, selectedCollection } =
    props;

  const dispatch = useDispatch();

  const [collectionName, setCollectionName] = useState(
    selectedCollection?.name || ''
  );
  const [nameError, setNameError] = useState('');
  const [selectedList, setSelectedList] = useState(props.selectedList);

  const isCollectionNameValid = () => {
    const collectionNameList = collectionList?.userFilterCollectionDTO || [];
    const isCollectionExist = collectionNameList.filter(item =>
      isEqual(item.name, collectionName)
    );

    let errorMessage = '';

    if (collectionName.length > 35) {
      errorMessage =
        '* Collection name length should be less than 35 characters.';
    } else if (
      !isEmpty(isCollectionExist) &&
      (!isEditModeOn || !isEqual(collectionName, selectedCollection?.name))
    ) {
      errorMessage = '* User collection already exists with same name.';
    }

    setNameError(errorMessage);
    return isEmpty(errorMessage);
  };

  const onSaveClick = () => {
    if (!isCollectionNameValid()) {
      return;
    }

    const selectedUserList = memberList.filter(employee =>
      selectedList.includes(employee.value)
    );
    let selectedUserDetails = [];
    let selectedUserId = [];
    selectedUserList.map(user => {
      let value = { employeeName: user.label, employeeIdHRO: user.value };
      selectedUserDetails.push(value);
      selectedUserId.push(user.value);
    });
    let postData = {
      name: collectionName,
      userSelectedFilterDTO: {
        selectedUserDetails,
      },
    };
    if (isEditModeOn) postData['id'] = selectedCollection.id;
    dispatch(actions.protoTypeActions.saveCollection(postData, isEditModeOn));
    dispatch(
      actions.protoTypeActions.saveAppliedCollection({
        ...postData,
        selectedUserId,
      })
    );
    props.applyFilter(selectedList);
    props.closeModal();
  };

  const renderDropDown = (list, checked) => {
    return list.map(el => {
      return (
        <div
          key={el.value}
          className={`filter-display  ${checked ? 'highlighted' : ''}`}
        >
          <KTCheckbox
            checked={checked || selectedList.includes(el.value)}
            checkboxColor="darkest-blue"
            value={el.value}
            name={el.label}
          />
          <div className="filter-name">{el.label}</div>
        </div>
      );
    });
  };

  const handleCheckboxChange = changedList => {
    setSelectedList(changedList);
  };

  return (
    <Modal
      closeIcon={!isEditModeOn}
      className="save_filter_modal"
      size="tiny"
      open
      onClose={() => {
        props.closeModal();
      }}
    >
      <Modal.Header className="save_filter_modal__header">
        {isEditModeOn ? `Edit "${collectionName}"` : 'Save as'}
        {isEditModeOn && (
          <img
            src="./img/kt-delete-icon.svg"
            className="delete-icon"
            alt="delete-icon"
            onClick={props.deleteCollection}
          />
        )}
      </Modal.Header>
      <Modal.Content className="save_filter_modal__content admin-filter-container">
        <div className="name">Name</div>
        <input
          type="text"
          placeholder=""
          className="save-input"
          onChange={e => setCollectionName(e.target.value)}
          value={collectionName}
        />
        <div className="error">{nameError}</div>
        {isEditModeOn ? (
          <DropDownFilter
            dataList={memberList}
            onCheckBoxChange={handleCheckboxChange}
            selectedList={selectedList}
            stateField={CONFIG.PROTOTYPE_FILTERS.NAME.field}
            isAllSelected={isEqual(
              memberList?.length || 0,
              selectedList?.length || 0
            )}
            showSearch={true}
            placeHolder={'Search Name'}
            searchPosition={'Right'}
            showSelectedOnTop={true}
            filterName={CONFIG.PROTOTYPE_FILTERS.NAME.text}
            hideCollection={true}
            className="edit-collection"
            hideButtonFooter={true}
          />
        ) : (
          <div className="filter-list">
            {renderDropDown(selectedDropDownList, true)}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions className="save_filter_modal__footer">
        <Button
          onClick={() => props.closeModal()}
          className="cancel"
          content="Cancel"
        />
        <Button
          onClick={() => onSaveClick()}
          disabled={
            isEmptyOrNull(collectionName) ||
            (isEditModeOn &&
              isEqual(selectedList, props.selectedList) &&
              isEqual(collectionName, selectedCollection?.name))
          }
          content={`${isEditModeOn ? 'Update' : 'Save'} & Apply`}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SaveCollection;
