import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.scss';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.valueProp.length > 0) {
      this.state = {
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(this.props.valueProp))
        ),
      };
    } else {
      this.state = {
        editorState: EditorState.createEmpty(),
      };
    }
  }

  /**
   * This method will update the state on any change in text editor
   */
  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
    let editorSourceHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    let plainText = editorState.getCurrentContent().getPlainText();
    this.props.getHTMLTextHandler(editorSourceHTML, plainText);
  };

  uploadImageCallBack = file => {
    return new Promise(resolve => {
      const reader = new FileReader(); // eslint-disable-line no-undef
      reader.onloadend = () => resolve({ data: { link: reader.result } });
      reader.readAsDataURL(file);
    });
  };

  /**
   * React life cycle method
   */
  render() {
    const { editorState } = this.state;
    const {
      textAreaStyle,
      valueProp,
      editorWrapperClass,
      editorTextEditorClass,
      toolbarHidden,
      editorPlaceHolder,
    } = this.props;
    const toolbarDefaultOptions = {
      options: [
        'inline',
        'blockType',
        'list',
        'fontSize',
        'textAlign',
        'link',
        'image',
        'colorPicker',
        'fontFamily',
      ],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
      blockType: {
        inDropdown: true,
        options: [
          'Normal',
          'H1',
          'H2',
          'H3',
          'H4',
          'H5',
          'H6',
          'Blockquote',
          'Code',
        ],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
      },
      list: {
        options: ['unordered', 'ordered'],
      },
      fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      },
      link: {
        defaultTargetOption: '_blank',
        showOpenOptionOnHover: false,
      },
      image: {
        uploadCallback: this.uploadImageCallBack,
        urlEnabled: true,
        uploadEnabled: true,
        previewImage: true,
        alignmentEnabled: true,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: false, mandatory: false },
        defaultSize: {
          height: 'auto',
          width: 'auto',
        },
        title: undefined,
      },
      fontFamily: {
        options: [
          'Arial',
          'Georgia',
          'Impact',
          'Tahoma',
          'Times New Roman',
          'Verdana',
          'Calibri',
          'Inter',
        ],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
      },
      colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: [
          'rgb(97,189,109)',
          'rgb(26,188,156)',
          'rgb(84,172,210)',
          'rgb(44,130,201)',
          'rgb(147,101,184)',
          'rgb(71,85,119)',
          'rgb(204,204,204)',
          'rgb(65,168,95)',
          'rgb(0,168,133)',
          'rgb(61,142,185)',
          'rgb(41,105,176)',
          'rgb(85,57,130)',
          'rgb(40,50,78)',
          'rgb(0,0,0)',
          'rgb(247,218,100)',
          'rgb(251,160,38)',
          'rgb(235,107,86)',
          'rgb(226,80,65)',
          'rgb(163,143,132)',
          'rgb(239,239,239)',
          'rgb(255,255,255)',
          'rgb(250,197,28)',
          'rgb(243,121,52)',
          'rgb(209,72,65)',
          'rgb(184,49,47)',
          'rgb(124,112,107)',
          'rgb(209,213,216)',
        ],
      },
    };
    const EditorTextAreaStyle = textAreaStyle
      ? textAreaStyle
      : { padding: '0 2rem' };
    return (
      <Editor
        editorState={
          valueProp.length > 0 ? editorState : EditorState.createEmpty()
        }
        wrapperClassName={editorWrapperClass + ' rich-text-editor-wrapper'}
        editorClassName={editorTextEditorClass + ' rich-text-editor-area'}
        onEditorStateChange={this.onEditorStateChange}
        toolbarHidden={toolbarHidden}
        toolbar={{
          options: toolbarDefaultOptions.options,
          image: {
            uploadCallback: this.uploadImageCallBack,
            urlEnabled: true,
            uploadEnabled: true,
            previewImage: true,
            alignmentEnabled: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
            title: undefined,
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          link: {
            defaultTargetOption: '_blank',
            showOpenOptionOnHover: false,
          },
        }}
        editorStyle={EditorTextAreaStyle}
        placeholder={editorPlaceHolder}
        stripPastedStyles={true}
      />
    );
  }
}

export default RichTextEditor;
