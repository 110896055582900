import React from 'react';
import { isEmptyOrNull } from 'utils';
import KTHomeLoader from './KTHomeLoader';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isLoading } from 'utils/common';

const KTTopDownloads = props => {
  const data = props.data.data;
  const knPageDetailsData = !isEmptyOrNull(data?.results)
    ? data.results.knPageDetails
    : [];
  return (
    <div className="home_page_main_split_row_top_downloads main_conatiner">
      <div className="heading">
        <div className="heading_text">TOP DOWNLOADS</div>
        {/* <img
          name="download"
          class="download-icon"
          alt="downloadIcon"
          src="/img/download.png"
        /> */}
      </div>
      <div className="chart__table">
        <div className="chart__table__header">
          <div className="downloadTitle">Title</div>
          <div className="downloadCount">Downloads</div>
        </div>
        <div className="chart__table__body data_table_body">
          {isLoading(props.data) ? (
            <KTHomeLoader isLoading={1} section="Most Downloaded" />
          ) : isEmptyOrNull(knPageDetailsData) ? (
            <div className="static-text">No data to display</div>
          ) : (
            knPageDetailsData.map((downloadData, index) => {
              return (
                <div
                  className="data_table_body_row"
                  key={`${downloadData.downloadCount}_${index}`}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="tooltip-basic">
                        <Popover.Content>
                          {downloadData.materialName}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <a
                      className="downloadTitle"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={downloadData.materialUrl}
                    >
                      {downloadData.materialName}
                    </a>
                  </OverlayTrigger>
                  <div className="downloadCount">
                    {downloadData.downloadCount}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default KTTopDownloads;
