import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import store from 'redux/store';
import { updateCalenderDropdownList } from 'redux/actions/headerFilterAction';
import Icon from 'components/common/Icons';
import './KTDropdownWithRadioBtn.scss';

const KTDropdownWithRadioBtn = props => {
  const refContainer = useRef();
  const [isDropdownOpen, toggleDropdown] = useState(false);
  function openDropdown() {
    toggleDropdown(!isDropdownOpen);
  }
  function onSelectionChange(item, index) {
    store.dispatch(updateCalenderDropdownList(item));
    toggleDropdown(!isDropdownOpen);
    props.onSelectionChange(item, index);
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        refContainer.current &&
        !refContainer.current.contains(event.target)
      ) {
        toggleDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`dropdownWith-btn ${props.disabled ? 'disabled' : ''} 
    ${props.className ? props.className : ''}`}
      ref={refContainer}
    >
      <span className="dropdown-selected-option" onClick={openDropdown}>
        {props.calenderDropdownVal}
        <Icon
          name="kt-scorecard-white-arrow"
          class="kt-drop-arrow kt-header-dropdown"
          alt=""
          src="/img/kt-scorecard-white-arrow.svg"
        />
      </span>
      {isDropdownOpen && props.dropdownData && (
        <ul className="dd-list">
          {props.dropdownData.map((item, index) => (
            <li
              className="time-period-option"
              key={item}
              onClick={() => onSelectionChange(item, index)}
              name="dropdownRadio"
              value={item}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = function (reduxStore) {
  return {
    calenderDropdownVal: reduxStore.headerFilterData.calenderDropdownVal,
  };
};
export default connect(mapStateToProps)(KTDropdownWithRadioBtn);
