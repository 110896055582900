import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AdminImageSkeleton = ({ isAdminImage }) => {
  return isAdminImage ? (
    <Skeleton width={20} height={20} circle duration={1} count={1} />
  ) : null;
};

export default AdminImageSkeleton;
