import React, { useEffect } from 'react';
import { globalAdobeAnalytics } from 'config/analytics';

const KTSurveys = () => {
  useEffect(() => {
    globalAdobeAnalytics(`KTDashboard-HomePage`);
  }, []);
  return <div>Surveys</div>;
};

export default KTSurveys;
