import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './UnknownRoute.scss';

const UnknownRoute = () => {
  return (
    <Row className="unknownRoutes">
      <Col sm={5}>
        <div className="error-404">Error 404</div>
        <div className="oops">Oops!</div>
        <div className="we-cant-seem-to-find">{`We can't seem to find the page you are looking for`}</div>
        <Row className="button-404">
          <Col
            sm={7}
            className="home-page-404"
            onClick={() => (window.location.href = '/')}
          >
            Take me to the home page
          </Col>
        </Row>
      </Col>
      <Col sm={7}>
        <figure className="figure-404">
          <img className="image-404" src="/img/404_image.jpg" alt="404_image" />
        </figure>
      </Col>
    </Row>
  );
};

export default UnknownRoute;
