/* eslint-disable func-names */
import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
  UPDATE_SELECTED_PULSE_QUESTION,
  SAVE_SELECTED_USER_ID_MEMBER,
  SAVE_SELECTED_USER_ID_BUSINESS,
  UPDATE_SELECTED_BILLABILITY_QUESTION,
  SAVE_SELECTED_VIEW,
  UPDATE_SELECTED_TOP_PROJECTS_QUESTION,
  UPDATE_SELECTED_USER_BY_IMPERSONATOR,
  RESET_DATA,
  UPDATE_LOGGED_IN_USER_ROLE,
  UPDATE_LOGGED_IN_USER_ID,
  SAVE_DEFAULT_LOGGED_IN_USER_ROLE,
} from './actionTypes';

import { batch } from 'react-redux';
import { beginAPICall, endAPICall } from './apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { fetchGet, fetchPost } from 'utils/KtCommonServices';
import CONFIG from 'config/configProps';
import { isEmptyOrNull } from 'utils';

const MESSAGES = {
  GROUP: 'KTHOMEPAGE',
  ERROR: 'Network error, please try again later',
};

/**
 * Action Creator: Seed Data fetch Success
 * @param {*} data data received from API
 * @param {*} apiIdentifier API identifier
 */
function seedDataFetchSuccess(data, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_SUCCESS,
    id: apiIdentifier,
    results: data,
  };
}
/**
 * Action Creator: Seed Data Fetch Failure
 * @param {*} error error received from API
 * @param {*} apiIdentifier  API identifier
 */
function seedDataFetchFailure(error, apiIdentifier) {
  return {
    type: FILTER_DATA_FETCH_FAILURE,
    id: apiIdentifier,
    error,
  };
}

/**
 * THUNK: fetch seed data for ALL Filters for Business Comparison
 */
function fetchSeedDataMemberView(
  fetchBillabilityTrend,
  fetchTopClients,
  fetchTopDownloads,
  fetchEngagementDepth,
  fetchTimesheet,
  fetchHourlyBillRate,
  fetchCdBillability,
  fetchTopProjects,
  fetchRmsData,
  fetchKnMaterials,
  postData,
  selectedTopProjectsQuestion
) {
  return async function (dispatch) {
    fetchTopProjects &&
      dispatch(saveAppliedTopProjectQuestion(selectedTopProjectsQuestion));
    batch(() => {
      // batch all Seed Data Calls dispatch as ONE
      fetchBillabilityTrend &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_MEMBER)
        );
      fetchTopClients &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_MEMBER));
      fetchTopDownloads &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_MEMBER));
      fetchEngagementDepth &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_MEMBER)
        );
      fetchTimesheet &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TIMESHEET_MEMBER));
      fetchHourlyBillRate &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_HOURLY_BILL_RATE));
      fetchCdBillability &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_CD_BILLABILITY_MEMBER)
        );
      fetchTopProjects &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_MEMBER));
      fetchRmsData &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_MEMBER)
        );
      fetchKnMaterials &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_MEMBER));
    });
    fetchBillabilityTrend &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_MEMBER
      );
    fetchTopClients &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_MEMBER
      );
    fetchTopDownloads &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_MEMBER
      );
    fetchEngagementDepth &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_MEMBER
      );
    fetchTimesheet &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_MEMBER
      );
    fetchHourlyBillRate &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_HOURLY_BILL_RATE
      );
    fetchCdBillability &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_CD_BILLABILITY_MEMBER
      );
    fetchTopProjects &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_MEMBER,
        selectedTopProjectsQuestion
      );
    fetchRmsData &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_MEMBER
      );
    fetchHourlyBillRate &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_HOURLY_BILL_RATE
      );
    fetchCdBillability &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_CD_BILLABILITY_MEMBER
      );
    fetchHourlyBillRate &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_HOURLY_BILL_RATE
      );
    fetchCdBillability &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_CD_BILLABILITY_MEMBER
      );
    fetchKnMaterials &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_MEMBER
      );
  };
}

function fetchSeedDataBusinessView(
  fetchBillabilityTrend,
  fetchTopClients,
  fetchktPulseCheck,
  fetchEngagementDepth,
  fetchTeamSize,
  fetchTopDownloads,
  fetchTimesheet,
  fetchKtBillability,
  fetchCdBillability,
  fetchTopProjects,
  fetchRmsData,
  fetchKnMaterials,
  postData,
  selectedKtPulseQuestion,
  selectedBillabilityQuestion,
  selectedTopProjectsQuestion
) {
  return async function (dispatch) {
    fetchktPulseCheck &&
      dispatch(saveAppliedKtPulseQuestion(selectedKtPulseQuestion));
    fetchKtBillability &&
      dispatch(saveAppliedBillabilityQuestion(selectedBillabilityQuestion));
    fetchTopProjects &&
      dispatch(saveAppliedTopProjectQuestion(selectedTopProjectsQuestion));
    batch(() => {
      // batch all Seed Data Calls dispatch as ONE
      fetchBillabilityTrend &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_BUSINESS)
        );
      fetchTopClients &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_BUSINESS));
      fetchktPulseCheck &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_KT_PULSE_CHECK));
      fetchEngagementDepth &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_BUSINESS)
        );
      fetchTeamSize &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TEAM_SIZE));
      fetchTopDownloads &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_BUSINESS)
        );
      fetchTimesheet &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_TIMESHEET_BUSINESS));
      fetchKtBillability &&
        dispatch(beginAPICall(API_CALL_IDENTIFIERS.FETCH_KT_BILLABILITY));
      fetchCdBillability &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_CD_BILLAILITY_BUSINESS)
        );
      fetchTopProjects &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_BUSINESS)
        );
      fetchRmsData &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_BUSINESS)
        );
      fetchKnMaterials &&
        dispatch(
          beginAPICall(API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_BUSINESS)
        );
    });
    fetchBillabilityTrend &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_BUSINESS
      );
    fetchTopClients &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_BUSINESS
      );

    fetchktPulseCheck &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_KT_PULSE_CHECK,
        selectedKtPulseQuestion
      );
    fetchEngagementDepth &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_BUSINESS
      );
    fetchTeamSize &&
      fetchPostData(dispatch, postData, API_CALL_IDENTIFIERS.FETCH_TEAM_SIZE);
    fetchTopDownloads &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_BUSINESS
      );
    fetchTimesheet &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_BUSINESS
      );
    fetchKtBillability &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_KT_BILLABILITY,
        selectedBillabilityQuestion
      );
    fetchCdBillability &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_CD_BILLAILITY_BUSINESS
      );
    fetchTopProjects &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_BUSINESS,
        selectedTopProjectsQuestion
      );
    fetchRmsData &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_BUSINESS
      );
    fetchKnMaterials &&
      fetchPostData(
        dispatch,
        postData,
        API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_BUSINESS
      );
  };
}

/**
 * Method to check if 'error' is being set from ServiceLayer. If yes, dispatch API-FAIL action
 * @param {*} param0 error
 * @param {*} apiIdentifier API Identifier
 * @param {*} dispatch dispatch Ref
 */
function isErroneousResponse({ error }) {
  if (error) {
    throw new Error(`${MESSAGES.ERROR}`);
  }
  return false;
}

async function fetchPostData(dispatch, data, apiIdentifier, selectedQuestion) {
  let endpoint;
  let baseUrl = CONFIG.KT_BILLING_API_URL;
  let dataToPost = { ...data };
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_BILLABILITY_TREND_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.BILLABILITY_TRENDS;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_TOP_CLIENTS_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TOP_CLIENTS;
      break;
    case API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.ENGAGEMENT_DEPTH;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_TOP_DOWNLOADS_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TOP_DOWNLOADS;
      baseUrl = CONFIG.KT_CONTENT_API_URL;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TIMESHEET_MEMBER:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TIMESHEET;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TIMESHEET_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TIMESHEET_SUMMARY;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_BUSINESS:
    case API_CALL_IDENTIFIERS.FETCH_TOP_PROJECTS_MEMBER:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TOP_PROJECTS;
      dataToPost = {
        ...dataToPost,
        billabilityType: selectedQuestion
          ? CONFIG.KT_BILLABILITY_QUESTIONS[selectedQuestion]
          : CONFIG.DEFAULT_BILLABILITY_QUESTIONS,
      };
      break;
    case API_CALL_IDENTIFIERS.FETCH_KT_PULSE_CHECK:
      endpoint = CONFIG.PROTOTYPE_API_URLS.KT_PULSE_CHECK;
      baseUrl = CONFIG.KT_SCORECARD_API_URL;
      dataToPost = {
        ...dataToPost,
        pulseSurveyQuestion: selectedQuestion
          ? CONFIG.KT_PULSE_CHECK_QUESTIONS[selectedQuestion]
          : CONFIG.DEFAULT_KT_PULSE_CHECK_QUESTIONS,
      };
      break;
    case API_CALL_IDENTIFIERS.FETCH_ENGAGMENT_DEPTH:
      endpoint = CONFIG.PROTOTYPE_API_URLS.ENGAGEMENT_DEPTH;
      break;
    case API_CALL_IDENTIFIERS.FETCH_TEAM_SIZE:
      endpoint = CONFIG.PROTOTYPE_API_URLS.TEAM_SIZE;
      baseUrl = CONFIG.KT_USER_BASE_URL;
      break;
    case API_CALL_IDENTIFIERS.FETCH_KT_BILLABILITY:
      endpoint = CONFIG.PROTOTYPE_API_URLS.KT_BILLABILITY;
      dataToPost = {
        ...dataToPost,
        billabilityType: selectedQuestion
          ? CONFIG.KT_BILLABILITY_QUESTIONS[selectedQuestion]
          : CONFIG.DEFAULT_BILLABILITY_QUESTIONS,
      };
      break;
    case API_CALL_IDENTIFIERS.FETCH_HOURLY_BILL_RATE:
      endpoint = CONFIG.PROTOTYPE_API_URLS.HOURLY_BILL_RATE;
      break;
    case API_CALL_IDENTIFIERS.FETCH_CD_BILLABILITY_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_CD_BILLAILITY_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.CD_BILLABILITY_URL;
      break;
    case API_CALL_IDENTIFIERS.FETCH_IMPERSONATED_USER_DETAILS:
      baseUrl = CONFIG.KT_USER_BASE_URL;
      endpoint = CONFIG.PROTOTYPE_API_URLS.IMPERSONATED_USER_DETAILS;
      break;
    case API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_RMS_SATISFACTION_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.RMS_SATISFACTION;
      baseUrl = CONFIG.KT_SCORECARD_API_URL;
      break;
    case API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_MEMBER:
    case API_CALL_IDENTIFIERS.FETCH_KN_MATERIALS_BUSINESS:
      endpoint = CONFIG.PROTOTYPE_API_URLS.KN_MATERIALS;
      baseUrl = CONFIG.KT_CONTENT_API_URL;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchPost(endpoint, dataToPost, baseUrl);
    if (!isErroneousResponse(response)) {
      const apiResponse = response.data;
      return batch(() => {
        dispatch(seedDataFetchSuccess(apiResponse, apiIdentifier));
      });
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

async function fetchGetData(dispatch, apiIdentifier, employeeIdHRO) {
  let endpoint;
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  let defaultUserInfo = false;
  switch (apiIdentifier) {
    case API_CALL_IDENTIFIERS.FETCH_USER_DETAILS:
      endpoint = CONFIG.API_URLS.USER_DETAILS;
      defaultUserInfo = true;
      break;
    case API_CALL_IDENTIFIERS.FETCH_IMPERSONATED_USER_DETAILS:
      endpoint = `${CONFIG.PROTOTYPE_API_URLS.IMPERSONATED_USER_DETAILS}?employeeIdHRO=${employeeIdHRO}`;
      break;
    default:
      endpoint = '';
  }
  try {
    const response = await fetchGet(endpoint, baseUrl);
    if (!isErroneousResponse(response)) {
      return batch(() => {
        if (defaultUserInfo)
          dispatch(saveDefaultLoggedInUserRole(response.defaultKTRole));
        dispatch(
          saveLoggedInUserRole(
            defaultUserInfo ? response.defaultKTRole : response
          )
        );
        dispatch(saveLoggedInUserId(response?.hrid || employeeIdHRO));
        dispatch(seedDataFetchSuccess(response, apiIdentifier));
      });
    }
  } catch (error) {
    batch(() => {
      dispatch(seedDataFetchFailure(error, apiIdentifier));
    });
  } finally {
    dispatch(endAPICall(apiIdentifier));
  }
}

function updateUserDetails(fetchUserDetails) {
  const apiIdentifier = API_CALL_IDENTIFIERS.FETCH_USER_DETAILS;
  return async function (dispatch) {
    if (fetchUserDetails) {
      dispatch(beginAPICall(apiIdentifier));
      fetchGetData(dispatch, apiIdentifier);
    }
  };
}

export function saveLoggedInUserRole(loggedInUserRole) {
  return {
    type: UPDATE_LOGGED_IN_USER_ROLE,
    loggedInUserRole,
  };
}

export function saveDefaultLoggedInUserRole(defaultLoggedInRole) {
  return {
    type: SAVE_DEFAULT_LOGGED_IN_USER_ROLE,
    defaultLoggedInRole,
  };
}

export function saveLoggedInUserId(loggedInUserId) {
  return {
    type: UPDATE_LOGGED_IN_USER_ID,
    loggedInUserId,
  };
}

export function saveAppliedKtPulseQuestion(selectedKtPulseQuestion) {
  return {
    type: UPDATE_SELECTED_PULSE_QUESTION,
    selectedKtPulseQuestion,
  };
}

export function saveAppliedBillabilityQuestion(selectedBillabilityQuestion) {
  return {
    type: UPDATE_SELECTED_BILLABILITY_QUESTION,
    selectedBillabilityQuestion,
  };
}

export function saveAppliedTopProjectQuestion(selectedTopProjectsQuestion) {
  return {
    type: UPDATE_SELECTED_TOP_PROJECTS_QUESTION,
    selectedTopProjectsQuestion,
  };
}

export function saveSelectedUserIdMember(selectedId) {
  return {
    type: SAVE_SELECTED_USER_ID_MEMBER,
    selectedId,
  };
}

export function saveSelectedUserIdBusiness(selectedId) {
  return {
    type: SAVE_SELECTED_USER_ID_BUSINESS,
    selectedId,
  };
}

export function saveSelectedView(selectedView) {
  return {
    type: SAVE_SELECTED_VIEW,
    selectedView,
  };
}

export function updateSelectedUserByImpersonator(selectedUserByImpersonator) {
  return {
    type: UPDATE_SELECTED_USER_BY_IMPERSONATOR,
    selectedUserByImpersonator,
  };
}

export function updateImpersonatedUserDetails(employeeIdHRO) {
  const apiIdentifier = API_CALL_IDENTIFIERS.FETCH_IMPERSONATED_USER_DETAILS;
  return async function (dispatch) {
    if (isEmptyOrNull(employeeIdHRO)) {
      batch(() => {
        dispatch(seedDataFetchSuccess({}, apiIdentifier));
      });
    } else {
      batch(() => {
        dispatch(beginAPICall(apiIdentifier));
        fetchGetData(dispatch, apiIdentifier, employeeIdHRO);
      });
    }
  };
}

export function updateIsResetData(isResetData) {
  return {
    type: RESET_DATA,
    isResetData,
  };
}

export const homepageActions = {
  fetchSeedDataMemberView,
  fetchSeedDataBusinessView,
  updateUserDetails,
  saveSelectedUserIdMember,
  saveSelectedUserIdBusiness,
  saveSelectedView,
  updateSelectedUserByImpersonator,
  updateImpersonatedUserDetails,
  updateIsResetData,
  saveLoggedInUserId,
  saveLoggedInUserRole,
};
