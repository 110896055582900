import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './UnknownRoute.scss';

const Unauthorized = props => {
  const [emailUrl, setEmailUrl] = useState('');

  const handleClick = () => {
    const { userName } = props.location.state;
    const mailTo = 'KTDashboardSupport@bcg.com';
    const subject = 'KT Dashboard access request';
    const emailBody = `Hi Team, \n\nI am requesting access to the KT Dashboard:\n\nPractice Area(s):
    \nSector(s) or Topic(s):\n\nMy role / justification for access:\n\nRegards,\n ${userName}`;
    const url =
      'mailto:' +
      mailTo +
      '?subject=' +
      subject +
      '&body=' +
      encodeURIComponent(emailBody);
    setEmailUrl(url);
  };

  return (
    <Row className="unauthorized">
      <Col sm={3}>
        <figure className="figure-401">
          <img className="image-401" src="/img/401.png" alt="401_image" />
        </figure>
      </Col>
      <Col sm={9}>
        <div className="error-404">Error 401</div>
        <div className="oops">Oops!</div>
        <div className="unauthorized-access-message">
          <div>
            There was an error giving you access to this section of the
            application.
          </div>
          <ol>
            <li>
              If you believe you should have access, try clearing cookies and
              other site data by pressing CTRL-SHIFT-DEL
              <Row>
                <Col sm={6}>
                  <img
                    src="/img/clear-cache-1.png"
                    alt="clear-cache"
                    className="clear-cache"
                  />
                </Col>
                <Col sm={6}>
                  <img
                    src="/img/clear-cache-2.png"
                    alt="clear-cache"
                    className="clear-cache"
                  />
                </Col>
              </Row>
            </li>
            <li>
              If you still cannot access, please reach out to &nbsp;
              <a
                href={emailUrl}
                onClick={e => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                KT Dashboard Support
              </a>
            </li>
          </ol>
        </div>
      </Col>
    </Row>
  );
};

export default Unauthorized;
