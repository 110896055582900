import moment from 'moment';

import { formatDate } from 'utils/common';
import { dropdownFormat } from 'utils';
import CONFIG from 'config/configProps';
import {
  getSectorList,
  getSubSectorList,
  getPaList,
} from 'redux/actions/headerFilterAction';
import {
  updateActiveUserInfo,
  addNewAlignment,
  saveUserAndActivate,
  saveUserInfoAsDraft,
} from 'redux/actions/selfServiceActions';

export const initialState = {
  path: null,
  managedBy: null,
  geoMarket: null,
  practiceArea: null,
  segment: null,
  subSegment: null,
  openCalendar: false,
  effectiveDate: '',
  teamManager: '',
  lastModified: '',
  errorMessage: {},
  loading: {
    getDetails: false,
    save: false,
    activate: false,
  },
};

/**
 *
 * @param {Object}   state   - current app state
 * @param {Object}   action
 * @returns {Object}         - updated state
 */
export const editFormStateReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return {
        ...state,
        ...action.formData,
        errorMessage: {},
        openCalendar: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case 'SET_OPEN_CALENDAR':
      return {
        ...state,
        openCalendar: action.openCalendar,
      };
    default:
      throw new Error();
  }
};

/**
 *
 * @param   {Object}   state - current app state
 * @returns {Object[]}       - dropdown fields config data
 */
export const dropdownFields = state => {
  return [
    {
      label: 'Path',
      field: 'path',
      placeholder: 'Select Path',
      dataList: state?.pathList?.data,
      value: state?.path,
      testid: 'path-dropdown',
      showDropdown: true,
      required: true,
      errorField: 'slotting',
    },
    {
      label: 'Managed By',
      field: 'managedBy',
      placeholder: 'Select Managed By',
      dataList: state?.managedByList?.data,
      value: state?.managedBy,
      testid: 'managedby-dropdown',
      showDropdown: true,
      required: true,
      errorField: 'managedBy',
    },
    {
      label: 'Geo Market',
      field: 'geoMarket',
      placeholder: 'Select Geo Market',
      dataList: state?.geoMarketList?.data,
      value: state?.geoMarket,
      testid: 'geo-market-dropdown',
      showDropdown: state?.managedBy?.value !== 'PA Managed',
      required: true,
      errorField: 'geoRegion',
    },
    {
      label: 'Practice Area',
      field: 'practiceArea',
      placeholder: 'Select Practice Area',
      dataList: state?.paList,
      value: state?.practiceArea,
      testid: 'practice-area-dropdown',
      showDropdown: true,
      required: true,
      maxMenuHeight: '200px',
      errorField: 'paId',
    },
    {
      label: 'Topic/Segment',
      field: 'segment',
      placeholder: 'Select Topic/Segment',
      dataList: state?.sectorList,
      value: state?.segment,
      testid: 'segment-dropdown',
      showDropdown: true,
      required: state?.managedBy?.value === 'PA & Geo Managed',
      maxMenuHeight: '150px',
      errorField: 'topicSectorId',
    },
    {
      label: 'Sub-Topic/Segment',
      field: 'subSegment',
      placeholder: 'Select Sub-Topic/Segment',
      dataList: state?.subSectorList,
      value: state?.subSegment,
      testid: 'sub-segment-dropdown',
      showDropdown: true,
      required: false,
      maxMenuHeight: '120px',
    },
  ];
};

/**
 *
 * @param   {Object[]|Array} data  - Array of object or Array of strings
 * @param   {String}         field - Field name
 * @param   {Object}         state - current state
 * @returns {Object[]}             - Array of object for dropdown list
 */
export const getDataValue = (data, field, state) => {
  switch (field) {
    case 'managedBy':
    case 'geoMarket':
    case 'path':
      return data?.map(item =>
        dropdownFormat(item, item === 'PA & Geo Managed' ? 'Co-Managed' : item)
      );
    case 'practiceArea': {
      return data?.all
        ?.filter(
          item =>
            item.paId &&
            (state?.managedBy?.value !== 'Geo Managed' ||
              item.practiceArea === 'No PA')
        )
        ?.map(({ practiceArea: paTeam, paId }) =>
          dropdownFormat(paId, CONFIG.PA_NAME_ALIAS_MAP[paTeam] || paTeam)
        );
    }

    case 'segment': {
      return data[state?.practiceArea?.value || '']
        ?.filter(item => item.topicSectorId)
        ?.map(({ topicSectorId, sector }) =>
          dropdownFormat(topicSectorId, sector)
        );
    }

    case 'subSegment': {
      return data[state?.segment?.value || '']
        ?.filter(item => item.subSectorId)
        ?.map(({ subSectorId, subSector }) =>
          dropdownFormat(subSectorId, subSector)
        );
    }

    default:
      return [];
  }
};

/**
 *
 * @param   {String} selectedManagedBy  - selected managed by value
 * @param   {Object} state              - current app state
 * @returns {Object}                    - Practice area data
 */
const selectDefaultPa = (selectedManagedBy, state) => {
  // for geo Managed select NO PA by default if its not selected
  if (
    selectedManagedBy === 'Geo Managed' &&
    state?.practiceArea?.label !== 'No PA'
  ) {
    const selectedPa = state?.paList?.all?.find(
      item => item.practiceArea === 'No PA'
    );
    return {
      value: selectedPa?.paId,
      label: selectedPa?.practiceArea,
    };
  }
};

/**
 *
 * @param   {object} selectedItem  - selected dropdown item
 * @param   {Object} state         - current state
 * @returns {Object}               - new state object to update
 */
export const handleGeoMarketChange = (selectedItem, state) => {
  const formData = {
    segment: null,
    subSegment: null,
    geoMarket: selectedItem,
  };
  if (state?.practiceArea?.label !== 'No PA') {
    formData.practiceArea = null;
  }
  return formData;
};

/**
 *
 * @param   {object} selectedItem  - selected dropdown item
 * @param   {Object} state         - current state
 * @returns {Object}               - new state object to update
 */
export const handleManagedByChange = (selectedItem, state) => {
  const formData = {};
  if (selectedItem?.value === 'PA Managed') {
    formData.geoMarket = null;
  } else if (selectedItem?.value === 'Geo Managed') {
    const defaultPa = selectDefaultPa(selectedItem?.value, state);
    if (defaultPa) formData.practiceArea = defaultPa;
    formData.segment = null;
    formData.subSegment = null;
  }

  formData.managedBy = selectedItem;
  return formData;
};

/**
 *
 * @param   {object} selectedItem  - selected dropdown item
 * @param   {Object} state         - current state
 * @param   {Object} dispatch      - redux state dispatch method
 * @returns {Object}               - new state object to update
 */
export const handlePracticeAreaChange = async (
  selectedItem,
  state,
  dispatch
) => {
  const formData = {
    practiceArea: selectedItem,
  };
  if (selectedItem?.value) {
    formData.segment = null;
    formData.subSegment = null;
    const sectorOptions = state?.sectorList[selectedItem?.value];
    if (!sectorOptions?.length) {
      await dispatch(getSectorList(selectedItem?.value));
    }
  }

  return formData;
};

/**
 *
 * @param   {object} selectedItem  - selected dropdown item
 * @param   {Object} state         - current state
 * @param   {Object} dispatch      - redux state dispatch method
 * @returns {Object}               - new state object to update
 */
export const handleSegmentChange = async (selectedItem, state, dispatch) => {
  const formData = {
    segment: selectedItem,
  };
  if (selectedItem?.value) {
    formData.subSegment = null;
    const sectorOptions = state.subSectorList[selectedItem?.value];
    if (!sectorOptions?.length) {
      await dispatch(getSubSectorList(selectedItem?.value));
    }
  }
  return formData;
};

/**
 * @param   {String} type          - field name
 * @param   {object} selectedItem  - selected dropdown item
 * @param   {Object} state         - current state
 * @param   {Object} dispatch      - redux state dispatch method
 * @returns {Object}               - new state object to update
 */
export const handleDropdownChange = async (
  type,
  selectedItem,
  componentState,
  dispatch
) => {
  let formData = {};
  switch (type) {
    case 'geoMarket':
      formData = handleGeoMarketChange(selectedItem, componentState);
      break;
    case 'managedBy':
      formData = handleManagedByChange(selectedItem, componentState);
      break;
    case 'practiceArea':
      formData = await handlePracticeAreaChange(
        selectedItem,
        componentState,
        dispatch
      );
      break;
    case 'segment':
      formData = await handleSegmentChange(
        selectedItem,
        componentState,
        dispatch
      );
      break;

    default:
      formData = { [type]: selectedItem };
  }

  return formData;
};

/**
 *
 * @param   {Object} userInfo - selected user Info to edit
 * @param   {Object} state    - current app state
 * @returns {Object}          - formated data for form submit
 */
export const preparePostData = (userInfo, state) => {
  const { employeeIdHRO, id } = userInfo;

  return {
    id,
    employeeIdHRO,
    teamManager: state?.teamManager,
    paId: state?.practiceArea?.value,
    topicSectorId: state?.segment?.value,
    subSectorId: state?.subSegment?.value,
    geoRegion: state?.geoMarket?.value,
    slotting: state?.path?.value,
    managedBy: state?.managedBy?.value,
    effectiveDate: state?.effectiveDate
      ? moment(
          state?.effectiveDate,
          CONFIG.DATE_TIME_FORMAT.SELF_SERVICE
        ).format('YYYY-MM-DD')
      : null,
  };
};

/**
 *
 * @param   {Object} postData - form post data
 * @returns {Object}          - list of errors
 */
export const validateFormData = postData => {
  const errors = {};

  const requiredFields = {
    id: 'Id is required field',
    employeeIdHRO: 'Employee Id is required field',
    teamManager: 'Please enter Team manager name',
    paId: 'Please select Practice Area',
    managedBy: 'Please select Managed By',
    effectiveDate: 'Please select Effective Date',
    slotting: 'Please select Path',
  };

  Object.keys(requiredFields).forEach(field => {
    if (!postData[field]) {
      errors[field] = requiredFields[field];
    }
  });

  //Topic sector Id is mandatory for co managed
  if (postData.managedBy === 'PA & Geo Managed' && !postData.topicSectorId) {
    errors['topicSectorId'] = 'Please select Segment';
  }

  //Geo region is mandatory for all other than Pa Managed
  if (postData.managedBy !== 'PA Managed' && !postData.geoRegion) {
    errors['geoRegion'] = 'Please select Geo market';
  }

  return errors;
};

/**
 *
 * @param   {String}   buttonAction - action to perform
 * @returns {Function}              - function related to action
 */
export const getApiActionMethod = buttonAction => {
  const apiMethods = {
    edit_user: updateActiveUserInfo,
    change_alignment: addNewAlignment,
    activate: saveUserAndActivate,
  };

  return apiMethods[buttonAction] || saveUserInfoAsDraft;
};

/**
 *
 * @param   {String} rowEffectiveDate - date string
 * @param   {String} action           - action to perform on edit screen
 * @returns {Date}                    - minmum date value
 */
export const getMinDate = (rowEffectiveDate, action) => {
  return isChangeAlignment(action)
    ? moment(new Date(rowEffectiveDate)).add(1, 'day')
    : moment().subtract(1, 'year');
};

/**
 *
 * @param   {String} value
 * @param   {String} label
 * @returns {object}
 */
export const dropdownOptionFormat = (value, label) => {
  const hasValueOrlabel = value || label;
  return hasValueOrlabel
    ? {
        value,
        label,
      }
    : null;
};

/**
 *
 * @param   {String} action
 * @returns {Boolean}
 */
export const isChangeAlignment = action => action === 'change_alignment';

/**
 *
 * @param   {String} action
 * @returns {Boolean}
 */
export const isEditUser = action => action === 'edit_user';

/**
 *
 * @param   {String} action
 * @param   {String} rowEffectiveDate
 * @returns {String}                - formted date string
 */
export const getEffectiveDate = (action, rowEffectiveDate) => {
  return isChangeAlignment(action)
    ? moment(rowEffectiveDate).add(1, 'day').format('MMM DD yyyy')
    : formatDate(rowEffectiveDate, 'MMM dd yyyy');
};

/**
 *
 * @param {Object} userData
 * @param {Object} dispatch
 */
export const getPaSectorSubSectorList = (userData, dispatch) => {
  dispatch(getPaList());
  userData?.paId && dispatch(getSectorList(userData?.paId));
  userData?.topicSectorId &&
    dispatch(getSubSectorList(userData?.topicSectorId));
};

/**
 *
 * @param   {String} buttonAction
 * @returns {String}
 */
export const getMessageByButtonAction = buttonAction => {
  return `Sucessfully saved User details 
  ${buttonAction === 'activate' && 'and activated the user'}`;
};
