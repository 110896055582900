import React, { Component } from 'react';
import Spinner from 'components/common/Spinner/Spinner';
import LineInput from 'components/common/CommonHtml/lineInput';
import { isEmpty, isEqual } from 'utils/common';
import './MemberSearch.scss';

class MemberSearch extends Component {
  constructor(props) {
    super(props);
    const { placeholder } = this.props;
    this.state = {
      searchValue: '',
      searchedResultList: [],
      showSearchedResultList: false,
      placeholder: placeholder,
      isSpinnerVisible: false,
    };
    this.minimumCharacterForSearch = 3;
    this.typingTimer = null;
  }

  /**
   * This method will do the server call to get the searched result
   * after entering the 3 character
   */
  searchResultHandler = event => {
    const { onSearch, placeholder, isCollectionSearch } = this.props;
    if (!isCollectionSearch && onSearch) {
      onSearch();
    }
    event.stopPropagation();
    let searchValue = event.target.value;
    if (searchValue.trim().length >= this.minimumCharacterForSearch) {
      this.setState({ isSpinnerVisible: true, searchValue });
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.getSearchedData.bind(this, searchValue),
        250
      );
    } else {
      this.setState({
        placeholder,
        isSpinnerVisible: false,
        searchedResultList: [],
        showSearchedResultList: false,
        searchValue,
      });
    }
  };

  getSearchedData = searchValue => {
    clearTimeout(this.typingTimer);
    const { searchList } = this.props;
    const { placeholder, isCollectionSearch } = this.props;
    let setNoDataVal = false;
    if (searchValue) {
      const searchKey = isCollectionSearch ? 'name' : 'employeeName';
      const searchedResult =
        searchList.filter(el =>
          el[searchKey].toLowerCase().includes(searchValue.toLowerCase())
        ) || [];
      if (searchedResult.length > 0) {
        this.setState({
          isSpinnerVisible: false,
          searchedResultList: searchedResult,
          showSearchedResultList: true,
        });
      } else {
        setNoDataVal = true;
      }
    } else {
      setNoDataVal = true;
    }
    if (setNoDataVal) {
      this.setState({
        placeholder,
        isSpinnerVisible: false,
        searchedResultList: [{ searchResult: 'No result found' }],
        showSearchedResultList: true,
      });
    }
  };

  getMasterSearchList = () => {
    const { searchedResultList } = this.state;
    const { isCollectionSearch } = this.props;
    const fieldName = isCollectionSearch ? 'name' : 'employeeName';
    return (
      searchedResultList &&
      searchedResultList.map((item, index) => {
        const noResult = isEqual(item.searchResult, 'No result found');
        return (
          <div
            className={`kt-searched-item ${noResult ? 'disabled' : ''}`}
            onClick={() => this.handleClick(item)}
            key={index}
          >
            {noResult ? item.searchResult : item[fieldName]}
          </div>
        );
      })
    );
  };

  /**
   * This method will update the state to hide searched report list after 100 millisecond
   */
  hideResultList = () => {
    this.setState({
      placeholder: this.props.placeholder,
    });
    setTimeout(() => {
      this.setState({ showSearchedResultList: false });
    }, 250);
  };

  handleClick = selectedValue => {
    const { onClick, placeholder, isCollectionSearch } = this.props;
    setTimeout(() => {
      this.setState({
        showSearchedResultList: false,
        searchedResultList: [],
        searchValue: '',
        placeholder,
      });
    }, 500);
    return (
      onClick &&
      onClick(
        isCollectionSearch ? selectedValue.id : selectedValue.employeeIdHRO,
        selectedValue.employeeName
      )
    );
  };

  /**
   * This method will update the state to show searched report list
   */
  showResultList = () => {
    const { searchValue, searchedResultList } = this.state;
    if (searchValue && searchValue.length > 2 && !isEmpty(searchedResultList)) {
      this.setState({ showSearchedResultList: true, placeholder: '' });
    } else {
      this.setState({ showSearchedResultList: false, placeholder: '' });
    }
  };

  /**
   * React Life Cycle method - re-render the view on state update
   */
  render() {
    const {
      searchValue,
      placeholder,
      isSpinnerVisible,
      showSearchedResultList,
    } = this.state;
    const { disableSearch } = this.props;
    return (
      <div
        className={`${disableSearch ? 'disabled' : ''} 
                ${
                  (searchValue &&
                    searchValue.length > 2 &&
                    showSearchedResultList) ||
                  (searchValue &&
                    searchValue.length === 0 &&
                    showSearchedResultList)
                    ? 'kt-opened-list '
                    : ''
                }kt-member-rectangle`}
      >
        <div className="kt-member-container">
          <LineInput
            className="kt-member-input"
            placeholder={placeholder}
            type="text"
            value={searchValue}
            name="MemberSearch"
            handleChange={this.searchResultHandler}
            handleBlur={this.hideResultList}
            handleFocus={this.showResultList}
          />
        </div>
        {isSpinnerVisible ? (
          <Spinner />
        ) : (
          <img
            className="kt-img-icon"
            src={'/img/KtSearch.svg'}
            alt="search"
            height="15"
            width="15"
          />
        )}
        {/* {searchValue && searchValue.length > 2 && showSearchedResultList ? ( */}
        <div className="kt-member-result-container">
          <div className="kt-member-result-list">
            {this.getMasterSearchList()}
          </div>
        </div>
        {/* ) : null} */}
      </div>
    );
  }
}

export default MemberSearch;
