import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import ToolTipShowEdit from 'components/common/ToolTipShowEdit';
import { isEqual } from 'utils/common';
import KTDownloads from 'components/KTDownloads';
import KTChargesBillRate from './KTChargesBillRate';
import { globalAdobeAnalytics } from 'config/analytics';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { isAnalyst } from 'utils';
import KTAnalystFilters from 'components/KTAnalystFilters/KTAnalystFilters';

const getHeading = activeIndex => {
  let heading = '',
    keyName = '';
  switch (activeIndex) {
    case 0:
      heading = 'Charges/Hours by Team Member';
      keyName = 'chargesHours';
      break;
    case 1:
      heading = 'Bill Rate by Team Member';
      keyName = 'billRate';
      break;
    default:
      heading = '';
      keyName = '';
  }
  return { heading, keyName };
};

const KTCharges = props => {
  const { adobeData, loggedInUserRole, appliedFilters } = props;
  const isAnalystUser = isAnalyst(loggedInUserRole);
  const location = useLocation();
  let path = location.pathname;
  const activeIndex = isEqual(path, CONFIG.PROTOTYPE_URLS.CHARGES_HOURS)
    ? 0
    : 1;
  const [showTooltip, setShowToolTip] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const currentPage = getHeading(activeIndex);
  useEffect(() => {
    if (!adobeData?.[currentPage.keyName]) {
      const isScriptLoaded = globalAdobeAnalytics(
        `KTDashboard-${currentPage.heading}`
      );
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData, activeIndex]);

  const updateAdobeData = () => {
    if (!adobeData?.[currentPage.keyName])
      props.updateAdobeDetails({ [currentPage.keyName]: true });
  };

  const closeToolTipBox = () => {
    setShowToolTip(false);
  };

  const getDownloadsData = selectedFilters => {
    setSelectedValues(selectedFilters);
  };

  const onDateChange = (startDate, endDate, dateFilterType, timePeriodType) => {
    let dateAppliedFilters = {
      startDate,
      endDate,
      dateFilterType,
      isDateRangeChanged: true,
      selectedUserId: appliedFilters.selectedUserId,
      timePeriodType,
    };
    props.saveAppliedFilters(dateAppliedFilters);
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column className="Kt_main_body_heading_left" width={8}>
          {isAnalystUser ? (
            <KTAnalystFilters
              onDateChange={onDateChange}
              isDetailedViewOpen={true}
            />
          ) : null}
        </Grid.Column>
        <Grid.Column
          className="Kt_main_body_heading_right"
          width={2}
          floated="right"
        >
          <KTDownloads
            showDownloadModal={isEqual(activeIndex, 0)}
            reportName={CONFIG.CHARGES_REPORT_NAMES[activeIndex]}
            url={CONFIG.CHARGES_DOWNLOAD_URL[activeIndex]}
            pageUrl={`KTDashboard-${currentPage.heading}`}
            selectedFilters={selectedValues}
            buttonName="Export"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column className="Kt_main_body_tabs" width={16}>
        <KTChargesBillRate
          selectedIndex={activeIndex}
          getDownloadsData={getDownloadsData}
          isAnalystUser={isAnalystUser}
        />
      </Grid.Column>
      {showTooltip && (
        <ToolTipShowEdit
          showPopup={showTooltip}
          handlePopupClose={closeToolTipBox}
          popupSelectedText={getHeading()}
          popupBody={CONFIG.CHARGES_HOURS_TOOLTIP_TEXT[activeIndex]}
          showEditIcon={false}
          hideEditInfo={true}
          popupBodyClassName="infoText"
        />
      )}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    adobeData: store.adobeData.adobeAnalyticsData,
    loggedInUserRole: store.homePageData.loggedInUserRole,
    appliedFilters: store.filterDataPt.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateAdobeDetails: bindActionCreators(
      actions.adobeAnalyticsActions.updateAdobeDetails,
      dispatch
    ),
    saveAppliedFilters: bindActionCreators(
      actions.protoTypeActions.saveAppliedFilters,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTCharges);
