import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Message, Progress } from 'semantic-ui-react';

import { isEmpty, downloadFile } from 'utils/common';
import { getApiFailMessage } from 'utils';
import CONFIG from 'config/configProps';
import { downloadBillabilityData } from 'redux/actions/tableauStackAction';
import { updateToastNotificationDetails } from 'redux/actions';
import Button from 'components/common/button/Button';
import Icon from 'components/common/icon';
import './BillabilityDownload.scss';

const BillabilityDownload = props => {
  const {
    tableauStringData,
    dispatch,
    defaultMemberList,
    isManager,
    employeeInitialData,
    detailedViewPage,
  } = props;
  const { employeeIdHRO } = employeeInitialData;
  const [downLoadStatus, setDownloadStatus] = useState({
    uploading: false,
    percentCompleted: 0,
  });
  const { percentCompleted, downloading } = downLoadStatus;
  const [postData, setPostData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const downloadBillability = useCallback(() => {
    if (!isEmpty(postData)) {
      setDownloadStatus({ downloading: true, percentCompleted: 0 });
      dispatch(downloadBillabilityData(postData, setDownloadStatus))
        .then(response => {
          downloadFile(
            response.data,
            'KT_DASHBOARD_BILLING_DETAILS.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
        })
        .catch(error => {
          const { status, data } = error;
          dispatch(
            updateToastNotificationDetails(
              getApiFailMessage(status, data?.detailErrorMessage)
            )
          );
        })
        .finally(() => {
          setDownloadStatus({ downloading: false, percentCompleted: 100 });
        });
    }
  }, [dispatch, postData]);

  const getSelectedUserId = useCallback(() => {
    const { selectedTeamMembers } = tableauStringData;

    if (!isManager) {
      return [employeeIdHRO];
    }

    return isEmpty(selectedTeamMembers)
      ? defaultMemberList
      : selectedTeamMembers;
  }, [tableauStringData, defaultMemberList, employeeIdHRO, isManager]);

  useEffect(() => {
    const { initialDate, calenderDropdownVal: timePeriod } = tableauStringData;

    const selectedUserId = getSelectedUserId();
    const allParamsExist =
      initialDate && timePeriod && !isEmpty(selectedUserId);
    if (allParamsExist) {
      setPostData({
        month: initialDate.getMonth() + 1,
        year: initialDate.getFullYear(),
        timePeriod,
        selectedUserId,
      });
    }
  }, [tableauStringData, defaultMemberList, getSelectedUserId]);

  useEffect(() => {
    if (percentCompleted >= 100) {
      setTimeout(() => setShowModal(false), 500);
    }
  }, [percentCompleted]);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const showDownload =
    detailedViewPage === CONFIG.DETAILED_VIEW_TOKEN.CASE_BILLABILITY_SPLIT;
  if (!showDownload) {
    return null;
  }

  return (
    <>
      <div className="download-container">
        <Button
          onClick={openModal}
          disabled={downloading}
          icon={<Icon name="download" />}
          className="download-container__btn"
          content="Download"
        />
      </div>
      <Modal
        className="download-modal"
        size="tiny"
        open={showModal}
        onClose={closeModal}
      >
        <Modal.Header className="download-modal__header">
          Download billability data
        </Modal.Header>
        {downloading && (
          <Progress
            percent={percentCompleted}
            color="green"
            size="tiny"
            attached="bottom"
            active
          />
        )}
        <Modal.Content className="download-modal__content">
          <Message info className="download-modal__content__info">
            Download billability data for your own analysis. Validate your date
            and filter settings and click Download. Large requests can take
            several minutes to process.
          </Message>
        </Modal.Content>
        <Modal.Actions className="download-modal__footer">
          <Button
            onClick={closeModal}
            className="download-modal__footer__cancel-btn"
            icon={<Icon name="cancel" />}
            disabled={downloading}
            content="Cancel"
          />
          <Button
            onClick={downloadBillability}
            className="download-modal__footer__download-btn"
            icon={!downloading && <Icon name="download" />}
            disabled={downloading}
            content={downloading ? 'Downloading...' : 'Download'}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const mapStateToProps = store => {
  return {
    employeeInitialData: store.userRoleData.employeeInitialData,
    tableauStringData: store.tableauStackData.tableauStringData,
    isManager: store.userRoleData.isManager,
    defaultMemberList: store.headerFilterData.defaultMemberList,
  };
};
export default connect(mapStateToProps)(BillabilityDownload);
