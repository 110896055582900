import React from 'react';
import Icon from 'components/common/Icons';
import CaseBillabilityLoader from 'components/KTTableauStackWrapper/KTCaseBillability/KTCaseBillabilityLoader';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import KTCaseBillabilityTeamPlaceholder from 'components/KTTableauStackWrapper/KTCaseBillabilityTeam/KTCaseBillabilityTeamPlaceholder';
import { getHeaderTextCaseBillabilitySplit } from 'utils';

const getCaseBillabilityClassName = isManager => {
  return isManager ? 'manager-case-billability' : 'member-case-billability';
};

const getCaseBillabilityByYouClassName = isManager => {
  return isManager
    ? 'manager-case-billability-split'
    : 'member-case-billability-split';
};

const TopicLoader = props => {
  const { isManager, heading } = props;
  const caseBillabilityClassName = getCaseBillabilityClassName(isManager);
  const caseBillabilityByYouClassName =
    getCaseBillabilityByYouClassName(isManager);

  return (
    <div className="tile-stack">
      <div className="tile-stack-header">
        <div className="header-left-text">
          <div className="heading">{heading}</div>
          <div className="dates">
            <KtLabelWithSkeleton isLoading={1} showLine size="medium" />
          </div>
        </div>
      </div>
      <div className="tile-stack-body">
        <div className="tableau-card-container">
          <div className={`case-billability ${caseBillabilityClassName}`}>
            <div className="card-heading">
              <span className="heading-text">Case Billability</span>
              <span className="heading-image">
                <Icon
                  name="tablue-arrow"
                  className=""
                  alt="arrow"
                  src="/img/tablue-arrow.svg"
                />
              </span>
            </div>
            <CaseBillabilityLoader isLoading width="medium" showLabelValue />
          </div>

          <div
            className={`case-billability-split ${caseBillabilityByYouClassName} 
					`}
          >
            <div className="card-heading border-bottom">
              <span className="heading-text hide-edit">
                {getHeaderTextCaseBillabilitySplit(props)}
              </span>
              <span className="heading-middle-text">Work Split</span>
              {/* <span className="heading-right-text">Case Engagement Depth</span> */}
              <span className="heading-image">
                <Icon
                  name="tablue-arrow"
                  className=""
                  alt="arrow"
                  src="/img/tablue-arrow.svg"
                />
              </span>
            </div>
            <div className="chart-container">
              {isManager ? (
                <KTCaseBillabilityTeamPlaceholder isLoading={1} width="large" />
              ) : (
                <KTCaseBillabilityTeamPlaceholder
                  isLoading={1}
                  width="x-large"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopicLoader;
