import * as types from '../actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'userRoleData';

const userRoleReducer = function (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case types.UPDATE_EMPlOYEE_INITIAL_DATA:
      return Object.assign({}, state, {
        employeeInitialData: action.data,
      });

    case types.UPDATE_LOGGED_IN_EMPLOYEE_DATA: {
      return Object.assign({}, state, {
        loggedInEmployeeData: action.data,
      });
    }

    case types.UPDATE_LOGGED_IN_KT_USER_DATA: {
      return Object.assign({}, state, {
        loggedInKtUserDetails: action.data,
      });
    }

    case types.GET_KT_USER_DETAILS: {
      return Object.assign({}, state, {
        ktUserDetails: action.ktUserDetails,
      });
    }

    case types.UPDATE_USER_TYPES: {
      return Object.assign({}, state, {
        userTypes: action.userTypes,
      });
    }

    case types.UPDATE_USER_TYPE_DATA: {
      return Object.assign({}, state, {
        userTypeData: action.userTypeData,
      });
    }

    case types.UPDATE_USER_HIGHEST_ROLE: {
      return Object.assign({}, state, {
        userHighestRole: action.data,
      });
    }

    case types.UPDATE_HIGHEST_ROLE_OF_LOGGED_IN_USER:
      return Object.assign({}, state, {
        loggedInUserHighestRole: action.data,
      });

    case types.IS_GEO_MANAGER: {
      return Object.assign({}, state, {
        isGeoManager: action.isGeoManager,
      });
    }

    case types.IS_GEO_MANAGED: {
      return Object.assign({}, state, {
        isGeoManaged: action.isGeoManaged,
      });
    }

    case types.IS_GEO_LEAD: {
      return Object.assign({}, state, {
        isGeoLead: action.isGeoLead,
      });
    }

    case types.SET_MANAGER_VALUE:
      return Object.assign({}, state, {
        isManager: action.data,
      });

    case types.SET_ACCESS_FLAG_FOR_KT_FROM_OKTA: {
      return Object.assign({}, state, {
        haveAccessForKTFromOkta: action.data,
      });
    }

    default:
      return state;
  }
};

export default userRoleReducer;
