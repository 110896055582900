import { SET_USER_LIST } from '../actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'selfServiceData';

const selfServiceReducer = (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) => {
  if (action.type === SET_USER_LIST) {
    return {
      ...state,
      userList: action.data,
    };
  }

  return state;
};

export default selfServiceReducer;
