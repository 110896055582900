import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SectionFteSkeleton = ({ showSectionsFte }) => {
  return showSectionsFte ? (
    <>
      <Skeleton width={30} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;
      <Skeleton width={75} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;
      <Skeleton width={4.5} height={4.5} duration={1} />
      <br />
    </>
  ) : null;
};

export default SectionFteSkeleton;
