import React from 'react';
import { Message } from 'semantic-ui-react';

const NotificationMessage = ({
  addedUsers,
  duplicateUsers,
  invalidMappedUsers,
}) => {
  return (
    <div className="upload-notification">
      {Boolean(addedUsers?.length) && (
        <Message
          success
          size="tiny"
          className="upload-notification__added-user-list"
          header="Newly added User ID's : "
          list={addedUsers}
        />
      )}
      {Boolean(duplicateUsers?.length) && (
        <Message
          warning
          className="upload-notification__duplicate-user-list"
          size="tiny"
          header="Duplicate User ID's : "
          list={duplicateUsers}
        />
      )}
      {Boolean(invalidMappedUsers?.length) && (
        <Message
          error
          className="upload-notification__invalid-user-list"
          size="tiny"
          header="Invalid User ID's : "
          list={invalidMappedUsers}
        />
      )}
    </div>
  );
};

export default NotificationMessage;
