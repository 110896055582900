import React, { Fragment } from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import './Icon.scss';
import WithTooltip from '../withTooltip';

export default function Icon({
  description,
  descriptionClass,
  secondaryIcon,
  customClassName,
  tooltipText,
  name,
  tooltipPosition,
  ...props
}) {
  const cssClass = `d-inline-flex iconContainer ${
    customClassName ? customClassName : ''
  }`;
  return (
    <div className={cssClass}>
      {Boolean(name) && (
        <Fragment>
          {tooltipText ? (
            WithTooltip(<SemanticIcon name={name} {...props} />)(tooltipText, {
              inverted: true,
              position: tooltipPosition,
            })
          ) : (
            <SemanticIcon name={name} {...props} />
          )}
        </Fragment>
      )}
      {Boolean(description) && (
        <div>
          <span className={descriptionClass}>{description}</span>
        </div>
      )}
      {Boolean(secondaryIcon) && <div> {secondaryIcon}</div>}
    </div>
  );
}
