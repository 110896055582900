import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import SubMenu from './SubMenu';
import { preventDefault } from 'utils/common';
import Icon from 'components/common/icon/Icon';

const MenuItem = ({ item, pathname, showIcon }) => {
  const [showSubMenu, setShowSubmenu] = useState({});

  const toggelSubMenu = subMenuId => () => {
    setShowSubmenu({
      ...showSubMenu,
      [subMenuId]: !showSubMenu[subMenuId],
    });
  };

  const getArrowIcon = () => {
    if (hasSubMenu()) {
      return isSubMenuOpen() ? 'up' : 'down';
    } else {
      return 'right';
    }
  };

  const hasSubMenu = () => {
    return Boolean(item.subMenuItems?.length);
  };

  const isSubMenuOpen = () => {
    return showSubMenu[`sub-menu-${item.key}`] || isActive(item);
  };

  const subMenuClassName = () => {
    return isSubMenuOpen()
      ? 'sidebar-item__sub-menu sidebar-item__sub-menu--show'
      : 'sidebar-item__sub-menu';
  };

  const isActive = useCallback(
    itemData => {
      return (
        pathname === itemData.link ||
        itemData.subMenuItems?.some(({ link }) => link === pathname)
      );
    },
    [pathname]
  );

  return (
    <>
      <div
        className={`sidebar-item__label ${isActive(item) ? 'active' : ''} ${
          item.showBorder ? 'borderTop' : ''
        }`}
        onClick={hasSubMenu() ? toggelSubMenu(`sub-menu-${item.key}`) : null}
      >
        {item.isMailLink ? (
          <a href={item.link}>{item.text}</a>
        ) : (
          <Link
            to={item.link || pathname}
            onClick={() => (item.link ? null : preventDefault)}
          >
            {/* <Icon name={item.icon} /> */}
            {item.text}
          </Link>
        )}
        {showIcon && <Icon name={`angle ${getArrowIcon()}`} />}
      </div>
      {hasSubMenu() && (
        <SubMenu
          id={`sub-menu-${item.key}`}
          menuItems={item.subMenuItems}
          className={subMenuClassName()}
          isActive={isActive}
        />
      )}
    </>
  );
};

export default MenuItem;
