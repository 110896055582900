import React from 'react';

import DataTable from 'components/common/DataTable';
import { processUserList } from 'utils';
import CONFIG from 'config/configProps';
import './UserList.scss';

const UserHistory = ({ loading, userHistory, titleClassName, columns }) => {
  columns = [
    ...columns,
    {
      title: 'Expiry Date',
      dataField: 'rowExpiryDate',
      renderColumn: { type: 'Date', format: 'MMM-dd, yyyy' },
    },
  ];

  return (
    <>
      <h3 className={titleClassName}>User History</h3>
      <DataTable
        id="user-list-table"
        className="user-list-table"
        loading={loading}
        dataList={processUserList(userHistory)}
        columns={columns?.length ? columns : CONFIG.USER_LIST_COLUMNS}
      />
    </>
  );
};

export default UserHistory;
