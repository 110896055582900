import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateToastNotificationDetails,
  showTooltipPopup,
} from 'redux/actions';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import {
  getApiFailMessage,
  shouldGetTableauStackApiData,
  getSelectedUserId,
} from 'utils';
import { fetchPost } from 'utils/KtCommonServices';
import Icon from 'components/common/Icons';
import CONFIG_KTS from 'config/configProps';
import store from 'redux/store';
import KtKnPlaceholder from 'components/KTTableauStackWrapper/KTKnDeck/KtKnPlaceholder';
import { isEmpty, cloneDeep } from 'utils/common';
import { OverlayTrigger, Popover } from 'react-bootstrap';
class KTMostDownloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.tableauParamString) {
      this.getApiData();
    }
  }

  // /**
  //  *
  //  * React Life cycle method
  //  */
  componentDidUpdate(prevProps) {
    if (shouldGetTableauStackApiData(this.props, prevProps)) {
      this.getApiData();
    }
  }

  getApiData = () => {
    const {
      apiRequestData,
      selectedTeamMembers,
      defaultMemberList,
      isAllSelected,
      isManager,
    } = this.props;
    const apiData = cloneDeep(apiRequestData);
    this.setState({ isLoading: true });
    apiData['selectedUserId'] = getSelectedUserId({
      isAllSelected,
      isManager,
      selectedTeamMembers,
      defaultMemberList,
    });

    this.getMostDownloadData(apiData);
  };

  getMostDownloadData = apiRequestData => {
    let apiIdentifier = API_CALL_IDENTIFIERS.FETCH_MOST_DOWNLOADS_DATA;
    const url = CONFIG_KTS.API_URLS.MOST_DOWNLOADS;
    const baseUrl = CONFIG_KTS.KT_CONTENT_API_URL;
    store.dispatch(beginAPICall(apiIdentifier));
    fetchPost(url, apiRequestData, baseUrl)
      .then(result => {
        this.setState({ apiData: result.data, isLoading: false });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
        this.setState({ apiData: [], isLoading: false });
      })
      .finally(() => store.dispatch(endAPICall(apiIdentifier)));
  };

  renderMostDownloadsList = () => {
    const { apiData, isLoading } = this.state;
    const noData = !isLoading && isEmpty(apiData.knPageDetails);

    if (isLoading) {
      return <KtKnPlaceholder isLoading={1} section="Most Downloaded" />;
    }

    return noData ? (
      <>No Records To Display</>
    ) : (
      <>
        <div className="body-heading">
          <div className="totalMetricName content-padding">{`Total`}</div>
          <div className="totalMetricValue content-padding">
            {apiData.totalDownloads}
          </div>
        </div>
        <div className="most-download-body">
          {apiData.knPageDetails.map((el, index) => {
            return (
              <div className="body-content content-body-padding" key={index}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="tooltip-basic">
                      <Popover.Content>{el.materialName}</Popover.Content>
                    </Popover>
                  }
                >
                  <a
                    className="metricName"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={el.materialUrl}
                  >{`${index + 1}. ${el.materialName}`}</a>
                </OverlayTrigger>
                <div className="metricValue">{el.downloadCount}</div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  render() {
    const { apiData, isLoading } = this.state;
    const { InfoText } = this.props;
    const noData = !isLoading && isEmpty(apiData.knPageDetails);
    return (
      <div className={`kt-most-download ${this.props.className}`}>
        <div className="card-heading border-bottom height9">
          <span className="heading-text">Top 10 - Most Downloaded</span>
          {InfoText ? (
            <div
              className="tooltip-with-icon homeTileToolTip"
              onClick={() => {
                store.dispatch(
                  showTooltipPopup({
                    showTooltip: true,
                    tooltipName: 'Top 10 - Most Downloaded',
                  })
                );
              }}
            >
              <Icon
                name="info-tableau"
                class="info-icon"
                alt="infoIcon"
                src="/img/info-tableau.svg"
              />
            </div>
          ) : null}
        </div>
        <div className={`${noData ? 'no-data-text' : ''} tableau-card-body`}>
          {this.renderMostDownloadsList()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (reduxStore) {
  return {
    apiRequestData: reduxStore.tableauStackData.apiRequestData,
    defaultMemberList: reduxStore.headerFilterData.defaultMemberList,
    isAllSelected: reduxStore.headerFilterData.isAllSelected,
    isManager: reduxStore.userRoleData.isManager,
    selectedTeamMembers: reduxStore.headerFilterData.selectedTeamMembers,
    tableauParamString: reduxStore.tableauStackData.tableauParamString,
    tableauStringData: reduxStore.tableauStackData.tableauStringData,
  };
};

export default connect(mapStateToProps)(KTMostDownloads);
