import React, { Fragment } from 'react';
import { Label } from 'semantic-ui-react';
import './AppliedFilterLabel.scss';
import Icon from 'components/common/icon';
import WithTooltip from '../withTooltip';
import FilterLabel from './Views/FilterLabel';

const AppliedFilterLabel = ({
  filterTitle,
  filterValue,
  showIcon,
  onIconClick,
  iconName,
  className,
  iconHoverTitle,
  showSeparator,
  invert,
  id,
  filterTooltipText,
  ...props
}) => (
  <div className={` filterContainer  ${className ? className : ''}`} {...props}>
    <Label className={`filterLabel ${invert ? 'invert' : ''}`}>
      {filterTooltipText ? (
        <Fragment>
          {WithTooltip(
            <FilterLabel {...{ filterTitle, showSeparator, filterValue }} />
          )(filterTooltipText, { inverted: true })}
        </Fragment>
      ) : (
        <FilterLabel {...{ filterTitle, showSeparator, filterValue }} />
      )}
    </Label>
    {showIcon && (
      <Icon
        name={iconName}
        id={id}
        onClick={onIconClick}
        datavalue={filterValue}
        tooltipText={iconHoverTitle}
      />
    )}
  </div>
);

export default AppliedFilterLabel;
