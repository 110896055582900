import * as types from './actionTypes';

export function updateEmployeeInitialData(data) {
  return {
    type: types.UPDATE_EMPlOYEE_INITIAL_DATA,
    data,
  };
}

export function updateLoggedInEmployeeData(data) {
  return {
    type: types.UPDATE_LOGGED_IN_EMPLOYEE_DATA,
    data,
  };
}

export function updateLoggedInKtUserDetails(data) {
  return {
    type: types.UPDATE_LOGGED_IN_KT_USER_DATA,
    data,
  };
}

export function getKtUserDetails(ktUserDetails) {
  return { type: types.GET_KT_USER_DETAILS, ktUserDetails };
}

export function updateUserTypes(userTypes) {
  return { type: types.UPDATE_USER_TYPES, userTypes };
}

export function updateUserTypeData(userTypeData) {
  return { type: types.UPDATE_USER_TYPE_DATA, userTypeData };
}

export function updateUserHighestRole(data) {
  return {
    type: types.UPDATE_USER_HIGHEST_ROLE,
    data,
  };
}

export function updateLoggedInUserHighestRole(data) {
  return {
    type: types.UPDATE_HIGHEST_ROLE_OF_LOGGED_IN_USER,
    data,
  };
}

export function updateIsGeoManager(isGeoManager) {
  return { type: types.IS_GEO_MANAGER, isGeoManager };
}

export function updateUserIsGeoManaged(isGeoManaged) {
  return { type: types.IS_GEO_MANAGED, isGeoManaged };
}

export function updateIsGeoLead(isGeoLead) {
  return { type: types.IS_GEO_LEAD, isGeoLead };
}

export function setManagerValue(data) {
  return {
    type: types.SET_MANAGER_VALUE,
    data,
  };
}

export function setAccessFlagForKTFromOkta(data) {
  return {
    type: types.SET_ACCESS_FLAG_FOR_KT_FROM_OKTA,
    data,
  };
}
