import React, { useEffect, useCallback, useReducer } from 'react';
import { Card, Form, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';

import Icon from 'components/common/icon/Icon';
import TeamManagerList from '../TeamManagerList';
import EditUserAction from './EditUserAction';
import actions, { updateToastNotificationDetails } from 'redux/actions';
import { getUserDetails } from 'redux/actions/selfServiceActions';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { getApiFailMessage, getPaName } from 'utils';
import { formatDate, isEmpty } from 'utils/common';
import {
  initialState,
  editFormStateReducer,
  getDataValue,
  handleDropdownChange,
  preparePostData,
  validateFormData,
  getMinDate,
  getApiActionMethod,
  dropdownOptionFormat,
  getEffectiveDate,
  dropdownFields,
  getPaSectorSubSectorList,
  getMessageByButtonAction,
} from 'utils/EditUser';
import CalendarDateRange from 'components/common/calendarDateRange';

import './EditUser.scss';
import CONFIG from 'config/configProps';

const EditUser = props => {
  const { fetchSeedDataFilters } = actions.seedDataActions;
  const {
    dispatch,
    userInfo,
    pathList,
    managedByList,
    geoMarketList,
    paList,
    sectorList,
    subSectorList,
    onClickGoBack,
    action,
  } = props;
  const [state, reactDispatch] = useReducer(editFormStateReducer, initialState);
  const { employeeIdHRO, id } = userInfo;
  const maximumDate = moment().add(1, 'year');
  const minimumDate = getMinDate(userInfo?.rowEffectiveDate, action);

  const { openCalendar, effectiveDate, lastModified, errorMessage, loading } =
    state;

  const setUserDetails = useCallback(
    userData => {
      const rowEffectiveDate = getEffectiveDate(
        action,
        userData?.rowEffectiveDate
      );
      const formData = {
        practiceArea: dropdownOptionFormat(
          userData?.paId,
          getPaName(userData?.paTeam)
        ),
        segment: dropdownOptionFormat(
          userData?.topicSectorId,
          userData?.sector
        ),
        subSegment: dropdownOptionFormat(
          userData?.subSectorId,
          userData?.subSector
        ),
        geoMarket: dropdownOptionFormat(
          userData?.geoRegion,
          userData?.geoRegion
        ),
        managedBy: dropdownOptionFormat(
          userData?.managedBy,
          userData?.managedBy
        ),
        path: dropdownOptionFormat(userData?.slotting, userData?.slotting),
        teamManager: userData?.teamManager,
        effectiveDate: rowEffectiveDate,
        lastModified: {
          lastModifiedBy: userData?.lastModifiedByName,
          rowUpdatedDate: formatDate(userData?.rowUpdatedDate, 'MMM-dd, yyyy'),
        },
      };

      getPaSectorSubSectorList(userData, dispatch);
      reactDispatch({ type: 'SET_FORM_DATA', formData });
    },
    [action, dispatch]
  );

  const getUserInfo = useCallback(() => {
    reactDispatch({ type: 'SET_LOADING', loading: { getDetails: true } });
    dispatch(getUserDetails(employeeIdHRO, id))
      .then(response => {
        setUserDetails(response?.activeDetails);
      })
      .finally(() => {
        reactDispatch({ type: 'SET_LOADING', loading: { getDetails: false } });
      });
  }, [dispatch, employeeIdHRO, id, setUserDetails]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    const postData = {
      subSectorId: [],
      geoRegion: [],
      managedBy: [],
      path: [],
    };
    dispatch(
      fetchSeedDataFilters({
        fetchGeoMarketList: true,
        fetchPathList: true,
        fetchManagedByList: true,
        postData,
      })
    );
  }, [dispatch, fetchSeedDataFilters]);

  const getCurrentState = () => ({
    ...state,
    paList,
    sectorList,
    subSectorList,
    managedByList,
    geoMarketList,
    pathList,
  });

  const onDropdownChange = type => async selectedItem => {
    const formData = await handleDropdownChange(
      type,
      selectedItem,
      getCurrentState(),
      dispatch
    );

    return reactDispatch({
      type: 'SET_FORM_DATA',
      formData,
    });
  };

  const handleCalendarClick = () => {
    reactDispatch({ type: 'SET_OPEN_CALENDAR', openCalendar: !openCalendar });
  };

  const handleApiFailure = error => {
    const { status, data } = error;
    dispatch(
      updateToastNotificationDetails(
        getApiFailMessage(status, data?.detailErrorMessage)
      )
    );
  };

  const submitForm = buttonAction => () => {
    const postData = preparePostData(userInfo, state);
    const apiMethod = getApiActionMethod(buttonAction);
    const errors = validateFormData(postData);
    const postDataIsValid = isEmpty(errors);
    if (postDataIsValid) {
      reactDispatch({ type: 'SET_LOADING', loading: { [buttonAction]: true } });
      dispatch(apiMethod(postData))
        .then(() => {
          showSucessNotification(getMessageByButtonAction(buttonAction));
          onClickGoBack();
        })
        .catch(handleApiFailure)
        .finally(() => {
          reactDispatch({
            type: 'SET_LOADING',
            loading: { [buttonAction]: false },
          });
        });
    } else {
      reactDispatch({ type: 'SET_ERROR', errorMessage: errors });
    }
  };

  const showSucessNotification = message => {
    const notificationObj = {
      showToastNotification: true,
      notificationHeader: 'Success',
      notificationBody: message,
      notificationClassName: 'custom-notification',
    };

    dispatch(updateToastNotificationDetails(notificationObj));
  };

  const onSave = () => submitForm(action || 'save_as_draft');
  const onActivate = () => submitForm('activate');

  const renderErrorMessage = (field, testId) => {
    return (
      errorMessage[field] && (
        <Label
          basic
          pointing="left"
          color="red"
          content={errorMessage[field]}
          className="edit-user-container__card__content__description__form__error"
          data-testid={testId}
        />
      )
    );
  };

  return (
    <div className="edit-user-container" id="edit-user">
      <div
        className="edit-user-container__go-back"
        onClick={onClickGoBack}
        data-testid="go-back-link"
      >
        <Icon name="arrow left" /> Back
      </div>
      <div className="edit-user-container__header">
        <h2 className="edit-user-container__header__title">
          Edit User Details
        </h2>
      </div>
      <Card className="edit-user-container__card">
        <Card.Content className="edit-user-container__card__content">
          <Card.Header className="edit-user-container__card__content__header">
            <Icon
              name="user circle"
              className="edit-user-container__card__content__header__image"
            />
            <span className="edit-user-container__card__content__header__user-name">
              {userInfo.employeeName}
            </span>
          </Card.Header>
          <Card.Meta>
            Last modified By: {lastModified?.lastModifiedBy} on&nbsp;
            {lastModified?.rowUpdatedDate}
          </Card.Meta>
          <Card.Description className="edit-user-container__card__content__description">
            <Form
              size="large"
              className="edit-user-container__card__content__description__form"
            >
              <Form.Field inline>
                <label className="edit-user-container__card__content__description__form__required">
                  Effective Date
                  <span>*</span>
                </label>
                {action === 'edit_user' ? (
                  <input
                    className="edit-user-container__card__content__description__form__input"
                    type="text"
                    value={effectiveDate}
                    disabled
                  />
                ) : (
                  <CalendarDateRange
                    getIsVisible={openCalendar}
                    isSingleSelect={true}
                    containerClass="edit-user-container__card__content__description__form__calendar"
                    handleLabelClick={handleCalendarClick}
                    onDateChange={onDropdownChange('effectiveDate')}
                    filterValue={effectiveDate}
                    maxDate={maximumDate}
                    minDate={minimumDate}
                    dateFormat={CONFIG.DATE_TIME_FORMAT.SELF_SERVICE}
                    filterTitle=""
                    testId="effective-date-calendar"
                  />
                )}
                {renderErrorMessage('effectiveDate', 'effective-date-error')}
              </Form.Field>
              {dropdownFields(getCurrentState()).map(dropdown => {
                return (
                  dropdown.showDropdown && (
                    <Form.Field inline key={dropdown.field}>
                      <label className="edit-user-container__card__content__description__form__required">
                        {dropdown.label}
                        {dropdown.required && <span>*</span>}
                      </label>
                      <Select
                        className="edit-user-container__card__content__description__form__select"
                        classNamePrefix="edit-user-container__card__content__description__form__select"
                        hideSelectedOptions={false}
                        placeholder={dropdown.placeholder}
                        isClearable={true}
                        options={getDataValue(
                          dropdown.dataList,
                          dropdown.field,
                          state
                        )}
                        value={dropdown.value}
                        onChange={onDropdownChange(dropdown.field)}
                        blurInputOnSelect
                        data-testid={dropdown.testid}
                      />
                      {renderErrorMessage(
                        dropdown.errorField,
                        `${dropdown.testid}-error`
                      )}
                    </Form.Field>
                  )
                );
              })}
              <Form.Field inline>
                <label className="edit-user-container__card__content__description__form__required">
                  Team Manager
                  <span>*</span>
                </label>
                <TeamManagerList
                  defaultTeamManager={userInfo?.teamManager}
                  className="edit-user-container__card__content__description__form__select"
                  onChange={onDropdownChange('teamManager')}
                  testId="team-manager-dropdown"
                />
                {renderErrorMessage(
                  'teamManager',
                  'team-manager-dropdown-error'
                )}
              </Form.Field>
            </Form>
          </Card.Description>
        </Card.Content>
        <Card.Content className="edit-user-container__card__action" extra>
          <EditUserAction
            action={action}
            loading={loading}
            onCancel={onClickGoBack}
            onSave={onSave}
            onActivate={onActivate}
          />
        </Card.Content>
      </Card>
    </div>
  );
};

/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.filterSectionSelectors.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    geoMarketList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_GEO_MARKET_LIST
    ),
    pathList: getPropsForSeedData(state, API_CALL_IDENTIFIERS.FETCH_PATH_LIST),
    managedByList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_MANAGED_BY_LIST
    ),
    paList: state.headerFilterData.paList,
    sectorList: state.headerFilterData.sectorList,
    subSectorList: state.headerFilterData.subSectorList,
  };
}
export default connect(mapStateToProps)(EditUser);
