import React, { Component } from 'react';
import { connect } from 'react-redux';
import LineInput from 'components/common/CommonHtml/lineInput';
import './KtSearchSmall.scss';
import { isEqual } from 'utils/common';

class KtSearchSmall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  /**
   * This method will call to get the searched result
   */
  getSearchResult = event => {
    let searchValue = event.target.value.toLowerCase();
    this.setState({ inputValue: event.target.value });
    return this.props.onChangeHandler(searchValue);
  };

  renderImg = marginClass => {
    return (
      <img
        src={'/img/kt-search-icon-small.svg'}
        className={`kt-small-search-icon ${marginClass}`}
        alt="search"
      />
    );
  };

  render() {
    const { searchPosition, containerClassName, placeholder } = this.props;
    const searchContainerClass = `kt-search-container ${containerClassName}`;
    const searchonRight = isEqual(searchPosition, 'Right');
    return (
      <div className={searchContainerClass}>
        <div className="kt-search-rectangle-2">
          {!searchonRight ? this.renderImg('marginLeft') : null}
          <LineInput
            className={`kt-search-input ${searchonRight ? 'paddingRight' : ''}`}
            placeholder={placeholder}
            type="text"
            name="KtSearchSmall"
            handleChange={this.getSearchResult}
            handleKeyDownChange={this.getSearchResult}
            value={this.state.inputValue}
            autoComplete="off"
            autoFocus={true}
          />
          {searchonRight ? this.renderImg() : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return store;
};

export default connect(mapStateToProps)(KtSearchSmall);
