import {
  FILTER_DATA_FETCH_SUCCESS,
  FILTER_DATA_FETCH_FAILURE,
  UPDATE_USER_DETAILS,
  UPDATE_SELECTED_PULSE_QUESTION,
  UPDATE_SELECTED_BILLABILITY_QUESTION,
  SAVE_SELECTED_USER_ID_BUSINESS,
  SAVE_SELECTED_USER_ID_MEMBER,
  SAVE_SELECTED_VIEW,
  UPDATE_SELECTED_TOP_PROJECTS_QUESTION,
  UPDATE_SELECTED_USER_BY_IMPERSONATOR,
  UPDATE_IMPERSONATED_USER_DETAILS,
  RESET_DATA,
  UPDATE_LOGGED_IN_USER_ROLE,
  UPDATE_LOGGED_IN_USER_ID,
  SAVE_DEFAULT_LOGGED_IN_USER_ROLE,
} from 'redux/actions/actionTypes';

import InitialState from './InitialState';
const STATE_SLICE_NAME = 'homePageData'; // must match the INitial State Key

/**
 * This reducer will return the state-slice for seed data (filter values)
 * When the action.type is :
 * - FILTER_DATA_FETCH_FAILURE  the action will have  property 'id' and 'error'
 * -  FILTER_DATA_FETCH_SUCCESS the action will have  property 'id' and 'results'
 * . These are pssed along from Dispatcher (Action)
 * @param {*} state state object.
 * @param {*} action action object
 */
export function ktHomeReducer(
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case FILTER_DATA_FETCH_SUCCESS:
    case UPDATE_USER_DETAILS:
    case UPDATE_IMPERSONATED_USER_DETAILS: {
      const newState = { ...state };
      newState[action.id] = { results: action.results, error: '' };
      return newState;
    }

    case UPDATE_LOGGED_IN_USER_ROLE:
      return {
        ...state,
        loggedInUserRole: action.loggedInUserRole,
      };

    case UPDATE_LOGGED_IN_USER_ID:
      return {
        ...state,
        loggedInUserId: action.loggedInUserId,
      };

    //invoked when case search api call is failure
    case FILTER_DATA_FETCH_FAILURE: {
      const newState = { ...state };
      newState[action.id] = { results: [], error: action.error };
      return newState;
    }

    case UPDATE_SELECTED_PULSE_QUESTION:
      //set the filters applied for this API call
      return {
        ...state,
        selectedKtPulseQuestion: action.selectedKtPulseQuestion,
      };

    case UPDATE_SELECTED_BILLABILITY_QUESTION:
      //set the filters applied for this API call
      return {
        ...state,
        selectedBillabilityQuestion: action.selectedBillabilityQuestion,
      };

    case UPDATE_SELECTED_TOP_PROJECTS_QUESTION:
      return {
        ...state,
        selectedTopProjectsQuestion: action.selectedTopProjectsQuestion,
      };

    case SAVE_SELECTED_USER_ID_MEMBER:
      //set the filters applied for this API call
      return {
        ...state,
        selectedMemberId: action.selectedId,
      };

    case SAVE_SELECTED_USER_ID_BUSINESS:
      //set the filters applied for this API call
      return {
        ...state,
        selectedBusinessMemberId: action.selectedId,
      };

    case SAVE_SELECTED_VIEW:
      return {
        ...state,
        selectedView: action.selectedView,
      };

    case UPDATE_SELECTED_USER_BY_IMPERSONATOR:
      return {
        ...state,
        selectedUserByImpersonator: action.selectedUserByImpersonator,
      };

    case RESET_DATA:
      return {
        ...state,
        isResetData: action.isResetData,
      };

    case SAVE_DEFAULT_LOGGED_IN_USER_ROLE:
      return {
        ...state,
        defaultLoggedInRole: action.defaultLoggedInRole,
      };

    //default: return existing state
    default:
      return state;
  }
}

export const selectors = {
  /**
   * Get Seed Data results for specific API Identifier
   */
  getResults: (appState, apiIdentifier) => {
    return appState[STATE_SLICE_NAME][apiIdentifier];
  },
  /**
   * Get Errors occured during API Call for a specific API Identifier
   */
  getError: (appState, apiIdentifier) =>
    appState[STATE_SLICE_NAME][apiIdentifier] &&
    appState[STATE_SLICE_NAME][apiIdentifier].error,
};
