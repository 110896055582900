import React, { Component } from 'react';
import { withRouter } from 'react-router';
import KTPieChart from './KTPieChart';
import Icon from 'components/common/Icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { getPaName } from 'utils';

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPaValue: undefined,
      selectedClassName: 'Positive',
    };
  }

  handleClick = selectedLegend => {
    const { pieChartRawData } = this.props;
    this.setState({
      selectedPaValue: pieChartRawData[`${selectedLegend.toLowerCase()}Map`],
      selectedClassName: selectedLegend,
    });
  };

  render() {
    const { pieChartRawData, className, InfoText } = this.props;
    const { selectedPaValue, selectedClassName } = this.state;
    const selectedPa = selectedPaValue
      ? selectedPaValue
      : pieChartRawData?.positiveMap || {};
    return (
      <div>
        <div className={`${className} request-padding-right`}>
          <div className="totalMetricName">Survey Count:</div>
          <div className="totalMetricValue">
            {pieChartRawData && pieChartRawData.totalCount}
          </div>
        </div>
        {pieChartRawData ? (
          <div className="request-satisfaction-chart">
            <KTPieChart
              rawData={pieChartRawData}
              legends={['Negative', 'Neutral', 'Positive']}
              colors={{
                Positive: '#3BC34E',
                Neutral: '#EDC948',
                Negative: '#CD1313',
              }}
              pieSizeOptions={{
                size: 60,
                center: [7, 10],
              }}
              initialLegendKey={'Positive'}
              targetValue={pieChartRawData.totalCount || 100}
              handleClick={this.handleClick}
            />
          </div>
        ) : null}
        {Object.entries(selectedPa).map(([key, value]) => {
          const keyValue = getPaName(key);
          return (
            <div className="pa-info-container" key={keyValue}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id="tooltip-basic">
                    <Popover.Content>{keyValue}</Popover.Content>
                  </Popover>
                }
                key={keyValue}
              >
                <div className="popContent key">
                  {keyValue.length > 18 ? (
                    <div>{keyValue.substring(0, 18)}...</div>
                  ) : (
                    keyValue
                  )}
                </div>
              </OverlayTrigger>
              <div className={`value ${selectedClassName}`}>{`${value}%`}</div>
            </div>
          );
        })}
        {InfoText ? (
          <div
            className="tooltip-with-icon homeTileToolTip"
            onClick={() => {
              this.props.showToolTip();
            }}
          >
            <Icon
              name="info-tableau"
              class="info-icon"
              alt="infoIcon"
              src="/img/info-tableau.svg"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(PieChart);
