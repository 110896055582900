import axios from 'axios';
import CONFIG from './configProps';

axios.interceptors.request.use(
  config => {
    const tokens = JSON.parse(localStorage.getItem(CONFIG.OKTA.ACCESS_TOKEN));
    if (tokens && tokens.accessToken && tokens.accessToken.accessToken) {
      const accessToken = tokens.accessToken.accessToken;
      let AuthStr = 'Bearer ' + accessToken;
      return {
        ...config,
        headers: {
          ...config.headers,
          Accept: '*',
          Authorization: AuthStr,
          'x-content-type-options': 'nosniff',
          'x-frame-options': 'deny',
          'x-xss-protection': '1; mode=block',
          'Cache-Control':
            'private, no-cache, no-store, max-age=0, must-revalidate',
          Pragma: 'no-cache',
          'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        },
      };
    } else {
      window.location.reload();
      return {};
    }
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.clear();
          window.location.reload();
          break;
        case 404:
          return Promise.reject(error.response);
        default:
          return Promise.reject(error.response);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
