/* eslint-disable func-names */
import {
  FETCH_PEOPLE_IMAGES_SUCCESS,
  FETCH_PEOPLE_IMAGES_IN_PROGRESS,
} from './actionTypes';
import { fetchGet } from 'utils/KtCommonServices';
import CONFIG from 'config/configProps';

function fetchPeopleImageSuccess(hrId, bytes) {
  return {
    type: FETCH_PEOPLE_IMAGES_SUCCESS,
    hrId,
    imageBytes: bytes,
  };
}

function fetchPeopleImageInProgress(hrId) {
  return {
    type: FETCH_PEOPLE_IMAGES_IN_PROGRESS,
    hrId,
  };
}

function fetchPeopleImage(hrId) {
  return async function innerFn(dispatch, getState) {
    try {
      const currentPeopleImageState = getState()['peopleImages'];
      if (currentPeopleImageState && currentPeopleImageState[hrId]) {
        const { activeRequest } = currentPeopleImageState[hrId];
        if (activeRequest > 0) {
          // a request already in progress so eat this request
          return;
        }
      }
      dispatch(fetchPeopleImageInProgress(hrId));
      const endpoint = `/picture?hrid=${hrId}`;
      const exactBaseURL = CONFIG.PEOPLE_API_URL;
      const response = await fetchGet(
        endpoint,
        exactBaseURL,
        CONFIG.EXTERNAL_APPLICATION.PEOPLE_API
      );
      if (response) {
        const byteSequence = new Uint8Array(response).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        );
        const peopleImageData = `data:image/jpeg;base64,${Buffer.from(
          byteSequence
        ).toString('base64')}`;
        dispatch(fetchPeopleImageSuccess(hrId, peopleImageData));
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export const actions = {
  fetchPeopleImage,
};
