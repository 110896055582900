import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LabelBoxSkeleton = ({ showLabelBox, width }) => {
  return showLabelBox ? (
    <>
      <Skeleton width={width} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={6.5} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={6.5} height={4.5} duration={1} />
    </>
  ) : null;
};

export default LabelBoxSkeleton;
