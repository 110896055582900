import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LineInput from 'components/common/CommonHtml/lineInput';
import Icon from 'components/common/Icons';
import CONFIG_KTS from 'config/configProps';
import store from 'redux/store';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { beginAPICall, endAPICall } from 'redux/actions/apiCallActions';
import { fetchPost, fetchPut, fetchGet } from 'utils/KtCommonServices';
import {
  updateToastNotificationDetails,
  updateCollectionData,
} from 'redux/actions';
import { getApiFailMessage } from 'utils';
import MemberSearch from '../../common/MemberSearch/MemberSearch';
import { isEmpty, cloneDeep, isEqual, showSearch } from 'utils/common';

class SaveCollections extends PureComponent {
  constructor(props) {
    super(props);
    const { fromEditCollection, editSectionDetails, selectedUsers } =
      this.props;
    this.state = {
      collectionName: fromEditCollection ? editSectionDetails.name : '',
      nameError: false,
      selectedUserList: selectedUsers,
      errorMessage: '',
    };
    isEmpty(this.props.collectionData) && this.getCollectionList();
  }

  getCollectionList = () => {
    const apiIdentifier = API_CALL_IDENTIFIERS.FETCH_COLLECTION_LIST;
    const apiUrl = CONFIG_KTS.API_URLS.SAVE_COLLECTION;
    const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
    store.dispatch(beginAPICall(apiIdentifier));
    fetchGet(apiUrl, baseUrl)
      .then(result => {
        this.setState({
          collectionList: result,
          noCollectionData: isEmpty(result),
          collectionLength: result.userFilterCollectionDTO.length,
        });
        store.dispatch(updateCollectionData(result));
      })
      .catch(() => {
        store.dispatch(updateCollectionData({}));
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      })
      .finally(() => store.dispatch(endAPICall(apiIdentifier)));
  };

  updateCollectionName = event => {
    this.setState({ collectionName: event.target.value });
  };

  isCollectionNameValid = () => {
    const { collectionName } = this.state;
    const { collectionData, fromEditCollection, editSectionDetails } =
      this.props;
    const collectionList = collectionData.userFilterCollectionDTO;
    const isCollectionExist = collectionList.filter(item =>
      isEqual(item.name, collectionName)
    );

    let errorMessage = '';
    let nameError = false;

    if (isEmpty(collectionName)) {
      errorMessage = '* Please enter collection name';
      nameError = true;
    } else if (collectionName.length > 35) {
      errorMessage =
        '* Collection name length should be less than 35 characters.';
      nameError = true;
    } else if (
      !isEmpty(isCollectionExist) &&
      (!fromEditCollection ||
        !isEqual(collectionName, editSectionDetails?.name))
    ) {
      errorMessage = '* User collection already exists with same name.';
      nameError = true;
    }

    this.setState({ nameError, errorMessage });
    return !nameError;
  };

  saveCollection = () => {
    const { collectionName, selectedUserList } = this.state;
    const {
      filterDto,
      selectedUsers,
      closeMemberFilter,
      memberList,
      fromEditCollection,
      editSectionDetails,
      applyFilter,
    } = this.props;
    const url = CONFIG_KTS.API_URLS.SAVE_COLLECTION;
    const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
    const userSelectedFilterDTO = { ...filterDto };
    const userList = fromEditCollection ? selectedUserList : selectedUsers;
    const selectedUserData = memberList.filter(
      item => userList.indexOf(item.employeeIdHRO) >= 0
    );

    if (!this.isCollectionNameValid()) {
      return;
    }

    userSelectedFilterDTO['selectedUserId'] = selectedUsers;
    userSelectedFilterDTO['selectedUserDetails'] = selectedUserData;
    const dataToBePosted = {
      name: collectionName,
      userSelectedFilterDTO,
    };
    let methodName = '';
    if (fromEditCollection) {
      dataToBePosted['id'] = editSectionDetails.id;
      userSelectedFilterDTO['selectedUserId'] = selectedUserList;
      methodName = fetchPut;
      applyFilter(dataToBePosted);
    } else {
      methodName = fetchPost;
      applyFilter(collectionName);
    }
    methodName(url, dataToBePosted, baseUrl)
      .then(result => {
        const message = result.data;
        let notificationDetailsObj = {
          showToastNotification: true,
          notificationHeader: 'Success',
          notificationBody: message,
          notificationClassName: 'save-collection-notification',
        };
        store.dispatch(updateCollectionData({}));
        !fromEditCollection &&
          store.dispatch(
            updateToastNotificationDetails(notificationDetailsObj)
          );
      })
      .catch(error => {
        store.dispatch(updateCollectionData({}));
        if (isEqual(error.status, 409)) {
          store.dispatch(
            updateToastNotificationDetails(
              getApiFailMessage(false, error.data.detailErrorMessage)
            )
          );
        } else {
          store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
        }
      });
    closeMemberFilter();
  };

  handleMemberDeleteClick = el => e => {
    const { fromEditCollection, handleDeleteClick } = this.props;

    e.nativeEvent.stopImmediatePropagation();
    fromEditCollection
      ? this.handleTeamMemberClick(el.employeeIdHRO, el.employeeName, true)
      : handleDeleteClick(el.employeeIdHRO);
  };

  handleTeamMemberClick = (employeeId, employeeName, isDeleteClicked) => {
    const { selectedUserList } = this.state;
    const selectedUsersCopy = cloneDeep(selectedUserList);
    const index = selectedUsersCopy.indexOf(employeeId);
    if (isDeleteClicked) {
      selectedUsersCopy.splice(index, 1);
    } else if (index > -1) {
      return;
    } else {
      selectedUsersCopy.push(employeeId);
    }
    this.setState({ selectedUserList: selectedUsersCopy });
  };

  handleBackClick = e => {
    const { onBackClick } = this.props;
    e.nativeEvent.stopImmediatePropagation();
    onBackClick();
  };

  handleDeleteCollection = () => {
    const { editSectionDetails, deleteCollection } = this.props;

    deleteCollection(editSectionDetails.id);
  };

  renderActionButtons = () => {
    const { selectedUserList, collectionName } = this.state;
    const { fromEditCollection, selectedUsers } = this.props;

    const disabledCondition =
      isEmpty(collectionName) ||
      (fromEditCollection ? isEmpty(selectedUserList) : isEmpty(selectedUsers));
    const disabledClass = disabledCondition ? 'disabled' : '';

    return (
      <div className="button-footer">
        {fromEditCollection ? (
          <div className="button">
            <div className="cancel-button" onClick={this.handleBackClick}>
              Cancel
            </div>
            <div
              className={`action-button update ${disabledClass}`}
              onClick={this.saveCollection}
            >
              Update & Apply
            </div>
            <div
              className="action-button delete"
              onClick={this.handleDeleteCollection}
            >
              Delete
            </div>
          </div>
        ) : (
          <div className="apply-button">
            <div
              className={`apply ${disabledClass}`}
              onClick={this.saveCollection}
            >
              Save & Apply
            </div>
          </div>
        )}
      </div>
    );
  };

  renderSeletedMemberList = () => {
    const { selectedUserList } = this.state;
    const { fromEditCollection, selectedUsers, memberList } = this.props;
    const userList = fromEditCollection ? selectedUserList : selectedUsers;
    const selectedUserData = memberList.filter(
      item => userList.indexOf(item.employeeIdHRO) >= 0
    );

    return (
      <div className={`selected-user-list ${fromEditCollection ? 'mT10' : ''}`}>
        {isEmpty(selectedUserData) ? (
          <div className="no-data">Please select user</div>
        ) : (
          selectedUserData.map(el => {
            return (
              <div className="selected-user-pill" key={el.employeeIdHRO}>
                <div className="user-name">{el.employeeName}</div>
                <img
                  src="/img/kt-delete-icon.svg"
                  className="delete-icon"
                  alt="delete-icon"
                  onClick={this.handleMemberDeleteClick(el)}
                />
              </div>
            );
          })
        )}
      </div>
    );
  };

  render() {
    const { collectionName, nameError, errorMessage } = this.state;
    const { memberList, fromEditCollection, editSectionDetails } = this.props;

    return (
      <div>
        <div className="save-user-collection">
          <div onClick={this.handleBackClick}>
            <Icon
              name="tableau-arrow"
              class="back-arrow"
              alt="arrow"
              src="/img/tableau-arrow.svg"
            />
          </div>
          <div>
            {fromEditCollection
              ? `Edit "${editSectionDetails.name}"`
              : 'Save Collection'}
          </div>
        </div>
        <div className="name">Name</div>
        <div className="input-section">
          <LineInput
            className={`${nameError ? 'redBorder' : ''} save-input`}
            placeholder="Collection Name"
            type="text"
            name="SaveCollection"
            handleChange={this.updateCollectionName}
            handleKeyDownChange={this.updateCollectionName}
            value={collectionName}
            autoComplete="off"
            autoFocus={true}
          />
          {nameError && <div className="errorMessage">{errorMessage}</div>}
        </div>
        <div className="selected">
          {fromEditCollection ? 'Team Members' : 'Selected'}
        </div>
        {!isEmpty(memberList) &&
          fromEditCollection &&
          showSearch(memberList) && (
            <MemberSearch
              onClick={this.handleTeamMemberClick}
              searchList={memberList}
              placeholder="Search Team Members"
            />
          )}
        {this.renderSeletedMemberList()}
        {this.renderActionButtons()}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    collectionData: reduxStore.ktScorecard.collectionData,
  };
};
export default connect(mapStateToProps)(withRouter(SaveCollections));
