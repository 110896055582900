import React from 'react';
import CONFIG from 'config/configProps';
import { isEqual } from 'utils/common';
import { isEmptyOrNull } from 'utils';

export const AuthorizeHoc = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        userDetails: this.props.userDetails,
        impersonatedUserDetails: this.props.impersonatedUserDetails,
      };
    }

    /**
     * Lifecycle method: Get Derived State from Props
     * @param {*} props props
     * @param {*} state state
     */
    static getDerivedStateFromProps(props, state) {
      const { userDetails } = props;
      if (!isEqual(state.userDetails, userDetails)) {
        //caused by setState
        return {
          userDetails: userDetails,
        };
      }
      return null;
    }

    componentDidMount = () => {
      const {
        actions: { updateUserDetails },
        userDetails,
      } = this.props;
      if (isEmptyOrNull(userDetails)) updateUserDetails(true);
    };

    redirectToWrapper = () => {
      const { userDetails } = this.state;
      const { results } = userDetails;
      const hasAccess = results.ktdAccessible;
      if (hasAccess) {
        let defaultUserId = results.defaultUser;
        return (
          <WrappedComponent {...this.props} defaultUserId={defaultUserId} />
        );
      } else if (hasAccess === false) {
        this.props.history.push({
          pathname: CONFIG.APP_CONST.UNAUTHORIZED,
          state: { userName: userDetails.results.userFullName },
        });
        return null;
      } else {
        return null;
      }
    };

    render() {
      const { userDetails } = this.state;
      if (!isEmptyOrNull(userDetails) && !isEmptyOrNull(userDetails.results)) {
        return this.redirectToWrapper();
      } else {
        return null;
      }
    }
  };
};

const AuthorizeHOCWithState = WrapperComponent =>
  AuthorizeHoc(WrapperComponent);

export default AuthorizeHOCWithState;
