import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/common';
import icons from './icons.svg';

const Icon = props => {
  const checkBrowser = useSelector(state => state.ktScorecard.checkBrowser);
  if (!checkBrowser) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={`svg-icon ${props.class}`}
      >
        {!isEmpty(props.title) ? <title>{props.title}</title> : null}
        <use
          xlinkHref={`${icons}#${props.name}`}
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
      </svg>
    );
  } else {
    return <img src={props.src} alt={props.alt} className={props.class} />;
  }
};

export default Icon;
