import CONFIG from 'config/configProps';
import { isKtLineManger } from 'utils';
import { isEqual } from 'utils/common';

export function getFilters(roleName, pageName) {
  const isHomePage = isEqual(pageName, CONFIG.PROTOTYPE_URLS.HOME);
  const isBillabilityPage = isEqual(pageName, CONFIG.PROTOTYPE_URLS.BILLING);
  const defaultFilters = {
    name: true,
    status: true,
    jobTitle: true,
    location: true,
    managementModel: true,
    geoBusiness: true,
    practiceArea: true,
    path: true,
  };
  const line_manager_filters = {
    ...defaultFilters,
    geoBusiness: false,
    practiceArea: false,
    status: false,
  };
  // Define your filters here
  const filters = {
    ktd_super_admin: {
      homePage: { ...defaultFilters },
      billabilityPage: { ...defaultFilters },
    },
    ktd_leader: {
      homePage: { ...defaultFilters },
      billabilityPage: { ...defaultFilters },
    },
    ktd_manager: {
      homePage: { ...defaultFilters },
      billabilityPage: { ...defaultFilters },
    },
    ktd_line_manager: {
      homePage: {
        ...line_manager_filters,
      },
      billabilityPage: {
        ...line_manager_filters,
      },
    },
  };
  let filtersToShow = {};
  const isLineManger = isKtLineManger(roleName);
  if (isHomePage) filtersToShow = filters[roleName]['homePage'] || {};
  else if (isBillabilityPage)
    filtersToShow = filters[roleName]['billabilityPage'] || {};
  else
    filtersToShow = isLineManger
      ? { ...line_manager_filters }
      : { ...defaultFilters };
  return filtersToShow;
}
