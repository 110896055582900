import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isEqual, isEmpty } from 'utils/common';
import { connect } from 'react-redux';
import store from 'redux/store';
import 'resources/css/index.scss';
import CONFIG from 'config/configProps';
import MultiSelectHeader from 'components/common/MultiSelectHeader';
import KTDashboardHeader from 'components/KTDashboardHeader';
import KTDashboardSwitch from 'components/KTDashboard/KTSwitchHeader';
import KtDashboard from 'components/KTDashboard';
import KtEmployeeBillability from 'components/KtEmployeeBillability';
import KtAdminListing from 'components/KtAdminListing';
import DetailedView from 'components/KTTableauContainer';
import BusinessComparison from 'components/BusinessComparison';
import NewUserManagement from 'components/UserManagement/NewUserManagement';
import ExistingUserManagement from 'components/UserManagement/ExistingUserManagement';
import UnknownRoute from 'components/UnknownRoute/UnknownRoute';
import withTitle from 'components/common/pageWithTitle';
import KtToastNotification from 'components/common/Toaster/KtToastNotification';

import { fetchGet } from 'utils/KtCommonServices';
import {
  updateHeaderHeight,
  updateShowPageContentStatus,
  updateToastNotificationDetails,
  updateSidebarPathName,
} from 'redux/actions';
import { setAllPaList, passGeoDetails } from 'redux/actions/headerFilterAction';
import {
  updateEmployeeInitialData,
  updateLoggedInEmployeeData,
  updateLoggedInKtUserDetails,
  getKtUserDetails,
  updateUserTypes,
  updateUserTypeData,
  updateUserHighestRole,
  updateLoggedInUserHighestRole,
  updateUserIsGeoManaged,
  setAccessFlagForKTFromOkta,
} from 'redux/actions/userRoleAction';
import {
  getViewName,
  navigateToUnauthorized,
  getApiFailMessage,
  getHeaderProps,
} from 'utils';
import { getStaffId } from 'config/analytics';
import SideBar from 'components/SideBar';

let env = CONFIG.ENV;
const KtDashboardComponent = withTitle({
  component: KtDashboard,
  title: `KT Dashboard ${env} - Home`,
});

const KtEmployeeBillabilityComponent = withTitle({
  component: KtEmployeeBillability,
  title: `KT Dashboard ${env} - Billing`,
});

const KtAdminListingComponent = withTitle({
  component: KtAdminListing,
  title: `KT Dashboard ${env} - Admin`,
});

const DetailedViewComponent = withTitle({
  component: DetailedView,
  title: `KT Dashboard ${env} - Detailed View`,
});

const BusinessComparisonComponent = withTitle({
  component: BusinessComparison,
  title: `KT Dashboard ${env} - Business Comparison`,
});

const NewUserManagementComponent = withTitle({
  component: NewUserManagement,
  title: `KT Dashboard ${env} - New User Management`,
});

const ExistingUserManagementComponent = withTitle({
  component: ExistingUserManagement,
  title: `KT Dashboard ${env} - Existing User Management`,
});

class ProtectedPage extends PureComponent {
  constructor(props) {
    super(props);
    this.headerContainer = React.createRef();
    this.state = {
      headerHeight: 0,
      pathName: '',
    };
  }

  componentDidMount() {
    this.getPAList();
    this.setEmployeeDetails();
    this.setKtUserDetails();
    this.setUserTypes();
    // dispatch an action to close notification on route change.
    this.props.history.listen(() => this.closeNotification());
  }

  componentDidUpdate() {
    const {
      loggedInKtUserDetails,
      loggedInEmployeeData,
      userHighestRole,
      userTypes,
      headerHeight,
      sidebarPathName,
    } = this.props;
    const height = this.headerContainer?.current?.offsetHeight;
    const pathName = this.props.history.location.pathname;
    if (headerHeight !== height) {
      store.dispatch(updateHeaderHeight(height));
    }
    if (sidebarPathName !== pathName) {
      store.dispatch(updateSidebarPathName(pathName));
    }
    if (
      typeof userHighestRole === 'undefined' &&
      !isEmpty(loggedInKtUserDetails) &&
      !isEmpty(loggedInEmployeeData) &&
      !isEmpty(userTypes)
    ) {
      this.setHighestRole();
    }
  }

  setEmployeeDetails = () => {
    const { loggedInEmployeeData, history } = this.props;

    if (isEmpty(loggedInEmployeeData)) {
      let userDetailsUrl = CONFIG.API_URLS.EMP;
      const baseUrl = CONFIG.KT_USER_BASE_URL;
      fetchGet(userDetailsUrl, baseUrl)
        .then(data => {
          if (typeof data === 'object') {
            const geoIndex = CONFIG.GEO_USER_TYPES.indexOf(data.managedBy);
            store.dispatch(passGeoDetails(!isEmpty(data.geoRegion)));
            store.dispatch(updateUserIsGeoManaged(isEqual(geoIndex, 0)));
            store.dispatch(updateLoggedInEmployeeData(data));
          } else {
            navigateToUnauthorized(history, store);
          }
        })
        .catch(() => {
          this.showErrorNotification();
          navigateToUnauthorized(history, store);
        });
    }
  };

  setKtUserDetails = () => {
    const { loggedInKtUserDetails, history } = this.props;

    if (isEmpty(loggedInKtUserDetails)) {
      const url = CONFIG.API_URLS.USER_DETAILS;
      const baseUrl = CONFIG.KT_USER_BASE_URL;
      fetchGet(url, baseUrl)
        .then(ktUserDetails => {
          if (
            typeof ktUserDetails === 'object' &&
            ktUserDetails.ktdAccessible
          ) {
            store.dispatch(setAccessFlagForKTFromOkta(true));
            store.dispatch(updateLoggedInKtUserDetails(ktUserDetails));
            getStaffId(ktUserDetails.email);
          } else {
            navigateToUnauthorized(history, store);
          }
        })
        .catch(() => {
          this.showErrorNotification();
          navigateToUnauthorized(history, store);
        });
    }
  };

  setUserTypes = () => {
    const { userTypes } = this.props;

    if (isEmpty(userTypes)) {
      const url = CONFIG.API_URLS.GET_ROLES;
      const baseUrl = CONFIG.KT_USER_BASE_URL;
      return fetchGet(url, baseUrl).then(result => {
        let responseArray = [];
        result.forEach(el => {
          responseArray.push(el.name);
        });
        store.dispatch(updateUserTypeData(result));
        store.dispatch(updateUserTypes(responseArray));
      });
    }
  };

  getPAList = async () => {
    const { allPaList } = this.props;
    const PAUrl = CONFIG.API_URLS.EMP_PA;
    const baseUrl = CONFIG.KT_USER_BASE_URL;

    if (!allPaList?.length) {
      fetchGet(PAUrl, baseUrl).then(data => {
        store.dispatch(setAllPaList(data));
      });
    }
  };

  setHighestRole = () => {
    const { loggedInKtUserDetails, loggedInEmployeeData, userTypes, history } =
      this.props;

    if (
      typeof loggedInKtUserDetails === 'string' ||
      typeof loggedInEmployeeData === 'string' ||
      typeof userTypes === 'string' ||
      !loggedInKtUserDetails.ktdAccessible
    ) {
      navigateToUnauthorized(history, store, loggedInKtUserDetails);
    }

    let hightestRole = getViewName(
      loggedInEmployeeData,
      userTypes,
      loggedInKtUserDetails
    );
    store.dispatch(updateShowPageContentStatus(true));
    store.dispatch(getKtUserDetails(loggedInKtUserDetails));
    store.dispatch(updateEmployeeInitialData(loggedInEmployeeData));
    store.dispatch(updateLoggedInUserHighestRole(hightestRole));
    store.dispatch(updateUserHighestRole(hightestRole));

    return hightestRole;
  };

  showErrorNotification = () => {
    store.dispatch(updateShowPageContentStatus(false));
    store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
  };

  closeNotification = async () => {
    const notificationDetailsObj = this.props.toastNotificationDetails;
    notificationDetailsObj.showToastNotification = false;
    await store.dispatch(
      updateToastNotificationDetails(notificationDetailsObj)
    );
  };

  render() {
    const {
      userHighestRole,
      history,
      showKTScorecardPageContent,
      employeeInitialData,
      isMenuOpen,
      toastNotificationDetails,
      loggedInEmployeeData,
    } = this.props;
    const {
      showToastNotification,
      notificationHeader,
      notificationBody,
      notificationClassName,
    } = toastNotificationDetails;
    const {
      location: { pathname },
    } = history;
    const mainClassName =
      pathname === CONFIG.KT_SCORECARD_CONST.ADMIN_PAGE_URI
        ? 'kt-admin-listing-page'
        : 'kt-home-screen';
    const headerProps = getHeaderProps(pathname);

    if (typeof userHighestRole === 'undefined') {
      return null;
    }
    return (
      <div>
        <div
          className={`KTDashboard-home d-flex home-page-header ${mainClassName}`}
        >
          <div className="KTDashboard-home__container">
            <div className="KTDashboard-home__container__page">
              <div
                className="KTDashboard-home-header"
                ref={this.headerContainer}
              >
                {showKTScorecardPageContent && (
                  <>
                    {!headerProps?.hideSwitchHeader && (
                      <KTDashboardSwitch {...headerProps} />
                    )}
                    {!headerProps?.hideFilterHeader && (
                      <KTDashboardHeader
                        {...headerProps}
                        empInitialData={employeeInitialData}
                      />
                    )}
                    {headerProps?.showMultiSelectHeader && (
                      <MultiSelectHeader
                        history={history}
                        loggedInEmployeeData={loggedInEmployeeData}
                      />
                    )}
                  </>
                )}
              </div>
              <SideBar headerContainer={this.headerContainer} />
              <div className={isMenuOpen ? 'marginWithMenu' : 'defaultMargin'}>
                <Switch>
                  <Route
                    exact
                    path={CONFIG.KT_SCORECARD_CONST.HOME_URL}
                    component={KtDashboardComponent}
                  />
                  <Route
                    path={[
                      CONFIG.KT_SCORECARD_CONST.EMPLOYEE_BILLABILITY,
                      CONFIG.KT_SCORECARD_CONST.CD_BILLABILITY_URL,
                      CONFIG.KT_SCORECARD_CONST.CASE_ENGAGEMENT_DEPTH_URL,
                    ]}
                    component={KtEmployeeBillabilityComponent}
                  />
                  <Route
                    path={CONFIG.KT_SCORECARD_CONST.ADMIN_PAGE_URI}
                    component={KtAdminListingComponent}
                  />
                  <Route
                    path={`${CONFIG.KT_SCORECARD_CONST.KT_TABLEAU_URL}:token`}
                    component={DetailedViewComponent}
                  />
                  <Route
                    path={CONFIG.KT_SCORECARD_CONST.BUSINESS_COMPARISON}
                    component={BusinessComparisonComponent}
                  />
                  <Route
                    path={CONFIG.KT_SCORECARD_CONST.MANAGE_NEW_USER_PAGE_URI}
                    component={NewUserManagementComponent}
                  />
                  <Route
                    path={
                      CONFIG.KT_SCORECARD_CONST.MANAGE_EXISTING_USER_PAGE_URI
                    }
                    component={ExistingUserManagementComponent}
                  />
                  <Route component={UnknownRoute} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        {showToastNotification && (
          <KtToastNotification
            showToastNotification={showToastNotification}
            header={notificationHeader}
            body={notificationBody}
            notificationClassName={notificationClassName}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    loggedInEmployeeData: reduxStore.userRoleData.loggedInEmployeeData,
    loggedInKtUserDetails: reduxStore.userRoleData.loggedInKtUserDetails,
    userTypes: reduxStore.userRoleData.userTypes,
    userHighestRole: reduxStore.userRoleData.userHighestRole,
    allPaList: reduxStore.headerFilterData.allPaList,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    showKTScorecardPageContent:
      reduxStore.ktScorecard.showKTScorecardPageContent,
    isMenuOpen: reduxStore.ktScorecard.isMenuOpen,
    toastNotificationDetails: reduxStore.ktScorecard.toastNotificationDetails,
    headerHeight: reduxStore.ktScorecard.headerHeight,
    sidebarPathName: reduxStore.ktScorecard.sidebarPathName,
  };
};

export default connect(mapStateToProps)(ProtectedPage);
