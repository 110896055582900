import { connect } from 'react-redux';
import KTFiltersSection from './KTFilterSection';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.filterSectionSelectorsPt.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    appliedFilters: state.filterDataPt.filters,
    appliedFiltersBc: state.filterDataPt.filtersBc,
    practiceAreaList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST
    ),
    geoLocationList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST
    ),
    memberList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST
    ),
    jobTitleList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST
    ),
    locationList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST
    ),
    defaultUserFilters: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS
    ),
    isFilterOpen: state.filterDataPt.isFilterOpen,
    userDetails: selectors.ktHomeSelectors.getResults(
      state,
      API_CALL_IDENTIFIERS.FETCH_USER_DETAILS
    ),
    selectedIdMember: state.homePageData.selectedMemberId,
    selectedBusinessMemberId: state.homePageData.selectedBusinessMemberId,
    isDataReset: state.homePageData.isResetData,
    paTopicSubTopics: state.filterDataPt.paTopicsSubtopics,
    practiceAreaListBc: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST_BC
    ),
    geoLocationListBc: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST_BC
    ),
    memberListBc: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST_BC
    ),
    jobTitleListBc: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST_BC
    ),
    locationListBc: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST_BC
    ),
    loggedInUserRole: state.homePageData.loggedInUserRole,
    loggedInUserId: state.homePageData.loggedInUserId,
    filterState: state.filterDataPt.filterState,
    filterStateBc: state.filterDataPt.filterStateBc,
    savedCollection: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_COLLECTION_DATA
    ),
    selectedUserByImpersonator: state.homePageData.selectedUserByImpersonator,
    impersonatorUserList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_IMPERSONATOR_USER_LIST
    ),
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchFiltersData: bindActionCreators(
        actions.protoTypeActions.fetchSeedDataFilters,
        dispatch
      ),
      updateIsFilterOpen: bindActionCreators(
        actions.protoTypeActions.updateIsFilterOpen,
        dispatch
      ),
      fetchUserData: bindActionCreators(
        actions.protoTypeActions.fetchMemberList,
        dispatch
      ),
      updateIsMemberFilterSelected: bindActionCreators(
        actions.protoTypeActions.updateIsMemberFilterSelected,
        dispatch
      ),
      resetData: bindActionCreators(
        actions.homepageActions.updateIsResetData,
        dispatch
      ),
      updateUserDetails: bindActionCreators(
        actions.homepageActions.updateUserDetails,
        dispatch
      ),
      fetchFiltersDataBc: bindActionCreators(
        actions.protoTypeActions.fetchFiltersForBusinessComparison,
        dispatch
      ),
      updateFilterState: bindActionCreators(
        actions.protoTypeActions.updateFilterState,
        dispatch
      ),
      updateFilterStateBc: bindActionCreators(
        actions.protoTypeActions.updateFilterStateBc,
        dispatch
      ),
      fetchSavedCollection: bindActionCreators(
        actions.protoTypeActions.fetchSavedCollection,
        dispatch
      ),
      saveAppliedCollection: bindActionCreators(
        actions.protoTypeActions.saveAppliedCollection,
        dispatch
      ),
      updateDateFilter: bindActionCreators(
        actions.protoTypeActions.updateSelectedDate,
        dispatch
      ),
      saveSelectedFiltersData: bindActionCreators(
        actions.protoTypeActions.saveSelectedFiltersData,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTFiltersSection);
