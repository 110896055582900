import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LabelGraphSkeleton = ({ showLabelGraphValue, width }) => {
  return showLabelGraphValue ? (
    <>
      <Skeleton width={35} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={width} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={4.5} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={width} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={4.5} height={4.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={width} height={4.5} duration={1} />
      <br />
    </>
  ) : null;
};

export default LabelGraphSkeleton;
