import * as types from './actionTypes';
import CONFIG from 'config/configProps';
import { fetchPost } from 'utils/KtCommonServices';

export function updateTotalRecords(data) {
  return {
    type: types.UPDATE_TOTAL_RECORDS,
    data,
  };
}

export function updateApiRequestData(apiRequestData) {
  return { type: types.UPDATE_API_REQUEST_DATA, apiRequestData };
}

export function loadHomeEditPage(loadPage) {
  return { type: types.LOAD_HOME_EDIT_PAGE, loadHomeEditPage: loadPage };
}

export function updateTableauStringData(string) {
  return {
    type: types.UPDATE_TABLEAU_STRING,
    string,
  };
}

export function updateTableauFilterParams(data) {
  return {
    type: types.UPDATE_TABLEAU_FILTER_PARAMS,
    data,
  };
}

export function updateTableauParamsForBC(data) {
  return {
    type: types.UPDATE_BC_TABLEAU_PARAMS,
    data,
  };
}

export const downloadBillabilityData = (postData, setProgress, url) => () => {
  const endpoint = url ? url : CONFIG.API_URLS.CASE_BILLABILITY_DOWNLOAD;
  const baseUrl = CONFIG.KT_BILLING_API_URL;
  let totalSize = 150 * 1024; // 1kb
  const extraConfig = {
    responseType: 'arraybuffer',
    onDownloadProgress: ({ loaded, lengthComputable, total }) => {
      const setTotalSize = !lengthComputable && !total;
      // Hack to show some progress bar
      // for stream request lengthComputable will be false
      // and total will not be returned
      if (setTotalSize) {
        total = loaded > totalSize ? loaded + totalSize : totalSize;
      }

      const percentCompleted = Math.round((loaded * 100) / total);
      setProgress({
        downloading: true,
        percentCompleted: percentCompleted,
      });
    },
  };

  return fetchPost(endpoint, postData, baseUrl, false, extraConfig);
};

export const getTotalCaseBillabilitySplit =
  (postData, checkCDPage, checkCaseEngegementPage) => () => {
    const baseUrl = CONFIG.KT_BILLING_API_URL;
    let endpoint;
    if (checkCDPage) endpoint = CONFIG.API_URLS.KT_CD_BILLING_API_URL;
    else if (checkCaseEngegementPage)
      endpoint = CONFIG.API_URLS.KT_CASE_ENGAGEMENT_DEPTH_URL;
    else endpoint = CONFIG.API_URLS.CASE_BILLABILITY_TOTAL;
    return fetchPost(endpoint, postData, baseUrl).then(
      response => response?.data
    );
  };
