import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getSkeletonWidth } from 'utils';

import {
  LineSkeleton,
  ParagraphSkeleton,
  LabelSkeleton,
  LabelBoxSkeleton,
  LabelValueSkeleton,
  LabelGraphSkeleton,
  SectionSkeleton,
  ImageGraphSkeleton,
  GraphLabelSkeleton,
  SectionFteSkeleton,
  AdminImageSkeleton,
} from './Views';

const KtSkeletonTheme = ({
  size,
  isImage,
  showParagraph,
  showLine,
  imageCount = 2,
  showLabel,
  showLabelBox,
  showLabelValue,
  showLabelGraphValue,
  showSections,
  isImageGraph,
  isGraphLabel,
  showSectionsFte,
  isAdminImage,
  height,
}) => {
  const width = getSkeletonWidth(size);

  return (
    <SkeletonTheme color="#ccc" highlightColor="#fefefe">
      {isImage ? (
        <Skeleton
          width={65}
          height={65}
          circle
          duration={1}
          count={imageCount}
        />
      ) : (
        <>
          <LineSkeleton showLine={showLine} width={width} height={height} />
          <ParagraphSkeleton showParagraph={showParagraph} />
          <LabelSkeleton showLabel={showLabel} width={width} />
          <LabelBoxSkeleton showLabelBox={showLabelBox} width={width} />
          <LabelValueSkeleton showLabelValue={showLabelValue} width={width} />
          <LabelGraphSkeleton
            showLabelGraphValue={showLabelGraphValue}
            width={width}
          />
          <SectionSkeleton showSections={showSections} width={width} />
          <ImageGraphSkeleton isImageGraph={isImageGraph} />
          <GraphLabelSkeleton isGraphLabel={isGraphLabel} />
          <SectionFteSkeleton showSectionsFte={showSectionsFte} />
          <AdminImageSkeleton isAdminImage={isAdminImage} />
        </>
      )}
    </SkeletonTheme>
  );
};

export default KtSkeletonTheme;
