import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import store from 'redux/store';
import MemberSearch from '../../common/MemberSearch/MemberSearch';
import KTCheckbox from 'components/common/KTCheckbox';
import { updateAllTeamMemberSelected } from 'redux/actions/headerFilterAction';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import Collections from './Collections';
import SaveCollections from './SaveCollection';
import './MemberFilter.scss';
import { isEmpty, cloneDeep, isEqual, showSearch } from 'utils/common';

class MemberFilter extends PureComponent {
  constructor(props) {
    super(props);
    const { memberList, selectedUsers, hideHeading } = this.props;
    this.state = {
      showUsers: true,
      memberList: memberList,
      noData: false,
      selectedUsers: selectedUsers,
      isAllSelected:
        isEqual(selectedUsers.length, memberList.length) &&
        !isEmpty(selectedUsers),
      alphaArray: ['A-E', 'F-J', 'K-N', 'O-S', 'T-Z'],
      selectedAlpha: '',
      filteredMemberList: memberList,
      isAlphaSearchApplied: false,
      openSaveCollection: false,
      collectionName: '',
      filterDto: this.getFilterDto(),
      hideHeading: hideHeading,
    };
  }

  getFilterDto = () => {
    const {
      selectedSectionDetails,
      employeeInitialData,
      path,
      managedBy,
      passGeo,
    } = this.props;
    const employeeDetails = isEmpty(selectedSectionDetails)
      ? employeeInitialData
      : selectedSectionDetails;
    const apiRequestData = {
      paIds: employeeDetails?.paIds || [employeeDetails.paId],
      practiceAreas: employeeDetails?.paTeams || [employeeDetails.paTeams],
      sectors: employeeDetails?.sectors || [employeeDetails.sectors],
      subSectors: employeeDetails?.subSectors || [employeeDetails.subSectors],
      topicSectorIds: employeeDetails?.topicSectorIds || [
        employeeDetails.topicSectorIds,
      ],
      subSectorIds: employeeDetails?.subSectorIds || [
        employeeDetails.subSectorIds,
      ],
      path: path.toUpperCase(),
      sharedSubSectors: employeeDetails?.sharedSubSectors || [
        employeeDetails.sharedSubSectors,
      ],
      geoRegion: isEqual(employeeDetails.geoRegion, 'No Geo')
        ? null
        : employeeDetails.geoRegion,
    };
    if (!passGeo) apiRequestData['selectedManagedBy'] = managedBy;
    return apiRequestData;
  };

  handleCheckboxClick = employeeId => {
    const {
      selectedUsers,
      isAlphaSearchApplied,
      filteredMemberList,
      memberList,
    } = this.state;
    let selectedUsersCopy = cloneDeep(selectedUsers);
    let isAllSelected = this.state.isAllSelected;
    const userList = isAlphaSearchApplied ? filteredMemberList : memberList;

    if (employeeId === -1) {
      selectedUsersCopy = userList.reduce((acc, el) => {
        const searchIndex = acc.indexOf(el.employeeIdHRO);
        if (!isAllSelected && searchIndex < 0) {
          return [...acc, el.employeeIdHRO];
        } else if (isAllSelected) {
          acc.splice(searchIndex, 1);
        }
        return acc;
      }, selectedUsersCopy);
      isAllSelected = !isAllSelected;
    } else {
      const index = selectedUsersCopy.indexOf(employeeId);
      if (index > -1) {
        selectedUsersCopy.splice(index, 1);
      } else {
        selectedUsersCopy.push(employeeId);
      }
      isAllSelected = selectedUsersCopy.length === userList.length;
    }

    this.setState({
      selectedUsers: selectedUsersCopy,
      isAllSelected,
    });
  };

  applyFilter = collectionName => {
    const { selectedUsers, isAllSelected } = this.state;
    const { onChange } = this.props;
    if (isEmpty(selectedUsers)) {
      return null;
    } else {
      store.dispatch(updateAllTeamMemberSelected(isAllSelected));
      return onChange(selectedUsers, isAllSelected, collectionName);
    }
  };

  onClickApply = () => {
    this.applyFilter();
  };

  searchByLetter = alphaGroup => () => {
    const { memberList, selectedAlpha, selectedUsers } = this.state;
    const isAlphaSearchApplied = !isEqual(selectedAlpha, alphaGroup);
    let filteredMemberListCopy = [];
    let isAllSelected = this.state;
    if (isAlphaSearchApplied) {
      const startRange = alphaGroup.charCodeAt(0);
      const endRange = alphaGroup.charCodeAt(2);
      filteredMemberListCopy = memberList.filter(
        el =>
          el.employeeName.charCodeAt(0) >= startRange &&
          el.employeeName.charCodeAt(0) <= endRange
      );
      const unSelectedFilteredData = filteredMemberListCopy.filter(
        item => selectedUsers.indexOf(item.employeeIdHRO) < 0
      );
      isAllSelected =
        isEmpty(unSelectedFilteredData) &&
        !isEmpty(selectedUsers) &&
        !isEmpty(filteredMemberListCopy);
    } else {
      alphaGroup = '';
    }
    isAllSelected =
      !isEmpty(selectedUsers) &&
      (memberList.length === selectedUsers.length ||
        filteredMemberListCopy.length === selectedUsers.length);

    store.dispatch(updateAllTeamMemberSelected(isAllSelected));
    this.setState({
      selectedAlpha: alphaGroup,
      filteredMemberList: filteredMemberListCopy,
      isAlphaSearchApplied,
      isAllSelected,
    });
  };

  displayUserList = (memberList, isAllSelected) => {
    const { selectedUsers } = this.state;
    return memberList.map(el => {
      return (
        <div key={el.employeeIdHRO} className="user-display">
          <KTCheckbox
            checked={
              isAllSelected || selectedUsers.indexOf(el.employeeIdHRO) > -1
            }
            checkboxColor="darkest-blue"
            handleChange={this.handleCheckboxClick}
            isReturn={true}
            value={el.employeeIdHRO}
          />
          <div className="user-name">{el.employeeName}</div>
        </div>
      );
    });
  };

  handleSearch = () => {
    const { isAlphaSearchApplied, isAllSelected } = this.state;
    this.setState({
      isAllSelected: isAlphaSearchApplied ? false : isAllSelected,
      isAlphaSearchApplied: false,
      filteredMemberList: [],
      selectedAlpha: '',
    });
  };

  renderAlphaSearch = () => {
    const { alphaArray, selectedAlpha } = this.state;

    return (
      <div className="selection-option">
        {alphaArray.map(el => {
          return (
            <div
              className={`text ${isEqual(selectedAlpha, el) ? 'selected' : ''}`}
              onClick={this.searchByLetter(el)}
              key={el}
            >
              {el}
            </div>
          );
        })}
      </div>
    );
  };

  renderMemberList = () => {
    const {
      memberList,
      noData,
      selectedUsers,
      isAllSelected,
      isAlphaSearchApplied,
      filteredMemberList,
      isLoading,
    } = this.state;

    let userList = isAlphaSearchApplied ? filteredMemberList : memberList;

    const selectedUserList = userList.filter(
      item => selectedUsers.indexOf(item.employeeIdHRO) >= 0
    );
    const unSelectedUserList = userList.filter(
      item => selectedUsers.indexOf(item.employeeIdHRO) < 0
    );
    userList = selectedUserList.concat(unSelectedUserList);

    if (isLoading) {
      return (
        <>
          {[...Array(5)].map((e, i) => (
            <LabelWithSkeleton
              isLoading={isLoading}
              showLine
              size="medium"
              key={i}
            />
          ))}
        </>
      );
    }

    if (noData) {
      return <div className="no-data">No Users Present</div>;
    }

    return (
      <div className="show-user-list">
        <div className="user-list">
          {!isEmpty(userList)
            ? this.displayUserList(userList, isAllSelected)
            : isAlphaSearchApplied && 'No Result Found'}
        </div>
        {this.renderAlphaSearch()}
      </div>
    );
  };

  openSaveCollection = e => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ openSaveCollection: true, showUsers: false });
  };

  getDisplayName = () => {
    const { selectedUsers, memberList } = this.state;
    const selectedMember = memberList.find(
      item => selectedUsers.indexOf(item.employeeIdHRO) >= 0
    );
    return selectedUsers?.length === 1
      ? `Selected Member (${selectedMember?.employeeName})`
      : `${selectedUsers?.length || 0} Selected`;
  };

  showUsers = () => {
    const { memberList, noData, selectedUsers, isAllSelected } = this.state;
    const { saveCollection } = this.props;

    return (
      <div>
        {!isEmpty(memberList) && showSearch(memberList) && (
          <MemberSearch
            onClick={this.handleCheckboxClick}
            searchList={memberList}
            onSearch={this.handleSearch}
            placeholder="Search Team Members"
          />
        )}
        <div className="selected-section">{this.getDisplayName()}</div>
        <div className="show-all-section">
          <KTCheckbox
            checked={isAllSelected}
            checkboxColor="darkest-blue"
            handleChange={this.handleCheckboxClick}
            labelName="All"
            isReturn={true}
            value={-1}
          />
        </div>
        {this.renderMemberList()}
        <div className="button-footer">
          {saveCollection && (
            <div className="save-button">
              <div
                className={`${
                  isEmpty(selectedUsers) ? 'disabled' : ''
                } save-collection`}
                onClick={this.openSaveCollection}
              >
                <div className="save">Save collection</div>
                <img
                  className="save-icon"
                  src="/img/save_icon.svg"
                  alt="save-icon"
                />
              </div>
            </div>
          )}
          <div className="apply-button">
            <div
              className={`apply ${
                noData || isEmpty(selectedUsers) ? 'disabled' : ''
              }`}
              onClick={this.onClickApply}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    );
  };

  applyCollections = appliedFilters => {
    return this.props.applyCollections(appliedFilters);
  };

  closeSaveCollection = () => {
    this.setState({ openSaveCollection: false, showUsers: true });
  };

  updateEditCollection = () => {
    this.setState({ openSaveCollection: false, hideHeading: true });
  };

  updateState = () => {
    this.setState({
      openSaveCollection: false,
      hideHeading: false,
      showUsers: false,
    });
  };

  displaySavedCollectionsTab = e => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ showUsers: false, openSaveCollection: false });
  };

  displayUserListTab = e => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ showUsers: true, openSaveCollection: false });
  };

  renderMemberColection = () => {
    const {
      selectedUsers,
      showUsers,
      openSaveCollection,
      memberList,
      filterDto,
    } = this.state;
    const { closeMemberFilter } = this.props;

    if (openSaveCollection) {
      return (
        <SaveCollections
          memberList={memberList}
          selectedUsers={selectedUsers}
          handleDeleteClick={this.handleCheckboxClick}
          onBackClick={this.closeSaveCollection}
          filterDto={filterDto}
          closeMemberFilter={closeMemberFilter}
          applyFilter={this.applyFilter}
        />
      );
    }

    if (showUsers) {
      return this.showUsers();
    }

    return (
      <Collections
        applyCollections={this.applyCollections}
        isEditCollection={this.updateEditCollection}
        closeMemberFilter={closeMemberFilter}
        onBackClick={this.updateState}
      />
    );
  };

  render() {
    const { showUsers, openSaveCollection, hideHeading } = this.state;
    return (
      <div className="kt-drop-down-with-search-container team-member-container">
        {!(openSaveCollection || hideHeading) && (
          <div className="user-collection">
            <div
              className={`${showUsers ? 'active' : ''} blocks`}
              onClick={this.displayUserListTab}
            >
              Users
            </div>
            <div
              className={`${!showUsers ? 'active' : ''} collection blocks`}
              onClick={this.displaySavedCollectionsTab}
            >
              Saved Collection
            </div>
          </div>
        )}
        {this.renderMemberColection()}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    memberList: reduxStore.headerFilterData.memberList,
    collectionData: reduxStore.ktScorecard.collectionData,
    selectedSectionDetails: reduxStore.headerFilterData.selectedSectionDetails,
    initialDate: reduxStore.headerFilterData.initialDate,
    calenderDropdownVal: reduxStore.headerFilterData.calenderDropdownVal,
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    path: reduxStore.headerFilterData.path,
    fetchMemberList: reduxStore.ktScorecard.fetchMemberList,
    managedBy: reduxStore.headerFilterData.managedBy,
    passGeo: reduxStore.headerFilterData.passGeo,
  };
};
export default connect(mapStateToProps)(withRouter(MemberFilter));
