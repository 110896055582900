import React from 'react';
import TopicTableauCard from 'components/KTTableauStackWrapper/TopicTableau';
import ContentTableau from 'components/KTTableauStackWrapper/ContentTableau';
import TeamTableau from 'components/KTTableauStackWrapper/TeamTableau';
export default function TableauStack(props) {
  const {
    pageHeading,
    startDate,
    endDate,
    isTeam,
    isContent,
    isAdmin,
    isTopicClient,
    section,
    className,
  } = props;
  return (
    <div className={`${className} tile-stack`}>
      <div className="tile-stack-header">
        <div className="header-left-text">
          <div className="heading">{pageHeading}</div>
          <div className="dates">{`(${startDate} - ${endDate})`}</div>
        </div>
      </div>
      <div className="tile-stack-body">
        {isTeam && <TeamTableau pageHeading={pageHeading} isAdmin={isAdmin} />}
        {isTopicClient && (
          <TopicTableauCard
            section={section}
            pageHeading={pageHeading}
            isAdmin={isAdmin}
          />
        )}
        {isContent && (
          <ContentTableau pageHeading={pageHeading} isAdmin={isAdmin} />
        )}
      </div>
    </div>
  );
}
