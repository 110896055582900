import React from 'react';
import { Table } from 'semantic-ui-react';

import TableLoader from './TableLoader';
import { renderColumnData } from 'utils';

const TableBody = ({ dataList, loading, columns }) => {
  if (loading) {
    return <TableLoader loading={loading} columns={columns} />;
  }

  return (
    <Table.Body>
      {dataList?.length ? (
        dataList.map(rowData => {
          const { id } = rowData;
          return (
            <Table.Row key={id}>
              {columns.map((columnConf, index) => {
                return (
                  <Table.Cell
                    key={`${id}-${columnConf.dataField}-${index}`}
                    {...(columnConf.columnProps || {})}
                  >
                    {renderColumnData(
                      rowData[columnConf.dataField],
                      rowData,
                      columnConf
                    ) || rowData[columnConf.dataField]}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell
            colSpan={columns?.length}
            textAlign="center"
            className="no-records"
          >
            No Records To Display
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

export default React.memo(TableBody);
