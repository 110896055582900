import React from 'react';
import { Card } from 'semantic-ui-react';

import Icon from 'components/common/icon/Icon';
import { formatDate, isEmpty } from 'utils/common';
import { renderColumnData, processUserList } from 'utils';

import './UserDetails.scss';

const UserDetails = ({
  userInfo,
  titleClassName,
  columns,
  toggleEditView,
  loading,
}) => {
  const [userData] = !isEmpty(userInfo)
    ? processUserList([{ ...userInfo }])
    : [];
  const handleEditClick = () => toggleEditView('edit_user', userInfo, true);
  const handleChangeAlignmentClick = () =>
    toggleEditView('change_alignment', userInfo, true);

  if (loading) {
    return null;
  }

  return (
    <div id="user-info-card-container">
      <h3 className={titleClassName}>User Details</h3>
      <Card className="user-info-card">
        <Card.Content className="user-info-card__content">
          {isEmpty(userInfo) ? (
            'No Records To Display'
          ) : (
            <>
              <Card.Header className="user-info-card__content__header">
                <Icon
                  name="user circle"
                  className="user-info-card__content__header__image"
                />
                <div className="user-info-card__content__header__user-name">
                  {userInfo?.employeeName}
                </div>
                <div className="user-info-card__content__header__actions">
                  <Icon
                    name="pencil"
                    className="user-info-card__content__header__actions__edit"
                    onClick={handleEditClick}
                    tooltipText="Edit user details"
                    data-testid="edit-user"
                  />
                  <Icon
                    name="plus"
                    className="user-info-card__content__header__actions__new-alignment"
                    onClick={handleChangeAlignmentClick}
                    tooltipText="Add new alignment"
                    data-testid="change-alignment"
                  />
                </div>
              </Card.Header>
              <Card.Meta>
                Last modified By: {userInfo?.lastModifiedByName || 'NA'}{' '}
                on&nbsp;
                {formatDate(userInfo?.rowUpdatedDate, 'MMM-dd, yyyy')}
              </Card.Meta>
              <Card.Description className="user-info-card__content__description">
                {columns
                  .filter(({ dataField }) => dataField !== 'employeeName')
                  .map(columnConf => {
                    return (
                      (columnConf.dataField !== 'geoRegion' ||
                        userData['managedBy'] !== 'PA Managed') && (
                        <div
                          key={columnConf.dataField}
                          className="user-info-card__content__description__row"
                        >
                          <div className="user-info-card__content__description__row__label">
                            {columnConf.title}
                          </div>
                          <div className="user-info-card__content__description__row__data">
                            {renderColumnData(
                              userData[columnConf.dataField],
                              userData,
                              columnConf
                            )}
                          </div>
                        </div>
                      )
                    );
                  })}
              </Card.Description>
            </>
          )}
        </Card.Content>
      </Card>
    </div>
  );
};

export default UserDetails;
