import { UPDATE_ABODE_ANALYTICS_DETAILS } from 'redux/actions/actionTypes';
import InitialState from './InitialState';
const STATE_SLICE_NAME = 'adobeData'; // MUST match INITIAL STate key

export function AdobeAnalyticsReducer(
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case UPDATE_ABODE_ANALYTICS_DETAILS: {
      return {
        ...state,
        adobeAnalyticsData: action.adobeDetails,
      };
    }
    //default: return existing state
    default:
      return state;
  }
}
