/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import store from 'redux/store';
import { fetchGet, fetchPost } from 'utils/KtCommonServices';
import KTCheckbox from '../KTCheckbox';
import CONFIG from 'config/configProps';
import { updateToastNotificationDetails, closeGrantModal } from 'redux/actions';
import {
  getApiFailMessage,
  isBusinessComparisonRoleId,
  isAllowedRole,
  isAllPARole,
} from 'utils';
import { isEqual, isEmpty } from 'utils/common';

class KtGrantPermissionModal extends Component {
  constructor(props) {
    super(props);
    const { loggedInUserHighestRole, userTypes, modalData, userTypeData } =
      this.props;
    this.state = {
      modalData: modalData,
      practiceAreaList: [{ paId: null, practiceArea: 'Select PA' }],
      practiceAreaId: null,
      topicSectorList: [{ sector: 'Select Topic/Sector', topicSectorId: null }],
      topicSectorId: null,
      topicSubSectorList: [
        { subSector: 'Select Sub-Topic/Segment', subSectorId: null },
      ],
      topicSubSectorId: null,
      typeKt: modalData.typeKt,
      isPaAdmin: isEqual(loggedInUserHighestRole, userTypes[1]),
      isSuperAdmin: isEqual(loggedInUserHighestRole, userTypes[0]),
      isImpersonator: isEqual(loggedInUserHighestRole, 'Impersonator'),
      isGeoLead: isEqual(loggedInUserHighestRole, 'Geo Lead'),
      viewTypeId: '',
      viewTypeList: modalData.typeKt
        ? userTypeData
        : userTypeData.filter(item => !item.editor),
      viewType: '',
      geoMarketId: null,
      geoMarketList: [{ label: 'Select Geo Market', value: null }],
      geoRoleId: CONFIG.GEO_ROLE_IDS,
      submitEnabled: false,
    };
  }

  handleViewTypeChange = e => {
    const { geoRegionList } = this.props;
    const { geoRoleId } = this.state;
    let viewTypeId = parseInt(e.target.value);
    const index = e.nativeEvent.target.selectedIndex;
    const viewType = e.nativeEvent.target[index].text;
    const isGeoRole = geoRoleId.includes(viewTypeId);
    this.setState({
      viewType,
      viewTypeId,
      geoMarketId: !isGeoRole ? null : geoRegionList[0].value,
      geoMarketList: !isGeoRole
        ? [{ label: 'NA', value: null }]
        : geoRegionList,
      submitEnabled: false,
    });
    if (viewTypeId) this.getPaValue(viewTypeId);
    else this.updateInitialState();
  };

  getPaValue = viewTypeId => {
    const { ktSaPaList, ktPaPaList } = this.props;
    const { isPaAdmin, isSuperAdmin, isGeoLead, isImpersonator } = this.state;

    let practiceAreaList = [];
    if (isSuperAdmin || isGeoLead || isImpersonator) {
      practiceAreaList = ktSaPaList;
    } else if (isPaAdmin) {
      practiceAreaList = ktPaPaList;
    }
    if (isBusinessComparisonRoleId(viewTypeId)) {
      this.setState({
        practiceAreaId: null,
        topicSectorId: null,
        topicSubSectorId: null,
        practiceAreaList: [],
        topicSectorList: [],
        topicSubSectorList: [],
        submitEnabled: true,
      });
    } else if (isAllPARole(viewTypeId)) {
      this.setState({
        practiceAreaId: '-1',
        topicSectorId: -1,
        topicSubSectorId: -1,
        practiceAreaList: [{ practiceArea: 'All', paId: '-1' }],
        topicSectorList: [{ sector: 'All', topicSectorId: '-1' }],
        topicSubSectorList: [{ subSector: 'All', subSectorId: '-1' }],
        submitEnabled: true,
      });
    } else {
      this.setState({
        practiceAreaList,
        submitEnabled: false,
        practiceAreaId: practiceAreaList[0].paId,
        topicSectorList: [],
      });
      this.getSectorList(practiceAreaList[0].paId, viewTypeId);
    }
  };

  /**
   * This method handles change in value of topic sector from dropdown
   */
  handlePracticeAreaChange = e => {
    let practiceAreaId = parseInt(e.target.value);
    this.setState({
      practiceAreaId,
      topicSectorList: [],
      topicSubSectorList: [],
    });
    this.getSectorList(practiceAreaId, this.state.viewTypeId);
  };

  getSectorList = (practiceAreaId, viewTypeId) => {
    let url = `${CONFIG.API_URLS.EMP_SECTOR}?id=${practiceAreaId}`;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    if (isEqual(viewTypeId, 2) || isEqual(viewTypeId, 5)) {
      this.setState({
        topicSectorId: -1,
        topicSubSectorId: -1,
        topicSectorList: [{ sector: 'All', topicSectorId: '-1' }],
        topicSubSectorList: [{ subSector: 'All', subSectorId: '-1' }],
        submitEnabled: true,
      });
    } else {
      fetchGet(url, baseUrl)
        .then(sectorList => {
          const filteredSectorList = sectorList.filter(
            item => item.topicSectorId
          );
          const sectorListToShow =
            isEmpty(sectorList) || isEmpty(filteredSectorList)
              ? [{ sector: 'All', topicSectorId: '-1' }]
              : filteredSectorList;

          this.setState(
            {
              topicSectorList: sectorListToShow,
              topicSectorId: sectorList[0].topicSectorId,
              topicSubSectorId: -1,
              topicSubSectorList: [{ subSector: 'All', subSectorId: '-1' }],
              submitEnabled: true,
            },
            () => {
              if (sectorListToShow[0].topicSectorId > 0) {
                this.getSubSectorList(sectorListToShow[0].topicSectorId);
              }
            }
          );
        })
        .catch(() => {
          store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
        });
    }
  };

  /**
   * This method handles change in value of topic sector from dropdown
   */

  handleTopicSectorChange = e => {
    const topicSectorId = parseInt(e.target.value);
    this.setState({ topicSectorId });
    this.getSubSectorList(topicSectorId);
  };

  getSubSectorList = topicSectorId => {
    let url = `${CONFIG.API_URLS.EMP_SUB_SECTOR}?id=${topicSectorId}`;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    fetchGet(url, baseUrl)
      .then(async topicSubSectorList => {
        const subSectorListToShow = topicSubSectorList.filter(
          item => item.subSectorId
        );
        const subSectorList = [
          { subSector: 'All', subSectorId: '-1' },
          ...subSectorListToShow,
        ];
        this.setState({
          topicSubSectorList: subSectorList,
          topicSubSectorId: subSectorList[0].subSectorId,
        });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
  };

  /**
   * This method handles change in value of topic sub sector from dropdown
   */

  handleTopicSubSectorChange = e => {
    const topicSubSectorId = parseInt(e.target.value);
    this.setState({ topicSubSectorId });
  };

  handleGeoMarketChange = e => {
    const index = e.nativeEvent.target.selectedIndex;
    const geoMarket = e.nativeEvent.target[index].text;
    this.setState({ geoMarketId: geoMarket });
  };

  /**
   * This methods handles submit data for KT member
   */

  submit = async () => {
    const { modalData } = this.props;
    const { allowBusinessComparison } = modalData;
    const {
      practiceAreaId,
      topicSectorId,
      topicSubSectorId,
      typeKt,
      viewTypeId,
      addMoreRoles,
      geoMarketId,
      geoRoleId,
    } = this.state;
    let notificationDetailsObj = {
      showToastNotification: true,
      notificationHeader: 'Success',
      notificationBody: '',
      notificationClassName: 'custom-notification',
    };
    if (isBusinessComparisonRoleId(viewTypeId) && !allowBusinessComparison) {
      notificationDetailsObj.notificationHeader = 'Error';
      notificationDetailsObj.notificationBody =
        'Business Comparison role is not allowed for the selected user';
      store.dispatch(closeGrantModal(true));
      store.dispatch(updateToastNotificationDetails(notificationDetailsObj));
      this.props.onHide();
      return;
    }

    const isGeoRole = geoRoleId.includes(viewTypeId);
    const geoRegion = geoMarketId ? geoMarketId : modalData.geoRegion;
    const postObj = {
      emailId: modalData.emailId,
      employeeFullName: modalData.fullName,
      employeeIdHRO: modalData.userId,
      practiceAreaId,
      sectorId: topicSectorId,
      subSectorId: topicSubSectorId,
      typeKt: typeKt,
      roleId: viewTypeId,
      geoRegion:
        isBusinessComparisonRoleId(viewTypeId) || !isGeoRole ? null : geoRegion,
      managedBy: modalData.managedBy ? modalData.managedBy : 'NA',
    };
    const urlVal = CONFIG.API_URLS.ADMIN;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    fetchPost(urlVal, postObj, baseUrl)
      .then(async () => {
        if (addMoreRoles) {
          this.setState({
            modalData: modalData,
            practiceAreaId,
            topicSector: 'All',
            topicSectorId: -1,
            topicSubSector: 'All',
            topicSubSectorId: -1,
            viewTypeId: null,
            addMoreRoles: false,
          });
        } else if (!addMoreRoles) {
          this.props.onHide();
          this.props.dataRefreshHandler();
          notificationDetailsObj.notificationBody =
            'Selected role(s) permission to Member granted successfully. Please raise access request in Service Now to get access for Tableau Server for the user';
          store.dispatch(
            updateToastNotificationDetails(notificationDetailsObj)
          );
        }
      })
      .catch(error => {
        notificationDetailsObj = getApiFailMessage(true);
        if (
          error.data.errorMessage ===
          'User role for the combination of practice area, sector and sub sector already exists'
        ) {
          notificationDetailsObj.notificationBody = error.data.errorMessage;
          notificationDetailsObj.notificationHeader = 'Error';
        }
        store.dispatch(closeGrantModal(true));
        store.dispatch(updateToastNotificationDetails(notificationDetailsObj));
        this.props.onHide();
      });
  };

  /**
   * This methods closes the grant permission modal.
   */

  handlePermissionKtMemberClose = () => {
    this.updateInitialState();
    this.props.dataRefreshHandler();
    this.props.onHide();
  };

  updateInitialState = () => {
    this.setState({
      modalData: {},
      practiceAreaList: [{ paId: null, practiceArea: 'Select PA' }],
      practiceAreaId: null,
      topicSectorList: [
        {
          sector: 'Select Topic/Sector',
          sharedSubSector: null,
          topicSectorId: null,
        },
      ],
      topicSectorId: null,
      topicSubSectorList: [
        {
          sharedSubSector: null,
          subSector: 'Select Sub-Topic/Segment',
          subSectorId: null,
        },
      ],
      topicSubSectorId: null,
      addMoreRoles: false,
      viewTypeId: '',
      viewType: '',
      geoMarketId: null,
      geoMarketList: [{ label: 'Select Geo Market', value: null }],
    });
  };

  practiceAreaMarkup = () => {
    return this.state.practiceAreaList.map(practiceArea => {
      return (
        <option key={practiceArea.paId} value={practiceArea.paId}>
          {CONFIG.PA_NAME_ALIAS_MAP[practiceArea.practiceArea] ||
            practiceArea.practiceArea}
        </option>
      );
    });
  };

  viewTypeMarkup = () => {
    const { allowBusinessComparison } = this.props.modalData;
    return this.state.viewTypeList.map(({ id, name }) => {
      if (
        isAllowedRole(id) &&
        (!isBusinessComparisonRoleId(id) || allowBusinessComparison)
      ) {
        return (
          <option key={id} value={id}>
            {name}
          </option>
        );
      }
      return null;
    });
  };

  sectorMarkup = () => {
    return this.state.topicSectorList.map(topicSector => {
      return (
        <option
          key={topicSector.topicSectorId}
          value={topicSector.topicSectorId}
        >
          {topicSector.sector}
        </option>
      );
    });
  };

  subSectorMarkup = () => {
    return this.state.topicSubSectorList.map(topicSubSector => {
      return (
        <option
          key={topicSubSector.subSectorId}
          value={topicSubSector.subSectorId}
        >
          {topicSubSector.subSector}
        </option>
      );
    });
  };

  geoMarketMarkup = () => {
    return this.state.geoMarketList.map(geoMarket => {
      return (
        <option key={geoMarket.label} value={geoMarket.value}>
          {geoMarket.label}
        </option>
      );
    });
  };

  render() {
    const {
      geoMarketId,
      practiceAreaId,
      typeKt,
      viewTypeId,
      viewType,
      topicSectorId,
      topicSubSectorId,
      addMoreRoles,
      submitEnabled,
    } = this.state;
    const { onHide, modalData } = this.props;
    const isBusinesscomparisonView = isBusinessComparisonRoleId(viewTypeId);

    return (
      <div className="tile-modal-container">
        <Modal centered show onHide={onHide} className="kt-scorecard-modal">
          <div className="info-text">
            <span className="kt-download-icon" />
            <span className="info-text-1">
              Note: Team members will be provided editor access to their
              respective decks and not throughout the app.
            </span>
          </div>
          <Modal.Body className="tile-modal-view">
            <div className="tile-modal-view-container">
              <div className="main-content">
                <div className="kt-modal-header">
                  <div className="modal-heading">
                    <span>{modalData.fullName}</span>
                  </div>
                </div>
                <div>
                  {!typeKt && (
                    <p className="kt-role-container non-kt-msg">
                      <img
                        className="kt-alert-img"
                        src="./img/kt-no-roles-alert.svg"
                        alt="kt-no-roles-alert"
                        height="25"
                        width="25"
                      />
                      <span>
                        This Member does not have access to the KT Dashboard.
                      </span>
                    </p>
                  )}
                  {typeKt && viewTypeId && (
                    <p className="kt-role-container">
                      <img
                        className="kt-alert-img"
                        src="./img/kt-super-admin-alert-img.svg"
                        alt="kt-admin-alert"
                        height="22"
                        width="22"
                      />
                      <span>{`${viewType} is selected`}</span>
                    </p>
                  )}
                </div>
                <div className="view-type-list">
                  <select
                    className="select-style view-type-list-item"
                    name="viewTypeId"
                    value={viewTypeId}
                    onChange={this.handleViewTypeChange}
                  >
                    <option value={null} selected={true}>
                      Select View Type
                    </option>
                    {this.viewTypeMarkup()}
                  </select>
                </div>
                {!isBusinesscomparisonView && (
                  <>
                    <hr className="kt-dropdown-separator" />
                    <div className="pa-name-list">
                      <select
                        className="select-style pa-name-list-item"
                        name="practiceAreaId"
                        value={practiceAreaId}
                        onChange={this.handlePracticeAreaChange}
                      >
                        {this.practiceAreaMarkup()}
                      </select>
                    </div>
                    <div className="topic-name-list">
                      <select
                        className="select-style topic-name-list-item"
                        name="topicSectorId"
                        value={topicSectorId}
                        onChange={this.handleTopicSectorChange}
                      >
                        {this.sectorMarkup()}
                      </select>
                    </div>
                    <div className="sub-topic-name-list">
                      <select
                        className="select-style sub-topic-name-list-item"
                        name="topicSubSectorId"
                        value={topicSubSectorId}
                        onChange={this.handleTopicSubSectorChange}
                      >
                        {this.subSectorMarkup()}
                      </select>
                    </div>
                    <div className="geo-market-list">
                      <select
                        className="select-style geo-market-list-item"
                        name="geoMarketId"
                        value={geoMarketId}
                        onChange={this.handleGeoMarketChange}
                      >
                        {this.geoMarketMarkup()}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="kt-modal-buttons">
              <button
                className="kt-button cancel-button"
                onClick={this.handlePermissionKtMemberClose}
              >
                Close
              </button>
              <div className="kt-add-roles-checkbox">
                <KTCheckbox
                  checked={addMoreRoles}
                  labelName="Add More Roles"
                  value="Add More Roles"
                  checkboxColor="light-blue"
                  handleChange={() =>
                    this.setState({ addMoreRoles: !addMoreRoles })
                  }
                />
                <button
                  className={`kt-button save-button${
                    submitEnabled ? '' : '-disabled'
                  }`}
                  onClick={this.submit}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    ktSaPaList: reduxStore.headerFilterData.ktSaPaList,
    ktPaPaList: reduxStore.headerFilterData.ktPaPaList,
    userTypes: reduxStore.userRoleData.userTypes,
    userTypeData: reduxStore.userRoleData.userTypeData,
    geoRegionList: reduxStore.ktScorecard.geoRegionList,
  };
};
export default connect(mapStateToProps)(KtGrantPermissionModal);
