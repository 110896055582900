import { Toast } from 'react-bootstrap';
import React from 'react';
import { updateToastNotificationDetails } from 'redux/actions';
import { connect } from 'react-redux';
import './Toaster.scss';

function KtToastNotification(props) {
  const { toastNotificationDetails, dispatch } = props;
  const { showToastNotification } = toastNotificationDetails;

  async function closeNotification() {
    const notificationDetailsObj = {
      ...toastNotificationDetails,
      showToastNotification: false,
    };

    await dispatch(updateToastNotificationDetails(notificationDetailsObj));
  }

  return (
    <div
      id="toaster"
      data-animation="true"
      style={{
        top: showToastNotification ? '18rem' : '0px',
        height: showToastNotification ? 'auto' : '0px',
        border: showToastNotification ? 'initial' : 'unset',
      }}
      className={`kt-toast-notification ${
        props.notificationClassName ? props.notificationClassName : 'Error'
      }`}
    >
      <Toast show={showToastNotification} onClose={() => closeNotification()}>
        <Toast.Body>
          {props.header === 'Success' ? (
            <div>
              <img src="/img/kt-toast-success.svg" alt="kt-toast-success" />
            </div>
          ) : (
            <div>
              <img src="/img/kt-toast-failure.svg" alt="kt-toast-failure" />
            </div>
          )}
          <div className="notification-content">
            <div className="toast-header">{props.header}</div>
            <div className="toast-message">{props.body}</div>
          </div>
          <div className="kt-cross" onClick={() => closeNotification()}>
            <img src="/img/kt_cross.svg" alt="kt_cross" />
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
}

const mapStateToProps = function (reduxStore) {
  return {
    toastNotificationDetails: reduxStore.ktScorecard.toastNotificationDetails,
  };
};
export default connect(mapStateToProps)(KtToastNotification);
