import React from 'react';
import { isEmptyOrNull } from 'utils';
import KTHomeLoader from './KTHomeLoader';
import KtDropdown from 'components/common/KtDropdown';
import { isLoading } from 'utils/common';
import CONFIG from 'config/configProps';

const KTBillability = props => {
  const { className, heading, legends, isCd } = props;
  const data = props.data.data;
  const billabilityData = data?.results || {};
  const dropdownOptions = Object.keys(CONFIG.KT_BILLABILITY_QUESTIONS);

  const changeFilterHandler = selectedItem => {
    props.onQuestionChange(selectedItem);
  };

  return (
    <div className={`home_page_main_split_row_${className} main_conatiner`}>
      <div className="heading">
        <div className="heading_text">{heading}</div>
        {isCd ? null : (
          <div className="heading_sortby">
            <KtDropdown
              dropdownOptionsList={dropdownOptions}
              selectedOptionName={props.selectedQuestion}
              changeEventHandler={changeFilterHandler}
            />
          </div>
        )}
      </div>

      <div className="chart__table">
        <div className="chart__table__body data_table_body">
          {isLoading(props.data) ? (
            <KTHomeLoader isLoading={1} section="KT Billability" count="5" />
          ) : isEmptyOrNull(billabilityData) ? (
            <div className="static-text">No data to display</div>
          ) : (
            <>
              {isCd && (
                <div className="total">{`${billabilityData.cdBillability}%`}</div>
              )}
              {Object.keys(legends).map(key => {
                return (
                  <div className="data_table_body_row" key={key}>
                    <div className="billabilityType">{key}</div>
                    <div className="billabilityValue">
                      {`${Math.round(billabilityData[legends[key]])}%`}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KTBillability;
