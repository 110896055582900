import React, { Component } from 'react';
import store from 'redux/store';
import { connect } from 'react-redux';
import { updateToastNotificationDetails } from 'redux/actions';
import KtGrantPermissionModal from 'components/common/KtGrantDeletePermissionModal/KtGrantPermissionModal';
import KtGrantDeletePermissionModal from 'components/common/KtGrantDeletePermissionModal';
import LineInput from 'components/common/CommonHtml/lineInput';
import { fetchGet, fetchPost } from 'utils/KtCommonServices';
import { isEqual, debounce, isEmpty } from 'utils/common';
import Spinner from 'components/common/Spinner/Spinner';
import CONFIG from 'config/configProps';
import PeopleImage from 'components/common/peopleImage';
import { getApiFailMessage, getSearchByNameQuery } from 'utils';
import './KtSearch.scss';

class KtSearch extends Component {
  constructor(props) {
    super(props);
    const { placeholder, value } = this.props;
    this.state = {
      searchValue: value ? value : '',
      memberSearchedResultList: [],
      showMemberSearchedResultList: false,
      placeholder: placeholder,
      openModal: false,
      openModalGrantDelete: false,
      requireModelData: {},
      isSpinnerVisible: false,
    };
    this.minimumCharacterForSearch = 3;
    this.typingTimer = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.searchValue !== props.value &&
      props.value !== '' &&
      props.fromOthersModal
    ) {
      return {
        searchValue: props.value,
      };
    }
    return null;
  }

  /**
   * This method will do the server call to get the searched result
   * after entering the 3 character
   */
  memberSearchResultHandler = event => {
    const { placeholder } = this.props;
    event.stopPropagation();
    const searchValue = event.target.value;
    const regex = /^[a-z\d\-_\s]+$/i;
    if (regex.test(searchValue)) {
      this.setState({ searchValue });
      if (searchValue.trim().length >= this.minimumCharacterForSearch) {
        this.setState({ isSpinnerVisible: true });
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(
          debounce(this.getMemberSearchedData.bind(this, searchValue), 1500),
          250
        );
      } else {
        this.setState({
          placeholder: placeholder,
          isSpinnerVisible: false,
          memberSearchedResultList: [],
          showMemberSearchedResultList: false,
        });
      }
    } else if (isEmpty(searchValue)) {
      this.setState({
        placeholder: placeholder,
        isSpinnerVisible: false,
        memberSearchedResultList: [],
        showMemberSearchedResultList: false,
        searchValue: '',
      });
    }
  };

  getMemberSearchedData = searchValue => {
    clearTimeout(this.typingTimer);
    const externalApplication = CONFIG.EXTERNAL_APPLICATION.PEOPLE_API;
    const exactBaseUrl = CONFIG.PEOPLE_API_URL;
    const params = { query: getSearchByNameQuery(searchValue) };
    return fetchPost('', params, exactBaseUrl, externalApplication)
      .then(searchedResult => {
        const result = searchedResult.data.data.searchFilter.employees;
        this.setSearchResultList(result);
      })
      .catch(() => {
        this.handleErrorMessage();
      });
  };

  setSearchResultList = searchedResult => {
    const { placeholder } = this.props;
    if (searchedResult.length) {
      this.setState({
        isSpinnerVisible: false,
        memberSearchedResultList: searchedResult,
        showMemberSearchedResultList: true,
      });
    } else {
      this.setState({
        placeholder: placeholder,
        isSpinnerVisible: false,
        memberSearchedResultList: [{ searchResult: 'No result found' }],
        showMemberSearchedResultList: true,
      });
    }
  };

  handleErrorMessage = () => {
    this.setState({
      isSpinnerVisible: false,
      memberSearchedResultList: [],
      showMemberSearchedResultList: false,
    });
    store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
  };

  getMasterSearchList = () => {
    const { fromOthersModal, peopleImage } = this.props;
    const { memberSearchedResultList } = this.state;
    return (
      memberSearchedResultList &&
      memberSearchedResultList.map((item, index) => {
        if (isEqual(item.searchResult, 'No result found')) {
          return (
            <div className="kt-search-main-container" key={index}>
              <div className={`kt-searched-item kt-list-number-${index}`}>
                <div className="member-left-container">
                  <div className="kt-member-name">{item.searchResult}</div>
                </div>
              </div>
            </div>
          );
        } else {
          const hrId = item.id;
          const activeRequest =
            isEmpty(peopleImage) || isEmpty(peopleImage[hrId])
              ? 0
              : peopleImage[hrId].activeRequest;
          return (
            <div
              className="kt-search-main-container"
              onClick={() =>
                fromOthersModal
                  ? this.setMemberName(item)
                  : this.openModal(item)
              }
              key={index}
            >
              <div className={`kt-searched-item kt-list-number-${index}`}>
                <div className="member-left-container">
                  <div className="kt-user-image">
                    {activeRequest < 1 ? (
                      <PeopleImage hrId={hrId} position="top right" />
                    ) : null}
                  </div>
                  <div>
                    <div className="kt-member-name">{`${item.firstName} ${item.lastName}`}</div>
                    {fromOthersModal ? null : (
                      <div className="kt-member-role">
                        <div className="kt-member-practiceArea">
                          {item.title}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {index < memberSearchedResultList.length - 1 && (
                <div className="kt-style-line" />
              )}
            </div>
          );
        }
      })
    );
  };

  setMemberName = item => {
    const { memberIndex } = this.props;
    this.setState({
      searchValue: `${item.firstName} ${item.lastName}`,
      showMemberSearchedResultList: false,
    });
    return this.props.setMemberNameHandler(item, memberIndex);
  };

  /**
   * This method will update the state to hide searched report list after 100 millisecond
   */
  hideResultList = () => {
    const { placeholder } = this.props;
    this.setState({ placeholder: placeholder });
    setTimeout(() => {
      this.setState({ showMemberSearchedResultList: false });
    }, 2000);
  };

  /**
   * This method will update the state to show searched report list
   */
  showResultList = () => {
    const { searchValue, memberSearchedResultList } = this.state;
    this.setState({ placeholder: '' });

    if (
      searchValue &&
      searchValue.length > 2 &&
      memberSearchedResultList &&
      memberSearchedResultList.length
    ) {
      this.setState({ showMemberSearchedResultList: true });
    } else {
      this.setState({ showMemberSearchedResultList: false });
    }
  };

  /**
   * Onclick function of member search
   */
  openModal = async value => {
    const requireModelData = {
      userId: value.id,
      emailId: value.email,
      fullName: `${value.firstName} ${value.lastName}`,
    };
    this.setState({ searchValue: '' });

    const url = `${CONFIG.API_URLS.GET_USER_DETAILS}/${value.id}`;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    fetchGet(url, baseUrl)
      .then(async searchedUserDetails => {
        const typeKt = searchedUserDetails.ktMember;
        requireModelData.searchedUserDetails =
          searchedUserDetails.userAdminDetailsDTO;
        requireModelData.typeKt = typeKt;
        if (!isEmpty(requireModelData.searchedUserDetails)) {
          this.setState({
            openModalGrantDelete: true,
            requireModelData,
            openModal: false,
          });
        } else {
          this.setState({
            openModal: true,
            openModalGrantDelete: false,
            requireModelData,
          });
        }
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
  };

  closeModal = () => {
    this.setState({
      requireModelData: {},
      openModal: false,
    });
  };

  closeModalGrantDelete = () => {
    this.setState({
      requireModelData: {},
      openModalGrantDelete: false,
    });
  };

  /**
   * React Life Cycle method - re-render the view on state update
   */
  render() {
    const {
      searchValue,
      placeholder,
      isSpinnerVisible,
      openModal,
      requireModelData,
      openModalGrantDelete,
      showMemberSearchedResultList,
    } = this.state;
    const { className, dataRefreshHandler } = this.props;
    return (
      <div
        className={`${
          (searchValue &&
            searchValue.length > 2 &&
            showMemberSearchedResultList) ||
          (searchValue &&
            searchValue.length === 0 &&
            showMemberSearchedResultList)
            ? 'kt-opened-list '
            : ''
        }kt-search-rectangle  ${
          className ? className : 'kt-master-search-rectangle'
        }`}
      >
        <div className="kt-search-container">
          <LineInput
            className="kt-search-input kt-search-input-placeholder"
            placeholder={placeholder}
            type="text"
            value={searchValue}
            name="ktsearch"
            handleChange={this.memberSearchResultHandler}
            handleBlur={this.hideResultList}
            handleFocus={this.showResultList}
          />
        </div>
        {isSpinnerVisible ? (
          <Spinner />
        ) : (
          <img
            className="kt-img-icon kt-search-landing-page"
            src={'/img/KtSearch.svg'}
            alt="search"
            height="15"
            width="15"
          />
        )}
        {searchValue &&
        searchValue.length > 2 &&
        showMemberSearchedResultList ? (
          <div className="kt-searched-result-container">
            <div className="kt-searched-result-list">
              {this.getMasterSearchList()}
            </div>
          </div>
        ) : null}
        {/* open model onclick of any member */}
        {openModal && (
          <KtGrantPermissionModal
            modalData={requireModelData}
            onHide={this.closeModal}
            dataRefreshHandler={dataRefreshHandler}
          />
        )}
        {openModalGrantDelete && (
          <KtGrantDeletePermissionModal
            modalData={requireModelData}
            onHide={this.closeModalGrantDelete}
            dataRefreshHandler={dataRefreshHandler}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    peopleImage: reduxStore.peopleImage,
  };
};

export default connect(mapStateToProps)(KtSearch);
