import * as types from '../actions/actionTypes';
import InitialState from './InitialState';

const STATE_SLICE_NAME = 'ktScorecard';

const ktScorecardReducer = function (
  state = InitialState[STATE_SLICE_NAME],
  action = ''
) {
  switch (action.type) {
    case types.UPDATE_USER_HRID:
      return Object.assign({}, state, {
        userHrId: action.data,
      });

    case types.SET_HEADER3_DATE:
      return Object.assign({}, state, {
        setDateHeader3: action.data,
      });

    case types.SET_DROPDOWN_GLOBAL_HEADER_VALUE:
      return Object.assign({}, state, {
        dropdownGlobalHeaderValue: action.data,
      });

    case types.SET_DROPDOWN_MEMBER_GLOBAL_HEADER_VALUE:
      return Object.assign({}, state, {
        dropdownMemberGlobalHeaderValue: action.data,
      });

    case types.BACK_TO_LIST_VIEW_PAGE:
      return Object.assign({}, state, {
        backToListView: action.data,
      });

    case types.UPDATE_TOAST_NOTIFICATION_DETAILS:
      return Object.assign({}, state, {
        toastNotificationDetails: action.data,
      });

    case types.CLOSE_GRANT_MODAL:
      return Object.assign({}, state, {
        closeGrantModal: action.data,
      });

    case types.SET_ADMIN_CLICK:
      return Object.assign({}, state, {
        isAdminButtonClicked: action.data,
      });

    case types.SHOW_PAGE_CONTENT: {
      return Object.assign({}, state, {
        showKTScorecardPageContent: action.data,
      });
    }
    case types.CHECK_IE_BROWSER: {
      return Object.assign({}, state, {
        checkBrowser: action.data,
      });
    }

    case types.UPDATE_COLLECTION_DATA: {
      return Object.assign({}, state, {
        collectionData: action.collectionData,
      });
    }

    case types.UPDATE_DISPLAY_NAME: {
      return Object.assign({}, state, {
        displayName: action.displayName,
      });
    }

    case types.UPDATE_ICON_INFORMATION: {
      return Object.assign({}, state, {
        iconInformation: action.iconInformation,
      });
    }

    case types.UPDATE_IMPERSONATOR_LIST: {
      return Object.assign({}, state, {
        impersonatorList: action.impersonatorList,
      });
    }

    case types.UPDATE_SELECTED_MEMBER_BY_IMPERSONATOR: {
      return Object.assign({}, state, {
        memberDetails: action.memberDetails,
      });
    }

    case types.UPDATE_GEO_REGION_LIST: {
      return Object.assign({}, state, {
        geoRegionList: action.geoRegionList,
      });
    }

    case types.FETCH_IMPERSONATOR_LIST: {
      return Object.assign({}, state, {
        fetchImpersonatorList: action.fetchImpersonatorList,
      });
    }

    case types.SHOW_TOOLTIP_POPUP: {
      return Object.assign({}, state, {
        tooltipData: action.tooltipData,
      });
    }

    case types.UPDATE_IS_MENU_OPEN: {
      return Object.assign({}, state, {
        isMenuOpen: action.status,
      });
    }

    case types.SET_HEADER_HEIGHT: {
      return Object.assign({}, state, {
        headerHeight: action.height,
      });
    }

    case types.SET_SIDEBAR_PATHNAME: {
      return Object.assign({}, state, {
        sidebarPathName: action.pathName,
      });
    }

    case types.UPDATE_ABODE_ANALYTICS_DETAILS: {
      return Object.assign({}, state, {
        adobeAnalyticsDetails: action.adobeAnalyticsDetails,
      });
    }

    default:
      return state;
  }
};
export default ktScorecardReducer;
