import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import CONFIG from './configProps';

export default new OktaAuth({
  issuer: CONFIG.OKTA.ISSUER,
  clientId: CONFIG.OKTA.CLIENT_ID,
  pkce: false,
  redirectUri: `${window.location.origin}${CONFIG.OKTA.REDIRECT_URL}`,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 5 * 60, // Emit expired event 5 minutes before expiration . Tokens accessed with tokenManager.get() will auto-renew within 5 minutes of expiration
  },
});

export const restoreOriginalUri = history => async (_oktaAuth, originalUri) => {
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};
