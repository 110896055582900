import { fetchGet, fetchPost, fetchPut } from 'utils/KtCommonServices';
import { SET_USER_LIST } from './actionTypes';
import CONFIG from 'config/configProps';

export const setUserList = data => {
  return {
    type: SET_USER_LIST,
    data,
  };
};

export const getUserList = () => dispatch => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = CONFIG.API_URLS.SELF_SERVICE_USER_LIST;

  return fetchGet(endpoint, baseUrl).then(response => {
    response?.length > 0 && dispatch(setUserList(response));
  });
};

export const getUserDetails = (employeeIdHRO, id) => () => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const url = CONFIG.API_URLS.SELF_SERVICE_USER_DETAILS;
  const endpoint = `${url}/${employeeIdHRO}/${id}`;

  return fetchGet(endpoint, baseUrl);
};

export const uploadUserList =
  ({ formData, setUploadStatus }) =>
  async () => {
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    const endpoint = CONFIG.API_URLS.SELF_SERVICE_UPLOAD_USER_LIST;
    setUploadStatus({
      uploading: true,
      percentCompleted: 0,
    });
    return fetchPost(endpoint, formData, baseUrl, '', {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadStatus({
          uploading: true,
          percentCompleted,
        });
      },
    });
  };

export const saveUserInfoAsDraft = formData => () => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = CONFIG.API_URLS.SELF_SERVICE_USER_DETAILS_SAVE_AS_DRAFT;

  return fetchPost(endpoint, formData, baseUrl);
};

export const saveUserAndActivate = formData => () => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = CONFIG.API_URLS.SELF_SERVICE_USER_DETAILS_SAVE_AND_ACTIVATE;

  return fetchPost(endpoint, formData, baseUrl);
};

export const updateActiveUserInfo = formData => () => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = CONFIG.API_URLS.SELF_SERVICE_UPDATE_USER_DETAILS;

  return fetchPut(endpoint, formData, baseUrl);
};

export const addNewAlignment = formData => () => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const endpoint = CONFIG.API_URLS.SELF_SERVICE_ADD_NEW_ALIGNMENT;

  return fetchPost(endpoint, formData, baseUrl);
};

export const searchUser = searchText => () => {
  const baseUrl = CONFIG.KT_USER_BASE_URL;
  const url = CONFIG.API_URLS.SELF_SERVICE_SEARCH_USER;
  const endpoint = `${url}/${searchText}`;

  return fetchGet(endpoint, baseUrl);
};
