import React from 'react';
import KtSkeletonTheme from './KtSkeletonTheme';
import { isArray } from 'utils/common';

export default function KtLabelWithSkeleton({
  children,
  isLoading,
  size,
  isImage,
  showParagraph,
  showLine,
  imageCount = 2,
  showLabel,
  showLabelBox,
  showLabelValue,
  showLabelGraphValue,
  showSections,
  isImageGraph,
  isGraphLabel,
  showSectionsFte,
  isAdminImage,
  height,
}) {
  const renderChildren = () => {
    return isArray(children) ? children.map(child => child) : children;
  };

  return isLoading ? (
    <KtSkeletonTheme
      {...{
        size,
        isImage,
        showParagraph,
        showLine,
        imageCount,
        showLabel,
        showLabelBox,
        showLabelValue,
        showLabelGraphValue,
        showSections,
        isImageGraph,
        isGraphLabel,
        showSectionsFte,
        isAdminImage,
        height,
      }}
    />
  ) : (
    renderChildren()
  );
}
