import React from 'react';
import CONFIG from 'config/configProps';
import TrendsChart from './TrendsChart';

const KTTeamSize = props => {
  const colors = CONFIG.PROTOTYPE_TEAM_SIZE_LEGENDS_API_KEY;
  const legends = CONFIG.PROTOTYPE_TEAM_SIZE_LEGENDS;

  return (
    <div className="home_page_main_split_row_team_size main_conatiner highchart_container">
      <div className="heading">
        <div className="heading_text">TEAM SIZE</div>
      </div>
      <TrendsChart
        colors={colors}
        legends={legends}
        data={props.data}
        section="Team Size"
        isPulseData={true}
      />
    </div>
  );
};

export default KTTeamSize;
