import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBackToListViewPage } from 'redux/actions';
import { isEmpty, isEqual, isObject } from 'utils/common';

import {
  showBusinessComparison,
  isImpersonatorRole,
  getUserGeoRegionAndPA,
  isGeoLeadRole,
  isInSuperAdminRoleList,
  getViewName,
} from 'utils';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
import { globalAdobeAnalytics } from 'config/analytics';
import AuthorizeHOC from 'components/common/authorizeHOC';
import CONFIG from 'config/configProps';
import { fetchGet } from 'utils/KtCommonServices';
import './index.scss';
let viz, workbook;

class BusinessComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empInitialData: props.employeeInitialData,
      userHighestRole: props.loggedInUserHighestRole,
    };
    this.props.dispatch(updateBackToListViewPage(true));
  }

  /**
   *
   * React life cycle method
   */
  componentDidMount() {
    // default scrolling page to top
    window.scrollTo(0, 0);
    globalAdobeAnalytics('KTDashboard-Legacy-BusinessComparison');

    if (!this.hasAccess()) {
      this.props.history.push({
        pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.memberDetails, this.props.memberDetails)) {
      this.makeEmpCall();
    }

    if (!isEqual(prevProps.tableauParamsForBC, this.props.tableauParamsForBC)) {
      this.LoadTableauDashboard();
    }

    if (!this.hasAccess()) {
      this.props.history.push({
        pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
      });
    }
  }

  componentWillUnmount() {
    if (viz) {
      viz.dispose();
    }
  }

  makeEmpCall = () => {
    const { memberDetails, userTypes, tableauParamsForBC } = this.props;

    const userDetailsUrl = !isEmpty(memberDetails)
      ? `${CONFIG.API_URLS.EMP}?employeeIdHRO=${memberDetails.employeeIdHRO}`
      : CONFIG.API_URLS.EMP;
    const baseUrl = CONFIG.KT_USER_BASE_URL;
    this.setState({ userSelectedManagedBy: false });
    fetchGet(userDetailsUrl, baseUrl).then(data => {
      const highestRole = getViewName(data, userTypes);
      this.setState(
        {
          empInitialData: data,
          userHighestRole: highestRole,
        },
        () => {
          const { paramString, selectedMetric } = tableauParamsForBC;
          if (paramString && selectedMetric && this.hasAccess()) {
            this.LoadTableauDashboard();
          }
        }
      );
    });
  };

  hasAccess = () => {
    const { userHighestRole, empInitialData } = this.state;
    return showBusinessComparison(userHighestRole, empInitialData);
  };

  getGeoRegionAndPA = () => {
    const { paList } = this.props;
    const { userHighestRole, empInitialData } = this.state;

    const showAllPaAndGeo =
      userHighestRole &&
      (isInSuperAdminRoleList(userHighestRole) ||
        isImpersonatorRole(userHighestRole));

    if (showAllPaAndGeo) {
      return {
        geoRegions: ['All'],
        paCodes: ['All'],
      };
    }

    if (empInitialData?.employeeRoleDTO?.length) {
      let { paCodes, geoRegions } = getUserGeoRegionAndPA(
        empInitialData?.employeeRoleDTO,
        paList
      );

      if (isGeoLeadRole(userHighestRole)) {
        paCodes = ['All'];
      } else {
        geoRegions = ['All'];
      }
      return { geoRegions, paCodes };
    }

    return {};
  };

  LoadTableauDashboard = () => {
    const { tableauParamsForBC } = this.props;
    let { paramString, selectedMetric, selectedHierarchy } = tableauParamsForBC;
    const tableauURLs = CONFIG.TABLEAU_URL.BUSINESS_COMPARISON_URL;

    if (viz) {
      viz.dispose();
    }
    if (paramString) {
      const { geoRegions = [], paCodes = [] } = this.getGeoRegionAndPA();
      paramString += `|${geoRegions.join(',')}|${paCodes.join(',')}`;
      const vizContainer = document.getElementById('tableau-deep-dive');

      const tableauLink = isObject(tableauURLs[selectedMetric])
        ? tableauURLs[selectedMetric][selectedHierarchy]
        : tableauURLs[selectedMetric];
      let options = {
        width: '1340px',
        height: '920px',
        hideToolbar: true,
        hideTabs: true,
        onFirstInteractive: () => {
          workbook = viz.getWorkbook();
          this.changeParams(paramString);
        },
      };

      viz = new window.tableau.Viz(vizContainer, tableauLink, options);
    } else {
      return;
    }
  };

  changeParams = paramString => {
    setTimeout(() => {
      workbook = viz.getWorkbook();
      workbook.changeParameterValueAsync('Paramstring', paramString);
    }, 0);
  };

  render() {
    return (
      <div className="tableau-container home-container business-tableau-container">
        <div className="main-container">
          <div id="tableau-deep-dive" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    employeeInitialData: reduxStore.userRoleData.employeeInitialData,
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
    paList: selectors.filterSectionSelectors.getResults(
      reduxStore,
      API_CALL_IDENTIFIERS.FETCH_PA_LIST
    ),
    memberDetails: reduxStore.ktScorecard.memberDetails,
    userTypes: reduxStore.userRoleData.userTypes,
    tableauParamsForBC: reduxStore.tableauStackData.tableauParamsForBC,
  };
};

export default connect(mapStateToProps)(AuthorizeHOC(BusinessComparison));
