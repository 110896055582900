import React, { useEffect, useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Search } from 'semantic-ui-react';

import { globalAdobeAnalytics } from 'config/analytics';
import AuthorizeHOC from 'components/common/authorizeHOC';

import CONFIG from 'config/configProps';
import { showUserManagementPage, sortData } from 'utils';
import UserHistory from 'components/UserManagement/UserList/UserHistory';
import UserDetails from 'components/UserManagement/UserDetails';
import EditUser from 'components/UserManagement/EditUser';
import { debounce, isEmpty } from 'utils/common';
import { searchUser, getUserDetails } from 'redux/actions/selfServiceActions';

import 'components/UserManagement/UserManagement.scss';

const initialState = {
  loading: false,
  results: [],
  value: '',
  selectedUser: {},
  userInfoLoading: false,
  userInfo: {},
  editView: {
    enabled: false,
    action: '',
    userInfo: null,
  },
};

function userStateReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return { ...initialState, selectedUser: {} };
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.value };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, selectedUser: action.selection };
    case 'SET_USER_INFO_LOADING':
      return { ...state, userInfoLoading: action.loading };
    case 'SET_USER_INFO': {
      sortData(
        action.userInfo?.expiredDetails,
        'date',
        'rowExpiryDate',
        'descending'
      );
      return { ...state, userInfo: action.userInfo };
    }
    case 'SET_EDIT_VIEW': {
      return { ...state, editView: action.editView };
    }

    default:
      throw new Error();
  }
}

const ExistingUserManagement = props => {
  const loggedInUserHighestRole = useSelector(
    state => state.userRoleData.loggedInUserHighestRole
  );
  const { history, dispatch } = props;
  const [state, reactDispatch] = useReducer(userStateReducer, initialState);
  const {
    loading,
    results,
    value,
    selectedUser,
    userInfoLoading,
    userInfo,
    editView,
  } = state;

  const hasAccess = useCallback(() => {
    return showUserManagementPage(loggedInUserHighestRole);
  }, [loggedInUserHighestRole]);

  useEffect(() => {
    // default scrolling page to top
    window.scrollTo(0, 0);
    globalAdobeAnalytics('KTDashboard-Legacy-UserManagement');
  }, []);

  useEffect(() => {
    if (!hasAccess()) {
      history.push({
        pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
      });
    }
  }, [hasAccess, history]);

  const getUserInfo = useCallback(() => {
    if (isEmpty(selectedUser)) return;

    const { employeeIdHRO, id } = selectedUser;
    reactDispatch({ type: 'SET_USER_INFO_LOADING', loading: true });
    dispatch(getUserDetails(employeeIdHRO, id))
      .then(response => {
        reactDispatch({ type: 'SET_USER_INFO', userInfo: response });
      })
      .finally(() => {
        reactDispatch({ type: 'SET_USER_INFO_LOADING', loading: false });
      });
  }, [dispatch, selectedUser]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const handleSearchChange = useCallback(
    debounce((e, data) => {
      reactDispatch({ type: 'START_SEARCH', value: data?.value });

      if (data?.value.length < 3) {
        reactDispatch({ type: 'CLEAN_QUERY', results: [] });
        return;
      }

      dispatch(searchUser(data?.value)).then(response => {
        const searchResult =
          response?.map(info => ({
            title: info.employeeName,
            user_info: info,
          })) || [];
        reactDispatch({ type: 'FINISH_SEARCH', results: searchResult });
      });
    }, 500),
    [dispatch]
  );

  const handleUserSelection = (e, data) => {
    reactDispatch({
      type: 'UPDATE_SELECTION',
      selection: data.result.user_info,
    });
  };

  const toggleEditView = (action, userDetails, enabled) => {
    reactDispatch({
      type: 'SET_EDIT_VIEW',
      editView: {
        action,
        enabled,
        userInfo: userDetails,
      },
    });
  };

  const onClickGoBack = () => {
    toggleEditView();
    getUserInfo();
  };

  const columns = [
    ...CONFIG.USER_LIST_COLUMNS,
    {
      title: 'Hire Date',
      dataField: 'originalHireDate',
      renderColumn: { type: 'Date', format: 'MMM-dd, yyyy' },
    },
    {
      title: 'Effective Date',
      dataField: 'rowEffectiveDate',
      renderColumn: { type: 'Date', format: 'MMM-dd, yyyy' },
    },
  ];

  return (
    <div
      className={`tableau-container ${editView?.enabled ? 'editMode' : ''}`}
      id="user-management"
    >
      <div className="user-management-container">
        {editView?.enabled ? (
          <div className="user-management-container__users">
            <EditUser
              userInfo={editView.userInfo}
              onClickGoBack={onClickGoBack}
              action={editView?.action}
            />
          </div>
        ) : (
          <>
            <div className="user-management-container__instructions">
              <h2 className="user-management-container__instructions__title">
                Existing User Management
              </h2>
              <ul className="user-management-container__instructions__items user-management-container__instructions__items--no-bullets">
                <li>
                  Enter the user name to search and select the user to view the
                  existing user details and edit/change aligment.
                </li>
              </ul>
            </div>
            <div className="user-management-container__users">
              <div className="user-management-container__users__search">
                <Search
                  className="user-management-container__users__search__input"
                  minCharacters={3}
                  placeholder="Search Users"
                  size="massive"
                  onSearchChange={handleSearchChange}
                  onResultSelect={handleUserSelection}
                  loading={loading}
                  results={results}
                  showNoResults={!loading && value?.length >= 3}
                  fluid
                />
              </div>
              <div className="user-management-container__users__info">
                {!isEmpty(selectedUser) && (
                  <>
                    <UserDetails
                      userInfo={userInfo?.activeDetails}
                      titleClassName="user-management-container__users__info__title"
                      columns={columns}
                      toggleEditView={toggleEditView}
                      loading={userInfoLoading}
                    />
                    <UserHistory
                      loading={userInfoLoading}
                      userHistory={userInfo?.expiredDetails || []}
                      titleClassName="user-management-container__users__info__title"
                      columns={columns}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthorizeHOC(ExistingUserManagement);
