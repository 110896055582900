import React from 'react';
import Helmet from 'react-helmet';

/**
 *
 * @title : This component will add a title to the page
 * @param {*} Title of the component
 */
const TitleComponent = ({ title }) => {
  let defaultTitle = 'KT Dashboard';
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  );
};

export { TitleComponent };
