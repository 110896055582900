import React from 'react';
import { Popup } from 'semantic-ui-react';
import './WithToolTip.scss';

export default function WithTooltip(WrappedComponent) {
  return function withTooltip(tooltipText, props, key) {
    let {
      countofitemhidden,
      isvaluegrid,
      customclass,
      iseditmaterial,
      isdoublegrid,
      ...restProps
    } = props ? props : '';
    let gridColumnType = ''; // Will define whether each row has single, double or triple column structure
    let cssClass = 'custom-tooltip';
    switch (countofitemhidden) {
      case 1:
        gridColumnType = 'single';
        break;
      case 2:
        gridColumnType = 'double';
        break;
      default:
        gridColumnType = 'triple';
        break;
    }

    gridColumnType =
      isdoublegrid && gridColumnType === 'triple' ? 'double' : gridColumnType;
    const gridClass = isvaluegrid ? gridColumnType : '';
    const editMaterialClass = iseditmaterial ? 'd-none' : '';
    const customClassName = customclass ? `${customclass}` : '';
    const doubleGridClass = isdoublegrid ? 'double-grid' : '';
    cssClass = `${cssClass} ${gridClass} ${editMaterialClass} ${customClassName} ${doubleGridClass}`;

    return (
      <Popup
        key={key ? key : 'tooltip-' + tooltipText}
        size="large"
        trigger={WrappedComponent}
        className={cssClass}
        {...restProps}
      >
        {tooltipText}
      </Popup>
    );
  };
}
