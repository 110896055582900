import React from 'react';
import Skeleton from 'react-loading-skeleton';

const GraphLabelSkeleton = ({ isGraphLabel }) => {
  return isGraphLabel ? (
    <>
      <Skeleton width={20} height={3.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={20} height={3.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={20} height={3.5} duration={1} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Skeleton width={20} height={3.5} duration={1} />
    </>
  ) : null;
};

export default GraphLabelSkeleton;
