import React, { useEffect, useState, Suspense } from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import KTBillabilityLoader from '../common/KTBillabilityLoader';
import KTDownloads from 'components/KTDownloads';
import {
  isEmptyOrNull,
  getPostData,
  isManagerUser,
  getPostDataForBusinessView,
  isLeaderRole,
  isAnalyst,
} from 'utils';
import KTBillabilityBusiness from './KTBillabilityBusiness';
import { isEqual, isLoading } from 'utils/common';
import Button from 'components/common/button';
import Icon from 'components/common/icon/Icon';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

const legends = CONFIG.PROTOTYPE_BILLABILITY_COLORS;
const sortingOptions = CONFIG.PROTOTYPE_BILLABILITY_SORT_BY_OPTIONS;

const KTBillability = props => {
  const {
    selectedIndex,
    appliedFilter,
    appliedFilterBc,
    memberList,
    practiceArea,
    geoMarket,
    loggedInUserRole,
    billabilityByBusiness,
    billabilityByMember,
    appliedCollection,
    pageUrl,
  } = props;
  if (isAnalyst(loggedInUserRole)) {
    props.history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }
  let defaultValue = {};
  defaultValue[`Case BillabilityDesc`] = true;

  const [selectedOption, setSelectedOption] = useState(sortingOptions[0]);
  const [apiData, setApiData] = useState({});

  useEffect(() => {
    setSelectedOption(sortingOptions[0]);
    getApiData();
  }, [selectedIndex, appliedFilter, appliedFilterBc]);

  useEffect(() => {
    if (selectedIndex === 0) {
      setApiData(billabilityByBusiness);
    } else {
      setApiData(billabilityByMember);
    }
  }, [billabilityByBusiness, billabilityByMember]);

  const getApiData = () => {
    const isBusinessData = isEqual(selectedIndex, 0);
    let postData = getPostData(appliedFilter, memberList);
    if (isEqual(appliedFilter.selectedUserId, '00000')) {
      return;
    }

    if (isEmptyOrNull(postData)) return;
    if (isBusinessData) {
      postData = isLeaderRole(loggedInUserRole)
        ? { ...appliedFilterBc }
        : { ...appliedFilter };
      if (isManagerUser(loggedInUserRole)) {
        if (
          isEmptyOrNull(appliedFilter.paIds) &&
          isEmptyOrNull(appliedFilter.geoRegion)
        ) {
          postData = getPostDataForBusinessView(
            practiceArea,
            geoMarket,
            postData,
            props.defaultUserFilters
          );
        }
      } else {
        postData['dateFilterType'] =
          !isEmptyOrNull(postData.dateFilterType) || postData?.dateFilterType
            ? postData.dateFilterType
            : 'MONTHLY';
      }
      if (!props.isMemberFilterSelected) delete postData.selectedUserId;
    }
    let apiIdentifier = '';
    if (isEqual(selectedIndex, 1)) {
      apiIdentifier = API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_MEMBER;
    } else {
      apiIdentifier = API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_BUSINESS;
    }
    if (!isEmptyOrNull(appliedCollection)) {
      postData['selectedUserId'] = appliedCollection?.selectedUserId;
    }
    props.fetchBillabilityData(apiIdentifier, postData);
  };

  const renderChartHeaders = () => {
    return (
      <div className="kt-billability-container__chart__headers">
        <div className="kt-billability-container__chart__headers__sortby">
          {isEqual(selectedOption, sortingOptions[0]) ? (
            <Button
              primary
              onClick={() => setSelectedOption(sortingOptions[1])}
              className="collpase"
            >
              <span>
                {sortingOptions[0]}
                <Icon name="angle down" />
              </span>
            </Button>
          ) : (
            <Button
              primary
              onClick={() => setSelectedOption(sortingOptions[0])}
              className="expand"
            >
              <span>
                {sortingOptions[1]}
                <Icon name="angle down" />
              </span>
            </Button>
          )}
        </div>
        {renderChartLegends()}
        <KTDownloads
          showDownloadModal={false}
          reportName={CONFIG.BILLABILITY_REPORT_NAMES[selectedIndex]}
          url={CONFIG.BILLABILITY_DOWNLOAD_URL[selectedIndex]}
          passAllValue={isEqual(selectedIndex, 0)}
          pageUrl={pageUrl}
          buttonName="Export"
        />
      </div>
    );
  };

  const renderChartLegends = () => {
    return (
      <div className="kt-billability-container__chart__headers__legends">
        <div>
          <div>
            {Object.entries(legends).map(([label, backgroundColor]) => {
              return (
                <span
                  key={label}
                  className="kt-billability-container__chart__headers__legends__item"
                >
                  <label
                    style={{ backgroundColor }}
                    className="kt-billability-container__chart__headers__legends__item__mark"
                  >
                    &nbsp;
                  </label>
                  {label}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderChartDataTable = () => {
    const propPack = {
      selectedOption: selectedOption,
      apiData: isEqual(selectedIndex, 1)
        ? billabilityByMember?.data
        : billabilityByBusiness?.data,
      legends: CONFIG.PROTOTYPE_BILLABILITY_LEGENDS,
      isMemberView: isEqual(selectedIndex, 1),
    };
    return <KTBillabilityBusiness {...propPack} />;
  };

  const renderBillabilityChart = () => {
    let headerColumns = CONFIG.PROTOTYPE_BILLABILITY_COLUMNS;
    headerColumns = isEqual(selectedIndex, 1)
      ? headerColumns.memberBillability
      : headerColumns.businessBillability;

    return (
      <div className="chart__table">
        <div className="chart__table__header">
          {Object.keys(headerColumns).map((col, index) => {
            return (
              <div
                key={col}
                className={isEqual(index, 0) ? 'first_header' : ''}
              >
                {col}
              </div>
            );
          })}
          <div />
        </div>
        <div className="chart__table__body">
          {isEmptyOrNull(apiData?.data) ||
          isEmptyOrNull(apiData?.data?.allBillabilityMap) ? (
            <div>No Record to display</div>
          ) : (
            <Suspense fallback={<KTBillabilityLoader />}>
              {renderChartDataTable()}
            </Suspense>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {renderChartHeaders()}
        {isLoading(apiData) ? (
          <KTBillabilityLoader />
        ) : (
          renderBillabilityChart()
        )}
      </div>
    </div>
  );
};
const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    appliedFilterBc: store.filterDataPt.filtersBc,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    practiceArea: store.filterDataPt.fetchPracticeAreaList?.results || [],
    geoMarket: store.filterDataPt.fetchGeoLocationList?.results || [],
    isMemberFilterSelected: store.filterDataPt.isMemberFilterSelected,
    defaultUserFilters:
      store.filterDataPt.fetchDefaultUserFilters?.results || [],
    loggedInUserRole: store.homePageData.loggedInUserRole,
    billabilityByBusiness: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_BUSINESS
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_BUSINESS
      ),
    },
    billabilityByMember: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_MEMBER
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_BILLABILITY_BY_MEMBER
      ),
    },
    appliedCollection: store.filterDataPt.appliedCollection,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBillabilityData: bindActionCreators(
      actions.apiActions.fetchApiData,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KTBillability);
