import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import classnames from 'classnames';
import { isEmpty, isObject } from 'utils/common';
import { selectOption } from 'utils';
export default class FTSizeChart extends React.Component {
  getSumOfKeys = keys => {
    const { rawData } = this.props;
    let sum = 0;
    keys.forEach(key => {
      sum += rawData[key];
    });
    return sum;
  };

  renderLegend = (key, index, rawData) => {
    return (
      <div className="legendItemContainer" key={`${key}${index}`}>
        <div className="legendItemLabel">{key}:</div>
        <div className="legendItemValue">{rawData[key]}</div>
      </div>
    );
  };

  renderProgressValue = (progressKey, value, index, maxValue, isTotalKey) => {
    return (
      <div
        key={`${progressKey}${index}`}
        className={classnames([
          `progressItemContainer`,
          { highlighted: isTotalKey },
        ])}
      >
        <div
          className={classnames([
            'progressTitle',
            { bold: isTotalKey },
            { addPadding: isTotalKey },
          ])}
        >
          {progressKey}
        </div>
        <ProgressBar
          className="ftProgressBar"
          now={value}
          label={`${value}%`}
          srOnly
          min={0}
          max={maxValue}
        />
        <div
          className={classnames([
            'progressValue',
            {
              bold: isTotalKey,
            },
          ])}
        >
          {value}
        </div>
      </div>
    );
  };

  renderProgressLegends = (progressInfo, geoChart) => {
    const { rawData } = this.props;
    const legends = geoChart ? Object.keys(progressInfo) : progressInfo.legends;
    const totalKey = geoChart ? 'Total' : progressInfo.totalKey;
    const maxValue = geoChart
      ? progressInfo[totalKey]
      : this.getSumOfKeys(legends);
    return (
      <div className="chartContainer">
        {this.renderProgressValue(totalKey, maxValue, 0, maxValue, true)}
        {legends.map((progressKey, index) => {
          if (geoChart && index > 0) {
            return this.renderProgressValue(
              progressKey,
              progressInfo[progressKey],
              index + 1,
              maxValue
            );
          } else if (!geoChart) {
            return this.renderProgressValue(
              progressKey,
              rawData[progressKey],
              index + 1,
              maxValue
            );
          }
          return null;
        })}
      </div>
    );
  };

  render() {
    const { ktLegends, extendedLegends, legendsKeys, geoChart, rawData } =
      this.props;
    const legendsKeysToPass = legendsKeys
      ? legendsKeys
      : selectOption(
          isObject(rawData?.Section2),
          Object.keys(rawData?.Section2 || {}),
          []
        );
    const dataToPass = legendsKeys ? rawData : rawData.Section2;
    const dataToRender = ktLegends
      ? ktLegends
      : selectOption(isObject(rawData.Section1), rawData.Section1, []);
    return (
      <div className="ftChartWrapper">
        {this.renderProgressLegends(dataToRender, geoChart)}
        {extendedLegends ? (
          <div className="legendContainer">
            <div className="pL05"> Extended Team</div>
            {extendedLegends.map((el, index) => {
              return this.renderLegend(el, index, dataToPass);
            })}
          </div>
        ) : null}
        {!isEmpty(legendsKeysToPass) ? (
          <div className="legendContainer">
            {legendsKeysToPass.map((el, index) => {
              return this.renderLegend(el, index, dataToPass);
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
