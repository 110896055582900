import React from 'react';
import { Table } from 'semantic-ui-react';

import { times } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';

const KtEmployeeBillabilityPlaceholder = props => {
  return times(6, i => (
    <Table.Row key={i}>
      <Table.Cell width={props.isCDPage ? 2 : 1}>
        <LabelWithSkeleton isLoading size="small" showLine />
      </Table.Cell>
      {!props.isCDPage && (
        <Table.Cell width={1}>
          <LabelWithSkeleton isLoading size="small" showLine />
        </Table.Cell>
      )}
      <Table.Cell>
        <LabelWithSkeleton isLoading size="xx-large" showLine />
      </Table.Cell>
    </Table.Row>
  ));
};
export default KtEmployeeBillabilityPlaceholder;
