import React from 'react';
import { Table } from 'semantic-ui-react';

const TableHeader = ({ columns }) => {
  return (
    <Table.Header>
      <Table.Row>
        {columns.map((columnConf, index) => {
          return (
            <Table.HeaderCell
              key={`${index}-${columnConf.dataField}`}
              {...(columnConf.columnProps || {})}
            >
              {columnConf.title}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export default React.memo(TableHeader);
