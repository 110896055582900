import React from 'react';
import './Tab.scss';
class Tab extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(event) {
    event.preventDefault();
    this.props.onClick(this.props.tabIndex);
    this.props.handleTabClick && this.props.handleTabClick(this.props.tabIndex);
  }

  render() {
    const {
      imgClassName,
      isImg,
      isActive,
      tabText,
      linkClassName,
      tabClassName,
      fromFilters,
    } = this.props;
    return (
      <li
        className={`tab ${tabClassName} ${
          isImg && isActive ? 'activeIndex' : ''
        } ${isActive && fromFilters ? 'activeLi' : ''} ${
          isActive ? 'active' : ''
        }`}
      >
        {isImg ? <div className={imgClassName} /> : null}
        <a
          href="/"
          className={`tab-link ${linkClassName}`} // eslint-disable-line
          onClick={this.handleTabClick}
        >
          {tabText}
        </a>
      </li>
    );
  }
}

export default Tab;
