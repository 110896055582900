import * as React from 'react';
import { Fragment, useState } from 'react';
import OptionComponent from './Option';

function OptionNode({
  option,
  parent,
  isSearchActive,
  showHideNodes,
  checkedOptions,
  handleOptionChange,
}) {
  const [expanded, setExpanded] = useState(false);
  const renderChildren = childOption => {
    return renderChild(
      childOption,
      { node: option, parent },
      isSearchActive,
      showHideNodes,
      checkedOptions,
      handleOptionChange
    );
  };
  const hideNode = isSearchActive && !showHideNodes[option.code];
  const { checked, partialChecked } = checkedOptions[option.code] || {
    checked: false,
    partialChecked: false,
  };
  const canExpand = !!(option.children && option.children.length >= 1);
  return hideNode ? null : (
    <Fragment key={option.code}>
      <OptionComponent
        option={option}
        checked={checked}
        partialChecked={partialChecked}
        expanded={expanded}
        canExpand={canExpand}
        handleExpandClick={() => {
          setExpanded(!expanded);
        }}
        handleOptionChange={({ target }) => {
          handleOptionChange(target.checked, option, parent);
        }}
      />
      {expanded && canExpand && (
        <div className="vertical-options-children-container">
          {(option.children || []).map(renderChildren)}
        </div>
      )}
    </Fragment>
  );
}

function renderChild(
  option,
  parent,
  isSearchActive,
  showHideNodes,
  checkedOptions,
  handleOptionChange
) {
  return (
    <OptionNode
      key={option.code}
      option={option}
      parent={parent}
      isSearchActive={isSearchActive}
      showHideNodes={showHideNodes}
      checkedOptions={checkedOptions}
      handleOptionChange={handleOptionChange}
    />
  );
}

export default OptionNode;
