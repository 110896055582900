import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classnames from 'classnames';

export default class KTPieChart extends React.PureComponent {
  getDefaultColorsForLegends = legends => {
    const colors = {};
    legends.forEach(legendKey => {
      colors[legendKey] =
        (this.props.colors || {})[legendKey] ||
        `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    });
    return colors;
  };

  getSubLegends = legends => {
    const subLegends = {};
    legends.forEach(legendKey => {
      subLegends[legendKey] =
        (this.props.subLegends || {})[legendKey] || legendKey;
    });
    return subLegends;
  };

  render() {
    const legends = this.props.legends || [];
    const sublegends = this.props.showSubLegends && this.getSubLegends(legends);
    const colors = this.getDefaultColorsForLegends(legends);
    const chartData = transformRawDataToChart(
      this.props.rawData || {},
      legends,
      colors,
      this.props.targetValue,
      this.props.initialLegendKey || legends[0],
      this.props.pieSizeOptions,
      this.props.handleClick
    );
    return (
      <div className="pie_chart_wrapper">
        <div
          className={classnames([
            'pie_chart',
            { 'full-height': !this.props.showSubLegends },
          ])}
        >
          {chartData && (
            <HighchartsReact
              allowChartUpdate={false}
              options={chartData}
              highcharts={Highcharts}
              containerProps={{
                style: {
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  left: -5,
                },
              }}
            />
          )}
        </div>
        {this.props.showSubLegends && (
          <div className="pie_legends">
            {legends.map(legend => (
              <div className="pie_legend_item" key={legend}>
                <div
                  className="pie_badge"
                  style={{ backgroundColor: colors[legend] }}
                />
                <div className="heading">{sublegends[legend]}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

function transformRawDataToChart(
  rawData,
  legends,
  colors,
  targetValue,
  initialLegendKey,
  options,
  handleClick
) {
  const handleChartItemClick = chartObj => {
    const chart = chartObj.series.chart;
    let x = chart.plotLeft + chart.series[0].center[0],
      y = chart.plotTop + chart.series[0].center[1];
    chart.pieCenter.textSetter(`${chartObj.y === null ? 0 : chartObj.y}%`);
    chart.pieCenter.css({
      color: chartObj.color,
    });
    chart.pieCenter.attr({
      x: x - ((chartObj && chartObj.y) || '0').toString().length * 9,
      y: y + 7,
    });
    handleClick(chartObj.name);
    return false;
  };

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      events: {
        load: function () {
          let chart = this,
            x = chart.plotLeft + chart.series[0].center[0],
            y = chart.plotTop + chart.series[0].center[1];
          const selectedValue =
            chart.series[0].data.find(
              values => values.name === initialLegendKey
            ) || {};
          const pieValue =
            isNaN(selectedValue.y) || selectedValue.y === null
              ? 0
              : selectedValue.y;
          chart.pieCenter = chart.renderer
            .text(`${pieValue}%`, x, y)
            .css({
              'text-align': 'center',
              fontSize: '20px',
              fontWeight: 'bold',
              fontFamily: 'tahoma',
              color: selectedValue.color,
            })
            .add();
          chart.pieCenter.attr({
            x:
              x -
              ((selectedValue && selectedValue.y) || '0').toString().length * 9,
            y: y + 7,
          });
        },
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return '<span><b>' + this.key + ': </b> ' + this.y + '%</span>';
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      enabled: true,
      floating: false,
      layout: 'vertical', // Display in columns
      symbolHeight: 8,
      align: 'right',
      verticalAlign: 'top',
      borderWidth: 0,
      itemMarginBottom: 3,
      labelFormatter: function () {
        // Includes cat & price in legend
        return `<span class="piechartlegends">${this.name}</span>`;
      },
    },
    plotOptions: {
      pie: {
        ...options,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          connectorWidth: 0,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
        borderWidth: 5,
        borderColor: null,
        innerSize: '98%',
        point: {
          events: {
            legendItemClick: function () {
              return handleChartItemClick(this);
            },
            click: function () {
              const chart = this.series.chart;
              let x = chart.plotLeft + chart.series[0].center[0],
                y = chart.plotTop + chart.series[0].center[1];
              chart.pieCenter.textSetter(`${this.y === null ? 0 : this.y}%`);
              chart.pieCenter.css({
                color: this.color,
              });
              chart.pieCenter.attr({
                x: x - ((this && this.y) || '0').toString().length * 9,
                y: y + 7,
              });
              handleClick(this.name);
              return false;
            },
          },
        },
      },
    },
    series: [
      {
        data: legends.map(legend => ({
          name: legend,
          y: getPercentageValue(rawData[legend.toLowerCase()], targetValue),
          color: colors[legend],
          selected: true,
        })),
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };
}

function getPercentageValue(value, targetValue) {
  if (value === 0) {
    return null;
  } else if (isNaN(value) || isNaN(targetValue)) {
    return 0;
  } else {
    return Math.round((value / targetValue) * 100);
  }
}
