import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Icon from 'components/common/icon/Icon';
import { Button } from 'react-bootstrap';
import MenuItem from './MenuItem';
import { updateIsMenuOpen } from 'redux/actions';
import './SideBar.scss';
import { getSidebarMenuItems } from 'utils';

const SideBar = ({
  dispatch,
  loggedInUserHighestRole,
  employeeInitialData,
  isMenuOpen,
  isManager,
  headerHeight,
  sidebarPathName,
}) => {
  const [showMenu, setShowMenu] = useState(isMenuOpen);

  useEffect(() => {
    setShowMenu(isMenuOpen);
  }, [isMenuOpen]);

  const menuItems = getSidebarMenuItems(
    employeeInitialData,
    loggedInUserHighestRole
  );

  const openMenu = () => {
    setShowMenu(true);
    dispatch(updateIsMenuOpen(true));
  };

  const closeMenu = () => {
    setShowMenu(false);
    dispatch(updateIsMenuOpen(false));
  };

  const button = (heading, name, onClickName) => {
    return (
      <div
        className={`sidebar-button ${showMenu ? '--hide' : '--show'}`}
        onClick={onClickName}
      >
        <Button
          className={`kt-navigation-button ${showMenu ? 'hide' : 'show'}`}
          style={{ marginTop: showMenu ? 0 : headerHeight + 20 }}
        >
          <span className="kt-navigation-button__text">{heading}</span>
          <Icon name={name} />
        </Button>
      </div>
    );
  };

  return (
    <>
      {!showMenu && button('Show Menu', 'angle right', openMenu)}
      <div
        className={`sidebar-container ${
          showMenu ? ' sidebar-container--show' : ''
        }`}
        style={{ marginTop: !isNaN(headerHeight) ? headerHeight - 2 : 0 }}
      >
        <ul className="sidebar-item-container">
          {showMenu && button('Hide Menu', 'angle left', closeMenu)}
          {menuItems
            .filter(({ showItem }) => showItem)
            .map(item => {
              return (
                <li key={item.key} className="sidebar-item">
                  <MenuItem
                    item={item}
                    pathname={sidebarPathName}
                    isManager={isManager}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  employeeInitialData: store.userRoleData.employeeInitialData,
  loggedInUserHighestRole: store.userRoleData.loggedInUserHighestRole,
  isMenuOpen: store.ktScorecard.isMenuOpen,
  isManager: store.ktScorecard.isManager,
  headerHeight: store.ktScorecard.headerHeight,
  sidebarPathName: store.ktScorecard.sidebarPathName,
});

export default connect(mapStateToProps)(SideBar);
