import React from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import { fetchGet } from 'utils/KtCommonServices';
import store from 'redux/store';
import { getKtUserDetails } from 'redux/actions/userRoleAction';
import { isEmpty } from 'utils/common';

export const AuthorizeHoc = WrappedComponent => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        ktUserDetails: this.props.ktUserDetails,
      };
    }

    componentDidMount = () => {
      if (isEmpty(this.props.ktUserDetails)) {
        const url = CONFIG.API_URLS.USER_DETAILS;
        const baseUrl = CONFIG.KT_USER_BASE_URL;
        fetchGet(url, baseUrl)
          .then(ktUserDetails => {
            this.setState({ ktUserDetails });
            store.dispatch(getKtUserDetails(ktUserDetails));
          })
          .catch(error => {
            console.log(error);
          });
      }
    };

    redirectToWrapper = () => {
      const { ktUserDetails } = this.state;
      const hasAccess = ktUserDetails.ktdAccessible;
      if (hasAccess) {
        return <WrappedComponent {...this.props} />;
      } else if (hasAccess === false) {
        this.props.history.push({
          pathname: CONFIG.APP_CONST.UNAUTHORIZED,
          state: { userName: ktUserDetails.userFullName },
        });
        return null;
      } else {
        return null;
      }
    };

    render() {
      const { ktUserDetails } = this.state;
      if (ktUserDetails) {
        return this.redirectToWrapper();
      }
    }
  };
};

const mapStateToProps = reduxStore => {
  return {
    ktUserDetails: reduxStore.userRoleData.ktUserDetails,
  };
};

const AuthorizeHOCWithState = WrapperComponent =>
  connect(mapStateToProps)(AuthorizeHoc(WrapperComponent));

export default AuthorizeHOCWithState;
