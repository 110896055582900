import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import KTPulse from 'components/KTTableauStackWrapper/KTPulse';
import KTSizeInFte from 'components/KTTableauStackWrapper/KTFte';
import {
  getIconInformation,
  isGeoLeadRole,
  isGeoManagerRole,
  isImpersonatorRole,
} from 'utils';

class TeamTableauCard extends Component {
  constructor(props) {
    super(props);
    const { loggedInUserHighestRole } = this.props;
    this.state = {
      isGeoManager: isGeoManagerRole(loggedInUserHighestRole),
      isImpersonator: isImpersonatorRole(loggedInUserHighestRole),
      isGeoLead: isGeoLeadRole(loggedInUserHighestRole),
      loggedInUserHighestRole,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { loggedInUserHighestRole } = props;
    if (state.loggedInUserHighestRole !== loggedInUserHighestRole) {
      return {
        isGeoManager: isGeoManagerRole(loggedInUserHighestRole),
        isImpersonator: isImpersonatorRole(loggedInUserHighestRole),
        isGeoLead: isGeoLeadRole(loggedInUserHighestRole),
      };
    }
    return null;
  }

  getKTPulseCardClassName = () => {
    const { isGeoManager, isGeoLead } = this.state;
    const { passGeo, isManager, isAdmin } = this.props;

    if (isGeoLead && passGeo) {
      return 'admin-kt-pulse';
    }

    if (isGeoManager) {
      return 'geo-manager-kt-pulse';
    }

    if (passGeo && !isManager) {
      return 'geo-kt-pulse';
    }

    if (isAdmin) {
      return 'admin-kt-pulse';
    }

    return isManager ? 'manager-kt-pulse' : 'member-kt-pulse';
  };

  getKTSizeInFteClassName = () => {
    const { isGeoManager, isGeoLead } = this.state;
    const { passGeo, isManager, isAdmin } = this.props;
    if (isGeoManager || isGeoLead) {
      return 'geo-manager-size-in-fte';
    }
    if (passGeo && !isManager) {
      return 'geo-size-in-fte';
    }

    if (isAdmin) {
      return 'admin-size-in-fte';
    }

    return isManager ? 'manager-size-in-fte' : 'member-size-in-fte';
  };

  getKTNccClassName = () => {
    const { isGeoManager, isGeoLead } = this.state;
    const { passGeo, isManager, isAdmin, isImpersonator } = this.props;

    if (isAdmin) {
      return 'admin-kt-ncc';
    }

    if (passGeo && !isGeoManager && !isImpersonator && !isGeoLead) {
      return 'geo-kt-ncc';
    }

    return isManager ? 'manager-kt-ncc' : 'member-kt-ncc';
  };

  render() {
    const { employeeInitialData, iconInformation, loggedInUserHighestRole } =
      this.props;
    const kTPulseCardClassName = this.getKTPulseCardClassName();
    const cardList = [
      { cardName: 'KT Pulse Check', isRmsData: false },
      { cardName: 'CT Satisfaction with KT', isRmsData: true },
    ];

    return (
      Object.keys(employeeInitialData || {}).length >= 1 && (
        <div className="tableau-card-container">
          {cardList.map(({ cardName, isRmsData }, i) => (
            <KTPulse
              className={kTPulseCardClassName}
              heading={cardName}
              key={i}
              isRmsData={isRmsData}
              InfoText={getIconInformation(
                cardName,
                iconInformation,
                loggedInUserHighestRole
              )}
            />
          ))}
          <KTSizeInFte
            className={this.getKTSizeInFteClassName()}
            InfoText={getIconInformation(
              'Team Size',
              iconInformation,
              loggedInUserHighestRole
            )}
          />
        </div>
      )
    );
  }
}
const mapStateToProps = function (store) {
  return {
    employeeInitialData: store.userRoleData.employeeInitialData,
    isManager: store.userRoleData.isManager,
    iconInformation: store.ktScorecard.iconInformation,
    passGeo: store.headerFilterData.passGeo,
    loggedInUserHighestRole: store.userRoleData.loggedInUserHighestRole,
  };
};
export default connect(mapStateToProps)(withRouter(TeamTableauCard));
