import React from 'react';
import KtLabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import Icon from 'components/common/Icons';
class RMSLoader extends React.PureComponent {
  render() {
    const { tableauCardBodyClass, heading, lastUpdatedLoaded } = this.props;
    return (
      <div className={tableauCardBodyClass}>
        <div className={`${lastUpdatedLoaded ? '' : 'heading'}`}>
          <div className="card-heading">
            <span className="heading-text">{heading}</span>

            <span className="heading-image">
              <Icon
                name="tableau-arrow"
                class=""
                alt="arrow"
                src="/img/tableau-arrow.svg"
              />
            </span>
          </div>
          {lastUpdatedLoaded ? (
            <KtLabelWithSkeleton isLoading showLabel size="large" />
          ) : null}
        </div>
        <div>
          <KtLabelWithSkeleton isLoading showLabel size="large" />
          <br />
          <KtLabelWithSkeleton isLoading isImage imageCount={1} />
          <br />
          <KtLabelWithSkeleton isLoading showLabel size="large" />
          <KtLabelWithSkeleton isLoading showLabel size="large" />
          <KtLabelWithSkeleton isLoading showLabel size="large" />
        </div>
      </div>
    );
  }
}

export default RMSLoader;
