import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import { isEmpty, isEqual, showSearch } from 'utils/common';
import { fetchGet, fetchDeleteWithId, fetchPost } from 'utils/KtCommonServices';
import {
  updateToastNotificationDetails,
  updateCollectionData,
} from 'redux/actions';
import { getApiFailMessage, getSelectedTimePeriod } from 'utils';
import store from 'redux/store';
import MemberSearch from '../../common/MemberSearch/MemberSearch';
import CONFIG_KTS from 'config/configProps';
import SaveCollections from './SaveCollection';

class Collections extends PureComponent {
  constructor(props) {
    super(props);
    const { collectionData } = this.props;
    this.state = {
      collectionList: this.props.collectionData,
      noCollectionData: false,
      expendedSectionId: '',
      isEditModeOn: false,
      editSectionDetails: '',
      memberList: [],
      noData: false,
      filterDto: {},
      showMore: false,
      collectionLength: !isEmpty(collectionData)
        ? collectionData.userFilterCollectionDTO.length
        : 0,
      hideShowMoreLess: false,
      isLoading: isEmpty(collectionData),
    };
    isEmpty(collectionData) && this.getCollectionList();
  }

  getCollectionList = () => {
    const apiUrl = CONFIG_KTS.API_URLS.SAVE_COLLECTION;
    const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
    this.setState({ isLoading: true });
    fetchGet(apiUrl, baseUrl)
      .then(result => {
        this.setState({
          collectionList: result,
          noCollectionData: isEmpty(result),
          collectionLength: result.userFilterCollectionDTO.length,
          isLoading: false,
        });
        store.dispatch(updateCollectionData(result));
      })
      .catch(() => {
        this.setState({ isLoading: false });
        store.dispatch(updateCollectionData({}));
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
  };

  /**
   * This method will toggle the css of open faq
   */
  addRemoveClass = id => {
    const {
      collectionList,
      expendedSectionId,
      showMore,
      collectionLength,
      hideShowMoreLess,
    } = this.state;
    const index = showMore || hideShowMoreLess ? collectionLength : 10;
    this.setState({
      expendedSectionId: isEqual(expendedSectionId, id) ? '' : id,
    });
    collectionList.userFilterCollectionDTO.forEach((el, i) => {
      if (i < index) {
        if (isEqual(id, el.id)) {
          document
            .getElementById('collection-list-' + id)
            .classList.add('expand-collections');
          let answerClassName = document.getElementById(
            'expended-' + id
          ).className;
          if (answerClassName.indexOf('show') > 0) {
            document
              .getElementById('collection-list-' + el.id)
              .classList.remove('expand-collections');
          }
        } else {
          document
            .getElementById('collection-list-' + el.id)
            .classList.remove('expand-collections');
          document.getElementById('expended-' + el.id).classList.remove('show');
        }
      }
    });
  };

  handleClick = id => {
    this.setState({ hideShowMoreLess: true }, () => {
      this.addRemoveClass(id);
      document.getElementById('expended-' + id).classList.add('show');
    });
  };

  applyCollections = collection => {
    const { collectionList, expendedSectionId } = this.state;
    let appliedCollection = collection;
    if (isEmpty(collection)) {
      appliedCollection = collectionList.userFilterCollectionDTO.filter(item =>
        isEqual(item.id, expendedSectionId)
      );
      appliedCollection = appliedCollection[0];
    }
    return this.props.applyCollections(appliedCollection);
  };

  onClickApply = () => {
    this.applyCollections();
  };

  updateApplyCollection = collection => {
    this.getCollectionList();
    this.applyCollections(collection);
  };

  editCollection = selectedCollection => {
    const filterDto = selectedCollection.userSelectedFilterDTO;
    this.getMemberList(filterDto);
    this.setState({
      isEditModeOn: true,
      editSectionDetails: selectedCollection,
      filterDto,
    });
    this.props.isEditCollection(true);
  };

  deleteCollection = collectionId => {
    const apiUrl = `${CONFIG_KTS.API_URLS.SAVE_COLLECTION}${collectionId}`;
    const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
    fetchDeleteWithId(apiUrl, baseUrl)
      .then(() => {
        store.dispatch(updateCollectionData({}));
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
    this.props.closeMemberFilter();
  };

  getMemberList = filterDto => {
    let userListUrl = CONFIG_KTS.API_URLS.KT_CARD_USERS;
    const baseUrl = CONFIG_KTS.KT_USER_BASE_URL;
    let dataToBePosted = { ...filterDto };
    const { initialDate, calenderDropdownVal, path } = this.props;
    const selectedDate = getSelectedTimePeriod(initialDate);
    dataToBePosted['month'] = selectedDate.month;
    dataToBePosted['year'] = selectedDate.year;
    dataToBePosted['timePeriod'] = calenderDropdownVal;
    dataToBePosted['path'] = isEmpty(dataToBePosted.path)
      ? path.toUpperCase()
      : dataToBePosted.path;
    fetchPost(userListUrl, dataToBePosted, baseUrl)
      .then(result => {
        let memberList = [];
        memberList = result.data;
        this.setState({
          memberList,
          noData: isEmpty(memberList),
        });
      })
      .catch(() => {
        store.dispatch(updateToastNotificationDetails(getApiFailMessage()));
      });
  };

  closeSaveCollection = () => {
    this.setState({ isEditModeOn: false });
    this.props.onBackClick();
  };

  toggleShowMore = () => {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  };

  renderMemberList = userDetails => {
    return (
      <div className="expended-collection-section">
        <div className="members">Members</div>
        <div className="collection-list-expended">
          {userDetails.map(({ employeeIdHRO, employeeName }) => {
            return (
              <div className="selected-user-pill" key={employeeIdHRO}>
                <div className="user-name">{employeeName}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderCollectionCard = collection => {
    const { id, name, userSelectedFilterDTO } = collection;
    return (
      <Card
        key={collection.id}
        className="collection-list"
        id={`collection-list-${id}`}
      >
        <Accordion.Toggle
          as={Card.Header}
          eventKey={id}
          onClick={() => this.addRemoveClass(id)}
          id={`collection-card-${id}`}
          className="collection-card"
        >
          <div className="collection-name">{name}</div>
          <div
            className="collection-icon"
            onClick={event => {
              event.nativeEvent.stopImmediatePropagation();
              this.editCollection(collection);
            }}
          >
            <img
              src="/img/collection-edit.svg"
              className="edit-icon"
              alt="edit-icon"
            />
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={id} id={`expended-${id}`}>
          {this.renderMemberList(userSelectedFilterDTO.selectedUserDetails)}
        </Accordion.Collapse>
      </Card>
    );
  };

  renderCollectionList = () => {
    const {
      noCollectionData,
      collectionList,
      collectionLength,
      hideShowMoreLess,
      isLoading,
      showMore,
    } = this.state;
    const index = showMore || hideShowMoreLess ? collectionLength : 10;

    if (isLoading) {
      return (
        <>
          {[...Array(5)].map((e, i) => (
            <LabelWithSkeleton
              isLoading={isLoading}
              showLine
              size="x-large"
              key={i}
            />
          ))}
        </>
      );
    }

    if (noCollectionData) {
      return <div className="no-data">No Collection Present</div>;
    }

    return (
      <div className="show-collection-list">
        <Accordion defaultActiveKey="0">
          {collectionList?.userFilterCollectionDTO.map((collection, i) => {
            return i < index ? this.renderCollectionCard(collection) : null;
          })}
        </Accordion>
        {collectionLength > 10 && !hideShowMoreLess && (
          <div className="show-more-less" onClick={this.toggleShowMore}>
            {showMore ? 'Show Less...' : 'Show More....'}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      collectionList,
      expendedSectionId,
      isEditModeOn,
      memberList,
      filterDto,
      editSectionDetails,
    } = this.state;
    const { closeMemberFilter } = this.props;
    return (
      <div>
        {isEditModeOn && (
          <SaveCollections
            memberList={memberList}
            selectedUsers={filterDto.selectedUserId}
            handleDeleteClick={this.handleTeamMemberClick}
            onBackClick={this.closeSaveCollection}
            filterDto={filterDto}
            fromEditCollection={true}
            editSectionDetails={editSectionDetails}
            closeMemberFilter={closeMemberFilter}
            applyFilter={this.updateApplyCollection}
            deleteCollection={this.deleteCollection}
          />
        )}
        {!isEditModeOn && (
          <div>
            {!isEmpty(collectionList?.userFilterCollectionDTO) &&
              showSearch(collectionList.userFilterCollectionDTO) && (
                <MemberSearch
                  searchList={collectionList.userFilterCollectionDTO}
                  placeholder="Search Collections"
                  isCollectionSearch={true}
                  onClick={this.handleClick}
                />
              )}
            <div className="collection-tabs">
              <div className="tab active">Created By you</div>
            </div>
            {this.renderCollectionList()}
            <div className="button-footer">
              <div className="apply-button">
                <div
                  className={`apply ${expendedSectionId ? '' : 'disabled'}`}
                  onClick={this.onClickApply}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = function (reduxStore) {
  return {
    collectionData: reduxStore.ktScorecard.collectionData,
    initialDate: reduxStore.headerFilterData.initialDate,
    calenderDropdownVal: reduxStore.headerFilterData.calenderDropdownVal,
    path: reduxStore.headerFilterData.path,
  };
};
export default connect(mapStateToProps)(withRouter(Collections));
